import React, {
  useMemo,
  useReducer,
  useRef,
  useCallback,
  useEffect,
  FC,
  MouseEvent,
  createContext,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { VariableSizeList } from 'react-window'
import { Column } from 'react-table'

import { TradeObject, PriceExtended } from '@bdswiss/mt4-connector'

import { VirtualizedTable, TableContext, VirtualizedTableProps, expandedReducer } from '../../VirtualizedTable'
import { appChangeActiveAsset, clearActiveSignal, toggleTradingFormPanel } from '../../../store/actions'
import {
  filteredSignalsSelector,
  filteredAssetsSelector,
  openPositionsSelector,
  activeAssetSelector,
  assetGroupsSelector,
  indexOfActiveAssetSelector,
} from '../../../store/selectors'
import { DailyChangeCell, SellCell, BuyCell } from './TradeTableColumns'
import TradeSubTable from './TradeSubTable'
import { FavouriteCell, AssetCell, TextCell, LeveragedLabelCell } from '../CommonCells'
import { ClosePositionDialog } from '../../ClosePositionDialog'
import { TradeMode } from '../../../enums'
import { TypeCell } from '../CommonCells/TypeCell'
import { getTradeModeByTradeAvailable } from '../../../utils'

const estimatedItemSize = 35
const estimatedOneItemSize = 32
const estimatedDefaultTrendsItemSize = 50
const estimatedButtonMoreSize = 45

export const TradeTableContext = createContext<(order: TradeObject) => void>(() => null)

export const TradeTable: FC = () => {
  const [requestedAsset, setRequestedAsset] = useState<string | null>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const assets = useSelector(filteredAssetsSelector)
  const openPositions = useSelector(openPositionsSelector)
  const signals = useSelector(filteredSignalsSelector)
  const activeAsset = useSelector(activeAssetSelector)
  const indexOfActiveAsset = useSelector(indexOfActiveAssetSelector)
  const assetGroups = useSelector(assetGroupsSelector)

  const [expandedPositionsState, expandedPositionsDispatch] = useReducer(expandedReducer, {})
  const listRef = useRef<VariableSizeList>(null)
  const [closingOrder, setClosingOrder] = useState<TradeObject | undefined>()

  const dialogOpen = useCallback(
    (order: TradeObject) => {
      setClosingOrder(order)
    },
    [setClosingOrder],
  )

  const dialogClose = useCallback(() => {
    setClosingOrder(undefined)
  }, [setClosingOrder])

  const columns = useMemo<VirtualizedTableProps<PriceExtended>['columns']>(() => {
    const columns: Array<Column<PriceExtended>> = [
      {
        Header: '',
        id: 'favorite',
        accessor: 'symbol',
        width: 50,
        Cell: FavouriteCell,
      },
      {
        Header: '',
        id: 'leveraged-label',
        width: 20,
        Cell: LeveragedLabelCell,
      },
      {
        Header: t('name').toString(),
        id: 'asset',
        accessor: 'label',
        maxWidth: 100,
        Cell: AssetCell,
      },
      {
        Header: t('description').toString(),
        accessor: 'description',
        minWidth: 300,
        Cell: TextCell,
      },
      {
        Header: t('type').toString(),
        accessor: ({ symbol }) => {
          const assetGroup = Object.values(assetGroups)
            .filter((group) => !['favourites', 'trendsAnalysis', 'popular'].includes(group.label))
            .find((group) => group.symbols.includes(symbol))
          return assetGroup?.label
        },
        maxWidth: 100,
        Cell: TypeCell,
      },
      {
        Header: t('24h change').toString(),
        id: 'daily-change',
        maxWidth: 100,
        Cell: DailyChangeCell,
      },
      {
        Header: t('sell').toString(),
        id: 'sell',
        accessor: 'mbid',
        maxWidth: 100,
        Cell: SellCell,
      },
      {
        Header: t('buy').toString(),
        id: 'buy',
        accessor: 'mask',
        maxWidth: 100,
        Cell: BuyCell,
      },
    ]

    return columns
  }, [assetGroups, t])

  const getItemSize = useCallback(
    (index: number) => {
      const asset = assets[index]
      const size = openPositions.filter((position) => position.symbol === asset.symbol).length

      switch (true) {
        case size === 0:
          return estimatedItemSize
        case size >= 1 && size <= 3:
          return size * estimatedItemSize + estimatedItemSize + estimatedOneItemSize
        default:
          return (
            (expandedPositionsState[asset.symbol] ? size : 3) * estimatedItemSize +
            estimatedDefaultTrendsItemSize +
            estimatedButtonMoreSize
          )
      }
    },
    [assets, openPositions, expandedPositionsState],
  )

  const rowClickHandle = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const { symbol } = e.currentTarget.dataset
      const [asset] = assets.filter((asset) => asset.symbol === symbol)
      const metaObj = !!asset ? getTradeModeByTradeAvailable(asset.trade) : TradeMode.disabled

      const currentAsset = signals.some((signal) => signal.symbol === asset.symbol)
      !currentAsset && dispatch(clearActiveSignal())

      if (symbol) {
        setRequestedAsset(symbol)
        !!asset
          ? dispatch(appChangeActiveAsset(symbol, { tradeMode: metaObj }))
          : dispatch(appChangeActiveAsset(symbol))
      }

      dispatch(toggleTradingFormPanel(true))
    },
    [assets, signals, dispatch],
  )
  const isRowActive = useCallback(({ symbol }: PriceExtended) => symbol === activeAsset, [activeAsset])

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.resetAfterIndex(0)
    }
  }, [assets, expandedPositionsState])

  useEffect(() => {
    if (listRef?.current && activeAsset !== requestedAsset && indexOfActiveAsset !== -1) {
      listRef.current.scrollToItem(indexOfActiveAsset, 'start')
    }
  }, [listRef?.current?.context, requestedAsset, activeAsset, indexOfActiveAsset])

  return (
    <TradeTableContext.Provider value={dialogOpen}>
      <TableContext.Provider
        value={useMemo(
          () => ({
            keyPropertyName: 'symbol',
            expandedPositionsDispatch,
            expandedPositionsState,
            listRef,
          }),
          [expandedPositionsState],
        )}
      >
        <VirtualizedTable
          className={'guided-tour-pairs-list'}
          columns={columns}
          data={assets}
          rowClickHandle={rowClickHandle}
          isRowActive={isRowActive}
          estimatedItemSize={estimatedItemSize}
          getItemSize={getItemSize}
          SubComponent={TradeSubTable}
        />
      </TableContext.Provider>
      <ClosePositionDialog order={closingOrder} onClose={dialogClose} />
    </TradeTableContext.Provider>
  )
}
