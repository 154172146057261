import React, { FC, useEffect, useState } from 'react'
import MuiDialogTitle from '@mui/material/DialogTitle'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'

import { Dialog, DialogContent } from '../../Dialog'
import { guidedTourRunSelector, showLoadingDialogSelector } from '../../../store/selectors'

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: '5px 24px 26px',
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    paddingTop: '1.5rem',
  },
  dialogText: {
    fontSize: '16px',
    color: '#757575',
  },
  container: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: 400,
      maxWidth: 450,
      borderRadius: 0,
      textAlign: 'center',
    },
  },
  button: {
    borderRadius: 0,
    textTransform: 'none',
    fontSize: 16,
  },
}))

interface DialogProps {
  handleStart: () => void
}

export const LoadingDialog: FC<DialogProps> = ({ handleStart }) => {
  const isShow = useSelector(showLoadingDialogSelector)
  const guidedTourRun = useSelector(guidedTourRunSelector)
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [showTimeOver, setShowTimeOver] = useState(false)

  useEffect(() => {
    if (!isShow && showTimeOver) {
      setOpen(false)
      setShowTimeOver(false)
      guidedTourRun && handleStart()
    }
  }, [guidedTourRun, handleStart, isShow, showTimeOver])

  useEffect(() => {
    if (!isShow) return
    setTimeout(() => {
      setShowTimeOver(true)
    }, 3000)
    setOpen(true)
  }, [isShow])

  return (
    <Dialog open={open} className={classes.container}>
      <Box marginTop={'30px'}>
        <CircularProgress />
      </Box>
      <MuiDialogTitle>
        <Typography className={classes.dialogTitle}>
          <Trans i18nKey={'guidedTourWait'} defaultValue={'Please wait'} />
        </Typography>
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box marginBottom={'20px'}>
          <Typography className={classes.dialogText}>
            {guidedTourRun ? (
              <Trans i18nKey={'guidedTourPreparing'} defaultValue={'We’re preparing your demo account...'} />
            ) : (
              <Trans i18nKey={'guidedTourExiting'} defaultValue={'Exiting a special demo account...'} />
            )}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
