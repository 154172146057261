import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { styled } from '@mui/system'
import { useTheme } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import QRCode from 'react-qr-code'
import get from 'lodash/get'

import DownloadButton from './DownloadButton'
import { isAndroid, isIos, isMobile, openUrlHandle } from '../../utils'
import { getShowDownloadMobileAppDialog, userCompanyLabelSelector } from '../../store/selectors'
import { setShowDownloadMobileAppDialog } from '../../store/actions'
import { Dialog as MuiDialog, DialogContent, DialogTitle } from '../Dialog'
import { ReactComponent as DownloadAndroidApkIcon } from './assets/downloadAndroidApk.svg'
import { ReactComponent as DownloadFromGooglePlayIcon } from './assets/downloadFromGooglePlay.svg'
import { getDownloadAppLinks, isViverno } from '../BentoMenu/utils'
import { trackEvent } from '../../analytics/firebaseLogger'
import { onQRCodeButtonClicked, onQRCodePopupPresented } from '../../analytics/events'
import { MobileAppLinksIndices } from '../../config'

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.7)',
  },
  '& .MuiDialog-paper': {
    background: theme.palette.mode === 'dark' ? '#1F1F1F' : theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: 'none',
    padding: '36px 36px 0px',
    ...(isMobile() ? { paddingTop: 20 } : {}),
  },
  '& .MuiDialogContent-root': {
    padding: '0 24px 24px',
    ...(isMobile() ? { padding: 0, margin: '12px 0px' } : {}),
  },
  '& .MuiDialogTitle-root': {
    fontSize: '18px',
    fontWeight: 400,
    padding: 24,
    ...(isMobile() ? { padding: 0, marginLeft: -17 } : {}),
  },
}))

const QRCodeWrap = styled('div')({
  display: 'flex',
  '& svg': {
    margin: '0 auto',
  },
})

const DownloadButtonsWrap = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  minHeight: 40,
  flexDirection: isMobile() ? 'column' : 'row',
  '& a:first-of-type': {
    marginBottom: 10,
  },
})

const useDialogTitleStyles = makeStyles(() => ({
  root: {
    padding: '0.8125rem 2.5rem',
  },
}))

const downloadLinks = getDownloadAppLinks()

const DownloadMobileAppDialog: FC = () => {
  const [activeSource, setActiveSource] = useState<MobileAppLinksIndices>('autoDetectStore')
  const dispatch = useDispatch()
  const showDownloadMobileDialog = useSelector(getShowDownloadMobileAppDialog)
  const userCompanyLabel = useSelector(userCompanyLabelSelector)
  const dialogTitleClasses = useDialogTitleStyles()
  const theme = useTheme()

  useEffect(() => {
    showDownloadMobileDialog &&
      trackEvent(onQRCodePopupPresented, {
        brand: userCompanyLabel,
        type: isMobile() ? 'links' : 'QR',
        source: (() => {
          switch (true) {
            case isIos():
              return 'iOS'
            case isAndroid():
              return 'Android'
            default:
              return 'Desktop'
          }
        })(),
      })
  }, [showDownloadMobileDialog, userCompanyLabel])

  const closeDialogHandle = useCallback(() => {
    dispatch(setShowDownloadMobileAppDialog(false))
  }, [dispatch])

  const clickDownloadButtonHandle = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (!isMobile()) {
        const key = e.currentTarget.dataset?.key
        key && setActiveSource(key as MobileAppLinksIndices)
      } else {
        e.preventDefault()
        openUrlHandle(e.currentTarget.getAttribute('href') as string)
      }

      trackEvent(onQRCodeButtonClicked, {
        brand: userCompanyLabel,
        type: isMobile() ? 'links' : 'QR',
        source: (() => {
          switch (true) {
            case isIos():
              return 'iOS'
            case isAndroid():
              return 'Android'
            default:
              return 'Desktop'
          }
        })(),
      })
    },
    [userCompanyLabel],
  )

  if (!get(downloadLinks, activeSource)) {
    console.warn('Link to download app are not set.')
    return null
  }

  return (
    <Dialog open={showDownloadMobileDialog}>
      <DialogTitle close={closeDialogHandle} classes={dialogTitleClasses}>
        {isMobile() ? (
          <Trans
            i18nKey={'companyMobileApplication'}
            defaults={'BDSwiss Mobile App'}
            values={{ company: userCompanyLabel }}
          />
        ) : (
          <Trans
            i18nKey={'scanToDownloadMobileApp'}
            defaults={'Scan to download {{company}} Mobile App'}
            values={{ company: userCompanyLabel }}
          />
        )}
      </DialogTitle>
      <DialogContent>
        {!isMobile() ? (
          <>
            <QRCodeWrap>
              <QRCode
                size={150}
                value={get(downloadLinks, activeSource) as MobileAppLinksIndices}
                fgColor={theme.palette.mode === 'dark' ? '#fff' : '#1F1F1F'}
                bgColor={theme.palette.mode === 'dark' ? '#1F1F1F' : '#fff'}
              />
            </QRCodeWrap>
            <DownloadButtonsWrap>
              {!isViverno() &&
                [
                  {
                    sourceKey: 'autoDetectStore',
                    translationKey: 'downloadApp',
                    defaultTranslation: 'Download App',
                  },
                  {
                    sourceKey: 'androidApkFile',
                    translationKey: 'androidApk',
                    defaultTranslation: 'Android Apk',
                  },
                ].map(({ sourceKey, translationKey, defaultTranslation }) => (
                  <DownloadButton
                    key={sourceKey}
                    active={sourceKey === activeSource}
                    translationKey={translationKey}
                    defaultTranslation={defaultTranslation}
                    data-key={sourceKey}
                    onClick={clickDownloadButtonHandle}
                  />
                ))}
            </DownloadButtonsWrap>
          </>
        ) : (
          <DownloadButtonsWrap>
            <a href={get(downloadLinks, 'googlePlayStore')} onClick={clickDownloadButtonHandle}>
              <DownloadFromGooglePlayIcon />
            </a>
            <a href={get(downloadLinks, 'androidApkFile')} onClick={clickDownloadButtonHandle}>
              <DownloadAndroidApkIcon />
            </a>
          </DownloadButtonsWrap>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default DownloadMobileAppDialog
