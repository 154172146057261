import React, { useCallback, useContext } from 'react'
import { CellProps, Renderer } from 'react-table'
import { MarketState, TradeObject } from '@bdswiss/mt4-connector'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { RootState } from '../../../store/types'
import { currentMobilePositionTabSelector, getSymbolMarketStateSelector } from '../../../store/selectors'
import { OpenPositionTableContext } from '../../Tables/OpenPositionTable'
import { PendingOrdersTableContext } from '../../Tables/PendingTable'

const CloseOrderContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))
const CloseIconStyled = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#ED1C24' : ' #FC4950',
}))
const CloseButton = styled('button')(({ theme }) => ({
  width: 28,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
  justifyContent: 'center',
  margin: '20px 16px 0px 16px',
  padding: 6,
  background: theme.palette.mode === 'light' ? '#FFEDEE' : '#390709',
  borderRadius: 6,
  '&.marketOpened': {
    color: theme.palette.grey[500],
  },
}))

export const CloseOrderButton: Renderer<CellProps<TradeObject, string>> = ({ row: { original } }) => {
  const { dialogOpen: dialogOpenPosition } = useContext(OpenPositionTableContext)
  const dialogOpenPending = useContext(PendingOrdersTableContext)
  const tab = useSelector(currentMobilePositionTabSelector)
  const marketOpened =
    useSelector((state: RootState) => getSymbolMarketStateSelector(state, original.symbol)) === MarketState.Open
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      tab === 'open' ? dialogOpenPosition(original) : dialogOpenPending(original)
    },
    [tab, dialogOpenPosition, original, dialogOpenPending],
  )

  return (
    <CloseOrderContainer>
      <CloseButton className={clsx({ marketOpened })} onClick={handleClick} disabled={!marketOpened}>
        <CloseIconStyled />
      </CloseButton>
    </CloseOrderContainer>
  )
}
