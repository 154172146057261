import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { composeWithDevTools } from '@redux-devtools/extension'

import { MT4Connector } from '@bdswiss/mt4-connector'

import { rootReducer } from './reducers'
import { getCookie, loadConfiguration, loadTranslations } from '../utils'
import { rootEpics } from './epics'
import graphqlClient from '../services/graphql'
import { ActionTypes, RootState } from './types'
import config from '../config'
import Bugsnag from '../services/bugsnag'

declare global {
  interface Window {
    Cypress: unknown
    store: unknown
    io: typeof MT4Connector.Instance
    config: typeof config
  }
}

// TODO: remove this line after the regression testing is done
window.config = config

const composeEnhancers =
  ((process.env.NODE_ENV !== 'production' || getCookie(config.get('redux.secretCookie'))) &&
    composeWithDevTools({ name: 'external' })) ||
  compose

const epicMiddleware = createEpicMiddleware<ActionTypes, ActionTypes, RootState>({
  dependencies: {
    graphqlClient,
    mt4Connector: MT4Connector.Instance,
    bugsnag: Bugsnag.Instance,
    loadConfiguration,
    loadTranslations,
    config,
  },
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)))

epicMiddleware.run(rootEpics)

if (window.Cypress) {
  window.store = store
  window.io = MT4Connector.Instance
}

export default store
