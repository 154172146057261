import { createSelector } from 'reselect'
import { escapeRegExp } from 'lodash'

import { MT4Connector } from '@bdswiss/mt4-connector'

import { RootState } from '../types'
import { assetFilterSelector } from './app'
import { assetGroupsSymbolsSelector } from './assetGroups'
import { getFilterableSignals } from '../../utils'

const mt4 = MT4Connector.Instance

export const getActiveSignal = createSelector(
  (state: RootState) => state.app.activeSignal,
  () => mt4.signals,
  (activeSignal, signals) => (activeSignal && signals[activeSignal]) || undefined,
)

export const signalsSelector = createSelector(
  () => mt4.assets,
  () => mt4.signals,
  assetGroupsSymbolsSelector,
  (assets, signals, symbols) => getFilterableSignals(assets, signals, symbols),
)

export const filteredSignalsSelector = createSelector(signalsSelector, assetFilterSelector, (signals, filter) => {
  const assets = filter
    ? signals.filter(({ symbol, direction, pattern, id, keylevel }) =>
        new RegExp(escapeRegExp(filter.replace('/', '')), 'i').test(
          [symbol, direction, pattern ? 'Chart Pattern' : 'Key Level', pattern ? pattern.name : keylevel.type, id].join(
            '|',
          ),
        ),
      )
    : signals

  return assets.sort((a, b) => (a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0))
})
