import { createReducer } from 'typesafe-actions'
import { omit } from 'lodash'

import { TradingAccount } from '@bdswiss/mt4-connector'

import { ActionTypes, OverrideParams } from '../types'
import {
  initialized,
  error,
  appChangeActiveAsset,
  appChangeTradeMode,
  appChangeTradeModeReason,
  updateAccountLogin,
  mt4Login,
  setAssetFilter,
  changeLocation,
  selectedOpenedPosition,
  selectedPendingPosition,
  clearSelectedOpenedPosition,
  clearSelectedPendingPosition,
  setActiveSignal,
  clearActiveSignal,
  balanceUpdateAction,
  updateMainCurrency,
  setTakeProfitEnabled,
  setStopLossEnabled,
  setOverrideParamsAction,
} from '../actions'
import { Location, TradeMode, TradeModeReason } from '../../enums'

export type AppState = {
  initialized: boolean
  login?: string
  loginInfo?: TradingAccount
  platform: string
  version: number
  mainCurrency: string
  activeAsset: string
  tradeMode: TradeMode
  tradeModeReason?: TradeModeReason
  error: Error | null
  assetFilter: string
  activeTab: string
  selectedOpenedPosition: string
  selectedPendingPosition: string
  activeSignal?: string
  overrideParams: OverrideParams
}

export const initialState = {
  initialized: false,
  platform: 'web',
  version: 4,
  mainCurrency: 'USD',
  activeAsset: '',
  tradeMode: TradeMode.buy,
  error: null,
  assetFilter: '',
  activeTab: window.location.pathname.split('/').pop() || Location.trade,
  selectedOpenedPosition: '',
  selectedPendingPosition: '',
  overrideParams: {},
}

export const app = createReducer<AppState, ActionTypes>(initialState)
  .handleAction(initialized, (state) => ({
    ...state,
    initialized: true,
  }))
  .handleAction(appChangeActiveAsset, (state, { payload, meta }) => ({
    ...state,
    activeAsset: payload,
    tradeMode: meta?.tradeMode || state.tradeMode,
    selectedOpenedPosition: meta?.positionId || '',
  }))
  .handleAction(appChangeTradeMode, (state, { payload, meta }) => ({
    ...state,
    tradeMode: payload,
    tradeModeReason: meta?.tradeModeReason || undefined,
  }))
  .handleAction(appChangeTradeModeReason, (state, { payload }) => ({
    ...state,
    tradeModeReason: payload,
  }))
  .handleAction(updateAccountLogin, (state, { payload, meta }) => ({
    ...state,
    login: payload,
    ...(meta?.currency && { mainCurrency: meta.currency }),
  }))
  .handleAction(mt4Login, (state, { payload }) => ({
    ...state,
    login: payload.login.toString(),
    loginInfo: omit(payload, ['trades', 'pendingOrders']),
  }))
  .handleAction(error, (state, { payload }) => {
    process.env.NODE_ENV === 'development' && console.error(payload)
    return { ...state, error: payload }
  })
  .handleAction(setAssetFilter, (state, { payload }) => ({
    ...state,
    assetFilter: payload,
  }))
  .handleAction(changeLocation, (state, { payload }) => ({
    ...state,
    activeTab: payload.replace('/', ''),
    selectedOpenedPosition: '',
  }))
  .handleAction(selectedOpenedPosition, (state, { payload, meta }) => ({
    ...state,
    selectedOpenedPosition: payload,
    ...(meta?.activeAsset ? { activeAsset: meta.activeAsset } : {}),
    ...(meta?.currentLocation ? { activeTab: meta.currentLocation } : {}),
  }))
  .handleAction(selectedPendingPosition, (state, { payload, meta }) => ({
    ...state,
    selectedPendingPosition: payload,
    ...(meta?.activeAsset ? { activeAsset: meta.activeAsset } : {}),
    ...(meta?.currentLocation ? { activeTab: meta.currentLocation } : {}),
  }))
  .handleAction(clearSelectedOpenedPosition, (state) => ({
    ...state,
    selectedOpenedPosition: initialState.selectedOpenedPosition,
  }))
  .handleAction(clearSelectedPendingPosition, (state) => ({
    ...state,
    selectedPendingPosition: initialState.selectedPendingPosition,
  }))
  .handleAction(setActiveSignal, (state, { payload }) => ({
    ...state,
    activeSignal: payload,
  }))
  .handleAction(clearActiveSignal, (state) => ({
    ...state,
    activeSignal: '',
  }))
  .handleAction([setTakeProfitEnabled, setStopLossEnabled], (state, { payload }) => ({
    ...state,
    activeSignal: payload ? '' : state.activeSignal,
  }))
  .handleAction(balanceUpdateAction, (state, { payload }) => ({
    ...state,
    loginInfo: {
      ...state.loginInfo,
      ...payload,
    },
  }))
  .handleAction(updateMainCurrency, (state, { payload }) => ({
    ...state,
    mainCurrency: payload,
  }))
  .handleAction(setOverrideParamsAction, (state, { payload }) => ({
    ...state,
    overrideParams: payload,
  }))
