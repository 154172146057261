import React, { FC, useCallback, useContext } from 'react'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

import { ThemeContext } from '../../ThemeProvider'
import { ReactComponent as SunIcon } from '../../../assets/images/icons/mobile/sun.svg'
import { ReactComponent as MoonIcon } from '../../../assets/images/icons/mobile/moon.svg'
import { BOTTOM_MENU_HEIGHT } from '../BottomMenu/BottomMenu'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme: Theme) => ({
  button: {
    padding: '6px 12px',
    gap: '6px',
    width: 'fit-content',
    height: 32,
    background: theme.palette.mode === 'light' ? '#E0EFFF' : 'rgba(0, 122, 255, 0.3);',
    borderRadius: 30,
    textTransform: 'none',
    position: 'absolute',
    left: 16,
    bottom: BOTTOM_MENU_HEIGHT,
    '&:hover': {
      background: theme.palette.mode === 'light' ? '#E0EFFF' : 'rgba(0, 122, 255, 0.3);',
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',

    color: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
  },
}))

export const LargeThemeSwitch: FC = () => {
  const { theme, setTheme } = useContext(ThemeContext)
  const classes = useStyle()
  const { t } = useTranslation()

  const handleChange = useCallback(() => {
    setTheme((oldTheme) => (oldTheme === 'Light' ? 'Dark' : 'Light'))
  }, [setTheme])

  return (
    <Button className={classes.button} onClick={handleChange}>
      {theme === 'Light' ? <MoonIcon /> : <SunIcon />}
      <Typography className={classes.title}>{theme === 'Light' ? t('darkMode') : t('lightMode')}</Typography>
    </Button>
  )
}
