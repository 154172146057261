import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

import { openPosTabSelector } from '../../../../store/selectors'
import { TitleTopPanel } from '../../TitleTopPanel'
import { OpenPositionTable } from '../../../Tables/OpenPositionTable'
import { isMobile } from '../../../../utils'

interface StyleProps {
  isMobileDevice: boolean
}

const useStyle = makeStyles<Theme, StyleProps>(() => ({
  container: {
    maxHeight: ({ isMobileDevice }) => (!isMobileDevice ? '50%' : '100%'),
    height: '100%',
  },
}))

export const OpenPositionContent: FC = () => {
  const isMobileDevice = isMobile()
  const classes = useStyle({ isMobileDevice })
  const { t } = useTranslation()
  const topPanelVisible = useSelector(openPosTabSelector)

  if (!topPanelVisible) return null
  return (
    <Grid
      data-guided-tour="openPositionsTable"
      item
      container
      className={classes.container}
      direction={'column'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
    >
      {!isMobileDevice && <TitleTopPanel title={t('openPositions')} />}
      <OpenPositionTable />
    </Grid>
  )
}
