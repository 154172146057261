import React, { JSX, useCallback, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { useTable, useFlexLayout, HeaderGroup } from 'react-table'
import { VariableSizeList } from 'react-window'
import InnerElementType from './InnerElementType'
import { TableContext } from './TableContext'

import { VirtualizedTableProps } from './types'
import VariableSizeListRow from './VariableSizeListRow'
import { scrollbarWidth } from './scrollbarWidth'
import { getClosedPositionsRequest } from '../../store/actions'
import { activeTabSelector } from '../../store/selectors'
import { Location } from '../../enums'
import { Theme } from '@mui/material'
import { isMobile } from '../../utils'

export interface StyleProps {
  showBorders?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  table: {
    width: 'calc(100vw - 80px)',
  },
  head: {
    background: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    left: 0,
    height: 26,
    zIndex: 3,
    justifyContent: 'stretch',
  },
  headItem: {
    display: 'flex',
    color: '#999',
    fontSize: isMobile() ? '.625rem' : '.7857rem',
  },
  rows: {
    cursor: 'pointer',
    borderBottom: ({ showBorders }) => {
      return showBorders ? `1px solid ${theme.palette.action.selected}` : ''
    },
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    alignContent: 'flex-start',
    color: theme.palette.text.secondary,
    ...(isMobile() && { fontFamily: 'Roboto Condensed' }),
  },
  active: {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.action.hover,
    boxShadow: 'inset 2px 0 0 0 #007bff',
  },
  cellsWrap: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    fontSize: '0.875rem',
    ...(isMobile() ? { padding: '8px 0' } : {}),
  },
}))

const defaultColumn = {
  width: 150,
}
// eslint-disable-next-line @typescript-eslint/ban-types
const List = <T extends object = {}>({
  data,
  columns,
  height,
  width,
  estimatedItemSize,
  getItemSize,
  className,
  plugins = [],
  SubComponent,
  isRowActive,
  rowClickHandle,
  showHeaders = true,
  showBorders = true,
}: VirtualizedTableProps<T> & { width: number; height: number }): JSX.Element => {
  const classes = useStyles({ showBorders })
  const dispatch = useDispatch()
  const tab = useSelector(activeTabSelector)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, totalColumnsWidth } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFlexLayout,
    ...plugins,
  )

  const itemData = useMemo(
    () => ({
      rows,
      prepareRow,
      classes,
      isRowActive,
      width: totalColumnsWidth > width ? totalColumnsWidth : width - scrollbarWidth(),
      rowClickHandle,
      SubComponent,
      showHeaders,
    }),
    [width, prepareRow, rows, classes, isRowActive, totalColumnsWidth, rowClickHandle, SubComponent, showHeaders],
  )

  const { listRef } = useContext(TableContext)

  const onScroll = useCallback(
    ({ scrollDirection, scrollOffset }) => {
      const estimatedItemSize = listRef?.current?.props.estimatedItemSize
      const height = Number(listRef?.current?.props.height)
      if (tab !== Location.history || !estimatedItemSize || scrollDirection !== 'forward') return
      const scrollItems = scrollOffset / estimatedItemSize
      const itemsOnScreen = height / estimatedItemSize
      const loadedEvents = rows.length
      if (scrollItems + itemsOnScreen > loadedEvents) {
        dispatch(getClosedPositionsRequest({ limit: 20, offset: loadedEvents }))
      }
    },
    [dispatch, listRef, rows.length, tab],
  )

  return (
    <div {...getTableProps()}>
      <div {...getTableBodyProps()}>
        <VariableSizeList
          width={width}
          height={height}
          estimatedItemSize={estimatedItemSize}
          itemSize={getItemSize}
          itemCount={rows.length}
          itemData={itemData}
          className={className}
          onScroll={onScroll}
          innerElementType={
            showHeaders
              ? InnerElementType(
                  headerGroups as unknown as HeaderGroup<{}>[], // eslint-disable-line @typescript-eslint/ban-types
                  classes,
                  totalColumnsWidth,
                  width,
                  scrollbarWidth(),
                )
              : undefined
          }
          ref={useCallback(
            (ref) => {
              listRef && (listRef.current = ref)
            },
            [listRef],
          )}
        >
          {VariableSizeListRow}
        </VariableSizeList>
      </div>
    </div>
  )
}

export default List
