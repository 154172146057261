import React, { FC } from 'react'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

const useStyle = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    marginLeft: '8px',
    height: 56,
    background: 'rgba(0, 0, 0, 0)',
    textTransform: 'none',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginLeft: '12px',
    color: theme.palette.mode === 'light' ? '#858585' : '#999999',
  },
  icon: {
    color: theme.palette.mode === 'light' ? '#858585' : '#999999',
    width: '26px',
  },
}))

interface Props {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  title: string
  callback: () => void
}

export const IconButton: FC<Props> = ({ Icon, title, callback }) => {
  const classes = useStyle()

  return (
    <Button className={classes.button} onClick={callback}>
      {<Icon className={classes.icon} />}
      <Typography className={classes.title}>{title}</Typography>
    </Button>
  )
}
