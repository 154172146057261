import React, { JSX, FC, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CssBaseline, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { isMobile } from './utils'
import { ROOT_PATH } from './utils/constants'
import { SideMenuBar } from './components/MenuBar'
import { Spinner } from './components/Spinner'
import { PageContent } from './components/pages'
import { TopMenu } from './components/TopMenu'
import { ThemeSwitch } from './components/ThemeSwitch'
import { appInitializedSelector } from './store/selectors'
import { trackEvent } from './analytics/firebaseLogger'
import { traderOpened } from './analytics/events'
import { GuidedTour } from './components/GuidedTour'
import { BottomMenu } from './components/mobile/BottomMenu/BottomMenu'
import { CloseToastButton } from './components/CloseToastButton'
import { DownloadAppBanner } from './components/mobile/DownloadAppBanner'
import { Feed } from './components/Feed'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: 'calc(100vh - 66px)',
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 66px)',
    },
  },
  centralArea: {
    flexGrow: 4,
  },
  mobileWrap: {
    height: 'calc(100vh - 120px)',
    // width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    '-webkit-overflow-scrolling': 'touch',
  },
  mobileMain: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mobileBottomMenu: {
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    zIndex: '2',
    background: theme.palette.background.default,
  },
  app: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  trade: {
    width: '100%',
    borderRight: `1px solid ${theme.palette.action.selected}`,
  },
  [theme.breakpoints.up('sm')]: {
    '@global': {
      ':root': {
        overflow: 'hidden',
      },
      '#root': {
        transform: 'scale(.8)',
        transformOrigin: 'top left',
        width: '125%',
        height: '125%',
      },
    },
  },
}))

export const App: FC = (): JSX.Element => {
  const classes = useStyles()
  const { palette } = useTheme()
  const initialized = useSelector(appInitializedSelector)
  const isMobileDevice: boolean = isMobile()

  useEffect(() => {
    initialized && trackEvent(traderOpened)
  }, [initialized])

  const handleContextMenu = (e: React.MouseEvent) => {
    if (process.env.NODE_ENV !== 'development') {
      e.preventDefault()
    }
  }

  return (
    <>
      <CssBaseline />
      <div className={classes.app} onContextMenu={handleContextMenu}>
        {!initialized ? (
          <Spinner />
        ) : (
          <Route path={ROOT_PATH}>
            <div className={classes.trade}>
              <GuidedTour />
              {isMobileDevice && <DownloadAppBanner />}
              <TopMenu />
              <div className={!isMobileDevice ? classes.mainContainer : classes.mobileWrap}>
                {!isMobileDevice && (
                  <div>
                    <SideMenuBar bottomActions={<ThemeSwitch />} />
                  </div>
                )}
                <div className={!isMobileDevice ? classes.centralArea : classes.mobileMain}>
                  <PageContent />
                </div>
                {isMobileDevice && (
                  <div className={classes.mobileBottomMenu}>
                    <BottomMenu />
                  </div>
                )}
              </div>
            </div>
            <Feed />
          </Route>
        )}
        <ToastContainer
          theme={palette.mode === 'light' ? 'light' : 'dark'}
          icon={false}
          closeButton={CloseToastButton}
        />
      </div>
    </>
  )
}
