import { createSelector } from 'reselect'

import { MarketState, MT4Connector } from '@bdswiss/mt4-connector'

import { Prices, RootState } from '../types'
import { Trade } from '../../services/graphql/types'
import { activeAssetSelector, activeTabSelector } from './app'
import { Location } from '../../enums'
import config from '../../config'

const mt4 = MT4Connector.getInstance({
  bufferTimeSpanForTickEvent: Number(config.get('mt4Connector.bufferTimeSpanForTickEvent')) || 200,
})

const pricesSelector = (): Prices => mt4.assets

export const openPositionsSelector = createSelector(
  () => mt4.positions,
  (positions) => {
    return positions
      .valueSeq()
      .toArray()
      .sort((a, b) => new Date(b.openTime).getTime() - new Date(a.openTime).getTime())
  },
)

export const getOpenPositionsMarketStateSelector = (): Record<string, MarketState> => {
  const allSymbols = mt4.positions.reduce((accumulator, value) => accumulator.add(value.symbol), new Set<string>())
  return Array.from(allSymbols).reduce(
    (accumulator, symbol) => {
      accumulator[symbol] = mt4.assets[symbol] ? mt4.assets[symbol].marketState : MarketState.Closed
      return accumulator
    },
    {} as Record<string, MarketState>,
  )
}

export const marketOpenedForExistingPositionsSelector = createSelector(
  getOpenPositionsMarketStateSelector,
  (marketState) => {
    return Object.values(marketState).reduce((accumulator, state) => accumulator && state === MarketState.Open, true)
  },
)

export const pendingPositionsSelector = createSelector(
  () => mt4.pendingOrders,
  (positions) =>
    Array.from(positions.values()).sort((a, b) => new Date(b.openTime).getTime() - new Date(a.openTime).getTime()),
)

export const closedPositionsSelector = createSelector(
  (state: RootState) => state.positions.closed.data,
  (positions): Trade[] =>
    Object.values(positions).sort((a, b) => new Date(b.closeTime).getTime() - new Date(a.closeTime).getTime()),
)

export const openPositionsLengthSelector = createSelector(openPositionsSelector, (positions) => positions.length)
export const pendingPositionsLengthSelector = createSelector(pendingPositionsSelector, (positions) => positions.length)
export const closedPositionsLengthSelector = createSelector(closedPositionsSelector, (positions) => positions.length)

export const currentPriceForOrder = createSelector(
  (state: RootState) => state.app.activeTab,
  (state: RootState) => state.app.selectedOpenedPosition,
  (state: RootState) => state.app.selectedPendingPosition,
  () => mt4.positions,
  () => mt4.pendingOrders,
  pricesSelector,
  (tab, openId, pendingId, openPositions, pendingPositions, prices) => {
    const orderOpen = openPositions.get(+openId)
    const orderPending = pendingPositions.get(+pendingId)
    if ((tab === Location.positions || tab === Location.trade) && orderOpen) return prices[orderOpen.symbol]
    if (tab === Location.order && orderPending) return prices[orderPending.symbol]
    return null
  },
)

export const selectedPositionSelector = createSelector(
  activeTabSelector,
  (state: RootState) => state.app.selectedOpenedPosition,
  (state: RootState) => state.app.selectedPendingPosition,
  () => mt4.positions,
  () => mt4.pendingOrders,
  (activeTab, selectedOpenedPosition, selectedPendingPosition, openPositions, pendingPositions) => {
    switch (activeTab) {
      case Location.trade:
        return openPositions.get(+selectedOpenedPosition)
      case Location.positions:
        return openPositions.get(+selectedOpenedPosition)
      case Location.order:
        return pendingPositions.get(+selectedPendingPosition)
      default:
        return undefined
    }
  },
)

export const currentSymbolOpenPositions = createSelector(
  activeTabSelector,
  activeAssetSelector,
  openPositionsSelector,
  (tab, symbol, positions) =>
    tab === Location.positions ? positions.filter((position) => position.symbol === symbol) : [],
)

export const currentSymbolPendingPositions = createSelector(
  activeTabSelector,
  activeAssetSelector,
  pendingPositionsSelector,
  (tab, symbol, positions) =>
    tab === Location.order ? positions.filter((position) => position.symbol === symbol) : [],
)
