import { createReducer } from 'typesafe-actions'

import { ActionTypes, AssetGroups } from '../types'
import {
  mt4AssetGroups,
  getFavouritesSuccess,
  updateFavouritesSuccess,
  signalsUpdatedAction,
  changeActiveAssetGroup,
  setAssetFilter,
} from '../actions'
import { get } from 'lodash'

export type AssetGroupsState = {
  activeGroup: string
  data: AssetGroups
}

export const assetGroups = createReducer<AssetGroupsState, ActionTypes>({
  activeGroup: 'favourites',
  data: {},
})
  .handleAction(mt4AssetGroups, (state, { payload }) => ({
    ...state,
    data: {
      ...Object.assign(
        {
          all: { label: 'all', symbols: [] },
          trendsAnalysis: { label: 'trendsAnalysis', symbols: [] },
          favourites: { label: 'favourites', symbols: get(state.data['favourites'], 'symbols', []) },
        },
        ...payload.map((position) => ({ [position.label]: position })),
      ),
    },
  }))
  .handleAction([getFavouritesSuccess, updateFavouritesSuccess], (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      favourites: {
        ...state.data.favourites,
        label: 'favourites',
        symbols: payload,
      },
    },
  }))
  .handleAction(signalsUpdatedAction, (state, { payload: symbols }) => ({
    ...state,
    data: {
      ...state.data,
      trendsAnalysis: {
        ...state.data.trendsAnalysis,
        symbols,
      },
    },
  }))
  .handleAction(changeActiveAssetGroup, (state, { payload }) => ({
    ...state,
    activeGroup: payload,
  }))
  .handleAction(setAssetFilter, (state) => ({
    ...state,
    activeGroup: state.activeGroup === 'trendsAnalysis' ? state.activeGroup : 'all',
  }))
