import React, { FC, ReactNode } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import Chart, { ChartPanel } from '../Chart'
import { isMobile } from '../../utils'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    flexWrap: 'nowrap',
    display: 'flex',
  },
  leftPanel: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: isMobile() ? '100%' : 'auto',
  },
}))

type Props = {
  mainContent: ReactNode
  tradePanel: ReactNode
}

export const PageLayout: FC<Props> = ({ mainContent, tradePanel }) => {
  const classes = useStyles()
  const shouldShowChartPanel = !isMobile()
  return (
    <div className={classes.container}>
      <div className={classes.leftPanel}>
        {mainContent}
        {shouldShowChartPanel && (
          <ChartPanel>
            <Chart />
          </ChartPanel>
        )}
      </div>
      {tradePanel}
    </div>
  )
}
