import { RootEpic } from '../types'
import { filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { openLoadingDialog, runGuidedTour, intentOpenGuidedTour } from '../actions'
import { of } from 'rxjs'

export const intentStartTourEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(intentOpenGuidedTour)),
    switchMap(() => of(openLoadingDialog(), runGuidedTour(true))),
  )

export default [intentStartTourEpic]
