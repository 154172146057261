import { createReducer } from 'typesafe-actions'
import { Account, ActionTypes } from '../types'
import { getUserRequest, getUserSuccess, getUserFailure } from '../actions'
import { LoadingState } from '../../enums'
import { guidedTourServiceAccount } from '../../components/GuidedTour/config'

export type AccountState = {
  loading: LoadingState
  data: Account[]
  error: Error | null
}

export const initialState = { loading: LoadingState.IDLE, data: [], error: null }

export const account = createReducer<AccountState, ActionTypes>(initialState)
  .handleAction(getUserRequest, (state) => ({ ...state, loading: LoadingState.LOADING }))
  .handleAction(getUserSuccess, (state, { payload }) => ({
    ...state,
    data: [...payload?.accounts, guidedTourServiceAccount],
    loading: LoadingState.SUCCESS,
  }))
  .handleAction(getUserFailure, (state, { payload }) => ({ ...state, error: payload, loading: LoadingState.ERROR }))
