import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import List from '@mui/material/List'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'
import get from 'lodash/get'

import { currentAccountSelector, formOrderTypeSelector } from '../../../../store/selectors'
import { setFormOrderStatus } from '../../../../store/actions'
import { OrderTypes } from '../../../../enums'

const useStyles = makeStyles((theme) => ({
  orderTypesList: {
    display: 'flex',
    padding: theme.spacing(0.4),
    margin: theme.spacing(2.4, 0, 0.1),
    backgroundColor: theme.palette.mode === 'dark' ? '#1F1F1F' : theme.palette.grey[100],
    borderRadius: 6,
  },
  orderTypesListItem: {
    height: 28,
    justifyContent: 'center',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    borderRadius: 4,
    padding: theme.spacing(0.4, 1),
    '&:hover': { background: theme.palette.mode === 'dark' ? '#1F1F1F' : theme.palette.grey[100] },
    '&.Mui-selected': {
      backgroundColor: theme.palette.mode === 'dark' ? '#474747' : theme.palette.background.paper,
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: '#0000000A',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#474747' : theme.palette.background.paper,
      },
    },
  },
}))

export const TypeOrderSelect: FC = () => {
  const dispatch = useDispatch()
  const formOrderType = useSelector(formOrderTypeSelector)
  const currentAccount = useSelector(currentAccountSelector)

  const classes = useStyles()
  const [orderType, setOrderType] = useState(formOrderType)

  const handleChange: ListItemButtonProps['onClick'] = useCallback(
    (event) => {
      const value = get(event, 'target.dataset.value', OrderTypes.marketOrder)
      const changedOrderType = OrderTypes[value as keyof typeof OrderTypes]
      setOrderType(changedOrderType)
      dispatch(setFormOrderStatus(changedOrderType))
    },
    [dispatch],
  )

  return (
    <List component="nav" className={classes.orderTypesList}>
      {Object.keys(OrderTypes)
        .filter((order) => {
          // TODO: Remove line below to set visible stop order and stop limit order
          if (order === OrderTypes.stopOrder || order === OrderTypes.stopLimitOrder) return false
          if (currentAccount?.__typename?.includes('MT5')) return true
          return order !== OrderTypes.stopLimitOrder
        })
        .map((order, index) => (
          <ListItemButton
            key={index}
            data-value={order}
            onClick={handleChange}
            className={classes.orderTypesListItem}
            selected={order === orderType}
          >
            <Trans i18nKey={order} />
          </ListItemButton>
        ))}
    </List>
  )
}
