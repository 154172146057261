import React from 'react'
import { CellProps, Renderer } from 'react-table'
import { useSelector } from 'react-redux'

import { TradeObject } from '@bdswiss/mt4-connector'

import { pricesSelector } from '../../../store/selectors'
import { currencyVolumeFormat } from '../../../utils'

export const VolumeCell: Renderer<CellProps<TradeObject, TradeObject['volume']>> = ({
  row: {
    original: { volume, symbol },
  },
}) => {
  const asset = useSelector(pricesSelector)
  const value = currencyVolumeFormat(volume * asset[symbol]?.lotSize, asset[symbol]?.contractType)
  return <div title={value}>{value}</div>
}
