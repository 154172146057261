import { createReducer } from 'typesafe-actions'
import { isEmpty } from 'lodash'

import { ActionTypes } from '../types'
import {
  toggleTradePanel,
  toggleOpenPosPanel,
  togglePendingPanel,
  toggleHistoryPanel,
  toggleTradingFormPanel,
  changeLocation,
  signalsRiskDisclaimer,
  appChangeActiveAsset,
  selectedOpenedPosition,
  selectedPendingPosition,
  clearSelectedOpenedPosition,
  clearSelectedPendingPosition,
  setMobileBackButtonData,
  setShowDownloadMobileAppDialog,
  setShowFeedNewSectionTooltip,
  toggleFullscreen,
  setFullscreenElement,
  currentMobilePositionTab,
  downloadAppBannerAction,
} from '../actions'
import { Location } from '../../enums'
import { FullscreenableElement } from '../../components/mobile/ToggleFullscreenButton'
import { getCookie, isMobile } from '../../utils'

export type MobileBackButtonData =
  | {
      show: boolean
      title?: string
    }
  | undefined

export type uiState = {
  tradeTab: boolean
  openPosTab: boolean
  pendingTab: boolean
  historyTab: boolean
  tradingFormPanel: boolean
  signalsRiskDisclaimer: boolean
  modifyPanel: {
    trade: boolean
    open: boolean
    pending: boolean
  }
  balancesSectionUpdateFlag: boolean
  mobileBackButtonData: MobileBackButtonData
  showDownloadMobileAppDialog: boolean
  showFeedNewSectionTooltip: boolean
  isFullscreen: boolean
  fullscreenElement?: FullscreenableElement
  currentMobilePositionTab: string
  downloadAppBanner: boolean
}

export const initialState: uiState = {
  tradeTab: true,
  openPosTab: true,
  pendingTab: true,
  historyTab: true,
  tradingFormPanel: !isMobile(),
  signalsRiskDisclaimer: false,
  modifyPanel: {
    trade: false,
    open: false,
    pending: false,
  },
  balancesSectionUpdateFlag: false,
  mobileBackButtonData: undefined,
  showDownloadMobileAppDialog: false,
  showFeedNewSectionTooltip: !localStorage.getItem('hideFeedNewSectionTooltip'),
  isFullscreen: false,
  currentMobilePositionTab: 'open',
  downloadAppBanner: !isEmpty(getCookie('download_app_banner')) ? getCookie('download_app_banner') === 'true' : true,
}

export const uiReducer = createReducer<uiState, ActionTypes>(initialState)
  .handleAction(changeLocation, (state, { payload }) => ({
    ...state,
    tradeTab: true,
    openPosTab: true,
    pendingTab: true,
    historyTab: true,
    tradingFormPanel: payload.slice(1) === Location.trade && initialState.tradingFormPanel,
    modifyPanel: {
      trade: payload.slice(1) === Location.trade && false,
      open: !isMobile() ? state.modifyPanel.open : false,
      pending: !isMobile() ? state.modifyPanel.pending : false,
    },
  }))
  .handleAction(toggleTradePanel, (state) => ({
    ...state,
    tradeTab: !state.tradeTab,
  }))
  .handleAction(toggleOpenPosPanel, (state) => ({
    ...state,
    openPosTab: !state.openPosTab,
  }))
  .handleAction(togglePendingPanel, (state) => ({
    ...state,
    pendingTab: !state.pendingTab,
  }))
  .handleAction(toggleHistoryPanel, (state) => ({
    ...state,
    historyTab: !state.historyTab,
  }))
  .handleAction(toggleTradingFormPanel, (state, { payload }) => ({
    ...state,
    tradingFormPanel: payload,
    modifyPanel: {
      ...state.modifyPanel,
      trade: false,
    },
  }))
  .handleAction(signalsRiskDisclaimer, (state, { payload: signalsRiskDisclaimer }) => ({
    ...state,
    signalsRiskDisclaimer,
  }))
  .handleAction(appChangeActiveAsset, (state, { meta }) => ({
    ...state,
    tradingFormPanel: !meta?.positionId && !isMobile() && !state.modifyPanel.trade,
    modifyPanel: {
      ...state.modifyPanel,
      trade: false,
    },
  }))
  .handleAction([selectedOpenedPosition, selectedPendingPosition], (state, { meta }) => ({
    ...state,
    modifyPanel: {
      ...state.modifyPanel,
      ...(meta?.currentLocation === Location.trade && {
        trade: true,
        open: true,
      }),
      ...(meta?.currentLocation === Location.positions && {
        open: true,
      }),
      ...(meta?.currentLocation === Location.order && {
        pending: true,
      }),
    },
    tradingFormPanel: meta?.currentLocation !== Location.trade,
  }))
  .handleAction([clearSelectedOpenedPosition, clearSelectedPendingPosition], (state, { meta }) => ({
    ...state,
    modifyPanel: {
      ...state.modifyPanel,
      ...(meta?.currentLocation === Location.trade && {
        trade: false,
        open: false,
      }),
      ...(meta?.currentLocation === Location.positions && {
        open: false,
      }),
      ...(meta?.currentLocation === Location.order && {
        pending: false,
      }),
    },
  }))
  .handleAction(setMobileBackButtonData, (state, { payload }) => ({
    ...state,
    mobileBackButtonData: payload,
  }))
  .handleAction(setShowDownloadMobileAppDialog, (state, { payload }) => ({
    ...state,
    showDownloadMobileAppDialog: payload,
  }))
  .handleAction(setShowFeedNewSectionTooltip, (state, { payload }) => ({
    ...state,
    showFeedNewSectionTooltip: payload,
  }))
  .handleAction(toggleFullscreen, (state) => {
    return {
      ...state,
      isFullscreen: !state.isFullscreen,
    }
  })
  .handleAction(setFullscreenElement, (state, { payload }) => ({
    ...state,
    fullscreenElement: payload,
  }))
  .handleAction(currentMobilePositionTab, (state, { payload }) => ({
    ...state,
    currentMobilePositionTab: payload,
  }))
  .handleAction(downloadAppBannerAction, (state) => ({
    ...state,
    downloadAppBanner: false,
  }))
