import React, { FC } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import HelpTooltip from '../../../TopMenu/HelpTooltip/HelpTooltip'
import { Trans } from 'react-i18next'

interface Props {
  color: 'green' | 'red' | 'neutral'
  title: string
  value: string
  hintKey: string
}

const useStyles = makeStyles((theme) => ({
  title: {
    left: 8,
    marginRight: '6px',
    color: theme.palette.mode === 'light' ? '#858585' : '#999999',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    margin: '5px 0px',
  },
  heavyText: {
    fontWeight: '500',
  },
  greenValue: {
    color: theme.palette.mode === 'light' ? '#009469' : '#00C78D',
  },
  redValue: {
    color: theme.palette.mode === 'light' ? '#ED1C24' : '#FC4950',
  },
  neutralValue: {
    color: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emptySpace: {
    flexGrow: 1000,
  },
}))

export const AccountInfoItem: FC<Props> = ({ color, title, value, hintKey }) => {
  const classes = useStyles()

  const colorClassName = {
    green: classes.greenValue,
    red: classes.redValue,
    neutral: classes.neutralValue,
  }[color]

  return (
    <div className={classes.container}>
      <Typography className={[classes.title, classes.text].join(' ')}>{title}</Typography>
      <HelpTooltip hint={<Trans i18nKey={hintKey} />} />
      <span className={classes.emptySpace}></span>
      <Typography className={[colorClassName, classes.text, classes.heavyText].join(' ')}>{value}</Typography>
    </div>
  )
}
