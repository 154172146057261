import { RootState } from '../types'
import { createSelector } from 'reselect'

import { FeedPanel } from '../../enums'

export const activeFeedPanelSelector = (state: RootState): FeedPanel | null => state.feed.activePanel
export const panelsSelector = createSelector(
  (state: RootState) => state.feed.panels,
  (panels) => Object.keys(panels),
)

export const tradingIdeasStatusSelector = (state: RootState): string => state.feed.panels.tradingIdeas.status
export const tradingIdeasDataSelector = createSelector(
  (state: RootState) => state.feed.panels.tradingIdeas.data,
  (items) => Object.values(items).reverse(),
)
