import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import clsx from 'clsx'

import { currentSymbolPriceSelector, tradeModeSelector } from '../../../../store/selectors'
import { TradeMode } from '../../../../enums'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1.2rem',
    },
  },
  value: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.4,
    paddingRight: 5,
  },
  positive: { color: '#00AD7B' },
  negative: { color: '#FC4950' },
}))

const DailyChange: FC = () => {
  const currentPrice = useSelector(currentSymbolPriceSelector)
  const tradeMode = useSelector(tradeModeSelector)
  const classes = useStyles()

  if (!currentPrice) {
    return null
  }

  const value = tradeMode === TradeMode.buy ? currentPrice.dailyReturnBid || 0 : currentPrice.dailyReturnAsk || 0

  return (
    <div className={classes.container}>
      <Typography
        variant={'body2'}
        className={clsx(classes.value, {
          [classes.positive]: value > 0,
          [classes.negative]: value < 0,
        })}
      >
        {value > 0 && '+'}
        {value.toFixed(2)}%
      </Typography>
      {value > 0 && <ArrowUpwardIcon className={classes.positive} fontSize={'small'} />}
      {value < 0 && <ArrowDownwardIcon className={classes.negative} fontSize={'small'} />}
    </div>
  )
}

export default DailyChange
