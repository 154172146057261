import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'

import HelpTooltip from '../../../TopMenu/HelpTooltip/HelpTooltip'

const useStyles = makeStyles(({ palette }) => ({
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 4,
    textAlign: 'center',
    fontSize: 11,
    lineHeight: 1.25,
    color: palette.mode === 'light' ? '#474747' : '#EBEBEB',
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    marginTop: -2,
  },
}))

type IProps = {
  name: string
  unit?: string
  tooltipText?: string
}

export const NumberInputLabel: FC<IProps> = ({ name, unit, tooltipText }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <p className={classes.label}>
      <Trans i18nKey={name} />
      {unit && `, ${t(unit)}`}
      {tooltipText && (
        <span className={classes.tooltip}>
          <HelpTooltip hint={<Trans i18nKey={tooltipText} />} />
        </span>
      )}
    </p>
  )
}
