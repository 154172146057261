import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import MuiIconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import Fade from '@mui/material/Fade'

import { ReactComponent as TCIcon } from '../../assets/images/icons/news-paper.svg'
import { ReactComponent as ECIcon } from '../../assets/images/icons/Union.svg'
import { ReactComponent as Reload } from '../../assets/images/icons/reloadFeeds.svg'
import { getTIItemsRequest, setActivePanelAction } from '../../store/actions'
import { FeedPanel } from '../../enums'
import { EconomicCalendar } from './EconomicCalendar'
import { TradingIdeas } from './TradingIdeas'
import { tradingIdeasStatusSelector } from '../../store/selectors'

const FeedMainContainer = styled('div')(({ theme }) => ({
  width: 240,
  height: '100vh',
  borderRight: `1px solid ${theme.palette.action.selected}`,
  [theme.breakpoints.up('sm')]: {
    height: '100%',
  },
}))

const FeedContentContainer = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 66px)',
  overflow: 'scroll',
  ['::-webkit-scrollbar']: {
    width: 0,
    height: 0,
  },
  [theme.breakpoints.up('sm')]: {
    height: 'calc(100% - 66px)',
  },
}))

const FeedMainHeader = styled('div')(({ theme }) => ({
  width: '100%',
  minWidth: 240,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  height: 66,
  borderBottom: `1px solid ${theme.palette.action.selected}`,
  ['& > svg']: {
    width: 40,
    height: 40,
    margin: 8,
  },
}))

const FeedMainHeaderText = styled('div')(({ theme }) => ({
  width: '100%',
  fontSize: 16,
  lineHeight: theme.spacing(3),
  letterSpacing: '0.15px',
}))

const ReloadButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#858585' : '#999999',
  padding: 6,
  '& svg': {
    fontSize: 16,
  },
}))

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#858585' : '#999999',
  padding: 6,
  marginRight: 12,
  '& svg': {
    fontSize: 16,
  },
}))

interface IFeedMainProps {
  activePanel: FeedPanel
}

export const FeedMain: FC<IFeedMainProps> = ({ activePanel }) => {
  const dispatch = useDispatch()
  const itemsStatus = useSelector(tradingIdeasStatusSelector)

  const handleReloadFeeds = useCallback(() => {
    dispatch(getTIItemsRequest())
  }, [dispatch])

  const handleDrawerClose = useCallback(() => {
    dispatch(setActivePanelAction(null))
  }, [dispatch])

  useEffect(() => {
    if (activePanel === FeedPanel.tradingIdeas) {
      dispatch(getTIItemsRequest())
    }
  }, [activePanel, dispatch])

  return (
    <Fade in={true}>
      <FeedMainContainer>
        <FeedMainHeader>
          {activePanel === FeedPanel.tradingIdeas ? <TCIcon /> : <ECIcon />}
          <FeedMainHeaderText>
            <Trans i18nKey={activePanel} />
          </FeedMainHeaderText>
          {activePanel === FeedPanel.tradingIdeas && (
            <ReloadButton disabled={itemsStatus === 'Fetching'} onClick={handleReloadFeeds}>
              <Reload />
            </ReloadButton>
          )}
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </FeedMainHeader>
        <FeedContentContainer>
          {activePanel === FeedPanel.tradingIdeas ? <TradingIdeas /> : <EconomicCalendar />}
        </FeedContentContainer>
      </FeedMainContainer>
    </Fade>
  )
}
