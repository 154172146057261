import { createAction, createCustomAction } from 'typesafe-actions'
import { toast, ToastOptions } from 'react-toastify'
import { GraphQLError } from 'graphql'

import { TradingAccount } from '@bdswiss/mt4-connector'

import { TradeMode, TradeModeReason } from '../../enums'
import { CandlesData } from '../../components/Chart/types'
import { OverrideParams } from '../types'

export const initialize = createAction('@@app/initialize')<void>()
export const initialized = createAction('@@app/initialized')<void>()
export const error = createAction('@@app/error')<GraphQLError>()
export const changeLocation = createAction('@@app/changeLocation')<string, { prev?: string }>()
export const changeActiveAssetGroup = createAction('@@app/changeActiveAssetGroups')<string>()
export const updateAccountLogin = createCustomAction(
  '@@app/updateAccountLogin',
  (payload: string, meta?: { currency: string }) => ({
    payload,
    meta,
  }),
)

export const updateMainCurrency = createAction('@@app/updateMainCurrency')<string>()

export const setAssetFilter = createAction('@@app/setAssetFilter')<string>()

export const appChangeActiveAsset = createCustomAction(
  '@@app/changeActiveAsset',
  (payload: string, meta?: { tradeMode: TradeMode; positionId?: string }) => {
    localStorage.setItem('activeAsset', payload)
    return {
      payload,
      meta,
    }
  },
)

export const appChangeTradeMode = createCustomAction(
  '@@app/changeTradeMode',
  (payload: TradeMode, meta?: { tradeModeReason: TradeModeReason }) => ({
    payload,
    meta,
  }),
)

export const appChangeTradeModeReason = createAction('@@app/changeTradeModeReason')<TradeModeReason>()

export const notify = createCustomAction('@@app/notify', (payload: string, meta?: Partial<ToastOptions>) => {
  toast(payload, meta)
  return {
    payload,
    meta,
  }
})

export const selectedOpenedPosition = createCustomAction(
  '@@app/selectedOpenedPosition',
  (payload: string, meta?: { activeAsset: string; currentLocation?: string }) => {
    meta?.activeAsset && localStorage.setItem('activeAsset', meta.activeAsset)
    return {
      payload,
      meta,
    }
  },
)
export const selectedPendingPosition = createCustomAction(
  '@@app/selectedPendingPosition',
  (payload: string, meta?: { activeAsset: string; currentLocation?: string }) => {
    meta?.activeAsset && localStorage.setItem('activeAsset', meta.activeAsset)
    return {
      payload,
      meta,
    }
  },
)
export const clearSelectedOpenedPosition = createCustomAction(
  '@@app/clearSelectedOpenedPosition',
  (meta?: { currentLocation: string }) => ({
    meta,
  }),
)
export const clearSelectedPendingPosition = createCustomAction(
  '@@app/clearSelectedPendingPosition',
  (meta?: { currentLocation: string }) => ({
    meta,
  }),
)

export const setDefaultAsset = createAction('@@app/setDefaultAsset')<string>()

export const setActiveSignal = createAction('@@app/setActiveSignal')<string>()
export const clearActiveSignal = createAction('@@app/clearActiveSignal')()
export const fulfillCandlesData = createAction('@tradingView/fulfillCandlesData')<CandlesData>()
export const balanceUpdateAction = createAction('@app/balanceUpdate')<TradingAccount>()
export const setOverrideParamsAction = createAction('@app/setOverrideParams')<OverrideParams>()
