import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import moment from 'moment-timezone'

import { InfoItem } from './InfoItem'
import { currencyFormat, currencyVolumeFormat, isMobile, positiveNegativeFormat } from '../../../../utils'
import { mainCurrencySelector } from '../../../../store/selectors'

interface InfoListProps {
  order: string | number
  direction: string
  investment: string | number
  leverage: string | number
  profit: string | number
  value: string | number
  currentPrice: string | number
  openPrice?: string | number
  swaps?: string | number
  commissions?: string | number
  modifyPendingOrder: boolean
  contractType: string
  digits: number
  openDate: string
}

export const InfoList: FC<InfoListProps> = ({
  modifyPendingOrder,
  order,
  direction,
  profit,
  investment,
  leverage,
  value,
  currentPrice,
  openPrice,
  swaps,
  commissions,
  contractType,
  digits,
  openDate,
}) => {
  const currency = useSelector(mainCurrencySelector)
  return (
    <Grid item container>
      <InfoItem name={'orderNumber'} value={order} />
      <InfoItem name={'direction'} value={direction} />
      {!isMobile() && (
        <InfoItem name={'investment'} value={Number(investment) ? currencyFormat(investment, currency) : investment} />
      )}
      {isMobile() && <InfoItem name={'value'} value={currencyVolumeFormat(value, contractType)} />}
      {isMobile() && (
        <InfoItem name={'profit'} value={positiveNegativeFormat(profit, currency, { shouldRound: false })} />
      )}
      <InfoItem name={'leverage'} value={leverage} />
      {!isMobile() && <InfoItem name={'value'} value={currencyVolumeFormat(value, contractType)} />}
      {!modifyPendingOrder && (
        <InfoItem
          name={'openPrice'}
          value={currencyVolumeFormat(openPrice || '0.00', '', { minimumFractionDigits: digits })}
        />
      )}
      <InfoItem
        name={'currentPrice'}
        value={currencyVolumeFormat(currentPrice, '', { minimumFractionDigits: digits })}
      />
      {!modifyPendingOrder && <InfoItem name={'swaps'} value={positiveNegativeFormat(swaps || '0.00', currency)} />}
      {!modifyPendingOrder && (
        <InfoItem name={'commission'} value={positiveNegativeFormat(commissions || '0.00', currency)} />
      )}
      {isMobile() && <InfoItem name={'openDate'} value={moment(openDate).format('DD/MM/YYYY hh:mm:ss')} />}
    </Grid>
  )
}
