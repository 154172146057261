import React from 'react'
import { CellProps, Renderer } from 'react-table'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { TradeObject } from '@bdswiss/mt4-connector'

import { currencyVolumeFormat } from '../../../utils'
import { pricesSelector } from '../../../store/selectors'

export const CurrentPriceCell: Renderer<CellProps<TradeObject, TradeObject>> = ({
  row: {
    original: { type, symbol, digits },
  },
}) => {
  const assets = useSelector(pricesSelector)
  const currentPrice = currencyVolumeFormat(
    /buy/i.test(type) ? get(assets, [symbol, 'mbid'], '0.00') : get(assets, [symbol, 'mask'], '0.00'),
    '',
    { minimumFractionDigits: digits },
  )

  return <div title={currentPrice}>{currentPrice}</div>
}
