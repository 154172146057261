import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import { GraphQLError } from 'graphql'

import { ModifyTrade, TradeObject, CloseTrade } from '@bdswiss/mt4-connector'

import { Trade } from '../../services/graphql/types'
import {
  orderOpenNotify,
  orderPendingNotify,
  orderClosedNotify,
  pendingOrderClosedNotify,
} from '../../components/Notifies'

export const openOrderAction = createAction('@@orders/open')()
export const closeOrderAction = createAction('@@orders/close')<CloseTrade>()
export const closeAllOpenPositionsAction = createAction('@@orders/closeAllOpenPositions')()

export const orderOpenedAction = createCustomAction('@@orders/opened', (payload: TradeObject) => {
  orderOpenNotify(payload)
  return { payload }
})

export const pendingOrderOpenedAction = createCustomAction('@@orders/pendingOpened', (payload: TradeObject) => {
  orderPendingNotify(payload)
  return { payload }
})

export const orderClosedAction = createCustomAction('@@orders/closed', (payload: CloseTrade | TradeObject) => {
  orderClosedNotify(payload)
  return { payload }
})

export const pendingOrderClosedAction = createCustomAction(
  '@@orders/pendingClosed',
  (payload: CloseTrade | TradeObject) => {
    pendingOrderClosedNotify(payload)
    return { payload }
  },
)

export const {
  request: getClosedPositionsRequest,
  success: getClosedPositionsSuccess,
  failure: getClosedPositionsFailure,
} = createAsyncAction('@@orders/get', '@@orders/set', '@@orders/error')<
  Partial<{ limit: number; offset: number; login: number }> | void,
  Trade[],
  GraphQLError
>()

export const addClosedPositionOnScroll = createAction('@orders/addClosedPosition')<Trade[]>()

export const modifyOrderAction = createAction('@orders/modifyOrder')<Pick<TradeObject, 'order'>>()

export const initialStateValuesForModifying = createAction('@orders/initialStateValuesForModifying')<
  Array<number | boolean>
>()

export const modifiedOpenOrderAction = createCustomAction('@orders/modifiedOrder', (payload: ModifyTrade) =>
  toast.success(i18n.t('orderModifyNotify', { orderId: payload.order })),
)
export const modifiedPendingOrderAction = createCustomAction('@orders/modifiedOrder', (payload: ModifyTrade) =>
  toast.success(i18n.t('pendingOrderModifyNotify', { orderId: payload.order })),
)
