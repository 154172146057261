import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'

import { changeLocation, notify, setUpdateActiveAsset } from '../../../store/actions'
import { TradeMode } from '../../../enums'
import { TradingIdeasDialog } from './TradingIdeasDialog'
import { isMobile } from '../../../utils'
import { useHistory } from 'react-router'
import { marketOpenedSelector } from '../../../store/selectors'
import { MenuItemsNames } from '../../pages/menuBarList'
import { useTranslation } from 'react-i18next'

const TradingIdeasImage = styled('img')(() => ({
  cursor: 'zoom-in',
  width: '100%',
  padding: '8px 0',
}))
const TradingIdeasButton = styled('button')(({ theme }) => ({
  width: 'inherit',
  cursor: 'pointer',
  color: theme.palette.mode === 'light' ? theme.palette.primary.main : '#419CFF',
  border: 'none',
  borderRadius: theme.spacing(0.5),
  background: theme.palette.mode === 'light' ? '#D6EAFF' : 'rgba(0, 73, 153, 0.4)',
  padding: '4px 16px',
  gap: 8,
  height: 32,
  fontSize: 14,
  lineHeight: '24px',
  ['&:hover']: {
    background: '#007AFF',
    color: '#fff',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(255,255,255,0.25);',
  },
}))

interface ITISubItem {
  open: boolean
  src: string
  fullImg: string
  name: string
  symbol: string
  mode: TradeMode
}

export const TradingIdeasSubItem: FC<ITISubItem> = ({ open, src, name, symbol, mode, fullImg }) => {
  const dispatch = useDispatch()
  const locationPath = location.pathname
  const history = useHistory()
  const marketOpened = useSelector(marketOpenedSelector)
  const isMobileDevice = isMobile()
  const { t } = useTranslation()
  const [openDialog, setOpenDialog] = useState(false)

  const handleAsset = useCallback(() => {
    dispatch(setUpdateActiveAsset({ symbol, mode }))
    dispatch(changeLocation(MenuItemsNames.chart, {}))
    !marketOpened && dispatch(notify(t('marketClosed', 'Market is Closed'), { type: 'warning' }))
    history.push(`${locationPath.split('/').slice(0, 3).join('/')}/${MenuItemsNames.chart}`)
  }, [dispatch, history, locationPath, marketOpened, mode, symbol, t])

  const handleImage = useCallback(() => {
    setOpenDialog(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpenDialog(false)
  }, [])

  if (!open) return null

  return (
    <>
      <TradingIdeasImage src={src} onClick={handleImage} />
      {isMobileDevice && <TradingIdeasButton onClick={handleAsset}>Trade&nbsp;{name}</TradingIdeasButton>}
      {!isMobileDevice && <TradingIdeasDialog open={openDialog} image={fullImg} onClose={handleClose} />}
    </>
  )
}
