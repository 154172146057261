import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import Button, { ButtonProps } from '@mui/material/Button'
import clsx from 'clsx'

interface Props extends ButtonProps {
  active: boolean
  translationKey: string
  defaultTranslation: string
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    border: `1.5px solid ${palette.mode === 'dark' ? 'rgba(0, 122, 255, 0.5)' : '#D6EAFF'}`,
    margin: '24px 6px',
    color: '#858585',
    textTransform: 'none',
    fontWeight: 400,
    borderRadius: 48,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '0.25px',
    padding: '10px 16px',
    '&.active': {
      borderColor: palette.mode === 'dark' ? '#419CFF' : '#007AFF',
      color: palette.mode === 'dark' ? '#419CFF' : '#007AFF',
    },
  },
}))

const DownloadButton: FC<Props> = ({ active, translationKey, defaultTranslation, ...props }) => {
  const classes = useStyles()
  return (
    <Button classes={classes} className={clsx({ active })} {...props}>
      <Trans i18nKey={translationKey} defaults={defaultTranslation} />
    </Button>
  )
}

export default DownloadButton
