import React, { useCallback, FC, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'

import { LabelValueTooltip } from '../LabelValueTooltip'
import { StyledSwitch } from '../StyledSwitch'
import { setTakeProfitEnabled, resetInputValue } from '../../../../store/actions'
import {
  formProfitPercentSelector,
  formProfitSelector,
  formTakeProfitEnabledSelector,
  initialFormValues,
  mainCurrencySelector,
} from '../../../../store/selectors'
import TakeProfitInput from '../TakeProfitInput'
import { NumberInputLabel } from '../NumberInputLabel'
import { numberFormat, positiveNegativeFormat } from '../../../../utils'

const useStyles = makeStyles<Theme, { isActive: boolean }>(() => ({
  container: {
    marginBottom: ({ isActive }) => (isActive ? 0 : 14),
  },
  labelWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tpEnabledContainer: {
    marginBottom: 23,
  },
  value: {
    display: 'flex',
    textAlign: 'right',
    fontSize: 10,
    lineHeight: 1.4,
  },
  positive: { color: '#00AD7B' },
  negative: { color: '#D41920' },
}))

const TakeProfitCheckbox: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tpEnabled = useSelector(formTakeProfitEnabledSelector)
  const currency = useSelector(mainCurrencySelector)
  const initValue = useSelector(initialFormValues)
  const profit = useSelector(formProfitSelector)
  const profitPercent = useSelector(formProfitPercentSelector)
  const classes = useStyles({ isActive: tpEnabled })

  const tpEnableChangeHandle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setTakeProfitEnabled(e.target.checked))
      !e.target.checked && dispatch(resetInputValue({ key: 'takeProfit', value: initValue[0] as number }))
    },
    [dispatch, initValue],
  )

  const tpValue = positiveNegativeFormat(profit, currency, {
    minimumFractionDigits: 2,
  })

  const tpPercent = numberFormat(profitPercent, {
    maximumFractionDigits: 2,
  })

  return (
    <div className={classes.container}>
      <div className={classes.labelWrap}>
        <LabelValueTooltip
          label={t('takeProfit')}
          tooltip={'helpTakeProfit'}
          control={<StyledSwitch name={'tpEnabled'} onChange={tpEnableChangeHandle} checked={tpEnabled} />}
        />
        {tpEnabled && (
          <div
            className={clsx(classes.value, {
              [classes.positive]: profit >= 0,
              [classes.negative]: profit < 0,
            })}
          >
            {tpValue} ({tpPercent}%)
          </div>
        )}
      </div>

      {tpEnabled && (
        <div className={classes.tpEnabledContainer}>
          <NumberInputLabel name="atPrice" unit={currency} />
          <TakeProfitInput />
        </div>
      )}
    </div>
  )
}

export default TakeProfitCheckbox
