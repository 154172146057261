import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import Fade from '@mui/material/Fade'
import clsx from 'clsx'

import { formSymbolExposureSelector } from '../../../../store/selectors'

const BorderLinearProgress = styled(LinearProgress)<{ long: string }>(({ theme, long }) => ({
  width: 'calc(100% - 80px)',
  height: 8,
  borderRadius: 2,
  transform: 'scale(-1, 1)',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      +long >= 50
        ? theme.palette.mode === 'light'
          ? '#ED1C24'
          : '#FC4950'
        : theme.palette.mode === 'light'
        ? '#00AD7B'
        : '#00C78D',
  },
  [`& .${linearProgressClasses.bar}`]: {
    transform: `translateX(${+long >= 50 ? +long - 100 : long}%) !important`,
    backgroundColor:
      +long >= 50
        ? theme.palette.mode === 'light'
          ? '#00AD7B'
          : '#00C78D'
        : theme.palette.mode === 'light'
        ? '#ED1C24'
        : '#FC4950',
  },
}))

const useStyles = makeStyles(({ palette }) => ({
  wrap: {
    padding: '8px 8px 0',
    borderRadius: 6,
    background: palette.mode === 'dark' ? '#1F1F1F' : palette.grey[100],
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 1.35,
    letterSpacing: 0.4,
    color: palette.mode === 'light' ? '#5C5C5C' : '#EBEBEB',
  },
  negative: {
    color: palette.mode === 'light' ? '#BA161C' : '#FC4950',
  },
  positive: {
    color: palette.mode === 'light' ? '#009469' : '#00C78D',
  },
  percent: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
  },
}))

export const MarketSentiment: FC<{ label: string }> = ({ label }) => {
  const sentiment = useSelector(formSymbolExposureSelector)
  const long = Number(sentiment.longPercent)
  const short = Number(sentiment.shortPercent)
  const classes = useStyles({ long })
  if (!Object.values(sentiment).length) return null

  return (
    <Fade in={true}>
      <div className={classes.wrap}>
        <div className={classes.container}>
          <div className={clsx(classes.percent, classes.negative)}>{short}%</div>
          <BorderLinearProgress variant="determinate" value={long || 0} long={long.toString()} />
          <div className={clsx(classes.percent, classes.positive)}>{long}%</div>
        </div>
        <p className={classes.text}>
          <Trans
            i18nKey={
              long !== short ? (long > 50 ? 'marketSentimentBuy' : 'marketSentimentSell') : 'marketSentimentEqually'
            }
            values={{ percent: (long > 50 ? long : short) || 0 }}
          />
          {label}
        </p>
      </div>
    </Fade>
  )
}
