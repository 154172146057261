import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import omit from 'lodash/omit'
import globalStore from '../store'
import { getDeviceSource } from './utils'
import config from '../config'

function createRealLogger(): (eventName: string, params?: Record<string, unknown>) => void {
  const analytics = getAnalytics(initializeApp(omit(config.get('services')['firebase'], 'enabled')))
  if (!analytics) throw new Error('Failed to initialize analytics')

  return (eventName: string, params?: Record<string, unknown>) => {
    const userId = globalStore.getState().user.data.id
    logEvent(analytics, eventName, { ...params, userId, deviceSource: getDeviceSource() })
  }
}

export const trackEvent: (eventName: string, params?: Record<string, unknown>) => void = config.get(
  'services.firebase.enabled',
)
  ? createRealLogger()
  : (eventName, params) => {
      const userId = globalStore.getState().user.data.id
      process.env.NODE_ENV !== 'test' &&
        console.log('Tracking event', { eventName, userId, params: { ...params, deviceSource: getDeviceSource() } })
    }
