import { createReducer } from 'typesafe-actions'
import omit from 'lodash/omit'

import { ActionTypes, User } from '../types'
import { getUserRequest, getUserSuccess, getUserFailure, updateThemePreferenceSuccess } from '../actions'
import { LoadingState } from '../../enums'

export type UserState = {
  status: LoadingState
  data: User
  error: Error | null
}

export const initialState = {
  status: LoadingState.IDLE,
  data: {},
  error: null,
}

export const user = createReducer<UserState, ActionTypes>(initialState)
  .handleAction(getUserRequest, (state) => ({ ...state, status: LoadingState.LOADING }))
  .handleAction(getUserSuccess, (state, { payload }) => ({
    ...state,
    status: LoadingState.SUCCESS,
    data: omit(payload, 'accounts'),
  }))
  .handleAction(getUserFailure, (state, { payload }) => ({ ...state, status: LoadingState.ERROR, error: payload }))
  .handleAction(updateThemePreferenceSuccess, (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      themePreference: payload as User['themePreference'],
    },
  }))
