import { isActionOf } from 'typesafe-actions'
import { EMPTY, from, of } from 'rxjs'
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators'

import { RootEpic } from '../types'
import {
  appChangeActiveAsset,
  getTIItemsFailure,
  getTIItemsRequest,
  getTIItemsSuccess,
  setUpdateActiveAsset,
} from '../actions'
import { tradeableAssetsSelector } from '../selectors'
import { getFeedItemTradeModeByTerm } from '../../utils'
import { TradeMode } from '../../enums'

export const getTradingIdeasItemsEpic: RootEpic = (action$, state$, { graphqlClient: { getTradingIdeas } }) => {
  return action$.pipe(filter(isActionOf([getTIItemsRequest]))).pipe(
    exhaustMap(() => {
      return from(getTradingIdeas({})).pipe(
        map((response) =>
          getTIItemsSuccess(
            response.data.tradingIdeas.items.filter(
              ({ code, term, option }) =>
                (Object.values(tradeableAssetsSelector(state$.value))
                  .map((price) => price.normalizedSymbol)
                  .includes(code?.filter((ticker) => ticker.type === 'TICKER')[0].key) ||
                  Object.values(tradeableAssetsSelector(state$.value))
                    .map((price) => price.normalizedSymbol)
                    .includes(`#${code?.filter((ticker) => ticker.type === 'TICKER')[0].key}`)) &&
                // Ignore ideas with consolidation period
                getFeedItemTradeModeByTerm({ term, option }) !== TradeMode.disabled,
            ),
          ),
        ),
        catchError((err) => {
          return of(getTIItemsFailure(err))
        }),
      )
    }),
  )
}

export const updateActiveAssetEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf([setUpdateActiveAsset])),
    switchMap(({ payload: { symbol, mode } }) => {
      const currentOrder = Object.values(tradeableAssetsSelector(state$.value)).find(
        (price) => price.normalizedSymbol === symbol || price.normalizedSymbol === `#${symbol}`,
      )
      return currentOrder ? of(appChangeActiveAsset(currentOrder.symbol, { tradeMode: mode })) : EMPTY
    }),
  )
}

export default [getTradingIdeasItemsEpic, updateActiveAssetEpic]
