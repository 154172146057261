import React, { FC, SyntheticEvent, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/system'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { activeAssetGroupSelector, assetGroupsModelSelector } from '../../../../store/selectors'
import { changeActiveAssetGroup } from '../../../../store/actions'

const MarketsCategoriesWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 52,
  '& .MuiTabs-root': {
    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? '#1F1F1F' : '#EBEBEB'}`,
  },
  '& .MuiTab-root': {
    textTransform: 'capitalize',
    fontWeight: 400,
    minWidth: 49,
    color: theme.palette.mode === 'dark' ? '#999' : '#707070',
  },
  '& .Mui-selected': {
    color: `${theme.palette.mode === 'dark' ? '#fff' : '#000'} !important`,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.mode === 'dark' ? '#FC4950' : '#ED1C24',
  },
}))

const MarketsCategories: FC = () => {
  const groupsArray = useSelector(assetGroupsModelSelector)
    .groupsArray.filter((group) => group.label !== 'trendsAnalysis')
    .sort((a, b) => {
      switch (b.label) {
        case 'all':
          return 1
        case 'favourites':
          return 0
      }

      return -1
    })

  const active = useSelector(activeAssetGroupSelector)
  const dispatch = useDispatch()

  const [value, setValue] = useState(0)
  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setValue(newValue)
      event.currentTarget.textContent && dispatch(changeActiveAssetGroup(event.currentTarget.textContent))
    },
    [dispatch],
  )

  useEffect(() => {
    const activeIndex = groupsArray.map((elem) => elem.label).indexOf(active)
    setValue(activeIndex > 0 ? activeIndex : 0)
  }, [groupsArray, active])

  return (
    <MarketsCategoriesWrap>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        {groupsArray.map((group, index) => {
          return <Tab key={index} label={group.label} data-label={group.label} />
        })}
      </Tabs>
    </MarketsCategoriesWrap>
  )
}

export default MarketsCategories
