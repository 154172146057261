import { RootState } from '../types'
import { MobileBackButtonData } from '../reducers/ui'
import { ChartMode } from '../../types'
import { Location } from '../../enums'
import { activeTabSelector } from '.'

export const tradeTabSelector = (state: RootState): boolean => state.ui.tradeTab
export const openPosTabSelector = (state: RootState): boolean => state.ui.openPosTab
export const pendingTabSelector = (state: RootState): boolean => state.ui.pendingTab
export const historyTabSelector = (state: RootState): boolean => state.ui.historyTab
export const signalRiskDisclaimerSelector = (state: RootState): boolean => state.ui.signalsRiskDisclaimer
export const tradingFormPanelSelector = (state: RootState): boolean => state.ui.tradingFormPanel
export const modifyPanelTradeSelector = (state: RootState): boolean => state.ui.modifyPanel.trade
export const modifyPanelOpenSelector = (state: RootState): boolean => state.ui.modifyPanel.open
export const modifyPanelPendingSelector = (state: RootState): boolean => state.ui.modifyPanel.pending

export const chartModeSelector = (state: RootState): ChartMode => {
  const modifyingOnTradeTab = modifyPanelTradeSelector(state)

  const activeTab = activeTabSelector(state)

  let result: ChartMode = 'trade'

  switch (activeTab as Location) {
    case Location.trade:
      result = modifyingOnTradeTab ? 'open' : 'trade'
      break
    case Location.positions:
      result = 'open'
      break
    case Location.order:
      result = 'pending'
      break
    default:
    // nothing
  }

  return result
}
export const mobileBackButtonDataSelector = (state: RootState): MobileBackButtonData | undefined =>
  state.ui.mobileBackButtonData

export const getShowDownloadMobileAppDialog = (state: RootState): boolean => state.ui.showDownloadMobileAppDialog
export const showFeedNewSectionTooltipSelector = (state: RootState): boolean => state.ui.showFeedNewSectionTooltip
export const isFullscreenSelector = (state: RootState): boolean => state.ui.isFullscreen
export const currentMobilePositionTabSelector = (state: RootState): string => state.ui.currentMobilePositionTab
export const downloadAppBannerSelector = (state: RootState): boolean => state.ui.downloadAppBanner
