import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { getUrlParam } from './utils'

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: getUrlParam('lng') || 'en',
    backend: {
      loadPath: '../../locales/{{lng}}/translation.json',
      crossDomain: true,
      withCredentials: false,
      requestOptions: {
        mode: 'cors',
      },
    },
    react: {
      useSuspense: false,
    },
  })

export default i18next
