export enum LoadingState {
  IDLE = 'IDLE',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
}

export enum TradeMode {
  buy = 'buy',
  sell = 'sell',
  disabled = 'disabled',
}

export enum TradeModeReason {
  insufficientFunds = 'insufficientFunds',
  readOnly = 'readOnly',
  noConnection = 'noConnection',
  noAssets = 'noAssets',
}

export enum Location {
  trade = 'trade',
  positions = 'positions',
  order = 'order',
  history = 'history',
  markets = 'markets',
  chart = 'chart',
  more = 'more',
  calendar = 'calendar',
}

export enum GuidedTourStatus {
  skipped = 'skipped',
  started = 'started',
  finished = 'finished',
}

export enum GuidedTourAction {
  tap = 'tap',
}

export enum OrderTypes {
  marketOrder = 'marketOrder',
  pendingOrder = 'pendingOrder',
  stopOrder = 'stopOrder',
  stopLimitOrder = 'stopLimitOrder',
}

export enum FieldErrorKeys {
  zeroValue = 'zeroValue',
  insufficientFunds = 'insufficientFunds',
  lowerPrice = 'lowerPrice',
  higherPrice = 'higherPrice',
  lessAmount = 'lessAmount',
  higherAmount = 'higherAmount',
  moreLimitPrice = 'moreLimitPrice',
  lessLimitPrice = 'lessLimitPrice',
  stopPriceBuy = 'stopPriceBuy',
  stopPriceSell = 'stopPriceSell',
  lessStopLimitPrice = 'lessStopLimitPrice',
  moreStopLimitPrice = 'moreStopLimitPrice',
}

export enum FeedPanel {
  tradingIdeas = 'tradingIdeas',
  economicCalendar = 'economicCalendar',
}

export enum LeverageType {
  default = 'default_leverage',
  dynamic = 'dynamic_leverage',
  undefined = '',
}

export enum PostMessageActionType {
  backToAccount = 'backToAccount',
  backToDashboard = 'backToDashboard',
  changeAccount = 'changeAccount',
  changeTheme = 'changeTheme',
  changePageTitle = 'changePageTitle',
  openUrl = 'openUrl',
  deposit = 'deposit',
  depositWithPreselectedValue = 'depositWithPreselectedValue',
}
