import React, { FC, MouseEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import clsx from 'clsx'

import { PriceExtended } from '@bdswiss/mt4-connector'

import { VirtualizedTable, VirtualizedTableProps } from '../../../VirtualizedTable'
import { BuyCell, SellCell } from '../../../Tables/TradeTable/TradeTableColumns'
import { NameCell } from './MarketTableColumns'
import { downloadAppBannerSelector, filteredAssetsSelector, marketOpenedSelector } from '../../../../store/selectors'
import { appChangeActiveAsset, changeLocation, notify } from '../../../../store/actions'
import { MenuItemsNames } from '../../../pages/menuBarList'

const MarketsTableWrap = styled('div')({
  display: 'flex',
  flexGrow: 1,
  paddingLeft: 16,
  paddingRight: 10,
  height: `calc(100vh - 236px)`,
  '&.bannerShown': {
    height: 'calc(100vh - 292px)',
  },
})

const getItemSize = () => 60
const estimatedItemSize = 60

const MarketsTable: FC = () => {
  const { t } = useTranslation()
  const assets = useSelector(filteredAssetsSelector)
  const marketOpened = useSelector(marketOpenedSelector)
  const bannerShown = useSelector(downloadAppBannerSelector)
  const dispatch = useDispatch()
  const locationPath = location.pathname
  const history = useHistory()

  const columns = useMemo<VirtualizedTableProps<PriceExtended>['columns']>(
    () => [
      {
        Header: t('mobileNameCell', 'Name / 24 change').toString(),
        id: 'name',
        accessor: 'symbol',
        width: 0.4,
        Cell: NameCell,
      },
      {
        Header: t('sell', 'Sell').toString(),
        id: 'sell',
        accessor: 'mbid',
        minWidth: 117,
        maxWidth: 0.3,
        Cell: SellCell,
      },
      {
        Header: t('buy', 'Buy').toString(),
        id: 'buy',
        accessor: 'mask',
        minWidth: 117,
        maxWidth: 0.3,
        Cell: BuyCell,
      },
    ],
    [t],
  )

  const rowClickHandle = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const { symbol } = e.currentTarget.dataset
      symbol && dispatch(appChangeActiveAsset(symbol))
      dispatch(changeLocation(MenuItemsNames.chart, {}))
      !marketOpened && dispatch(notify(t('marketClosed', 'Market is Closed'), { type: 'warning' }))
      history.push(`${locationPath.split('/').slice(0, 3).join('/')}/${MenuItemsNames.chart}`)
    },
    [dispatch, history, locationPath, marketOpened, t],
  )

  return (
    <MarketsTableWrap className={clsx({ bannerShown })}>
      <VirtualizedTable
        columns={columns}
        data={assets}
        getItemSize={getItemSize}
        showHeaders={true}
        showBorders={false}
        estimatedItemSize={estimatedItemSize}
        rowClickHandle={rowClickHandle}
      />
    </MarketsTableWrap>
  )
}

export default MarketsTable
