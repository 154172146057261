import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from '@mui/material/Link'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'

import { setCookie } from '../../../../../utils'
import { CloseButton } from '../../../../CloseButton'
import { signalsRiskDisclaimer } from '../../../../../store/actions'
import { displayRiskDisclaimerSelector } from '../../../../../store/selectors'
import config from '../../../../../config'

const useStyles = makeStyles((theme) => ({
  alert: {
    position: 'relative',
    background: theme.palette.mode === 'light' ? '#fed4d6' : '#86121a',
    borderRadius: '.25rem',
    padding: '.75rem 1.25rem',
    marginTop: 'auto',
  },
  text: {
    fontSize: '0.875rem',
    width: '95%',
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
  },
  link: {
    color: '#117dd5',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

export const SignalsRiskDisclaimer: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const alertSelector = useSelector(displayRiskDisclaimerSelector)
  const toggleCloseAlert = useCallback(() => {
    setCookie('topMenuAlert', 'false', 10)
    dispatch(signalsRiskDisclaimer(false))
  }, [dispatch])

  if (!alertSelector) return null
  return (
    <div className={classes.alert}>
      <div className={classes.text}>
        <Trans i18nKey="tradeAlert">
          <Link className={classes.link} href={config.get('termsAndConditionsUrl')} target="_blank">
            risk disclaimer
          </Link>
        </Trans>
      </div>

      <div className={classes.close}>
        <CloseButton onClose={toggleCloseAlert} />
      </div>
    </div>
  )
}
