import React from 'react'
import { CellProps, Renderer } from 'react-table'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { positiveNegativeFormat } from '../../../utils'
import makeStyles from '@mui/styles/makeStyles'
import { mainCurrencySelector } from '../../../store/selectors'

const useStyles = makeStyles((theme) => ({
  positive: {
    color: theme.palette.green.mint,
  },
  negative: {
    color: theme.palette.secondary.main,
  },
}))
export const ProfitCell: Renderer<CellProps<Record<string, unknown>>> = ({ value }) => {
  const classes = useStyles()
  const currency = useSelector(mainCurrencySelector)
  return (
    <span
      className={clsx({
        [classes.negative]: value < 0,
        [classes.positive]: value >= 0,
      })}
    >
      {positiveNegativeFormat(value, currency, { shouldRound: false })}
    </span>
  )
}
