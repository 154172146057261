import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import { SignalsRiskDisclaimer } from '../SignalsRiskDisclaimer'
import { TradeTable } from '../../../../Tables/TradeTable'
import { InputSearch } from '../../../../InputSearch'
import SignalsTable from '../../../../Tables/SignalsTable/SignalsTable'
import { activeAssetGroupSelector } from '../../../../../store/selectors'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const Search: FC = () => {
  const classes = useStyles()
  const activeAssetGroup = useSelector(activeAssetGroupSelector)

  return (
    <div className={classes.container}>
      <InputSearch />
      {activeAssetGroup === 'trendsAnalysis' ? <SignalsTable /> : <TradeTable />}
      <SignalsRiskDisclaimer />
    </div>
  )
}
