import React, { FC } from 'react'
import { styled } from '@mui/material/styles'

import spinner from '../../images/spinner.gif'

const LoaderContainer = styled('div')(({ theme }) => ({
  margin: 'auto',
  height: 'calc(100% - 64px)',
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  ['& img']: {
    margin: '0 auto',
    width: '60px',
    height: '60px',
  },
}))

export const Loader: FC = () => {
  return (
    <LoaderContainer>
      <img alt={'Loading...'} src={spinner} />
    </LoaderContainer>
  )
}
