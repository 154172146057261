import { createReducer } from 'typesafe-actions'

import { ActionTypes } from '../types'
import {
  addClosedPositionOnScroll,
  getClosedPositionsFailure,
  getClosedPositionsRequest,
  getClosedPositionsSuccess,
} from '../actions'
import { Trade } from '../../services/graphql/types'
import { LoadingState } from '../../enums'

export type PositionsState = {
  closed: {
    status: LoadingState
    data: Trade
    error: Error | null
  }
}

const initialState = {
  closed: {
    status: LoadingState.IDLE,
    data: {},
    error: null,
  },
}

export const positions = createReducer<PositionsState, ActionTypes>(initialState)
  .handleAction(getClosedPositionsRequest, (state) => ({
    ...state,
    closed: { ...state.closed, status: LoadingState.LOADING },
  }))
  .handleAction(getClosedPositionsSuccess, (state, { payload }) => ({
    ...state,
    closed: {
      ...state.closed,
      status: LoadingState.SUCCESS,
      data: Object.assign(
        {},
        ...payload.filter((position) => !!position.id).map((position) => ({ [position.id as string]: position })),
      ),
    },
  }))
  .handleAction(getClosedPositionsFailure, (state, { payload }) => ({
    ...state,
    closed: { ...state.closed, status: LoadingState.ERROR, error: payload },
  }))
  .handleAction(addClosedPositionOnScroll, (state, { payload }) => ({
    ...state,
    closed: {
      ...state.closed,
      status: LoadingState.SUCCESS,
      data: {
        ...state.closed.data,
        ...Object.assign(
          {},
          ...payload.filter((position) => !!position.id).map((position) => ({ [position.id as string]: position })),
        ),
      },
    },
  }))
