import React, { FC, useCallback, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/types'
import { getFavouriteSelector } from '../../../store/selectors'
import { updateFavouritesRequest } from '../../../store/actions'
import IconButton from '@mui/material/IconButton'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles({
  starIcon: {
    color: '#ff8c00',
    fontSize: '18px',
  },
  iconButton: {
    padding: '0px',
  },
})

export const Favourite: FC<{ value: string }> = ({ value: symbol }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isFavourite = useSelector((state: RootState) => getFavouriteSelector(state, symbol))
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      dispatch(updateFavouritesRequest(symbol))
    },
    [dispatch, symbol],
  )

  return (
    <IconButton className={clsx(classes.iconButton, 'favourite-cell-icon')} size={'small'} onClick={handleClick}>
      {isFavourite ? <StarIcon className={classes.starIcon} /> : <StarBorderIcon className={classes.starIcon} />}
    </IconButton>
  )
}
