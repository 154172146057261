import { createSelector } from 'reselect'

import { MT4Connector, TradingAccount } from '@bdswiss/mt4-connector'

const mt4 = MT4Connector.Instance

export const currentEquitySelector = (): TradingAccount['equity'] => mt4.account?.equity || 0
export const currentFreeMarginSelector = (): TradingAccount['freeMargin'] => mt4.account?.freeMargin || 0
export const currentMarginSelector = (): TradingAccount['margin'] => mt4.account?.margin || 0

export const infoSectionSelector = createSelector(
  currentEquitySelector,
  currentFreeMarginSelector,
  currentMarginSelector,
  (equity, freeMargin, margin) => {
    const marginLevel = margin > 0 ? ((equity / margin) * 100).toFixed(2) : margin
    return { equity, freeMargin, margin, marginLevel }
  },
)
