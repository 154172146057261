import React, { FC, MouseEvent, useCallback, useContext, useMemo } from 'react'
import { Column, useFlexLayout, useTable } from 'react-table'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { TradeObject } from '@bdswiss/mt4-connector'

import { filteredAssetsSelector, openPositionsSelector, selectedOpenOrderSelector } from '../../../store/selectors'
import { TableContext } from '../../VirtualizedTable'
import {
  CloseActionCell,
  InvestmentCell,
  LeverageCell,
  OpenPriceCell,
  ProfitCell,
  TypeCell,
  OrderIdCell,
} from './TradeSubTableColumns'
import { EditActionCell } from '../CommonCells'
import { appChangeActiveAsset } from '../../../store/actions'
import clsx from 'clsx'
import { getTradeModeByTradeAvailable } from '../../../utils'
import { TradeMode } from '../../../enums'

const columns: Array<Column<TradeObject>> = [
  {
    accessor: 'type',
    Cell: TypeCell,
    width: 70,
  },
  {
    accessor: 'order',
    Cell: OrderIdCell,
    width: 100,
  },
  {
    accessor: 'investment',
    Cell: InvestmentCell,
    width: 100,
  },
  {
    accessor: 'openPrice',
    Cell: OpenPriceCell,
    maxWidth: 135,
  },
  {
    accessor: 'leverage',
    Cell: LeverageCell,
    maxWidth: 120,
  },
  {
    accessor: 'profit',
    Cell: ProfitCell,
    maxWidth: 100,
  },
  {
    id: 'editAction',
    Cell: EditActionCell,
    maxWidth: 60,
  },
  {
    id: 'closeAction',
    Cell: CloseActionCell,
    maxWidth: 60,
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '11px 8px 0',
    fontSize: '0.875rem',
  },
  table: {
    width: '100%',
  },
  row: {
    borderBottom: `1px solid ${theme.palette.action.selected}`,
    padding: '6px 0',
    '&:last-child': {
      borderBottom: '1px solid transparent',
    },
    '&.active': {
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.action.hover,
    },
  },
  buttonShowAll: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: 86,
    display: 'flex',
    alignItems: 'center',
  },
  buttonLink: {
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  anchor: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  td: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const TradeSubTable: FC<TradeObject> = ({ symbol }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const data = useSelector(openPositionsSelector)
  const assets = useSelector(filteredAssetsSelector)
  const selectedPositionId = useSelector(selectedOpenOrderSelector)
  const { expandedPositionsState, expandedPositionsDispatch } = useContext(TableContext)
  const showAll = expandedPositionsState[symbol]

  const positions = useMemo(
    () =>
      data
        .filter((position) => position.symbol === symbol)
        .sort((a, b) => new Date(b.openTime).getTime() - new Date(a.openTime).getTime()),
    [data, symbol],
  )

  const toggleExpanded = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      expandedPositionsDispatch?.({ type: 'toggle', key: symbol })
    },
    [expandedPositionsDispatch, symbol],
  )

  const selectPositionHandle = useCallback(
    (e: MouseEvent<HTMLTableRowElement>) => {
      e.stopPropagation()
      const selectedAsset = assets.find((asset) => asset.symbol === symbol)
      const positionId = e.currentTarget.dataset.id
      dispatch(
        appChangeActiveAsset(symbol, {
          tradeMode: selectedAsset ? getTradeModeByTradeAvailable(selectedAsset.trade) : TradeMode.disabled,
          positionId,
        }),
      )
    },
    [assets, dispatch, symbol],
  )

  const tableInstance = useTable({ columns, data: showAll ? positions : positions.slice(0, 3) }, useFlexLayout)
  const { getTableProps, getTableBodyProps, rows, prepareRow } = tableInstance

  if (!positions.length) return null

  return (
    <div className={classes.root}>
      <div>
        {positions.length} <Trans i18nKey={'openPositions'} />
      </div>
      <div>
        <table {...getTableProps()} className={classes.table}>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  key={`row_${row.id}`}
                  className={clsx(classes.row, {
                    active: row.original.id?.toString() === selectedPositionId,
                  })}
                  data-id={row.original.id}
                  onClick={selectPositionHandle}
                >
                  {row.cells.map((cell, cellIndex) => (
                    <td {...cell.getCellProps()} key={`cell_${cellIndex}`} className={classes.td}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div>
        {positions.length > 3 ? (
          <div onClick={toggleExpanded} className={classes.buttonShowAll}>
            <div className={classes.anchor}>{showAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
            <button className={classes.buttonLink}>
              <Trans i18nKey={showAll ? 'showLess' : 'showMore'} />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TradeSubTable
