import { CellProps, Renderer } from 'react-table'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { getAssetLabelSelector, isMarketOpenedBySymbolSelector } from '../../../store/selectors'
import { RootState } from '../../../store/types'

const useStyles = makeStyles((theme) => ({
  asset: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  marketClosed: {
    color: '#999999',
  },
}))

export const AssetCell: Renderer<CellProps<Record<string, string>, string>> = ({
  value,
  row: {
    original: { symbol },
  },
}) => {
  const classes = useStyles()
  const isMarketOpened = useSelector((state: RootState) => isMarketOpenedBySymbolSelector(state, symbol))
  return (
    <div
      className={clsx(classes.asset, {
        [classes.marketClosed]: !isMarketOpened,
      })}
    >
      {value}
    </div>
  )
}

export const RenamedAssetCell: Renderer<CellProps<Record<string, string>, string>> = ({ value }) => {
  const classes = useStyles()
  const symbol = useSelector((state: RootState) => getAssetLabelSelector(state, value))
  return <span className={classes.asset}>{symbol}</span>
}
