import React, { FC } from 'react'
import MuiDialogContent, { DialogContentProps } from '@mui/material/DialogContent'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  content: {
    padding: 24,
    '& *': {
      fontSize: '.9286rem',
    },
  },
})

export const DialogContent: FC<DialogContentProps> = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <MuiDialogContent className={classes.content} {...rest}>
      {children}
    </MuiDialogContent>
  )
}
