import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { currentSymbolDigitsSelector, formPriceLimitSelector } from '../../../../store/selectors'
import { NumberInput } from '../NumberInput'
import { numberFormat } from '../../../../utils'
import { useTranslation } from 'react-i18next'

const PriceLimitInput: FC = () => {
  const { t } = useTranslation()
  const precision = useSelector(currentSymbolDigitsSelector)
  const { value, fieldInformation } = useSelector(formPriceLimitSelector)

  const handleValue = useMemo(() => {
    const multiplier = Number(`1${'0'.repeat(precision)}`)
    const numberValue = numberFormat(Math.floor(value * multiplier) / multiplier, {
      maximumFractionDigits: precision,
    })
    return numberValue ? numberValue.toString() : ''
  }, [precision, value])

  if (!precision) {
    return null
  }

  return (
    <NumberInput
      name={'priceLimit'}
      placeholder={t(`priceLimit`, 'Price limit')}
      value={handleValue}
      fieldInformation={fieldInformation}
    />
  )
}

export default PriceLimitInput
