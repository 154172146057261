import i18next from 'i18next'

import { Price, Prices } from '@bdswiss/mt4-connector'

import { LibrarySymbolInfo, SearchSymbolResultItem } from '../types/datafeed-api'
import { stringTimeToSessionTime, serverTimeZone } from '../utils'
import { ResolutionString } from '../types'

const days: { [key: string]: string } = {
  Sunday: '1',
  Monday: '2',
  Tuesday: '3',
  Wednesday: '4',
  Thursday: '5',
  Friday: '6',
  Saturday: '7',
}

function convertSessions(sessions: { [key: string]: string }) {
  return Object.keys(sessions)
    .reduce((acc: string[], day: string) => {
      const sessionsString = sessions[day]
      if (sessionsString !== '00:00-00:00') {
        const times = sessionsString.replace(/:/g, '').split('-')
        if (times.length) {
          const start = times.shift()
          const end = times.pop()
          start &&
            end &&
            acc.push(`${stringTimeToSessionTime(start, true)}-${stringTimeToSessionTime(end, true)}:${days[day]}`)
        }
      }
      return acc
    }, [])
    .join('|')
}

// Define mapper to map MT4 Price to JS API LibrarySymbolInfo
export function priceToSymbolInfo({ symbol, description, security, sessions, digits }: Price): LibrarySymbolInfo {
  return {
    name: symbol,
    full_name: symbol,
    ticker: symbol,
    description: description || '',
    type: security.replace(/([A-Za-z]+).*/, '$1'),
    session: convertSessions(sessions),
    exchange: '',
    listed_exchange: '',
    timezone: serverTimeZone,
    pricescale: Math.pow(10, digits),
    minmov: 1,
    minmove2: 0,
    has_intraday: true,
    supported_resolutions: ['1S', '1', '5', '15', '30', '60', '240', '1D', '1W', '1M'] as ResolutionString[],
    intraday_multipliers: ['1', '5', '15', '30', '60', '240'],
    has_seconds: true,
    seconds_multipliers: ['1'],
    has_daily: true,
    has_weekly_and_monthly: true,
    visible_plots_set: 'ohlcv',
    volume_precision: 3,
    data_status: 'streaming',
    format: 'price',
  }
}

interface SymbolInfoMap {
  [symbol: string]: LibrarySymbolInfo | undefined
}

export class SymbolsStorage {
  private static instance: SymbolsStorage
  private readonly symbolsList: string[] = []
  private prices: Prices = {}

  public readonly symbolsInfo: SymbolInfoMap = {}

  // BEWARE: this function does not consider symbol's exchange
  public resolveSymbol(symbolName: string): Promise<LibrarySymbolInfo> {
    const symbolInfo = this.symbolsInfo[symbolName]
    if (symbolInfo === undefined) {
      return Promise.reject(i18next.t('invalidSymbol'))
    }
    return Promise.resolve(symbolInfo)
  }

  public searchSymbols(
    searchString: string,
    exchange: string,
    symbolType: string,
    maxSearchResults: number,
  ): Promise<SearchSymbolResultItem[]> {
    interface WeightedItem {
      symbolInfo: LibrarySymbolInfo
      weight: number
    }

    const weightedResult: WeightedItem[] = []
    const queryIsEmpty = searchString.length === 0

    searchString = searchString.toUpperCase()
    for (const symbolName of this.symbolsList) {
      const symbolInfo = this.symbolsInfo[symbolName]

      if (symbolInfo === undefined) {
        continue
      }

      if (symbolType.length > 0 && symbolInfo.type !== symbolType) {
        continue
      }

      if (exchange && exchange.length > 0 && symbolInfo.exchange !== exchange) {
        continue
      }

      const positionInName = symbolInfo.name.toUpperCase().indexOf(searchString)
      const positionInDescription = symbolInfo.description.toUpperCase().indexOf(searchString)

      if (queryIsEmpty || positionInName >= 0 || positionInDescription >= 0) {
        const alreadyExists = weightedResult.some((item: WeightedItem) => item.symbolInfo === symbolInfo)
        if (!alreadyExists) {
          const weight = positionInName >= 0 ? positionInName : 8000 + positionInDescription
          weightedResult.push({ symbolInfo, weight })
        }
      }
    }

    const result = weightedResult
      .sort((item1: WeightedItem, item2: WeightedItem) => item1.weight - item2.weight)
      .slice(0, maxSearchResults)
      .map((item: WeightedItem) => {
        const symbolInfo = item.symbolInfo
        return {
          symbol: symbolInfo.name,
          full_name: symbolInfo.full_name,
          description: symbolInfo.description,
          exchange: symbolInfo.exchange,
          params: [],
          type: symbolInfo.type,
          ticker: symbolInfo.name,
        }
      })

    return Promise.resolve(result)
  }

  public _init(prices: Prices): void {
    this.prices = prices
    Object.entries(prices).forEach(([symbol, asset]) => {
      this.symbolsInfo[symbol] = priceToSymbolInfo(asset)
      this.symbolsList.push(symbol)
    })
    this.symbolsList.sort()
    // console.log('SymbolsStorage: All exchanges data loaded');
  }

  public getPrices(): Prices {
    return this.prices
  }

  public static get Instance(): SymbolsStorage {
    return this.instance || (this.instance = new this())
  }
}
