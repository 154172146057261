import React, { ChangeEvent, FC, FormEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Popover from '@mui/material/Popover'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import clsx from 'clsx'

import {
  accountsDataGuidedTourSelector,
  activeFeedPanelSelector,
  currentIdAccountSelector,
  downloadAppBannerSelector,
  loginSelector,
} from '../../../store/selectors'
import { AccountCard } from './AccountCard/AccountCard'
import { AccountInfo } from './AccountInfo/AccountInfo'
import { updateAccountLogin } from '../../../store/actions'
import { backToAccountsHandle, changeAccountHandle, isMobile } from '../../../utils'
import { AccountCardMobile } from './AccountCard/AccountCardMobile'
import { ReactComponent as MobileArrowDownIcon } from '../../../assets/images/icons/mobile/arrow-down.svg'
import { ReactComponent as MobileArrowUpIcon } from '../../../assets/images/icons/mobile/arrow-up.svg'

const POPOVER_TOP_POSITION_DESKTOP = 66
const POPOVER_TOP_POSITION_MOBILE_FULLWIDTH = 130
const POPOVER_TOP_POSITION_MOBILE_FULLWIDTH_WITH_BANNER = 186
const POPOVER_TOP_POSITION_MOBILE_COMPACT = 67
const POPOVER_TOP_POSITION_MOBILE_COMPACT_WITH_BANNER = 123
const POPOVER_LEFT_POSITION_MOBILE = 5
const POPOVER_LEFT_POSITION_DESKTOP = 40
const POPOVER_LEFT_POSITION_DESKTOP_ACTIVE_FEED = 240

const useStyles = makeStyles((theme) => ({
  menuItemAccount: {
    display: 'flex',
    padding: '10px',
    '&.mobile': {
      background: theme.palette.background.default,
      borderRadius: '8px',
      paddingTop: '0px',
      paddingBottom: '0px',
      '&.active': {
        position: 'relative',
        zIndex: '99999',
        background: theme.palette.mode === 'light' ? theme.palette.common.white : '#1F1F1F',
      },
    },
  },
  accountContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '5px 0px',
  },
  fullWidthAccountContainer: {
    flexGrow: 1000,
    margin: '15px 6px 0px 6px',
    border: theme.palette.mode === 'light' ? '1px solid #EBEBEB' : '0',
    borderRadius: '8px',
    background: theme.palette.mode === 'light' ? '#FFFFFF' : '#1F1F1F',
    justifyContent: 'space-between',
  },
  accounts: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 0 0',
    '&.mobile': {
      padding: '15px 0 0',
    },
  },
  dropDownArrow: {
    marginLeft: '10px',
    marginTop: '9px',
    '&.mobile': {
      '& svg path': {
        fill: theme.palette.mode === 'light' ? '#858585' : '#999999',
      },
    },
  },
  dropDownArrowFullWidth: {
    marginRight: '5px',
  },
  button: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    margin: '0 16px',
    letterSpacing: '0.55px',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.04)',
    },
  },
  popover: {
    right: '5px',
    '& .MuiPaper-rounded': {
      borderRadius: 0,
    },
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(.8)',
      transformOrigin: 'top right',
    },
  },
  popoverMobile: {
    '& .MuiPaper-root': {
      width: '95%',
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : '#1F1F1F',
    },
    '& .MuiPaper-rounded': {
      borderRadius: '8px',
      boxShadow: 'none',
    },
  },
}))

interface Props {
  variant?: 'normal' | 'fullWidth'
}

export const AccountDetails: FC<Props> = ({ variant }) => {
  const isMobileDevice = isMobile()
  const classes = useStyles()
  const dispatch = useDispatch()
  const accounts = useSelector(accountsDataGuidedTourSelector)
  const currentAccountId = useSelector(currentIdAccountSelector)
  const currentAccountLogin = useSelector(loginSelector)
  const bannerShown = useSelector(downloadAppBannerSelector)
  const activePanel = useSelector(activeFeedPanelSelector)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const open = !!anchorEl
  const id = open ? 'accounts-popover' : undefined
  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLButtonElement>) => {
      const accountId = event.currentTarget.value || event.currentTarget.dataset.value
      if (accountId) {
        changeAccountHandle(accountId)
        dispatch(updateAccountLogin(accountId))
      }

      handleClose()
    },
    [dispatch, handleClose],
  )

  const { t } = useTranslation()
  const arrowUpIcon = isMobileDevice ? <MobileArrowUpIcon /> : <KeyboardArrowUpIcon />
  const arrowDownIcon = isMobileDevice ? <MobileArrowDownIcon /> : <KeyboardArrowDownIcon />

  let popoverTopPosition = POPOVER_TOP_POSITION_DESKTOP
  let popoverLeftPosition = POPOVER_LEFT_POSITION_MOBILE

  if (!isMobileDevice) {
    popoverLeftPosition = window.innerWidth - POPOVER_LEFT_POSITION_DESKTOP
    if (activePanel)
      popoverLeftPosition =
        window.innerWidth - POPOVER_LEFT_POSITION_DESKTOP - POPOVER_LEFT_POSITION_DESKTOP_ACTIVE_FEED
  }

  if (isMobileDevice) {
    popoverTopPosition =
      variant === 'fullWidth' ? POPOVER_TOP_POSITION_MOBILE_FULLWIDTH : POPOVER_TOP_POSITION_MOBILE_COMPACT
    if (bannerShown) {
      popoverTopPosition =
        variant === 'fullWidth'
          ? POPOVER_TOP_POSITION_MOBILE_FULLWIDTH_WITH_BANNER
          : POPOVER_TOP_POSITION_MOBILE_COMPACT_WITH_BANNER
    }
  }

  const mobileHandleClose = useCallback(() => {
    isMobileDevice && open && handleClose()
  }, [isMobileDevice, open, handleClose])

  if (!currentAccountLogin) {
    return null
  }

  return (
    <div
      onClick={mobileHandleClose}
      className={clsx(
        classes.menuItemAccount,
        isMobileDevice ? 'mobile' : '',
        isMobileDevice && open && variant !== 'fullWidth' ? 'active' : '',
      )}
    >
      <div
        onClick={handleClick}
        className={clsx(classes.accountContainer, variant === 'fullWidth' ? classes.fullWidthAccountContainer : '')}
      >
        {accounts.map((account) => (
          <AccountInfo
            key={account.id}
            login={account.login}
            accountName={account.accountName}
            accountSubtype={account.accountSubtype}
            currentAccountLogin={currentAccountLogin}
            swapFree={account.swapFree}
            isDemo={account.isDemo}
            hidden={account.hidden}
            currency={account.currency || 'USD'}
            balance={account.balance}
            showExtendedInfo={variant === 'fullWidth'}
            account={account}
          />
        ))}
        {!accounts.length ? null : (
          <div
            className={clsx(
              classes.dropDownArrow,
              isMobileDevice ? 'mobile' : '',
              isMobileDevice ? classes.dropDownArrowFullWidth : '',
            )}
          >
            {anchorEl ? arrowUpIcon : arrowDownIcon}
          </div>
        )}
      </div>

      <Popover
        transitionDuration={{ enter: 350, exit: 500 }}
        TransitionComponent={Fade}
        id={id}
        open={open}
        anchorReference="anchorPosition"
        className={!isMobileDevice ? classes.popover : classes.popoverMobile}
        anchorPosition={{ top: popoverTopPosition, left: popoverLeftPosition }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        BackdropProps={{ invisible: !isMobileDevice }}
      >
        <div className={clsx(classes.accounts, isMobileDevice ? 'mobile' : '')}>
          <FormControl component={'fieldset'}>
            <RadioGroup aria-label="accountType" name="accountType" value={currentAccountLogin} onChange={handleChange}>
              {accounts
                .filter((e) => !e.hidden)
                .map((account, index) =>
                  !isMobileDevice ? (
                    <AccountCard
                      key={account.id}
                      login={account.login}
                      balance={account.balance}
                      currency={account.currency || 'USD'}
                      accountName={account.accountName}
                      accountSubtype={account.accountSubtype}
                      selected={currentAccountLogin === account.login}
                      swapFree={account.swapFree}
                      isDemo={account.isDemo}
                      isLast={accounts.length - 1 === index}
                    />
                  ) : (
                    <AccountCardMobile
                      key={account.id}
                      login={account.login}
                      balance={account.balance}
                      currency={account.currency || 'USD'}
                      accountName={account.accountName}
                      accountSubtype={account.accountSubtype}
                      selected={currentAccountLogin === account.login}
                      swapFree={account.swapFree}
                      isDemo={account.isDemo}
                      isMt5={account.__typename?.includes('MT5')}
                      isReadOnly={account.isReadOnly}
                    />
                  ),
                )}
            </RadioGroup>
          </FormControl>
          {!isMobileDevice && (
            <Button onClick={backToAccountsHandle.bind(null, currentAccountId)} className={classes.button}>
              {t('backToDashboard')}
            </Button>
          )}
        </div>
      </Popover>
    </div>
  )
}
