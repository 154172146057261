import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Avatar, Radio } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'
import { BdSwissForexMt4AccountType } from '../../../../services/graphql/types'
import { loginInfoSelector } from '../../../../store/selectors'
import { capitalize, currencyFormat } from '../../../../utils'
import { ReactComponent as CheckedIcon } from '../../../../assets/images/icons/checked.svg'
import { ReactComponent as ReadOnlyIcon } from '../../../../assets/images/icons/readonly.svg'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  dropdownItem: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0px 15px 15px 15px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  accounts: {
    width: '100%',
    display: 'flex',
  },
  accountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '15px',
    width: '100%',
    alignItems: 'center',
  },
  accountDiv: {
    textAlign: 'left',
  },
  accountBalance: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    color: theme.palette.mode === 'light' ? '#858585' : '#999999',
    whiteSpace: 'nowrap',
    lineHeight: '12px',
    fontWeight: '400',
  },
  radioInput: {
    '& svg path': {
      fill: theme.palette.text.primary,
    },
  },
  avatar: {
    borderRadius: '4px',
    width: '32px',
    height: '32px',
  },
  avatarText: {
    fontWeight: '510',
    fontSize: '10px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.066px',
  },
  raw: {
    color: theme.palette.mode === 'light' ? '#6F57FD' : '#8A78F9',
    backgroundColor: theme.palette.mode === 'light' ? '#E5E7FF' : '#1F2266',
  },
  premium: {
    color: theme.palette.mode === 'light' ? '#CC33CC' : '#E55CE5',
    backgroundColor: theme.palette.mode === 'light' ? '#FFEBFF' : '#491B49',
  },
  black: {
    color: theme.palette.mode === 'light' ? '#CC33CC' : '#E55CE5',
    backgroundColor: theme.palette.mode === 'light' ? '#FFEBFF' : '#491B49',
  },
  basic: {
    color: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
    backgroundColor: theme.palette.mode === 'light' ? '#D6EAFF' : '#0C3059',
  },
  cent: {
    color: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
    backgroundColor: theme.palette.mode === 'light' ? '#D6EAFF' : '#0C3059',
  },
  invest_plus: {
    color: theme.palette.mode === 'light' ? '#00B594' : '#00AD7B',
    backgroundColor: theme.palette.mode === 'light' ? '#DCF6F5' : '#0C393C',
  },
  greyed: {
    color: theme.palette.mode === 'light' ? '#707070' : '#C2C2C2',
    backgroundColor: theme.palette.mode === 'light' ? '#EBEBEB' : '#474747',
  },
  accountSvg: {
    color: theme.palette.text.primary,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    '&.raw > svg path': {
      fill: theme.palette.mode === 'light' ? '#6F57FD' : '#8A78F9',
    },
    '&.basic > svg path': {
      fill: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
    },
    '&.cent > svg path': {
      fill: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
    },
    '&.premium > svg path': {
      fill: theme.palette.mode === 'light' ? '#CC33CC' : '#E55CE5',
    },
    '&.black > svg path': {
      fill: theme.palette.mode === 'light' ? '#CC33CC' : '#E55CE5',
    },
    '&.invest_plus > svg path': {
      fill: theme.palette.mode === 'light' ? '#00B594' : '#00AD7B',
    },
    '&.greyed > svg path': {
      fill: theme.palette.mode === 'light' ? '#707070' : '#C2C2C2',
    },
  },
  readOnlySvg: {
    color: theme.palette.mode === 'light' ? '#ED1C24' : '#FC4950',
    fontSize: '11px',
    lineHeight: '12px',
    paddingLeft: '7.5px',
    display: 'flex',
    alignItems: 'center',
    '& svg path': {
      fill: theme.palette.mode === 'light' ? '#ED1C24' : '#FC4950',
    },
  },
}))

interface AccountCardMobileProps {
  login: BdSwissForexMt4AccountType['login']
  balance: BdSwissForexMt4AccountType['balance']
  accountName: BdSwissForexMt4AccountType['accountName']
  swapFree: BdSwissForexMt4AccountType['swapFree']
  accountSubtype: BdSwissForexMt4AccountType['accountSubtype']
  isDemo: BdSwissForexMt4AccountType['isDemo']
  isReadOnly: BdSwissForexMt4AccountType['isReadOnly']
  selected: boolean
  currency: string
  isMt5: boolean
}

export const AccountCardMobile: FC<AccountCardMobileProps> = ({
  selected,
  login,
  balance,
  accountName,
  accountSubtype,
  swapFree,
  currency,
  isDemo,
  isMt5,
  isReadOnly,
}) => {
  const classes = useStyles()
  const loginInfo = useSelector(loginInfoSelector)
  const accountSubTypeClassKey = (accountSubtype || 'greyed') as keyof typeof classes
  return (
    <button className={classes.dropdownItem}>
      <label className={classes.itemContainer}>
        <div className={classes.accounts}>
          <div>
            <Avatar
              variant="square"
              className={clsx(
                classes.avatar,
                isDemo || (!isDemo && isReadOnly) ? classes.greyed : classes[accountSubTypeClassKey],
              )}
            >
              <div className={classes.avatarText}>{isMt5 ? 'MT5' : 'MT4'}</div>
            </Avatar>
          </div>
          <div className={classes.accountRow}>
            <div className={classes.accountDiv}>
              <div>
                {!!accountName ? (
                  accountName
                ) : (
                  <div
                    className={clsx(
                      isDemo || (!isDemo && isReadOnly) ? 'greyed' : accountSubTypeClassKey,
                      classes.accountSvg,
                    )}
                  >
                    {swapFree && (
                      <>
                        <Trans i18nKey={'swapFree'} defaults={'Swap Free'} />
                        &nbsp;
                      </>
                    )}
                    <>
                      <Trans
                        i18nKey={`${isDemo ? 'practice' : 'real'}${capitalize(
                          `${accountSubtype ? accountSubtype : ''}`,
                        )}Account`}
                      />
                    </>
                    <>{` · #${login}`}</>
                  </div>
                )}
              </div>
              <div className={classes.accountBalance}>
                <Trans i18nKey={'balance'} />
                {!!balance && ':'}&nbsp;
                {currencyFormat((selected ? loginInfo?.balance : balance) || 0, currency)}
                {isReadOnly && (
                  <div className={classes.readOnlySvg}>
                    <ReadOnlyIcon />
                    &nbsp;
                    <Trans i18nKey={'readOnly'} defaults={'Read-only'} />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.radioInput}>
              <Radio size={'small'} value={login} checked={selected} checkedIcon={<CheckedIcon />} icon={<></>} />
            </div>
          </div>
        </div>
      </label>
    </button>
  )
}
