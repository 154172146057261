import React, { FC } from 'react'
import spinner from '../../images/spinner.gif'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

import { Logo } from '../Logo'
import { getCompanyName } from '../../utils'

const companyName = getCompanyName()

const useStyles = makeStyles(() => ({
  loadingLogo: {
    width: '50px',
  },
  spinnerContainer: {
    height: '100%',
    fontSize: '1.2em',
  },
  quote: {
    fontWeight: 900,
  },
}))

export const Spinner: FC = () => {
  const { loadingLogo, spinnerContainer, quote } = useStyles()
  const { t } = useTranslation()
  return (
    <Grid className={spinnerContainer} container direction={'column'} justifyContent={'center'} alignItems={'center'}>
      <Grid item>
        <Logo variant={'loader'} />
      </Grid>

      <Grid item>
        <div className={quote}>
          &quot;
          {t(`wtLoaderMsg${Math.floor(Math.random() * 6) + 1}`, { company: companyName })}
          &quot;
        </div>
        <div>- {t('wtLoaderBdswiss', { company: companyName })}</div>
      </Grid>
      <Grid item>
        <img className={loadingLogo} alt="Loading..." src={spinner} />
      </Grid>
    </Grid>
  )
}
