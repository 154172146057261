import { CellProps, Renderer } from 'react-table'
import React from 'react'
import { Trans } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { RootState } from '../../../store/types'
import { isMarketOpenedBySymbolSelector } from '../../../store/selectors'

const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  marketClosed: {
    color: '#999999',
  },
}))

export const TextCell: Renderer<CellProps<Record<string, unknown>, string>> = ({
  value,
  row: {
    original: { symbol },
  },
}) => {
  const classes = useStyles()

  const isMarketOpened = useSelector((state: RootState) => isMarketOpenedBySymbolSelector(state, symbol as string))
  return (
    <div
      className={clsx(classes.root, {
        [classes.marketClosed]: !isMarketOpened,
      })}
    >
      <Trans i18nKey={value} />
    </div>
  )
}
