import { createSelector } from 'reselect'

import { Account, RootState } from '../types'
import { loginSelector } from './app'
import { guidedTourRunSelector } from './guidedTour'
import { LeverageType } from '../../enums'
import { includes } from 'lodash'

export const accountsDataSelector = (state: RootState): Account[] => state.accounts.data

export const filteredAccountsDataSelector = createSelector(accountsDataSelector, (accounts) =>
  accounts.filter(
    (account) => 'id' in account && !/PAMM|IntroducingBroker|Affiliate|BitnukWallet/.test(account.__typename),
  ),
)

export const currentAccountSelector = createSelector(accountsDataSelector, loginSelector, (accounts, login) =>
  accounts.find((acc) => acc.login === login),
)

export const currentIdAccountSelector = createSelector(currentAccountSelector, (account) =>
  account ? `${account.id}` : '',
)

export const accountsDataGuidedTourSelector = createSelector(
  filteredAccountsDataSelector,
  guidedTourRunSelector,
  (accounts, guidedTourRun) => {
    const exludeCopyTradingAccountSubTypes = ['copy_classic', 'copy_zero', 'copy_cent', 'copy_vip']
    return guidedTourRun
      ? accounts
      : accounts.filter(
          (e) => !e.hidden && !e.isArchived && !includes(exludeCopyTradingAccountSubTypes, e.accountSubtype),
        )
  },
)

export const getLoginFirstAccountSelector = createSelector(
  filteredAccountsDataSelector,
  (accounts) => accounts.length && accounts[0].login,
)

export const getLoginFirstDemoAccountSelector = createSelector(filteredAccountsDataSelector, (accounts) => {
  const demoAccounts = accounts.filter((e) => e.isDemo)
  return (demoAccounts.length && demoAccounts[0].login) || undefined
})

export const getLoginFirstRealAccountSelector = createSelector(filteredAccountsDataSelector, (accounts) => {
  const realAccounts = accounts.filter((e) => !e.isDemo)
  return (realAccounts.length && realAccounts[0].login) || undefined
})

export const getAccountLeverageTypeSelector = createSelector(
  currentAccountSelector,
  (account): LeverageType | undefined => account?.leverageType as LeverageType,
)

export const isCurrentAccountLeverageTypeDynamicSelector = createSelector(
  getAccountLeverageTypeSelector,
  (leverageType) => leverageType === LeverageType.dynamic,
)
