import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TooltipRenderProps } from 'react-joyride'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { i18n } from 'i18next'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

import {
  accountsDataSelector,
  getLoginFirstDemoAccountSelector,
  getLoginFirstRealAccountSelector,
} from '../../../store/selectors'
import { runGuidedTour } from '../../../store/actions'

const useStyles = makeStyles<Theme, Pick<i18n, 'language'>>((theme) => {
  return {
    tooltipBody: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
      maxWidth: ({ language }) => (language === 'ru' ? '400px' : '300px'),
      position: 'relative',
      padding: 10,
      borderRadius: 4,
      boxShadow:
        theme.palette.mode === 'dark'
          ? '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(255 255 255 / 25%)'
          : '',
    },
    tooltipContent: {
      textAlign: 'justify',
      color: theme.palette.text.secondary,
      padding: '5px',
    },
    tooltipTitle: {
      color: '#f04',
      margin: 0,
    },
    tooltipFooter: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      backgroundColor: '#007AFF',
      color: '#fff',
      textTransform: 'none',
      border: 0,
      borderRadius: '4px',
      cursor: 'pointer',
      maxWidth: '180px',
      fontSize: '16px',
      lineHeight: 1,
      padding: 8,
      appearance: 'none',
    },
  }
})

export const TryTradingStep: FC<TooltipRenderProps> = ({
  step,
  tooltipProps,
  closeProps: { onClick: onClickFinish },
}) => {
  const { i18n } = useTranslation()
  const classes = useStyles({ language: i18n.language })
  const { path } = useRouteMatch()
  const history = useHistory()
  const accounts = useSelector(accountsDataSelector)
  const loginDemo = useSelector(getLoginFirstDemoAccountSelector)
  const loginReal = useSelector(getLoginFirstRealAccountSelector)
  const dispatch = useDispatch()
  const savedLogin = localStorage.getItem('loginToReturnFromGuideTour')
  const savedAccount = accounts.find((account) => account.login === savedLogin)

  const changeAccount = useCallback(
    (accountId?: string) => {
      accountId && history.push(generatePath(path, { accountId }) + '/trade')
    },
    [history, path],
  )

  const demoTradingHandle = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      dispatch(runGuidedTour(false))
      localStorage.removeItem('loginToReturnFromGuideTour')
      savedLogin ? changeAccount(savedAccount?.isDemo ? savedLogin : loginDemo) : changeAccount(loginDemo)
      onClickFinish(e)
    },
    [changeAccount, dispatch, loginDemo, onClickFinish, savedAccount, savedLogin],
  )

  const liveTradingHandle = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      dispatch(runGuidedTour(false))
      localStorage.removeItem('loginToReturnFromGuideTour')
      savedLogin ? changeAccount(!savedAccount?.isDemo ? savedLogin : loginReal) : changeAccount(loginReal)
      onClickFinish(e)
    },
    [changeAccount, dispatch, loginReal, onClickFinish, savedAccount, savedLogin],
  )

  const buttonDemo = loginDemo && (
    <button className={classes.button} onClick={demoTradingHandle}>
      <Trans i18nKey={'guidedTourDemoButton'} defaultValue={'Try demo trading'} />
    </button>
  )
  const buttonReal = loginReal && (
    <button className={classes.button} onClick={liveTradingHandle}>
      <Trans i18nKey={'guidedTourLiveButton'} defaultValue={'Try live trading'} />
    </button>
  )

  return (
    <div className={classes.tooltipBody} {...tooltipProps}>
      {step.title && <h2 className={classes.tooltipTitle}>{step.title}</h2>}
      <div className={classes.tooltipContent}>{step.content}</div>
      <div className={classes.tooltipFooter}>
        {buttonDemo}
        {buttonReal}
      </div>
    </div>
  )
}
