import { createReducer } from 'typesafe-actions'
import { ActionTypes } from '../types'
import { mt4Login, openLoadingDialog, runGuidedTour } from '../actions'

export type GuidedTourState = {
  isGuidedTourRun: boolean
  isShowLoadingDialog: boolean
}

const initialState: GuidedTourState = {
  isGuidedTourRun: false,
  isShowLoadingDialog: false,
}

export const guidedTour = createReducer<GuidedTourState, ActionTypes>(initialState)
  .handleAction(runGuidedTour, (state, { payload }) => ({
    ...state,
    isGuidedTourRun: payload,
  }))
  .handleAction(mt4Login, (state) => ({ ...state, isShowLoadingDialog: false }))
  .handleAction(openLoadingDialog, (state) => ({ ...state, isShowLoadingDialog: true }))
