import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { VariableSizeList } from 'react-window'
import { expandedReducer, TableContext, VirtualizedTable, VirtualizedTableProps } from '../../VirtualizedTable'
import { closedPositionsSelector, currentAccountSelector } from '../../../store/selectors'
import { MoreCell, CloseDateCell } from './HistoryTableColumns'
import { Trade } from '../../../services/graphql/types'
import { HistorySubComponent } from './HistorySubComponent'
import {
  FavouriteCell,
  ValueCell,
  DirectionCell,
  ProfitCell,
  OrderCell,
  VolumeCell,
  RenamedAssetCell,
} from '../CommonCells'
import { trackEvent } from '../../../analytics/firebaseLogger'
import { expandClosedOrder } from '../../../analytics/events'
import { clearActiveSignal } from '../../../store/actions'
import { isMobile } from '../../../utils'
import { removeTrailingSlash } from '../../../utils'
import { generatePath, useHistory, useRouteMatch } from 'react-router'
import { MobileAssetCell } from '../../mobile/Common'

const estimatedItemSize = !isMobile() ? 36 : 55
const estimatedOpenedItemSize = 68

export const HistoryTable: FC = () => {
  const { t } = useTranslation()
  const data = useSelector(closedPositionsSelector)
  const [expandedPositionsState, expandedPositionsDispatch] = useReducer(expandedReducer, {})
  const listRef = useRef<VariableSizeList>(null)
  const dispatch = useDispatch()
  const isMobileDevice = isMobile()
  const mobileColumns = useMemo<VirtualizedTableProps<Trade>['columns']>(
    () => [
      {
        Header: '',
        id: 'customCell',
        accessor: (row) => row,
        width: 120,
        Cell: MobileAssetCell,
      },
    ],
    [],
  )
  const columns = useMemo<VirtualizedTableProps<Trade>['columns']>(
    () => [
      {
        Header: '',
        id: 'more',
        width: 20,
        Cell: MoreCell,
      },
      {
        Header: '',
        id: 'favorite',
        accessor: 'symbol',
        width: 50,
        Cell: FavouriteCell,
      },
      {
        Header: t('asset').toString(),
        accessor: 'symbol',
        width: 130,
        Cell: RenamedAssetCell,
      },
      {
        Header: `${t('order')} #`,
        accessor: 'id',
        width: 130,
        Cell: OrderCell,
      },
      {
        Header: t('direction').toString(),
        accessor: 'type',
        width: 130,
        Cell: DirectionCell,
      },
      {
        Header: t('value').toString(),
        accessor: 'volume',
        width: 130,
        Cell: VolumeCell,
      },
      {
        Header: t('profit').toString(),
        accessor: 'profit',
        width: 170,
        Cell: ProfitCell,
      },
      {
        Header: t('openPrice').toString(),
        accessor: 'openPrice',
        width: 170,
        Cell: ValueCell,
      },
      {
        Header: t('closePrice').toString(),
        accessor: 'closePrice',
        width: 260,
        Cell: ValueCell,
      },
      {
        Header: t('closeDate').toString(),
        accessor: 'closeTime',
        width: 260,
        Cell: CloseDateCell,
      },
    ],
    [t],
  )

  const trackExpandEvent = useCallback(
    (tradeId?: string) => {
      const integralId = parseInt(tradeId || '')
      if (integralId && !expandedPositionsState[integralId]) {
        trackEvent(expandClosedOrder)
      }
    },
    [expandedPositionsState],
  )

  const toggleExpanded = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      const { id } = e.currentTarget.dataset
      trackExpandEvent(id)
      dispatch(clearActiveSignal())
      id && expandedPositionsDispatch?.({ type: 'toggle', key: id })
    },
    [expandedPositionsDispatch, trackExpandEvent, dispatch],
  )

  const getItemSize = useCallback(
    (index: number) => {
      const asset = data[index]

      return asset.id && expandedPositionsState[asset.id] ? estimatedOpenedItemSize : estimatedItemSize
    },
    [expandedPositionsState, data],
  )

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.resetAfterIndex(0)
    }
  }, [listRef, data, expandedPositionsState])

  const history = useHistory()
  const { path } = useRouteMatch()
  const login = useSelector(currentAccountSelector)?.login
  const mobileRowClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      const { id, symbol } = e.currentTarget.dataset
      if (id && symbol && login) {
        const currentPath = generatePath(`${removeTrailingSlash(path)}`, { accountId: login })
        const historyDetailsPath = generatePath(`${currentPath}/${id}`)
        const historyDetails = data.find((h) => h.id === id)
        trackExpandEvent(id)
        history.push({ pathname: historyDetailsPath, state: historyDetails })
      }
    },
    [history, login, path, data, trackExpandEvent],
  )
  return (
    <TableContext.Provider
      value={{
        keyPropertyName: 'id',
        expandedPositionsDispatch,
        expandedPositionsState,
        listRef,
      }}
    >
      <VirtualizedTable
        showHeaders={!isMobileDevice}
        showBorders={!isMobileDevice}
        columns={!isMobileDevice ? columns : mobileColumns}
        data={data}
        rowClickHandle={!isMobileDevice ? toggleExpanded : mobileRowClick}
        estimatedItemSize={estimatedItemSize}
        getItemSize={getItemSize}
        SubComponent={HistorySubComponent}
      />
    </TableContext.Provider>
  )
}
