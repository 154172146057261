import { Step } from 'react-joyride'
import { GuidedTourAction } from '../../enums'
import { LoadingStep } from './Templates/LoadingStep'
import { TryTradingStep } from './Templates/TryTradingStep'

export const tradeDemo: Step[] = [
  {
    content: 'guidedTourAssetsText',
    placement: 'left',
    target: '.guided-tour-pairs-list',
    hideCloseButton: true,
    disableBeacon: true,
  }, //0
  {
    content: 'guidedTourAssetItem',
    placement: 'left',
    target: '[data-symbol="USOIL"]',
    hideCloseButton: true,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, //1
  {
    content: 'guidedTourChart',
    placement: 'top',
    target: '[data-guided-tour="chart"]',
    spotlightPadding: 0,
    hideCloseButton: true,
  }, //2
  {
    content: 'guidedTourBuyButton',
    placement: 'top',
    target: '[data-symbol="USOIL"] .cell-buy',
    hideCloseButton: true,
    hideBackButton: true,
    // hideFooter: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, //3
  {
    content: 'guidedTourFreeMargin',
    placement: 'bottom',
    target: '[data-guided-tour="freeMarginFunds"]',
    hideCloseButton: true,
    spotlightPadding: -5,
  }, // 4
  {
    content: 'guidedTourRequiredMargin',
    placement: 'left',
    target: '[data-guided-tour="requiredMarginAmount"]',
    hideCloseButton: true,
  }, //5
  {
    content: 'guidedTourTakeProfit',
    placement: 'left',
    target: '[data-guided-tour="takeProfitChBox"] input[type="checkbox"]',
    hideCloseButton: true,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
      spotlight: {
        paddingRight: 300,
        paddingBottom: 80,
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, //6
  {
    content: 'guidedTourStopLoss',
    placement: 'left',
    target: '[data-guided-tour="stopLossChBox"] input[type="checkbox"]',
    hideCloseButton: true,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
      spotlight: {
        paddingRight: 300,
        paddingBottom: 80,
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, //7
  {
    content: 'guidedTourPlaceTrade',
    placement: 'left',
    target: '[data-guided-tour="clickSellBuyButton"]',
    hideCloseButton: true,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, //8
  {
    content: 'guidedTourOpenPositions',
    placement: 'top',
    target: '[data-symbol="USOIL"] table ',
    hideCloseButton: true,
  }, // 9
  {
    content: 'guidedTourProfit',
    placement: 'top',
    target: '[data-symbol="USOIL"] [data-guided-tour="profitCell"]',
    hideCloseButton: true,
  }, //10
  {
    content: 'guidedTourEquity',
    placement: 'bottom',
    target: '[data-guided-tour="equityBalance"]',
    hideCloseButton: true,
    spotlightPadding: -5,
  }, //11
  {
    content: 'guidedTourFreeMarginChanging',
    placement: 'bottom',
    target: '[data-guided-tour="freeMarginFunds"]',
    hideCloseButton: true,
    spotlightPadding: -5,
  }, //12
  {
    content: 'guidedTourOpenAnotherPosition',
    placement: 'top',
    target: '[data-symbol="EURUSD"] .cell-sell',
    hideCloseButton: true,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, //13
  {
    content: 'guidedTourTapToOpenPosition',
    placement: 'left',
    target: '[data-guided-tour="clickSellBuyButton"]',
    hideCloseButton: true,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, // 14
  {
    tooltipComponent: LoadingStep,
    content: 'guidedTourModalRemaining',
    placement: 'center',
    target: 'body',
    hideCloseButton: true,
    hideBackButton: true,
  }, //15
  {
    content: 'guidedTourProfitItem',
    placement: 'top',
    target: '[data-symbol="EURUSD"] [data-guided-tour="profitCell"]',
    hideCloseButton: true,
  }, //16
  {
    content: 'guidedTourLossItem',
    placement: 'top',
    target: '[data-symbol="USOIL"] [data-guided-tour="profitCell"]',
    hideCloseButton: true,
  }, //17
  {
    content: 'guidedTourCloseOpenPositions',
    placement: 'right',
    target: '[data-guided-tour="openPositions"]',
    hideCloseButton: true,
    hideBackButton: true,
    spotlightPadding: -3,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, //18
  {
    content: 'guidedTourCloseAll',
    placement: 'left',
    target: '[data-guided-tour="closeAllBtn"]',
    hideCloseButton: true,
    hideBackButton: true,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  }, // 23
  {
    content: 'guidedTourConfirmToCloseAllPositions',
    placement: 'bottom',
    target: '[data-guided-tour="confirm"]',
    hideCloseButton: true,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  },
  {
    content: 'guidedTourEarned',
    placement: 'bottom',
    target: '[data-guided-tour="equityBalance"]',
    hideCloseButton: true,
    spotlightPadding: -5,
    hideFooter: true,
    spotlightClicks: true,
    nonce: GuidedTourAction.tap,
  },
  {
    tooltipComponent: TryTradingStep,
    content: 'guidedTourCompletedText',
    placement: 'right',
    showProgress: false,
    spotlightPadding: -3,
    target: '[data-guided-tour="start"]',
    hideCloseButton: true,
  }, // 26
]
