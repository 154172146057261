import React, { useState, useMemo, useCallback, createContext, ReactElement, FC } from 'react'
import { ThemeProvider as MuiThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import { getThemeByName } from './themes'
import { setCookie, getCookie } from '../../utils'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type ThemeName = 'Dark' | 'Light'
type ThemeProviderProps = {
  setTheme: (theme: ThemeName | ((theme: ThemeName) => ThemeName)) => void
  theme: ThemeName
}

export const ThemeContext = createContext<ThemeProviderProps>({
  setTheme: (name): void => {
    void name
    throw new Error('ThemeContext not initialized')
  },
  theme: 'Light',
})

const initialTheme = (getCookie('theme') || 'Light') as ThemeName

export const ThemeProvider: FC = ({ children }): ReactElement => {
  const [themeName, _setThemeName] = useState<ThemeName>(initialTheme)
  const theme = useMemo(() => getThemeByName(themeName), [themeName])

  const setThemeName = useCallback((_theme: ThemeName | ((theme: ThemeName) => ThemeName)): void => {
    if (typeof _theme === 'function') {
      _setThemeName((old) => {
        const newTheme = _theme(old)
        ;(async () => {
          setCookie('theme', newTheme, 30)
        })()
        return newTheme
      })
    } else {
      ;(async () => {
        setCookie('theme', _theme, 30)
      })()
      _setThemeName(_theme)
    }
  }, [])

  const context = useMemo(() => ({ theme: themeName, setTheme: setThemeName }), [themeName, setThemeName])

  return (
    <ThemeContext.Provider value={context}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  )
}
