import React, { FC, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { isArray } from 'lodash'
import { useTranslation } from 'react-i18next'

import { RootPage } from '../../pages/menuBarList'
import { changeLocation, intentOpenGuidedTour } from '../../../store/actions'
import { guidedTourRunSelector } from '../../../store/selectors'
import { MenuButtonCounter } from '../MenuButtonCounter'

interface MenuButtonProps {
  Icon: FC
  name: string
  path: RootPage['path']
  counter?: number
}

interface StyleProps {
  active: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  btnStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    gap: 2,
    flex: '1 1 100%',

    color: ({ active }) => {
      const blue = theme.palette.mode === 'light' ? '#007AFF' : '#419CFF'
      return active ? blue : '#858585'
    },
    fontSize: '.7857rem',
    textTransform: 'none',
    lineHeight: '.9286rem',
    fontWeight: 400,
    letterSpacing: '-0.5px',
    minHeight: 42,
    overflow: 'hidden',
    borderRadius: 'unset',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  title: {
    textTransform: 'none',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    textAlign: 'center',
    color: ({ active }) => {
      const blue = theme.palette.mode === 'light' ? '#007AFF' : '#419CFF'
      return active ? blue : '#858585'
    },
  },
  iconContainer: {
    height: 28,
    width: 28,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const BottomMenuButton: FC<MenuButtonProps> = ({ Icon, name, path, counter, ...props }: MenuButtonProps) => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const active = !!useRouteMatch(isArray(path) ? [] : `${url}${path}`)
  const classes = useStyles({ active })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const locationPath = location.pathname
  const guidedTour = useSelector(guidedTourRunSelector)

  return (
    <Button
      className={classes.btnStyle}
      title={t(name)}
      onClick={useCallback(() => {
        if (!path && !guidedTour) dispatch(intentOpenGuidedTour())
        if (locationPath !== url + path) {
          path && dispatch(changeLocation(isArray(path) ? path[0] : path, { prev: locationPath }))
          path && history.push(isArray(path) ? path[0] : `${url}${path}`)
        }
      }, [locationPath, url, path, dispatch, guidedTour, history])}
      disableRipple
      {...props}
    >
      <div className={classes.iconContainer}>
        <Icon />
      </div>

      <div className={classes.title}>{t(name)}</div>
      {!!counter && <MenuButtonCounter counter={counter} />}
    </Button>
  )
}
