import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material'
import { Trans } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import { TradeObject } from '@bdswiss/mt4-connector'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '../Dialog'
import { closeOrderAction } from '../../store/actions'
import { isMobile } from '../../utils'

interface IProps {
  order?: TradeObject
  onClose: () => void
}

const useStyles = makeStyles<Theme, { isMobileDevice?: boolean }>((theme) => ({
  container: {
    ...(isMobile() && {
      '& .MuiPaper-root': {
        boxShadow: 'none',
        background: theme.palette.mode === 'light' ? '#FFFFFF' : '#1F1F1F',
        borderRadius: 8,
      },
    }),
    '& .MuiDialog-paperWidthSm': {
      minWidth: ({ isMobileDevice }) => (!isMobileDevice ? 500 : 343),
    },
  },
  button: {
    width: ({ isMobileDevice }) => (!isMobileDevice ? '' : 'inherit'),
    maxHeight: ({ isMobileDevice }) => (!isMobileDevice ? 26 : 40),
    margin: 8,
    minWidth: 92,
    textTransform: 'none',
    boxShadow: '0 1px 4px 0 rgb(0 0 0 / 4%), 0 2px 8px 0 rgb(0 0 0 / 8%)',
    '&:hover': {
      boxShadow: '0 4px 16px 0 rgb(0 0 0 / 16%), 0 1px 4px 0 rgb(0 0 0 / 4%), 0 2px 8px 0 rgb(0 0 0 / 8%)',
    },
  },
  closePositionTextMobile: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
}))

export const ClosePositionDialog: FC<IProps> = ({ order, onClose }) => {
  const isMobileDevice = isMobile()
  const classes = useStyles({ isMobileDevice })
  const dispatch = useDispatch()

  const handleConfirm = useCallback(() => {
    if (order) {
      const { order: id, volume } = order
      dispatch(closeOrderAction({ order: id, volume, uuid: uuid() }))
    }
    onClose()
  }, [dispatch, onClose, order])

  return (
    <Dialog open={!!order} className={classes.container} hideBackdrop={!isMobileDevice}>
      <DialogTitle close={onClose}>
        <Typography className={isMobileDevice ? classes.closePositionTextMobile : ''}>
          <Trans i18nKey={'closingPosition'} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <Trans i18nKey={'closePositionMessage'} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          variant={'contained'}
          color={'primary'}
          onClick={handleConfirm}
          disableRipple
        >
          <Trans i18nKey={'confirm'} />
        </Button>
        <Button className={classes.button} variant={'outlined'} onClick={onClose}>
          <Trans i18nKey={'cancel'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
