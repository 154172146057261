import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import clsx from 'clsx'

import { TopMenuItem } from './TopMenuItem/TopMenuItem'
import { AccountDetails } from './AccountDetails/AccountDetails'
import {
  activeTabSelector,
  currentIdAccountSelector,
  infoSectionSelector,
  loginInfoSelector,
} from '../../store/selectors'
import { depositHandle, isMobile, numberFormat } from '../../utils'
import { MenuItemsNames } from '../pages/menuBarList'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    '&.mobile': {
      justifyContent: 'space-between',
      paddingRight: '16px',
      width: '100%',
    },
  },
  button: {
    width: '88px',
    height: '40px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textTransform: 'none',
    background: theme.palette.mode === 'light' ? '#D6EAFF' : '#133050',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#D6EAFF' : '#133050',
    },
  },
}))

export const TopMenuInfoSection: FC = () => {
  const classes = useStyles()
  const currentLogin = useSelector(loginInfoSelector)
  const accountId = useSelector(currentIdAccountSelector)
  const activeTab = useSelector(activeTabSelector)
  const { equity, freeMargin, marginLevel } = useSelector(infoSectionSelector)
  const isMobileDevice = isMobile()
  const { t } = useTranslation()
  const login = currentLogin?.login.toString() as string

  return (
    <div className={clsx(classes.root, isMobileDevice ? 'mobile' : '')}>
      {currentLogin && !isMobileDevice && (
        <>
          <TopMenuItem
            data-guided-tour="equityBalance"
            description={<Trans i18nKey="equity" />}
            hint={<Trans i18nKey="helpEquity" />}
            value={numberFormat(equity, { maximumFractionDigits: 2 })}
            currency={currentLogin.currency}
          />
          <TopMenuItem
            data-guided-tour="freeMarginFunds"
            description={<Trans i18nKey="freeMargin" />}
            hint={<Trans i18nKey="helpFreeMargin" />}
            value={numberFormat(freeMargin, { maximumFractionDigits: 2 })}
            currency={currentLogin.currency}
          />
          <TopMenuItem
            description={<Trans i18nKey="marginLevel" />}
            hint={<Trans i18nKey="helpMarginLevel" />}
            value={numberFormat(marginLevel, { maximumFractionDigits: 2 })}
            isMarginLevel
          />
        </>
      )}
      <div
      //TODO: Please verify the accountDetailsMobile class is needed.
      // className={isMobileDevice ? classes.accountDetailsMobile : ''}
      >
        {activeTab !== MenuItemsNames.more && <AccountDetails />}
      </div>

      {isMobileDevice && activeTab !== MenuItemsNames.more && (
        <Button onClick={depositHandle.bind(null, accountId, login, {})} className={classes.button}>
          {t('deposit')}
        </Button>
      )}
    </div>
  )
}
