import React, { JSX, FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'
import { Link } from '@mui/material'
import { toast } from 'react-toastify'

import { CloseTrade, TradeObject } from '@bdswiss/mt4-connector'

import { changeLocation, selectedOpenedPosition, selectedPendingPosition } from '../../store/actions'
import { capitalize, getCurrentLocation } from '../../utils'
import { Location } from '../../enums'
import config from '../../config'

const link = `${config.get('dashboardUrl')}/login` || `${getCurrentLocation()}/login`

export const mt4ValidationErrorNotify = (): JSX.Element => <Trans i18nKey="mt4ValidationError" />

export const mt4SystemErrorNotify = (): JSX.Element => (
  <Trans i18nKey="mt4SystemError">
    <Link href={link} target="_blank" />
  </Trans>
)

export const mt4AccessDeniedNotify = (): JSX.Element => (
  <Trans i18nKey="mt4AccessDenied">
    <Link href={link} target="_blank" />
  </Trans>
)

export const mt4InvalidCredentialNotify = (): JSX.Element => (
  <Trans i18nKey="mt4InvalidCredential">
    <Link href={link} target="_blank" />
  </Trans>
)

export const mt4InvestmentToLowNotify = (): JSX.Element => (
  <Trans i18nKey="mt4InvestmentToLow">
    <Link href={link} target="_blank" />
  </Trans>
)

export const mt4InvestmentToHighNotify = (): JSX.Element => (
  <Trans i18nKey="mt4InvestmentToHigh">
    <Link href={link} target="_blank" />
  </Trans>
)

export const mt4InvalidOrderNotify = (): JSX.Element => (
  <Trans i18nKey="mt4InvalidOrder">
    <Link href={link} target="_blank" />
  </Trans>
)

export const orderOpenNotify = (props: TradeObject): void => {
  toast.success(<OrderOpenNotify {...props} />)
}

export const OrderOpenNotify: FC<TradeObject> = ({ type, order, symbol }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentPath = location.pathname.split('/').slice(0, -1).join('/')

  const handleClick = useCallback(() => {
    history.push(`${currentPath}/${Location.positions}`)
    dispatch(selectedOpenedPosition(String(order), { activeAsset: symbol }))
    dispatch(changeLocation(Location.positions, {}))
  }, [history, currentPath, dispatch, order, symbol])

  return (
    <div onClick={handleClick}>
      <Trans i18nKey={`order${capitalize(type)}OpenedNotify`} values={{ orderId: order }} />
    </div>
  )
}

export const orderPendingNotify = (props: TradeObject): void => {
  toast.success(<OrderPendingNotify {...props} />)
}

export const OrderPendingNotify: FC<TradeObject> = ({ type, order, symbol }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentPath = location.pathname.split('/').slice(0, -1).join('/')

  const handleClick = useCallback(() => {
    history.push(`${currentPath}/${Location.order}`)
    dispatch(selectedPendingPosition(String(order), { activeAsset: symbol }))
    dispatch(changeLocation(Location.order, {}))
  }, [history, currentPath, dispatch, order, symbol])
  return (
    <div onClick={handleClick}>
      <Trans i18nKey={`pending${capitalize(type)}OrderOpenedNotify`} values={{ orderId: order }} />
    </div>
  )
}

export const orderClosedNotify = (props: CloseTrade | TradeObject): void => {
  toast.success(<OrderClosedNotify {...props} />)
}

export const OrderClosedNotify: FC<CloseTrade | TradeObject> = ({ order }) => {
  const history = useHistory()
  const currentPath = location.pathname.split('/').slice(0, -1).join('/')
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    history.push(`${currentPath}/${Location.history}`)
    dispatch(changeLocation(Location.history, {}))
  }, [history, currentPath, dispatch])

  return (
    <div onClick={handleClick}>
      <Trans i18nKey={'orderClosedNotify'} values={{ orderId: order }} />
    </div>
  )
}

export const pendingOrderClosedNotify = (props: CloseTrade | TradeObject): void => {
  toast.success(<PendingOrderClosedNotify {...props} />)
}

export const PendingOrderClosedNotify: FC<CloseTrade | TradeObject> = ({ order }) => (
  <>
    <Trans i18nKey={'pendingOrderClosedNotify'} values={{ orderId: order }} />
  </>
)
