import React, { JSX, FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'
import { BdSwissForexMt4AccountType } from '../../../../services/graphql/types'
import { capitalize, currencyFormat, isMobile } from '../../../../utils'
import clsx from 'clsx'
import Avatar from '@mui/material/Avatar'
import { Account } from '../../../../store/types'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemAccount: {
    display: 'flex',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: 0.5,
    '&.mobile': {
      lineHeight: '20px',
      fontWeight: '400',
      letterSpacing: '0.15px',
    },
  },
  transparent: {
    fontSize: 12,
    opacity: '0.65',
    marginTop: '3px',
    '&.mobile': {
      marginTop: '0px',
      opacity: 'unset',
    },
  },
  accountBalance: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    color: theme.palette.mode === 'light' ? '#858585' : '#999999',
    whiteSpace: 'nowrap',
    MarginBottom: '10px',
    lineHeight: '14px',
    fontWeight: '400',
    letterSpacing: '0.4px',
  },
  avatar: {
    borderRadius: '4px',
    width: '32px',
    height: '32px',
    margin: '0px 12px',
  },
  avatarText: {
    fontWeight: '510',
    fontSize: '10px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.066px',
  },
  raw: {
    color: theme.palette.mode === 'light' ? '#6F57FD' : '#8A78F9',
    backgroundColor: theme.palette.mode === 'light' ? '#E5E7FF' : '#1F2266',
  },
  premium: {
    color: theme.palette.mode === 'light' ? '#CC33CC' : '#E55CE5',
    backgroundColor: theme.palette.mode === 'light' ? '#FFEBFF' : '#491B49',
  },
  black: {
    color: theme.palette.mode === 'light' ? '#CC33CC' : '#E55CE5',
    backgroundColor: theme.palette.mode === 'light' ? '#FFEBFF' : '#491B49',
  },
  basic: {
    color: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
    backgroundColor: theme.palette.mode === 'light' ? '#D6EAFF' : '#0C3059',
  },
  cent: {
    color: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
    backgroundColor: theme.palette.mode === 'light' ? '#D6EAFF' : '#0C3059',
  },
  invest_plus: {
    color: theme.palette.mode === 'light' ? '#00B594' : '#00AD7B',
    backgroundColor: theme.palette.mode === 'light' ? '#DCF6F5' : '#0C393C',
  },
  greyed: {
    color: theme.palette.mode === 'light' ? '#707070' : '#C2C2C2',
    backgroundColor: theme.palette.mode === 'light' ? '#EBEBEB' : '#474747',
  },
}))

interface AccountDetailsProps {
  login: BdSwissForexMt4AccountType['login']
  accountName: BdSwissForexMt4AccountType['accountName']
  currentAccountLogin: string
  accountSubtype: BdSwissForexMt4AccountType['accountSubtype']
  swapFree: BdSwissForexMt4AccountType['swapFree']
  isDemo: BdSwissForexMt4AccountType['isDemo']
  hidden: BdSwissForexMt4AccountType['hidden']
  currency: string
  balance: BdSwissForexMt4AccountType['balance']
  account?: Account
  showExtendedInfo?: boolean
}

export const AccountInfo: FC<AccountDetailsProps> = ({
  login,
  accountName,
  currentAccountLogin,
  accountSubtype,
  swapFree,
  isDemo,
  hidden,
  currency,
  balance,
  account,
  showExtendedInfo,
}) => {
  const classes = useStyles()
  const isMobileDevice = isMobile()
  if (currentAccountLogin !== login) return null

  let accountAvatar: JSX.Element | null = null
  if (showExtendedInfo) {
    const isMt5 = account?.__typename?.includes('MT5')
    const accountSubTypeClassKey = (accountSubtype || 'greyed') as keyof typeof classes

    accountAvatar = (
      <div className={classes.iconContainer}>
        <Avatar
          variant="square"
          className={clsx(
            classes.avatar,
            isDemo || (!account?.isDemo && account?.isReadOnly) ? classes.greyed : classes[accountSubTypeClassKey],
          )}
        >
          <div className={classes.avatarText}>{isMt5 ? 'MT5' : 'MT4'}</div>
        </Avatar>
      </div>
    )
  }

  return (
    <div className={classes.mainContainer}>
      {accountAvatar}
      <div className={classes.menuItemAccount}>
        <div className={clsx(classes.bold, isMobileDevice ? 'mobile' : '')}>
          {!!accountName ? (
            accountName
          ) : (
            <>
              {swapFree && (
                <>
                  <Trans i18nKey={'swapFree'} defaults={'Swap Free'} />
                  &nbsp;
                </>
              )}
              <Trans
                i18nKey={`${isDemo ? 'practice' : 'real'}${
                  accountSubtype ? capitalize(`${accountSubtype}`) : ''
                }Account`}
              />
            </>
          )}
          {showExtendedInfo && ` · #${login}`}
        </div>
        {!hidden && (
          <div className={clsx(classes.transparent, isMobileDevice ? 'mobile' : '')}>
            {!isMobileDevice ? (
              login
            ) : (
              <div className={classes.accountBalance}>
                <Trans i18nKey={'balance'} />
                {!!balance && ':'}&nbsp;
                {currencyFormat(balance || 0, currency)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
