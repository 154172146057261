import { gql } from '@apollo/client'

export const TRADING_IDEAS = gql`
  query TradingIdeas(
    $type_product: String
    $product: String
    $term: String
    $days: Int
    $last_ta: Boolean
    $taday: Boolean
    $country: String
    $sectorid: String
    $title: String
    $pageSize: Int
    $pageNumber: Int
  ) {
    tradingIdeas(
      type_product: $type_product
      product: $product
      term: $term
      days: $days
      last_ta: $last_ta
      taday: $taday
      country: $country
      sectorid: $sectorid
      title: $title
      pageSize: $pageSize
      pageNumber: $pageNumber
    ) {
      items {
        id
        expanded
        name
        date
        hour
        product
        media
        alternativeMedia {
          theme
          width
          image
        }
        option {
          opinionIntraday
          opinionST
          opinionMT
        }
        code {
          key
          type
        }
        title
        summary
        term
      }
    }
  }
`
