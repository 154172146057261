import React, { useRef, useEffect, FC } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Trans, useTranslation } from 'react-i18next'

import { LabelValueTooltip } from '../../Controls/LabelValueTooltip'
import { TradeMode } from '../../../../enums'
import {
  tradeModeSelector,
  formAmountSelector,
  transformedRequiredMarginSelector,
  isCurrentAccountLeverageTypeDynamicSelector,
} from '../../../../store/selectors'
import HelpTooltip from '../../../TopMenu/HelpTooltip/HelpTooltip'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 6,
  },
  requiredMargin: {
    display: 'inline-block',
    fontWeight: 'bold',
    lineHeight: 1,
    transform: 'scale(1)',
    transition: 'all .15s ease-in,transform .15s ease-in,-webkit-transform .15s ease-in',
    letterSpacing: '0.02rem',
  },
  marginValue: {
    display: 'inline-block',
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: '0.02rem',
    '& span:nth-child(2)': {
      transition: 'all .15s ease-in,transform .15s ease-in,-webkit-transform .15s ease-in',
    },
  },
  pulseRequiredMargin: {
    '& span:nth-child(2)': {
      color: '#007bff',
    },
  },
}))

export const RequiredMargin: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const transformedRequiredMarginValue = useSelector(transformedRequiredMarginSelector)
  const tradeMode = useSelector(tradeModeSelector)
  const amount = useSelector(formAmountSelector)
  const isCurrentAccountLeverageTypeDynamic = useSelector(isCurrentAccountLeverageTypeDynamicSelector)

  const requiredMarginRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (requiredMarginRef.current && !isCurrentAccountLeverageTypeDynamic) {
      requiredMarginRef.current.classList.add(classes.pulseRequiredMargin)
      const timeout = setTimeout(() => {
        requiredMarginRef.current?.classList.remove(classes.pulseRequiredMargin)
      }, 300)

      return () => clearInterval(timeout)
    }
  }, [amount.value, classes, isCurrentAccountLeverageTypeDynamic])

  if (tradeMode === TradeMode.disabled || !transformedRequiredMarginValue) {
    return null
  }

  return (
    <div className={classes.container} data-guided-tour="requiredMarginAmount">
      <div className={classes.marginValue} ref={requiredMarginRef}>
        <LabelValueTooltip
          label={'requiredMargin'}
          value={isCurrentAccountLeverageTypeDynamic ? t('wtDynamic', 'Dynamic') : transformedRequiredMarginValue}
        />
      </div>

      <HelpTooltip hint={<Trans i18nKey={'requiredMarginTooltip'} />} />
    </div>
  )
}
