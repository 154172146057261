import { createAsyncAction } from 'typesafe-actions'
import { GraphQLError } from 'graphql'
import { UserResponse } from '../types'

export const {
  request: getUserRequest,
  success: getUserSuccess,
  failure: getUserFailure,
} = createAsyncAction('@@user/get', '@@user/set', '@@user/error')<void, UserResponse['viewer'], GraphQLError>()

export const {
  request: updateThemePreferenceRequest,
  success: updateThemePreferenceSuccess,
  failure: updateThemePreferenceFailure,
} = createAsyncAction(
  '@@user/updateThemePreferenceRequest',
  '@@user/updateThemePreferenceSuccess',
  '@@user/updateThemePreferenceFailure',
)<string, string, GraphQLError>()
