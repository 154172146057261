import React from 'react'
import { CellProps, Renderer } from 'react-table'
import { PriceExtended } from '@bdswiss/mt4-connector'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { isMarketOpenedBySymbolSelector, loginInfoSelector } from '../../../store/selectors'
import { RootState } from '../../../store/types'

const useStyles = makeStyles(() => ({
  leveragedLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 6,
    lineHeight: 12,
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#999999',
    borderRadius: 40,
  },
  marketClosed: {
    background: '#D6D6D6',
  },
}))

export const LeveragedLabelCell: Renderer<CellProps<PriceExtended>> = ({
  row: {
    original: { security, marginMode, percentage, symbol },
  },
}) => {
  const classes = useStyles()
  const accountInfo = useSelector(loginInfoSelector)
  const leverage = marginMode === 'Forex' ? accountInfo?.leverage : (100 / percentage).toFixed(0)

  const isMarketOpened = useSelector((state: RootState) => isMarketOpenedBySymbolSelector(state, symbol))
  return (
    security.startsWith('DMA') &&
    leverage && (
      <div
        className={clsx(classes.leveragedLabel, {
          [classes.marketClosed]: !isMarketOpened,
        })}
      >
        <Trans i18nKey={leverage > 1 ? 'leveragedLabel' : 'unleveragedLabel'} />
      </div>
    )
  )
}
