import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { downloadAppBannerSelector, historyTabSelector } from '../../store/selectors'
import { useRouteMatch } from 'react-router-dom'
import { isMobile } from '../../utils'
import { FullscreenableElement } from '../mobile/ToggleFullscreenButton'

const useStyles = makeStyles((theme) => ({
  chartPanel: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      minHeight: 340,
      order: 4,
    },
    '&.hidden': {
      display: 'none',
    },
    flexDirection: isMobile() ? 'column' : 'row',
  },
  paper: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1F1F1F',
    ...(isMobile() && {
      '&.bannerShown': {
        flexGrow: 'unset',
        height: 'calc(100% - 56px)',
      },
    }),
  },
}))

interface Props {
  fullscreenNodeRef?: React.RefObject<FullscreenableElement>
}

export const ChartPanel: FC<Props> = ({ children, fullscreenNodeRef }) => {
  const classes = useStyles()
  const hideChart = !!useRouteMatch(['*/history', '*/calendar'])
  const historySelector = useSelector(historyTabSelector) && hideChart
  const bannerShown = useSelector(downloadAppBannerSelector)
  const optionalAttributes = fullscreenNodeRef ? { ref: fullscreenNodeRef } : {}

  return (
    <div
      data-guided-tour={'chart'}
      className={clsx(classes.chartPanel, classes.paper, 'chart', historySelector ? 'hidden' : '', {
        bannerShown: bannerShown,
      })}
      data-test="chart-panel"
      {...optionalAttributes}
    >
      {children}
    </div>
  )
}
