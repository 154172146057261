import { ofType } from 'redux-observable'
import { of, interval, EMPTY } from 'rxjs'
import { filter, switchMap, exhaustMap, takeUntil, map } from 'rxjs/operators'
import { getType, isActionOf } from 'typesafe-actions'

import {
  formInputBlur,
  formInputChange,
  formChangeButtonPress,
  formChangeButtonRelease,
  formChangeButtonClick,
  formUpdateField,
  setTakeProfitEnabled,
  setStopLossEnabled,
  updateFormValues,
  appChangeTradeMode,
  clearSymbolExposure,
  appChangeActiveAsset,
} from '../actions'

import { TradeCommandType, calcSlInitial, calcTpInitial } from '@bdswiss/mt4-connector'

import { RootEpic } from '../types'
import { numberUnformat } from '../../utils'
import { getValidatedFieldValue, getStepForIncreaseFieldValue, getAllFieldInfo } from '../../utils/form'
import { Location, OrderTypes } from '../../enums'
import get from 'lodash/get'

export const inputBlurHandle: RootEpic = (action$, state$, { mt4Connector }) => {
  return action$.pipe(
    filter(isActionOf([formInputBlur])),
    switchMap(({ payload: { field, value } }) => {
      const asset = mt4Connector.assets[state$.value.app.activeAsset]
      const isValid = state$.value.form[field].fieldInformation.isValid

      if (!asset || isValid) {
        return EMPTY
      }

      const {
        form: {
          isPendingOrder,
          placedOrderTradeMode,
          priceLimit: { value: openPrice },
          stopPrice: { value: stopPrice },
          formOrderType,
        },
        app: { tradeMode },
      } = state$.value

      const validatedValue = getValidatedFieldValue(
        asset,
        field,
        numberUnformat(value),
        placedOrderTradeMode || tradeMode,
        openPrice,
        isPendingOrder,
        formOrderType,
        stopPrice,
      )

      return of(
        formUpdateField({
          field,
          value: validatedValue,
          fieldInformation: getAllFieldInfo({
            asset,
            field,
            value: validatedValue,
            tradeMode: placedOrderTradeMode || tradeMode,
            openPrice,
            isPendingOrder,
            formOrderType,
            stopPrice,
          }),
        }),
      )
    }),
  )
}

export const inputChangeHandle: RootEpic = (action$, state$, { mt4Connector }) => {
  return action$.pipe(
    filter(isActionOf([formInputChange])),
    switchMap(({ payload: { value, field } }) => {
      const asset = mt4Connector.assets[state$.value.app.activeAsset]
      if (!asset || !/^[+]?(\d+|\d+[.]?\d*)?$/.test(value.toString())) {
        return EMPTY
      }
      const {
        form: {
          isPendingOrder,
          placedOrderTradeMode,
          formOrderType,
          priceLimit: { value: openPrice },
          stopPrice: { value: stopPrice },
        },
        app: { tradeMode },
      } = state$.value

      return of(
        formUpdateField({
          field,
          value,
          fieldInformation: getAllFieldInfo({
            asset,
            field,
            value,
            tradeMode: placedOrderTradeMode || tradeMode,
            openPrice,
            isPendingOrder,
            formOrderType,
            stopPrice,
          }),
        }),
      )
    }),
  )
}

export const inputChangeButtonClickHandle: RootEpic = (action$, state$, { mt4Connector }) => {
  return action$.pipe(
    filter(isActionOf([formChangeButtonClick])),
    switchMap(({ payload: { action, field } }) => {
      const asset = mt4Connector.assets[state$.value.app.activeAsset]
      if (!asset) {
        return EMPTY
      }

      const {
        form: {
          isPendingOrder,
          placedOrderTradeMode,
          priceLimit: { value: openPrice },
          stopPrice: { value: stopPrice },
          [field]: { value: fieldValue },
          formOrderType,
        },
        app: { tradeMode },
      } = state$.value

      const step = getStepForIncreaseFieldValue(asset, field, { fieldValue, action })
      const updatedValue = action === 'increase' ? fieldValue + step : fieldValue - step
      return of(
        formUpdateField({
          field,
          value: updatedValue,
          fieldInformation: getAllFieldInfo({
            asset,
            field,
            value: updatedValue,
            tradeMode: placedOrderTradeMode || tradeMode,
            openPrice,
            isPendingOrder,
            formOrderType,
            stopPrice,
          }),
        }),
      )
    }),
  )
}

export const changeButtonPressHandle: RootEpic = (action$, state$, { mt4Connector }) => {
  return action$.pipe(
    filter(isActionOf([formChangeButtonPress])),
    exhaustMap(({ payload: { action, field } }) => {
      const asset = mt4Connector.assets[state$.value.app.activeAsset]
      if (!asset) {
        return EMPTY
      }
      const {
        [field]: { value: fieldValue },
      } = state$.value.form
      let step = getStepForIncreaseFieldValue(asset, field, { fieldValue, action })
      return interval(120).pipe(
        map((index) => {
          const {
            form: {
              isPendingOrder,
              placedOrderTradeMode,
              priceLimit: { value: openPrice },
              stopPrice: { value: stopPrice },
              [field]: { value: fieldValue },
              formOrderType,
            },
            app: { tradeMode },
          } = state$.value
          if (!(index % 10)) {
            step = step * 2
          }
          if (fieldValue - step < 0) {
            step = 1
          }
          const updatedValue = getValidatedFieldValue(
            asset,
            field,
            action === 'increase' ? fieldValue + step : fieldValue - step,
            placedOrderTradeMode || tradeMode,
            openPrice,
            isPendingOrder,
            formOrderType,
            stopPrice,
          )

          return formUpdateField({
            ...state$.value.form[field],
            field,
            value: updatedValue,
            fieldInformation: getAllFieldInfo({
              asset,
              field,
              value: updatedValue,
              tradeMode: placedOrderTradeMode || tradeMode,
              openPrice,
              isPendingOrder,
              formOrderType,
              stopPrice,
            }),
          })
        }),
        takeUntil(action$.pipe(ofType(getType(formChangeButtonRelease)))),
      )
    }),
  )
}

export const syncFieldsEpic: RootEpic = (action$, state$, { mt4Connector }) =>
  action$.pipe(
    filter(isActionOf([formInputBlur, formInputChange, formChangeButtonClick, formChangeButtonPress])),
    switchMap(({ payload: { field } }) => {
      const {
        app: { activeAsset, tradeMode },
        form: {
          stopPrice: { fieldInformation, value: stopPriceValue },
          priceLimit: { value: openPrice },
          formOrderType,
        },
      } = state$.value

      const asset = mt4Connector.assets[activeAsset]

      if (formOrderType === OrderTypes.stopLimitOrder && field === 'stopPrice' && fieldInformation.isValid) {
        return of(
          formUpdateField({
            field: 'priceLimit',
            value: stopPriceValue,
            fieldInformation: getAllFieldInfo({
              asset,
              field: 'priceLimit',
              value: stopPriceValue,
              tradeMode,
              openPrice,
              formOrderType,
              stopPrice: stopPriceValue,
            }),
          }),
        )
      }

      return EMPTY
    }),
  )

export const resetTpSlEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([formUpdateField, appChangeTradeMode])),
    switchMap(({ payload }) => {
      if (
        [OrderTypes.pendingOrder, OrderTypes.stopLimitOrder].includes(state$.value.form.formOrderType) &&
        state$.value.app.activeTab === Location.trade &&
        get(payload, 'field') === 'priceLimit'
      ) {
        return of(
          updateFormValues({
            takeProfitEnabled: false,
            stopLossEnabled: false,
          }),
        )
      }

      return EMPTY
    }),
  )

export const updateTpSlFieldValues: RootEpic = (action$, state$, { mt4Connector }) =>
  action$.pipe(
    filter(isActionOf([setTakeProfitEnabled, setStopLossEnabled])),
    switchMap(({ type, payload }) => {
      if (payload && state$.value.app.activeTab !== Location.order) {
        const {
          form: {
            isPendingOrder,
            placedOrderTradeMode,
            priceLimit: { value },
            priceLimitEnabled,
          },
          app: { tradeMode, activeAsset },
        } = state$.value
        const asset = mt4Connector.assets[activeAsset]
        if (!isPendingOrder || !payload || !asset) return EMPTY

        return of(
          updateFormValues(
            getType(setTakeProfitEnabled) === type
              ? {
                  takeProfit: {
                    ...state$.value.form.takeProfit,
                    value: calcTpInitial(
                      (placedOrderTradeMode || tradeMode) as TradeCommandType,
                      asset,
                      priceLimitEnabled || isPendingOrder ? value : undefined,
                      isPendingOrder,
                    ),
                  },
                }
              : {
                  stopLoss: {
                    ...state$.value.form.stopLoss,
                    value: calcSlInitial(
                      (placedOrderTradeMode || tradeMode) as TradeCommandType,
                      asset,
                      priceLimitEnabled || isPendingOrder ? value : undefined,
                      isPendingOrder,
                    ),
                  },
                },
          ),
        )
      }

      return EMPTY
    }),
  )

export const clearSymbolExposureEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(appChangeActiveAsset)),
    switchMap(() => of(clearSymbolExposure())),
  )

export default [
  inputBlurHandle,
  inputChangeHandle,
  changeButtonPressHandle,
  inputChangeButtonClickHandle,
  syncFieldsEpic,
  resetTpSlEpic,
  updateTpSlFieldValues,
  clearSymbolExposureEpic,
]
