import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/system'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg'
import { setAssetFilter } from '../../../../store/actions'

const MarketsSearchInputWrap = styled('div')({
  '& .MuiFormControl-root': {
    width: 'calc(100% - 32px)',
    margin: 16,
  },
  '& .MuiOutlinedInput-root': {
    height: 32,
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #007AFF',
    },
  },
})

const MarketsSearchInput: FC = () => {
  const [value, setValue] = useState<string>('')
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const changeHandle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value)
      dispatch(setAssetFilter(e.currentTarget.value))
    },
    [dispatch],
  )

  const resetHandle = useCallback(() => {
    setValue('')
    dispatch(setAssetFilter(''))
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(setAssetFilter(''))
    }
  }, [dispatch])

  return (
    <MarketsSearchInputWrap>
      <TextField
        id={'search-asset-input'}
        variant={'outlined'}
        placeholder={t('search', 'Search')}
        InputLabelProps={{ shrink: false }}
        onChange={changeHandle}
        value={value}
        InputProps={{
          endAdornment: value && (
            <InputAdornment position="end">
              <IconButton aria-label="Reset search input" onClick={resetHandle} edge="end">
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MarketsSearchInputWrap>
  )
}

export default MarketsSearchInput
