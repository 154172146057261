import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { currentIdAccountSelector, loginInfoSelector } from '../../../../store/selectors'
import { AccountDetails } from '../../../TopMenu/AccountDetails/AccountDetails'
import { IconButton } from '../../IconButton'
import { LargeThemeSwitch } from '../../LargeThemeSwitch'
import { ReactComponent as ChatIcon } from '../../../../assets/images/icons/mobile/chat.svg'
import { ReactComponent as InfoIcon } from '../../../../assets/images/icons/mobile/info.svg'
import { ReactComponent as CalendarIcon } from '../../../../assets/images/icons/mobile/calendar.svg'
import { ReactComponent as TradingIdeasIcon } from '../../../../assets/images/icons/news-paper.svg'
import { getTIItemsRequest, setActivePanelAction } from '../../../../store/actions'
import { FeedPanel } from '../../../../enums'
import { depositHandle } from '../../../../utils'

const useStyles = makeStyles((theme) => ({
  depositButton: {
    textTransform: 'none',
    background: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
    margin: '16px 16px 8px 16px',
    height: '40px',
    gap: '8px',
    borderRadius: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#FFFFFF',
    letterSpacing: '0.25px',
    '&:hover': {
      background: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
    },
  },
}))

export const MorePage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentLogin = useSelector(loginInfoSelector)
  const currentAccountId = useSelector(currentIdAccountSelector)
  const login = currentLogin?.login.toString() as string

  const supportURL = useMemo<string>(() => {
    const currentLocation = document.referrer || window.location.href
    const supportLocation = new URL(currentLocation)
    supportLocation.pathname = 'support'
    return supportLocation.toString()
  }, [])

  const goToAccountInfo = useCallback(() => {
    history.push('more/account-info')
  }, [history])

  const goToCalendar = useCallback(() => {
    dispatch(setActivePanelAction(FeedPanel.economicCalendar))
    history.push('more/calendar')
  }, [dispatch, history])

  const goToTradingIdeas = useCallback(() => {
    dispatch(setActivePanelAction(FeedPanel.tradingIdeas))
    dispatch(getTIItemsRequest())
    history.push('more/tradingIdeas')
  }, [dispatch, history])

  const goToSupport = useCallback(() => {
    window.open(supportURL, '_blank')
  }, [supportURL])

  return (
    <>
      <AccountDetails variant={'fullWidth'} />
      <Button onClick={depositHandle.bind(null, currentAccountId, login, {})} className={classes.depositButton}>
        {t('deposit')}
      </Button>
      <IconButton callback={goToAccountInfo} title={t('accountInfo')} Icon={InfoIcon}></IconButton>
      <IconButton callback={goToTradingIdeas} title={t('tradingIdeas')} Icon={TradingIdeasIcon}></IconButton>
      <IconButton callback={goToCalendar} title={t('economicCalendar')} Icon={CalendarIcon}></IconButton>
      <IconButton callback={goToSupport} title={t('support245')} Icon={ChatIcon}></IconButton>
      <LargeThemeSwitch />
    </>
  )
}
