import React, { FC, MouseEvent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

import { FeedMain } from './FeedMain'
import { ReactComponent as TCIcon } from '../../assets/images/icons/news-paper.svg'
import { ReactComponent as ECIcon } from '../../assets/images/icons/Union.svg'
import { activeFeedPanelSelector, panelsSelector, showFeedNewSectionTooltipSelector } from '../../store/selectors'
import { setActivePanelAction, setShowFeedNewSectionTooltip } from '../../store/actions'
import { FeedPanel } from '../../enums'
import { isMobile } from '../../utils'
import { NewSectionTooltip } from './NewSectionTooltip'
import clsx from 'clsx'

const FeedContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
  maxWidth: theme.spacing(5),
  [theme.breakpoints.up('sm')]: {
    height: '100%',
  },
}))

const ItemIcon = styled(IconButton)<{ selected: boolean }>(({ theme, selected }) => ({
  ['& svg']: {
    width: '100%',
    height: 'auto',
    ['& path']: {
      fill: selected ? theme.palette.primary.main : '#999999',
    },
  },
  ['&:hover']: {
    ['& path']: {
      fill: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '&.showFeedNewSectionTooltip': {
    ['& svg']: {
      ['& path']: {
        fill: theme.palette.mode === 'light' ? '#00AD7B' : '#00C78D',
      },
    },
  },
}))

export const Feed: FC = () => {
  const dispatch = useDispatch()
  const activePanel = useSelector(activeFeedPanelSelector)
  const panels = useSelector(panelsSelector)
  const showFeedNewSectionTooltip = useSelector(showFeedNewSectionTooltipSelector)

  const handleDrawerOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const panel = event.currentTarget.dataset.panel
      panel && dispatch(setActivePanelAction(panel as FeedPanel))
    },
    [dispatch],
  )
  const hideNewSectionTooltip = useCallback(() => {
    dispatch(setShowFeedNewSectionTooltip(false))
  }, [dispatch])

  if (isMobile()) return null

  return (
    <>
      {activePanel && <FeedMain activePanel={activePanel} />}
      <NewSectionTooltip open={showFeedNewSectionTooltip}>
        <FeedContainer>
          {panels.map((panel) => (
            <Tooltip
              PopperProps={{
                sx: {
                  [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
                    marginRight: '4px !important',
                  },
                  [`& .${tooltipClasses.tooltip}`]: {
                    fontSize: 14,
                    lineHeight: '20px',
                    background: (theme) => (theme.palette.mode === 'light' ? '#fff' : '#1F1F1F'),
                    boxShadow: (theme) => (theme.palette.mode === 'light' ? '0px 2px 6px rgba(0, 0, 0, 0.22)' : 'none'),
                  },
                  [`& .${tooltipClasses.arrow}`]: {
                    color: (theme) => (theme.palette.mode === 'light' ? '#fff' : '#1F1F1F'),
                    '&:before': {
                      boxShadow: (theme) =>
                        theme.palette.mode === 'light' ? '0px 2px 6px rgba(0, 0, 0, 0.22)' : 'none',
                    },
                  },
                },
              }}
              key={panel}
              title={<Trans i18nKey={panel} />}
              placement={'left'}
              arrow
            >
              <ItemIcon
                className={clsx({
                  showFeedNewSectionTooltip: showFeedNewSectionTooltip && panel === FeedPanel.tradingIdeas,
                })}
                selected={activePanel === panel}
                onClick={handleDrawerOpen}
                onMouseOver={hideNewSectionTooltip}
                data-panel={panel}
                disableRipple
              >
                {panel === FeedPanel.tradingIdeas ? <TCIcon /> : <ECIcon />}
              </ItemIcon>
            </Tooltip>
          ))}
        </FeedContainer>
      </NewSectionTooltip>
    </>
  )
}
