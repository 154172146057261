import { makeStyles } from '@mui/styles'
import React, { FC, useCallback } from 'react'
import Button from '@mui/material/Button'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StandoutDecimalPriceLabel } from './StandoutDecimalPriceLabel'
import { ReactComponent as BuyIcon } from '../../../../assets/images/icons/mobile/arrow_top_right.svg'
import { ReactComponent as SellIcon } from '../../../../assets/images/icons/mobile/arrow_bottom_right.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  appChangeTradeMode,
  setMobileBackButtonData,
  toggleFullscreen,
  toggleTradingFormPanel,
} from '../../../../store/actions'
import { TradeMode } from '../../../../enums'
import { activeAssetSelector, isFullscreenSelector } from '../../../../store/selectors'

interface Props {
  type: 'buy' | 'sell'
  price: number
  digits: number
  enabled: boolean
}

const useStyles = makeStyles((theme) => ({
  sell: {
    color: theme.palette.mode === 'light' ? '#ED1C24' : '#FC4950',
    backgroundColor: theme.palette.mode === 'light' ? '#FFEDEE' : '#390709',
    borderColor: theme.palette.mode === 'light' ? '#ED1C24' : '#FC4950',
  },
  buy: {
    color: theme.palette.mode === 'light' ? '#009469' : '#00C78D',
    backgroundColor: theme.palette.mode === 'light' ? '#E5F9F3' : '#003022',
    borderColor: theme.palette.mode === 'light' ? '#009469' : '#00C78D',
  },
  disabled: {
    color: theme.palette.mode === 'light' ? '#999999' : '#999999',
    backgroundColor: theme.palette.mode === 'light' ? '#FAFAFA' : '#1F1F1F',
    borderColor: theme.palette.mode === 'light' ? '#999999' : '#999999',
  },
  roundCornerButton: {
    borderRadius: '8px',
    border: '0.5px solid',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'justify',
  },
  buttonRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    minHeight: '64px',
    textTransform: 'uppercase',
    padding: '8px 12px 12px 12px',
  },
  orderDirection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '5px',
  },
}))

export const PrettyTradeButton: FC<Props> = ({ type, price, digits, enabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFullscreen = useSelector(isFullscreenSelector)
  const title = type === 'buy' ? t('buy') : t('sell')
  const symbol = useSelector(activeAssetSelector)

  const clickHandler = useCallback(() => {
    isFullscreen && dispatch(toggleFullscreen())
    if (enabled) {
      dispatch(appChangeTradeMode(type === 'buy' ? TradeMode.buy : TradeMode.sell))
      dispatch(toggleTradingFormPanel(true))
      dispatch(setMobileBackButtonData({ show: true, title: symbol }))
    }
  }, [dispatch, enabled, type, isFullscreen, symbol])

  const orderDirectionBlock = (
    <div className={classes.orderDirection}>
      <Typography className={classes.title}>{title}</Typography>
      {type === 'buy' ? <BuyIcon /> : <SellIcon />}
    </div>
  )

  let colorClass = classes.disabled

  if (enabled) {
    colorClass = type === 'buy' ? classes.buy : classes.sell
  }

  return (
    <Button className={clsx(classes.buttonRoot, classes.roundCornerButton, colorClass)} onClick={clickHandler}>
      {type === 'buy' && orderDirectionBlock}
      <StandoutDecimalPriceLabel value={price} digits={digits} />
      {type === 'sell' && orderDirectionBlock}
    </Button>
  )
}
