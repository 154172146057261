import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CellProps, Renderer } from 'react-table'
import makeStyles from '@mui/styles/makeStyles'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import { Trans } from 'react-i18next'

import { TradeObject } from '@bdswiss/mt4-connector'

import { selectedOpenedPosition, selectedPendingPosition } from '../../../store/actions'
import { activeTabSelector } from '../../../store/selectors'
import { Location } from '../../../enums'

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 14,
    margin: '0 4px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

export const EditActionCell: Renderer<CellProps<TradeObject, string>> = ({
  row: {
    original: { order, symbol },
  },
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const tab = useSelector(activeTabSelector)
  const handleClick = useCallback(
    (e) => {
      if (tab === Location.trade || tab === Location.positions) {
        e.stopPropagation()
        dispatch(selectedOpenedPosition(String(order), { activeAsset: symbol, currentLocation: tab }))
      }
      if (tab === Location.order) {
        e.stopPropagation()
        dispatch(selectedPendingPosition(String(order), { activeAsset: symbol, currentLocation: tab }))
      }
    },
    [dispatch, order, symbol, tab],
  )

  return (
    <button className={classes.button} onClick={handleClick}>
      <CreateOutlinedIcon className={classes.icon} />
      <Trans i18nKey={'edit'} />
    </button>
  )
}
