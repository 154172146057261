/* eslint-disable  @typescript-eslint/no-explicit-any */
import i18next from 'i18next'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import {
  DatafeedConfiguration,
  ErrorCallback,
  GetMarksCallback,
  HistoryCallback,
  IDatafeedChartApi,
  IExternalDatafeed,
  LibrarySymbolInfo,
  Mark,
  OnReadyCallback,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  ServerTimeCallback,
  SubscribeBarsCallback,
  TimescaleMark,
  Bar,
  PeriodParams,
} from '../types/datafeed-api'
import { GetBarsResult, HistoryProvider } from './history-provider'
import { DataPulseProvider } from './data-pulse-provider'
import { SymbolsStorage } from './symbols-storage'

export interface UdfCompatibleConfiguration extends DatafeedConfiguration {
  supports_search?: boolean
  supports_group_request?: boolean
}

export enum Constants {
  SearchItemsLimit = 30,
}

export class MT4DatafeedBase implements IExternalDatafeed, IDatafeedChartApi {
  protected configuration: UdfCompatibleConfiguration = defaultConfiguration()
  // private readonly dataStorage: DataStorage;
  private readonly configurationReadyPromise: Promise<void>
  private readonly dataPulseProvider: DataPulseProvider = DataPulseProvider.Instance
  private historyProvider: HistoryProvider = HistoryProvider.Instance
  public lastHistoryBar: Bar = {
    time: 0,
    open: 0,
    high: 0,
    low: 0,
    close: 0,
    volume: 0,
  }

  public symbolsStorage: SymbolsStorage = SymbolsStorage.Instance
  public resetCacheNeededCallbacks: Record<string, () => void>

  get supportedResolutions(): Array<ResolutionString> {
    return this.configuration.supported_resolutions || []
  }

  public constructor() {
    this.configurationReadyPromise = Promise.resolve(null).then(() => {
      this._setupWithConfiguration(defaultConfiguration())
    })
    this.resetCacheNeededCallbacks = {}
  }

  public onReady(callback: OnReadyCallback): void {
    this.configurationReadyPromise.then(() => {
      callback(this.configuration)
    })
  }

  public calculateHistoryDepth(
    resolution: ResolutionString,
    resolutionBack: 'D' | 'M',
    intervalBack: number,
  ):
    | {
        resolutionBack: 'D' | 'M'
        intervalBack: number | undefined
      }
    | undefined {
    void resolution, resolutionBack, intervalBack

    return undefined
  }

  public getMarks(
    symbolInfo: LibrarySymbolInfo,
    startDate: number,
    endDate: number,
    onDataCallback: GetMarksCallback<Mark>,
    resolution: ResolutionString,
  ): void {
    void symbolInfo, startDate, endDate, onDataCallback, resolution
    if (!this.configuration.supports_marks) {
      return
    }

    // const requestParams: RequestParams = {
    // 	symbol: symbolInfo.ticker || '',
    // 	from: startDate,
    // 	to: endDate,
    // 	resolution: resolution,
    // };
  }

  public getTimescaleMarks(
    symbolInfo: LibrarySymbolInfo,
    startDate: number,
    endDate: number,
    onDataCallback: GetMarksCallback<TimescaleMark>,
    resolution: ResolutionString,
  ): void {
    void symbolInfo, startDate, endDate, onDataCallback, resolution
    if (!this.configuration.supports_timescale_marks) {
      return
    }

    // const requestParams: RequestParams = {
    // 	symbol: symbolInfo.ticker || '',
    // 	from: startDate,
    // 	to: endDate,
    // 	resolution: resolution,
    // };
  }

  public getServerTime(callback: ServerTimeCallback): void {
    void callback
    if (!this.configuration.supports_time) {
      return
    }
  }

  public searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
    if (this.configuration.supports_search) {
      // const params: RequestParams = {
      // 	limit: Constants.SearchItemsLimit,
      // 	query: userInput.toUpperCase(),
      // 	type: symbolType,
      // 	exchange: exchange,
      // };
    } else {
      if (this.symbolsStorage === null) {
        throw new Error(i18next.t('symbolStorageConfiguration'))
      }

      this.symbolsStorage
        .searchSymbols(userInput, exchange, symbolType, Constants.SearchItemsLimit)
        .then(onResult)
        .catch(onResult.bind(null, []))
    }
  }

  public resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void {
    // console.log(`ResolveSymbol for ${symbolName}`);

    // const resolveRequestStartTime = Date.now();
    function onResultReady(symbolInfo: LibrarySymbolInfo): void {
      // console.log(`Symbol resolved: ${Date.now() - resolveRequestStartTime}ms`);
      onResolve(symbolInfo)
    }

    if (!this.configuration.supports_group_request) {
      // const params: RequestParams = {
      // 	symbol: symbolName,
      // };
    } else {
      if (this.symbolsStorage === null) {
        throw new Error(i18next.t('symbolStorageConfiguration'))
      }

      this.symbolsStorage.resolveSymbol(symbolName).then(onResultReady).catch(onError)
    }
  }

  private repeat = 0
  private handleTimeout = 0

  public getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onResult: HistoryCallback,
    onError: ErrorCallback,
  ): void {
    // console.log(`Get ${symbolInfo.name} bars with resolution ${resolution} for ${rangeStartDate} - ${rangeEndDate}, first request - ${firstDataRequest}`);
    this.historyProvider
      .getBars(symbolInfo, resolution, periodParams)
      .then((result: GetBarsResult) => {
        !isEmpty(result.bars) && (this.lastHistoryBar = result.bars[result.bars.length - 1])
        clearTimeout(this.handleTimeout)
        this.repeat = 0
        onResult(result.bars, result.meta)
      })
      .catch((error) => {
        if (this.repeat < 6) {
          this.repeat++
          this.handleTimeout = setTimeout(() => {
            this.getBars(symbolInfo, resolution, periodParams, onResult, onError)
          }, 10000) as unknown as number
        } else {
          clearTimeout(this.handleTimeout)
          this.repeat = 0
          toast.error(i18next.t('errorLoadingChartData'))
          onError(error)
        }
      })
  }

  public subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void,
  ): void {
    this.dataPulseProvider.subscribeBars(symbolInfo, resolution, onTick, listenerGuid, this.lastHistoryBar)
    this.resetCacheNeededCallbacks[listenerGuid] = onResetCacheNeededCallback
  }

  public unsubscribeBars(listenerGuid: string): void {
    this.dataPulseProvider.unsubscribeBars(listenerGuid)
    if (this.resetCacheNeededCallbacks.hasOwnProperty(listenerGuid)) {
      delete this.resetCacheNeededCallbacks[listenerGuid]
    }
  }

  protected _requestConfiguration(): Promise<UdfCompatibleConfiguration | null> {
    return Promise.resolve(null)
  }

  private _setupWithConfiguration(configurationData: UdfCompatibleConfiguration): void {
    this.configuration = configurationData

    if (configurationData.exchanges === undefined) {
      configurationData.exchanges = []
    }

    if (!configurationData.supports_search && !configurationData.supports_group_request) {
      throw new Error(i18next.t('datafeedConfiguration'))
    }

    // console.log(`MT4CompatibleDatafeed: Initialized with ${JSON.stringify(configurationData)}`);
  }

  public resetCache(): boolean {
    let resetted = false
    for (const listenerGuid in this.resetCacheNeededCallbacks) {
      if (this.resetCacheNeededCallbacks.hasOwnProperty(listenerGuid)) {
        this.resetCacheNeededCallbacks[listenerGuid]?.()
        resetted = true
      }
    }
    return resetted
  }
}

function defaultConfiguration(): UdfCompatibleConfiguration {
  return {
    supports_time: false,
    supports_search: false,
    supports_group_request: true,
    supported_resolutions: ['1S', '1', '5', '15', '30', '60', '240', '1D', '1W', '1M'] as ResolutionString[],
    supports_marks: false,
    supports_timescale_marks: false,
  }
}
