import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { Router } from 'react-router'
import { ThemeProvider } from './components/ThemeProvider'
import { Provider } from 'react-redux'
import store from './store'
import { initialize } from './store/actions'
import { history } from './utils'
import './i18next'
import Bugsnag from './services/bugsnag'

const bugsnag = Bugsnag.Instance
const BugsnagReactPlugin = bugsnag.getPlugin('react')
const ErrorBoundary = BugsnagReactPlugin && BugsnagReactPlugin.createErrorBoundary(React)

const mainApp = (
  <React.StrictMode>
    <Router history={history}>
      <Provider store={store}>
        <ThemeProvider>
          <Suspense fallback={<div>Load...</div>}>
            <App />
          </Suspense>
        </ThemeProvider>
      </Provider>
    </Router>
  </React.StrictMode>
)

ReactDOM.render(
  ErrorBoundary ? <ErrorBoundary>{mainApp}</ErrorBoundary> : mainApp,
  document.getElementById('root'),
  () => store.dispatch(initialize()),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
