import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'

interface IProps {
  counter: number
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 32,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    fontSize: '12px',
    position: 'absolute',
    marginLeft: 30,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '16px',
    padding: '0px 5px',
    gap: '10px',
    maxHeight: 16,
    minHeight: 16,
  },
  text: {
    fontWeight: 400,
    letterSpacing: '0.4px',
    fontFamily: 'Roboto',
    fontSize: 12,
  },
}))

export const MenuButtonCounter: FC<IProps> = ({ counter }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>{counter}</Typography>
    </div>
  )
}
