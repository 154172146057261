import React, { createContext, FC, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { TradeObject } from '@bdswiss/mt4-connector'

import { VirtualizedTable, VirtualizedTableProps } from '../../VirtualizedTable'
import { openPositionsSelector, selectedOpenOrderSelector } from '../../../store/selectors'

import { CloseActionCell, CloseAllHeader } from './OpenPositionTableColumns'
import {
  changeLocation,
  clearActiveSignal,
  selectedOpenedPosition,
  setMobileBackButtonData,
} from '../../../store/actions'
import { CloseAllPositionsDialog } from '../../CloseAllPositionsDialog'
import { ClosePositionDialog } from '../../ClosePositionDialog'
import {
  FavouriteCell,
  ValueCell,
  DirectionCell,
  ProfitCell,
  LeverageCell,
  EditActionCell,
  OrderCell,
  VolumeCell,
  OpenPriceCell,
  CurrentPriceCell,
  RenamedAssetCell,
} from '../CommonCells'
import { isMobile } from '../../../utils'
import { CloseOrderButton, MobileAssetCell } from '../../mobile/Common'
import { Location } from '../../../enums'

const estimatedItemSize = 35
const getItemSize = () => (!isMobile() ? 35 : 55)

interface PropsContext {
  dialogOpen: (order: TradeObject) => void
  dialogOpenAll: () => void
}

export const OpenPositionTableContext = createContext<PropsContext>({
  dialogOpen: () => {
    throw new Error('Not implemented dialogOpen function')
  },
  dialogOpenAll: () => {
    throw new Error('Not implemented dialogOpenAll function')
  },
})

export const OpenPositionTable: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(openPositionsSelector)
  const activeAsset = useSelector(selectedOpenOrderSelector)

  const [openCloseAllDialog, setOpenCloseAllDialog] = useState(false)
  const [closingOrder, setClosingOrder] = useState<TradeObject | undefined>()

  const dialogOpen = useCallback(
    (order: TradeObject) => {
      setClosingOrder(order)
    },
    [setClosingOrder],
  )

  const dialogClose = useCallback(() => {
    setClosingOrder(undefined)
  }, [setClosingOrder])

  const dialogOpenAll = useCallback(() => {
    setOpenCloseAllDialog(true)
  }, [setOpenCloseAllDialog])

  const dialogCloseAll = useCallback(() => {
    setOpenCloseAllDialog(false)
  }, [setOpenCloseAllDialog])

  const isMobileDevice = isMobile()
  const mobileColumns = useMemo<VirtualizedTableProps<TradeObject>['columns']>(
    () => [
      {
        Header: '',
        id: 'customCell',
        accessor: (row) => row,
        minWidth: 120,
        Cell: MobileAssetCell,
      },
      {
        Header: '',
        id: 'close',
        width: 30,
        Cell: CloseOrderButton,
      },
    ],
    [],
  )

  const columns = useMemo<VirtualizedTableProps<TradeObject>['columns']>(
    () => [
      {
        Header: '',
        id: 'favourite',
        accessor: 'symbol',
        maxWidth: 50,
        Cell: FavouriteCell,
      },
      {
        Header: t('asset').toString(),
        accessor: 'symbol',
        maxWidth: 100,
        Cell: RenamedAssetCell,
      },
      {
        Header: t('order').toString() + ' #',
        accessor: 'order',
        maxWidth: 100,
        Cell: OrderCell,
      },
      {
        Header: t('direction').toString(),
        accessor: 'type',
        maxWidth: 100,
        Cell: DirectionCell,
      },
      {
        Header: t('value').toString(),
        accessor: 'volume',
        maxWidth: 100,
        Cell: VolumeCell,
      },
      {
        Header: t('profit').toString(),
        accessor: 'profit',
        maxWidth: 100,
        Cell: ProfitCell,
      },
      {
        Header: t('leverage').toString(),
        accessor: 'leverage',
        maxWidth: 100,
        Cell: LeverageCell,
      },
      {
        Header: t('openPrice').toString(),
        accessor: 'openPrice',
        maxWidth: 100,
        Cell: OpenPriceCell,
      },
      {
        Header: t('currentPrice').toString(),
        id: 'currentPrice',
        maxWidth: 100,
        Cell: CurrentPriceCell,
      },
      {
        Header: t('openDate').toString(),
        accessor: 'openTime',
        maxWidth: 200,
        Cell: ValueCell,
      },
      {
        Header: '',
        id: 'edit',
        maxWidth: 80,
        Cell: EditActionCell,
      },
      {
        Header: data.length ? CloseAllHeader : '',
        id: 'close',
        maxWidth: 90,
        Cell: CloseActionCell,
      },
    ],
    [data.length, t],
  )

  const mobileRowClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      const { id, symbol } = e.currentTarget.dataset
      dispatch(changeLocation(Location.positions, {}))
      id && symbol && dispatch(selectedOpenedPosition(id, { activeAsset: symbol, currentLocation: 'positions' }))
      dispatch(setMobileBackButtonData({ show: true, title: symbol }))
    },
    [dispatch],
  )

  const rowClickHandle = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const { id, symbol } = e.currentTarget.dataset
      dispatch(clearActiveSignal())
      id && symbol && dispatch(selectedOpenedPosition(id, { activeAsset: symbol }))
    },
    [dispatch],
  )

  const isRowActive = useCallback(({ id }: TradeObject) => id === +activeAsset, [activeAsset])

  return (
    <>
      <OpenPositionTableContext.Provider value={{ dialogOpen, dialogOpenAll }}>
        <VirtualizedTable
          data={data}
          showHeaders={!isMobileDevice}
          showBorders={!isMobileDevice}
          columns={!isMobileDevice ? columns : mobileColumns}
          rowClickHandle={!isMobileDevice ? rowClickHandle : mobileRowClick}
          isRowActive={isRowActive}
          estimatedItemSize={estimatedItemSize}
          getItemSize={getItemSize}
          tableName={(isMobileDevice && 'open') || ''}
        />
      </OpenPositionTableContext.Provider>
      <CloseAllPositionsDialog open={openCloseAllDialog} onClose={dialogCloseAll} />
      <ClosePositionDialog order={closingOrder} onClose={dialogClose} />
    </>
  )
}
