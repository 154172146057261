import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Skeleton } from '@mui/material'

import { ReactComponent as Star } from '../../../assets/images/icons/skeletonStar.svg'
import { isMobile } from '../../../utils'

const SkeletonContainer = styled('div')(() => ({
  padding: 16,
}))

const SkeletonHeader = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

const StyledTitleSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: 20,
  background: theme.palette.mode === 'light' ? '#EBEBEB' : '#474747',
  margin: '0 11px 12px',
}))

const StyledStar = styled(Star)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#EBEBEB' : '#474747',
  marginBottom: 12,
}))

const StyledContentSkeleton = styled(Skeleton)(({ theme }) => ({
  background: theme.palette.mode === 'light' ? '#FAFAFA' : '#1F1F1F',
  borderRadius: 20,
  marginTop: 11,
}))

const TITLE_WIDTH = isMobile() ? [30, 40, 50] : [60, 100, 120]
const CONTENT_WIDTH = isMobile() ? [50, 60, 80, 90] : [140, 160, 180, 200]

export const SkeletonLoader: FC = () => {
  const isMobileDevice = isMobile()
  const items = Array.from(
    { length: Math.floor((isMobileDevice ? window.innerHeight - 120 : window.innerHeight) / 120) },
    (v, k) => k,
  )
  const width = (array: number[]): number | string =>
    isMobileDevice
      ? `${array[Math.floor(Math.random() * array.length)]}%`
      : array[Math.floor(Math.random() * array.length)]

  return (
    <>
      {items.map((elem) => (
        <SkeletonContainer key={elem}>
          <SkeletonHeader>
            <StyledStar />
            <StyledTitleSkeleton animation="wave" variant="rectangular" height={16} width={width(TITLE_WIDTH)} />
          </SkeletonHeader>
          <StyledContentSkeleton animation="wave" variant="rectangular" height={16} width={width(CONTENT_WIDTH)} />
          <StyledContentSkeleton animation="wave" variant="rectangular" height={16} width={width(CONTENT_WIDTH)} />
          <StyledContentSkeleton animation="wave" variant="rectangular" height={16} width={width(CONTENT_WIDTH)} />
        </SkeletonContainer>
      ))}
    </>
  )
}
