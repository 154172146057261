import { createAction, createCustomAction } from 'typesafe-actions'
import { FullscreenableElement } from '../../components/mobile/ToggleFullscreenButton'
import { MobileBackButtonData } from '../reducers/ui'

export const toggleTradePanel = createAction('@@ui/toggleTradePanel')<undefined>()
export const toggleOpenPosPanel = createAction('@@ui/toggleOpenPositionsPanel')<undefined>()
export const togglePendingPanel = createAction('@@ui/togglePendingPanel')<undefined>()
export const toggleHistoryPanel = createAction('@@ui/toggleHistoryPanel')<undefined>()
export const signalsRiskDisclaimer = createAction('@@ui/signalsRiskDisclaimer')<boolean>()
export const toggleTradingFormPanel = createAction('@@ui/toggleTradingFormPanel')<boolean>()
export const setMobileBackButtonData = createAction('@@ui/setMobileBackButtonData')<MobileBackButtonData | undefined>()
export const setShowDownloadMobileAppDialog = createAction('@@ui/setShowDownloadMobileAppDialog')<boolean>()
export const setShowFeedNewSectionTooltip = createCustomAction(
  '@@ui/setShowFeedNewSectionTooltip',
  (payload: boolean) => {
    localStorage.setItem('hideFeedNewSectionTooltip', 'true')
    return { payload }
  },
)
export const toggleFullscreen = createAction('@@ui/toggleFullscreen')<undefined>()
export const setFullscreenElement = createAction('@@ui/setFullscreenElement')<FullscreenableElement | undefined>()
export const currentMobilePositionTab = createAction('@app/currentMobilePositionTab')<string>()
export const downloadAppBannerAction = createAction('@app/downloadAppBannerAction')()
