import { createAction, createAsyncAction } from 'typesafe-actions'

import { FeedPanel, TradeMode } from '../../enums'
import { FeedItem } from '../reducers/feed'

export const setActivePanelAction = createAction('@@feed/setActivePanelAction')<FeedPanel | null>()
export const {
  request: getTIItemsRequest,
  success: getTIItemsSuccess,
  failure: getTIItemsFailure,
} = createAsyncAction('@@feed/getTIItemsRequest', '@@feed/getTIItemsSuccess', '@@feed/getTIItemsFailure')<
  void,
  FeedItem[],
  Error
>()

export const toggleExpandedTIItem = createAction('@@feed/toggleExpandedTIItem')<string>()
export const setUpdateActiveAsset = createAction('@@feed/setUpdateActiveAsset')<{ symbol: string; mode: TradeMode }>()
