import { ICommonConfig } from './types'
import { getEnv, getBooleanEnv } from './helpers'

const commonConfig: ICommonConfig = {
  mobileAppLinks: {
    autoDetectStore: getEnv('MOBILE_APP_AUTO_DETECT_STORE_LINK', 'https://p66rc.app.goo.gl/aYDa'),
    appleAppStore: getEnv(
      'MOBILE_APP_APPLE_STORE_LINK',
      'https://apps.apple.com/us/app/bdswiss-online-trading/id1238687228',
    ),
    googlePlayStore: getEnv(
      'MOBILE_APP_GOOGLE_PLAY_LINK',
      'https://play.google.com/store/apps/details?id=com.bdswissnative',
    ),
    androidApkFile: getEnv('MOBILE_APP_ANDROID_APK_FILE_LINK', 'https://bit.ly/3PsGnyW'),
  },
  telegramChannelsLinks: {
    en: getEnv('TELEGRAM_CHANNEL_EN_LINK', 'https://t.me/bdswiss_channel'),
    fr: getEnv('TELEGRAM_CHANNEL_FR_LINK', 'https://t.me/joinchat/TW8_l9K-dqp8dS1w'),
    de: getEnv('TELEGRAM_CHANNEL_DE_LINK', 'https://t.me/joinchat/xjTlMCB0ndNhYzk0'),
    pt: getEnv('TELEGRAM_CHANNEL_PT_LINK', 'https://t.me/joinchat/4oIDP4AFPdo2ZDM0'),
    it: getEnv('TELEGRAM_CHANNEL_IT_LINK', 'https://t.me/joinchat/givx1WdWPXoxOTg0'),
    es: getEnv('TELEGRAM_CHANNEL_ES_LINK', 'https://t.me/joinchat/oMXkpv0p4URjNjk0'),
  },
  services: {
    bugsnag: {
      enabled: getBooleanEnv('BUGSNAG_ENABLED', false),
      apiKey: getEnv('BUGSNAG_API_KEY', ''),
      releaseStage: getEnv('BUGSNAG_RELEASE_STAGE', 'staging'),
    },
    firebase: {
      enabled: getBooleanEnv('FIREBASE_ENABLED', false),
      apiKey: getEnv('FIREBASE_API_KEY', ''),
      authDomain: getEnv('FIREBASE_AUTH_DOMAIN', ''),
      projectId: getEnv('FIREBASE_PROJECT_ID', ''),
      storageBucket: getEnv('FIREBASE_STORAGE_BUCKET', ''),
      messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID', ''),
      appId: getEnv('FIREBASE_APP_ID', ''),
      measurementId: getEnv('FIREBASE_MEASUREMENT_ID', ''),
    },
  },
  mt4Connector: {
    bufferTimeSpanForTickEvent: getEnv('MT4_CONNECTOR_BUFFER_TIME_SPAN_FOR_TICK_EVENT', '200'),
  },
  redux: {
    secretCookie: getEnv('SECRET_REDUX_COOKIE', ''),
  },
}

export default commonConfig
