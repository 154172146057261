import { combineReducers } from 'redux'

import { account } from './account'
import { app } from './app'
import { user } from './user'
import { positions } from './positions'
import { uiReducer } from './ui'
import { assetGroups } from './assetGroups'
import { marketStateChanges } from './marketStateChanges'
import { favourites } from './favourites'
import { form } from './form'
import { guidedTour } from './guidedTour'
import { feed } from './feed'

export const rootReducer = combineReducers({
  accounts: account,
  app: app,
  user: user,
  ui: uiReducer,
  positions,
  assetGroups,
  marketStateChanges,
  favourites,
  form,
  guidedTour,
  feed,
})
