import React, { useContext } from 'react'
import { CellProps, Renderer } from 'react-table'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import makeStyles from '@mui/styles/makeStyles'

import { Trade } from '../../../services/graphql/types'
import { TableContext } from '../../VirtualizedTable'

const useStyles = makeStyles({
  arrowWrap: {
    position: 'relative',
    display: 'flex',
    width: '25px',
    height: '25px',
    marginLeft: '8px',
    '& svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
})

export const MoreCell: Renderer<CellProps<Trade, string>> = ({
  row: {
    original: { id },
  },
}) => {
  const { expandedPositionsState } = useContext(TableContext)
  const classes = useStyles()
  return (
    <div className={classes.arrowWrap}>
      {id && expandedPositionsState[id] ? (
        <KeyboardArrowDownOutlinedIcon style={{ fontSize: 20 }} />
      ) : (
        <KeyboardArrowRightOutlinedIcon style={{ fontSize: 20 }} />
      )}
    </div>
  )
}

export const CloseDateCell: Renderer<CellProps<Trade, Trade['closeTime']>> = ({ value }) => {
  return <span>{value && new Date(value).toLocaleString()}</span>
}
