import React, { FC } from 'react'

import { NumberInputLabel } from '../../Controls/NumberInputLabel'
import PriceLimitInput from '../../Controls/PriceLimitInput'

export const PendingOrderInput: FC<{ currency: string }> = ({ currency }) => {
  return (
    <>
      <NumberInputLabel name="limitPrice" unit={currency} />
      <PriceLimitInput />
    </>
  )
}
