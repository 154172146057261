import { isActionOf } from 'typesafe-actions'
import { concat, EMPTY, from, of } from 'rxjs'
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'

import { Error, OpenOrderTypeEnum, OpenTrade, TradeObject } from '@bdswiss/mt4-connector'

import { RootEpic } from '../types'
import {
  addClosedPositionOnScroll,
  closeAllOpenPositionsAction,
  closeOrderAction,
  error,
  getClosedPositionsFailure,
  getClosedPositionsRequest,
  getClosedPositionsSuccess,
  initialStateValuesForModifying,
  modifyOrderAction,
  openOrderAction,
  setSubmitEnabled,
  updateAccountLogin,
} from '../actions'
import { getServerTypeByAccountType } from '../../utils'
import {
  closeAllDemoAttempted,
  closeAllDemoCompleted,
  closeAllDemoFailed,
  closeAllRealAttempted,
  closeAllRealCompleted,
  closeAllRealFailed,
  demoTradeAttempted,
  demoTradeCompleted,
  demoTradeFailed,
  modifyDemoOrderAttempted,
  modifyDemoOrderCompleted,
  modifyDemoOrderFailed,
  modifyRealOrderAttempted,
  modifyRealOrderCompleted,
  modifyRealOrderFailed,
  realTradeAttempted,
  realTradeCompleted,
  realTradeFailed,
} from '../../analytics/events'
import { trackEvent } from '../../analytics/firebaseLogger'
import {
  ORDER_TYPE_OPEN,
  ORDER_TYPE_PENDING,
  trackCloseOrderAttemptEvent,
  trackCloseOrderCompletedEvent,
  trackCloseOrderFailedEvent,
} from '../../analytics/utils'
import { Location, OrderTypes } from '../../enums'

export const openOrderEpic: RootEpic = (action$, state$, { mt4Connector, bugsnag }) => {
  return action$.pipe(
    filter(isActionOf([openOrderAction])),
    switchMap(() => {
      const tradeMode = state$.value.app.tradeMode
      const formOrderType = state$.value.form.formOrderType
      const activeAsset = state$.value.app.activeAsset
      const activeAccount = state$.value.accounts.data.find((account) => account.login === state$.value.app.login)

      // NOTE: Temporarily commented out changes before server-side changes are made
      // const getCMD = (): OpenOrderTypeEnum | undefined => {
      //   if (tradeMode === 'buy') {
      //     if (formOrderType === OrderTypes.marketOrder) return OpenOrderTypeEnum.OP_BUY
      //     if (formOrderType === OrderTypes.limitOrder) return OpenOrderTypeEnum.OP_BUY_LIMIT
      //     if (formOrderType === OrderTypes.stopOrder) return OpenOrderTypeEnum.OP_BUY_STOP
      //     if (formOrderType === OrderTypes.stopLimitOrder) return OpenOrderTypeEnum.OP_BUY_STOP_LIMIT
      //   }
      //   if (tradeMode === 'sell') {
      //     if (formOrderType === OrderTypes.marketOrder) return OpenOrderTypeEnum.OP_SELL
      //     if (formOrderType === OrderTypes.limitOrder) return OpenOrderTypeEnum.OP_SELL_LIMIT
      //     if (formOrderType === OrderTypes.stopOrder) return OpenOrderTypeEnum.OP_SELL_STOP
      //     if (formOrderType === OrderTypes.stopLimitOrder) return OpenOrderTypeEnum.OP_SELL_STOP_LIMIT
      //   }
      // }
      // const cmd = getCMD()

      const priceLimit =
        formOrderType === OrderTypes.pendingOrder || formOrderType === OrderTypes.stopLimitOrder
          ? { price: state$.value.form.priceLimit.value }
          : {}

      const stopPrice =
        formOrderType === 'stopOrder' || formOrderType === 'stopLimitOrder'
          ? { price_trigger: state$.value.form.stopPrice.value }
          : {}

      const tradeOptions = {
        uuid: uuid(),
        symbol: activeAsset,
        cmd: tradeMode as unknown as OpenOrderTypeEnum,
        platform: state$.value.app.platform,
        volume: state$.value.form.lots,
        ...priceLimit,
        ...stopPrice,
        ...(state$.value.form.takeProfitEnabled ? { tp: state$.value.form.takeProfit.value } : {}),
        ...(state$.value.form.stopLossEnabled ? { sl: state$.value.form.stopLoss.value } : {}),
      } as OpenTrade

      activeAccount &&
        trackEvent(activeAccount.isDemo ? demoTradeAttempted : realTradeAttempted, {
          login: state$.value.app.login,
          symbol: tradeOptions.symbol,
          volume: tradeOptions.volume,
          price: tradeOptions.price,
          sl: tradeOptions.sl,
          tp: tradeOptions.tp,
          price_trigger: tradeOptions.price_trigger,
          mode: tradeOptions.price ? 'Pending' : 'Market',
          direction: tradeMode === 'buy' ? 'Buy' : 'Sell',
        })

      return concat(
        of(setSubmitEnabled(false)),
        mt4Connector.openOrder(tradeOptions).pipe(
          switchMap((value: Error | TradeObject) => {
            activeAccount &&
              trackEvent(activeAccount?.isDemo ? demoTradeCompleted : realTradeCompleted, {
                login: activeAccount?.login,
                symbol: activeAsset,
                positionId: (value as TradeObject).order,
              })

            return EMPTY
          }),
          catchError((error: Error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            activeAccount &&
              trackEvent(activeAccount?.isDemo ? demoTradeFailed : realTradeFailed, {
                login: activeAccount?.login,
                symbol: activeAsset,
                reason: error.type + (error.message ? `: ${error.message}` : ''),
              })

            return EMPTY
          }),
        ),
        of(setSubmitEnabled(true)),
      )
    }),
    catchError((message) => of(error(message))),
  )
}

export const closeOrderEpic: RootEpic = (action$, state$, { mt4Connector, bugsnag }) => {
  return action$.pipe(
    filter(isActionOf([closeOrderAction])),
    switchMap(({ payload: { order, volume, uuid } }) => {
      const activeAccount = state$.value.accounts.data.find((account) => account.login === state$.value.app.login)
      let orderType: string | undefined
      mt4Connector.pendingOrders.has(order) && (orderType = ORDER_TYPE_PENDING)
      mt4Connector.positions.has(order) && (orderType = ORDER_TYPE_OPEN)

      trackCloseOrderAttemptEvent(order, activeAccount, orderType)

      const executionObserver = {
        next: () => trackCloseOrderCompletedEvent(order, activeAccount, orderType),
        error: (error: Error) => {
          bugsnag.notify({ name: error.type, message: error.message })
          trackCloseOrderFailedEvent(order, error, activeAccount, orderType)
        },
      }

      mt4Connector.positions.has(order) && mt4Connector.closeOrder({ order, volume, uuid }).subscribe(executionObserver)
      mt4Connector.pendingOrders.has(order) &&
        mt4Connector.cancelPendingOrder({ order, uuid }).subscribe(executionObserver)

      return EMPTY
    }),
  )
}

export const closeAllOrdersEpic: RootEpic = (action$, state$, { mt4Connector, bugsnag }) =>
  action$.pipe(
    filter(isActionOf([closeAllOpenPositionsAction])),
    switchMap(() => {
      const activeAccount = state$.value.accounts.data.find((account) => account.login === state$.value.app.login)
      const positionIds = Array.from(mt4Connector.positions.values())
        .map((key) => key.order)
        .join(', ')

      activeAccount && trackEvent(activeAccount.isDemo ? closeAllDemoAttempted : closeAllRealAttempted, { positionIds })

      mt4Connector.closeAllOrders().subscribe({
        next: () => {
          activeAccount &&
            trackEvent(activeAccount.isDemo ? closeAllDemoCompleted : closeAllRealCompleted, { positionIds })
        },
        error: (error: Error) => {
          bugsnag.notify({ name: error.type, message: error.message })
          activeAccount &&
            trackEvent(activeAccount.isDemo ? closeAllDemoFailed : closeAllRealFailed, {
              positionIds,
              reason: error.type + (error.message ? `: ${error.message}` : ''),
            })
        },
      })

      return EMPTY
    }),
  )

export const closedPositionsOnUpdateAccountEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateAccountLogin)),
    switchMap(({ payload }) => {
      if (state$.value.app.activeTab === Location.history)
        return of(getClosedPositionsRequest({ limit: 50, login: +payload }))
      return EMPTY
    }),
  )

export const closedPositionsEpic: RootEpic = (action$, state$, { graphqlClient: { getClosedPositions }, bugsnag }) => {
  return action$.pipe(
    filter(isActionOf(getClosedPositionsRequest)),
    exhaustMap(({ payload }) => {
      const selectAccount = state$.value.accounts.data.find((account) => account.login === state$.value.app.login)
      if (selectAccount) {
        const { login, __typename } = selectAccount
        return from(
          getClosedPositions({
            limit: payload?.limit || 10,
            login: payload?.login || Number(login),
            offset: payload?.offset || 0,
            server: getServerTypeByAccountType(__typename),
          }),
        ).pipe(
          map((response) =>
            !payload?.offset
              ? getClosedPositionsSuccess(response.data.closedPositions)
              : addClosedPositionOnScroll(response.data.closedPositions),
          ),
          catchError((error) => {
            bugsnag.notify(error)
            return of(getClosedPositionsFailure(error))
          }),
        )
      }
      return EMPTY
    }),
  )
}

export const modifyOrderEpic: RootEpic = (action$, state$, { mt4Connector, bugsnag }) =>
  action$.pipe(
    filter(isActionOf([modifyOrderAction])),
    switchMap(({ payload }) => {
      const activeAccount = state$.value.accounts.data.find((account) => account.login === state$.value.app.login)
      const selectedPosition =
        mt4Connector.pendingOrders.get(payload.order) || mt4Connector.positions.get(payload.order)

      const tp = state$.value.form.takeProfitEnabled ? state$.value.form.takeProfit.value : 0
      const sl = state$.value.form.stopLossEnabled ? state$.value.form.stopLoss.value : 0

      const { priceLimit, takeProfit, stopLoss, takeProfitEnabled, stopLossEnabled } = state$.value.form
      const formValues = [
        takeProfit?.value || 0,
        stopLoss?.value || 0,
        priceLimit?.value || 0,
        takeProfitEnabled || false,
        stopLossEnabled || false,
      ]

      activeAccount &&
        selectedPosition &&
        trackEvent(activeAccount?.isDemo ? modifyDemoOrderAttempted : modifyRealOrderAttempted, {
          positionId: payload.order,
          investment: selectedPosition.investment,
          tp,
          sl,
          login: activeAccount.login,
          symbol: selectedPosition.symbol,
        })

      return concat(
        of(setSubmitEnabled(false)),
        mt4Connector
          .modifyOrder({
            uuid: uuid(),
            tp,
            sl,
            ...(state$.value.app.activeTab === Location.order ? { price: state$.value.form.priceLimit.value } : {}),
            ...payload,
          })
          .pipe(
            switchMap((value: Error | TradeObject) => {
              activeAccount &&
                selectedPosition &&
                trackEvent(activeAccount?.isDemo ? modifyDemoOrderCompleted : modifyRealOrderCompleted, {
                  login: activeAccount?.login,
                  symbol: selectedPosition.symbol,
                  positionId: (value as TradeObject).order,
                })

              return EMPTY
            }),
            catchError((error: Error) => {
              bugsnag.notify({ name: error.type, message: error.message })
              activeAccount &&
                selectedPosition &&
                trackEvent(activeAccount?.isDemo ? modifyDemoOrderFailed : modifyRealOrderFailed, {
                  positionId: payload.order,
                  symbol: selectedPosition.symbol,
                  reason: error.type + (error.message ? `: ${error.message}` : ''),
                })

              return EMPTY
            }),
          ),
        of(setSubmitEnabled(true)),
        of(initialStateValuesForModifying(formValues)),
      )
    }),
    catchError((message) => of(error(message))),
  )

export default [
  openOrderEpic,
  closeOrderEpic,
  closedPositionsEpic,
  closeAllOrdersEpic,
  modifyOrderEpic,
  closedPositionsOnUpdateAccountEpic,
]
