import { isActionOf } from 'typesafe-actions'
import { from, of } from 'rxjs'
import { catchError, exhaustMap, filter, map } from 'rxjs/operators'
import { get } from 'lodash'

import { RootEpic } from '../types'
import {
  getFavouritesFailure,
  getFavouritesRequest,
  getFavouritesSuccess,
  getUserSuccess,
  propagateFavouriteRequest,
  updateFavouritesFailure,
  updateFavouritesRequest,
  updateFavouritesSuccess,
} from '../actions'
import { favouriteSymbol, unfavouriteSymbol } from '../../analytics/events'
import { trackEvent } from '../../analytics/firebaseLogger'

export const getFavoritesEpic: RootEpic = (action$, state$, { graphqlClient: { getFavourites }, bugsnag }) => {
  return action$.pipe(
    filter(isActionOf([getFavouritesRequest, getUserSuccess])),
    exhaustMap(() =>
      from(getFavourites()).pipe(
        map((response) => getFavouritesSuccess(get(response, 'data.viewer.tradingConfig.favourites', []))),
        catchError((error) => {
          bugsnag.notify(error)
          return of(getFavouritesFailure(error))
        }),
      ),
    ),
  )
}

export const handleUpdateFavouritesEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isActionOf(propagateFavouriteRequest)),
    map(({ payload }) => updateFavouritesRequest(payload)),
  )
}

export const updateFavouritesEpic: RootEpic = (action$, state$, { graphqlClient: { updateFavourites }, bugsnag }) => {
  return action$.pipe(
    filter(isActionOf(updateFavouritesRequest)),
    exhaustMap(({ payload }) => {
      const favourites = state$.value.favourites.data
      trackEvent(favourites.includes(payload) ? unfavouriteSymbol : favouriteSymbol, { payload })

      return from(
        updateFavourites({
          favourites: (favourites.includes(payload) && favourites.filter((item) => item !== payload)) || [
            ...favourites,
            payload,
          ],
        }),
      ).pipe(
        map((response) => updateFavouritesSuccess(get(response, 'data.updateFavourites.favourites', []))),
        catchError((error) => {
          bugsnag.notify(error)
          return of(updateFavouritesFailure(error))
        }),
      )
    }),
  )
}

export default [getFavoritesEpic, updateFavouritesEpic, handleUpdateFavouritesEpic]
