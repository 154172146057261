import has from 'lodash/has'
import get from 'lodash/get'
import set from 'lodash/set'
import merge from 'lodash/merge'

import commonConfig from './common'
import { ICompanyConfig, IConfig, IConfigPath, IConfigPathValue, IMultipleConfigPath } from './types'

export class Config {
  private static instance: Config
  private readonly config: IConfig = commonConfig

  public static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config()
    }

    return Config.instance
  }

  /**
   * Check if parameter exists in config
   *
   * For example, check if single parameter exists:
   * ```js
   * config.has('companyName')
   * ```
   *
   * Check if every parameter are exists:
   * ```js
   * config.has(['companyName', 'companyKey', 'mobileAppLinks.appleAppStore'])
   * ```
   */
  public has<TPath extends IConfigPath | Array<IConfigPath>>(path: TPath): boolean {
    if (Array.isArray(path)) {
      return path.every((value) => has(this.config, value))
    }

    return has(this.config, path)
  }

  /**
   * Get parameter from config.
   *
   * For example, get single parameter:
   * ```js
   * config.get('companyName')
   * ```
   *
   * Get single nested parameter:
   * ```js
   * config.get('mobileAppLinks.appleAppStore')
   * ```
   *
   * Get multiple parameters:
   * ```js
   * config.get(['companyName', 'companyKey', 'mobileAppLinks.appleAppStore'])
   * ```
   *
   * Get multiple parameters with custom property names and default values:
   * ```js
   * config.get(['companyName', 'companyKey', {
   *    path: 'mobileAppLinks.appleAppStore',
   *    propertyName: 'appleAppStoreLink',
   *  }, {
   *    path: 'telegramChannelsLinks.en',
   *    propertyName: 'tgLink',
   *    defaultValue: 'https://t.me/bdswiss_channel',
   *  }])
   * ```
   */
  public get<TPath extends IConfigPath>(path: TPath): IConfigPathValue<TPath>
  public get<TPath extends IConfigPath>(path: IMultipleConfigPath<TPath>): Record<TPath | string, unknown>
  public get(path: string | IMultipleConfigPath<string>): Record<string, unknown> {
    if (Array.isArray(path)) {
      return path.reduce(
        (acc, value) => ({
          ...acc,
          [get(value, 'propertyName') || get(value, 'path', value)]: get(
            this.config,
            get(value, 'path', value),
            get(value, 'defaultValue'),
          ),
        }),
        {},
      )
    }

    return get(this.config, path)
  }

  /**
   * Get all parameters from config
   */
  public getAll(): IConfig {
    return this.config
  }

  /**
   * Set config parameter
   *
   * Examples:
   * ```js
   * config.set('services.firebase.enabled', false)
   *       .set('services.bugsnag.enabled', false)
   *       .set('companyName', 'BDSwiss')
   * ```
   */
  public set<TPath extends IConfigPath>(path: TPath, value: unknown): Config {
    set(this.config, path, value)
    return Config.instance
  }

  /**
   * Merge config with company config
   */
  public mergeWith(companyConfig: ICompanyConfig): Config {
    merge(this.config, companyConfig)
    return Config.instance
  }
}

export default Config.getInstance()
export * from './types'
