import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { styled } from '@mui/styles'
import clsx from 'clsx'

import { downloadAppBannerSelector, historyTabSelector } from '../../../store/selectors'
import { TitleTopPanel } from '../TitleTopPanel'
import { HistoryTable } from '../../Tables/HistoryTable'
import { getClosedPositionsRequest } from '../../../store/actions'
import { isMobile } from '../../../utils'

const MobileHistoryTableContainer = styled('div')({
  height: '100%',
  '&.bannerShown': {
    height: 'calc(100% - 56px)',
  },
})

export const TradeHistory: FC = () => {
  const isMobileDevice = isMobile()
  const historySelector = useSelector(historyTabSelector)
  const bannerShown = useSelector(downloadAppBannerSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getClosedPositionsRequest({ limit: 50 }))
  }, [dispatch])

  return (
    <>
      {historySelector && (
        <>
          {!isMobileDevice && <TitleTopPanel title={<Trans i18nKey="tradeHistory" />} />}
          {!isMobileDevice ? (
            <HistoryTable />
          ) : (
            <MobileHistoryTableContainer className={clsx({ bannerShown })}>
              <HistoryTable />
            </MobileHistoryTableContainer>
          )}
        </>
      )}
    </>
  )
}
