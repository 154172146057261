import I18n from '../../../i18next'
import { MT4Connector, Trade, Price, TradeObject, calculatePl, calculateInitialLots } from '@bdswiss/mt4-connector'

import { IChartWidgetApi, IPositionLineAdapter } from '../types'

import store from '../../../store'
import { formatProfitLoss, formatCurrency, formatPriceDifference } from '../utils'
import {
  activeAssetSelector,
  activeTabSelector,
  formSubmitEnabledSelector,
  pricesSelector,
  selectedPositionSelector,
  mainCurrencySelector,
  chartModeSelector,
  slTpInputSelector,
  formProfitSelector,
  formLossSelector,
  tradeModeSelector,
  getActiveSignal,
  isCurrentAccountLeverageTypeDynamicSelector,
} from '../../../store/selectors'
import { TradingPane, TradingPaneValues } from '../../../types'
import { Location, TradeMode } from '../../../enums'
import { selectedOpenedPosition, selectedPendingPosition } from '../../../store/actions'
import { positiveNegativeFormat, isMobile } from '../../../utils'

const mt4 = MT4Connector.Instance

const chartShapeFont = 'bold 10pt Verdana'

export interface PositionShape {
  order: number
  expanded: boolean
  pLine?: IPositionLineAdapter
  slLine?: IPositionLineAdapter
  tpLine?: IPositionLineAdapter
}

export interface PositionsShapes {
  [order: string]: PositionShape
}

export interface IChartPositions {
  initChart(chart: IChartWidgetApi): void

  displayPositions(): void

  clearPositions(): void
}

export class ChartPositions implements IChartPositions {
  private chart!: IChartWidgetApi
  private positionsShapes: PositionsShapes = {}

  public initChart(chart: IChartWidgetApi): void {
    this.chart = chart
  }

  public clearPositions(): void {
    const lines = Object.keys(this.positionsShapes)
    if (lines.length) {
      lines.forEach((line) => this._erasePositionShape(this.positionsShapes[line]))
      this.positionsShapes = {}
    }
  }

  public displayPositions(): void {
    const state = store.getState()
    const symbol = activeAssetSelector(state)
    const selectedPosition = selectedPositionSelector(state)
    const chartMode = chartModeSelector(state)
    const currentTradeMode = tradeModeSelector(state)

    const {
      value,
      valueAccount,
      requiredMargin,
      requiredMarginAccount,
      takeProfitEnabled,
      takeProfit,
      profit,
      profitPercent,
      stopLossEnabled,
      stopLoss,
      loss,
      lossPercent,
      isPendingOrder,
      lots,
      amount,
      priceLimit,
      priceLimitInitial,
      takeProfitInitial,
      stopLossInitial,
    } = state.form

    const tradeType = currentTradeMode === TradeMode.sell ? 'sell' : 'buy'

    const prices = pricesSelector()
    const asset = prices[symbol]
    if (!asset) {
      return
    }

    const { lotSize } = asset
    const loginInfo = state.app.loginInfo
    const lotsInitial = loginInfo ? calculateInitialLots(loginInfo, asset, mt4.assets) || 0 : 0
    const amountInitial = lotsInitial * lotSize || 0

    const formValues: TradingPaneValues = {
      value,
      valueAccount,
      requiredMargin,
      requiredMarginAccount,
      takeProfitEnabled,
      takeProfitInitial,
      takeProfit: takeProfit.value,
      profit: profit,
      profitPercent,
      stopLossEnabled,
      stopLossInitial,
      stopLoss: stopLoss.value,
      loss,
      lossPercent,
      pendingOrderEnabled: isPendingOrder,
      priceInitial: priceLimitInitial,
      price: priceLimit.value,
    }

    const submitEnabled = formSubmitEnabledSelector(state)

    const tradingPane: TradingPane = {
      tradeOperationEnabled: submitEnabled,
      lotsInitial: lots,
      lots,
      lotsInput: lots,
      amountInitial,
      amount: amount.value,
      amountInput: amount.value,
      tradeType,
      buy: formValues,
      sell: formValues,
    }

    const positions = Array.from(mt4.positions.values())
    const pendingOrders = Array.from(mt4.pendingOrders.values())

    let currentPositions: TradeObject[] = []
    switch (chartMode) {
      case 'open':
        currentPositions = positions.filter((position) => position.symbol === symbol)
        break
      case 'pending':
        currentPositions = pendingOrders.filter((position) => position.symbol === symbol)
        break
      case 'trade':
        const tradeType = tradingPane.tradeType
        const {
          price,
          pendingOrderEnabled,
          priceInitial,
          profit,
          loss,
          stopLoss,
          takeProfit,
          stopLossEnabled,
          takeProfitEnabled,
          takeProfitInitial,
          stopLossInitial,
        } = tradingPane[tradeType]
        currentPositions = selectedPosition
          ? positions.filter((position) => position.symbol === symbol && position.order === selectedPosition?.order)
          : [
              {
                closePrice: 0,
                closeTime: '',
                type: tradeType + (pendingOrderEnabled ? ' limit' : ''),
                commission: stopLossEnabled ? loss || stopLossInitial : 0,
                digits: 0,
                login: 0,
                openPrice: pendingOrderEnabled ? price || priceInitial : 0,
                openTime: '',
                profit,
                sl: stopLossEnabled ? stopLoss || stopLossInitial : 0,
                swaps: 0,
                symbol,
                order: -1,
                tp: takeProfitEnabled ? takeProfit || takeProfitInitial : 0,
                volume: tradingPane.lots,
                convRate1: 1,
                magic: 0,
                profitPercent: 0,
                tpInitial: takeProfitInitial,
                slInitial: stopLossInitial,
                currentPrice: 0,
                rate: 1,
              } as TradeObject,
            ]
        break
    }
    const shapes = Object.keys(this.positionsShapes)
    if (!isMobile() && (currentPositions.length || shapes.length)) {
      try {
        // cleanup positions shapes
        const remove = shapes.filter(
          (shape) => !currentPositions.find((position) => this.positionsShapes[shape].order === position.order),
        )
        if (remove.length) {
          remove.forEach((shape) => {
            this._erasePositionShape(this.positionsShapes[shape])
            delete this.positionsShapes[shape]
          })
        }
        // display positions shapes
        currentPositions.forEach((position) => this._createPositionShape(position))
      } catch (e) {
        console.log(`Error displaying positions on chart: ${e.message}`)
      }
    }
  }

  private _createPositionShape(position: Trade) {
    const state = store.getState()
    const activePosition = selectedPositionSelector(state)?.order

    const selected = activePosition || false
    const expanded = !!activePosition

    const positionShape = this.positionsShapes[position.order]
    if (positionShape) {
      if (!expanded || !selected || (expanded && position.order === selected)) {
        this._drawPositionLine(position, positionShape)
      } else {
        this._erasePositionShape(positionShape)
      }
    } else {
      if (!expanded || !selected || (expanded && position.order === selected)) {
        const newPositionShape = (this.positionsShapes[position.order] = {
          order: position.order,
          expanded: false,
        })
        this._drawPositionLine(position, newPositionShape)
      } else {
        this.positionsShapes[position.order] = { order: position.order, expanded: false }
      }
    }
  }

  private _drawPositionLine(position: Trade, positionShape: PositionShape) {
    const locale = localStorage.getItem('i18nextLng') || 'en'

    const state = store.getState()
    const symbol = activeAssetSelector(state)
    const chartMode = chartModeSelector(state)
    const currency = mainCurrencySelector(state)
    const isCurrentAccountLeverageTypeDynamic = isCurrentAccountLeverageTypeDynamicSelector(state)
    const activePosition = selectedPositionSelector(state)?.order
    const positionExpanded = !!activePosition
    const orderExpanded = positionExpanded
    const selectedPosition = activePosition
    const selectedOrder = activePosition

    const {
      priceLimit: { value: priceInput },
    } = state.form

    const assets = mt4.assets
    const asset = assets[symbol]
    if (chartMode === 'trade' && !selectedPosition) {
      // Trading pane price line
      const { type, openPrice } = position
      if (openPrice <= 0) {
        if (positionShape.pLine) {
          positionShape.pLine.remove()
          delete positionShape.pLine
        }
        this._drawPositionDetails(position, positionShape)
        return
      }
      let pLine = positionShape.pLine
      if (!positionShape.pLine) {
        try {
          pLine = this.chart.createPositionLine({ disableUndo: true })
        } catch (e) {
          console.log(`Error creating position line: ${e.message}`)
        }
      }
      let priceDifference: number | undefined
      let differencePercent
      if (/buy/i.test(type)) {
        priceDifference = openPrice - asset.mask
        differencePercent = (priceDifference * 100) / asset.mask
      } else {
        priceDifference = openPrice - asset.mbid
        differencePercent = (priceDifference * 100) / asset.mbid
      }

      if (priceDifference === undefined) {
        return
      }

      const formattedDifference = formatPriceDifference(
        priceDifference,
        asset.digits,
        asset.currency,
        priceDifference >= 0,
      )
      const formattedPercent = ' (' + (priceDifference >= 0 ? '+' : '') + differencePercent.toFixed(2) + '%)'
      const label = I18n.t(/buy/i.test(type) ? 'buyPrice' : 'sellPrice')
      const typeColor = priceDifference > 0 ? '#67AD5B' : '#E15241'

      if (pLine) {
        if (!positionShape.pLine) {
          pLine
            .setQuantityFont(chartShapeFont)
            .setQuantityTextColor('#FFF')
            .setQuantity(formattedDifference)
            .setLineStyle(0)
            .setLineWidth(1)
            .setPrice(+openPrice.toFixed(asset.digits))
            .setBodyFont(chartShapeFont)
            .setLineStyle(0)
            .setLineWidth(1)
          positionShape.pLine = pLine
        }
        pLine
          .setBodyTextColor(typeColor)
          .setText(label)
          .setBodyBorderColor(typeColor)
          .setQuantityBackgroundColor(typeColor)
          .setQuantityBorderColor(typeColor)
          .setLineColor(typeColor)
          .setPrice(+openPrice.toFixed(asset.digits))
          .setTooltip(`${formattedDifference} ${formattedPercent}\n ${I18n.t('priceDistance')}`)
          .setQuantity(formattedDifference)
        this._drawPositionDetails(position, positionShape)
      }
    } else if (chartMode === 'pending') {
      // Pending position price line
      const { profit, type, investment, leverage, volume, order, openPrice } = position
      const profitColor = profit > 0 ? '#67AD5B' : '#E15241'
      const invested =
        investment && leverage
          ? `${formatCurrency(investment, currency, locale)} ${
              !isCurrentAccountLeverageTypeDynamic ? `(x${leverage})` : ''
            }`
          : `${volume} ${I18n.t('lots')}`
      const price = priceInput || openPrice
      let pLine: IPositionLineAdapter = {} as IPositionLineAdapter
      if (order === selectedOrder && orderExpanded) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (positionShape.pLine && (positionShape.pLine as any)._line.toolname !== 'LineToolOrder') {
          positionShape.pLine.remove()
          delete positionShape.pLine
        }
        if (positionShape.pLine) {
          pLine = positionShape.pLine
        } else {
          try {
            pLine = this.chart.createPositionLine({ disableUndo: true })
          } catch (e) {
            console.log(`Error creating position line: ${e.message}`)
          }
        }

        if (!positionShape.pLine) {
          const typeColor = /buy/i.test(type) ? '#67AD5B' : '#E15241'
          pLine
            .setBodyTextColor(typeColor)
            .setBodyBorderColor(typeColor)
            .setQuantityFont(chartShapeFont)
            .setQuantityTextColor('#FFF')
            .setQuantityBackgroundColor(typeColor)
            .setQuantityBorderColor(typeColor)
            .setQuantity(price.toFixed(asset.digits))
            .setLineColor(typeColor)
            .setLineStyle(0)
            .setLineWidth(1)
            .setPrice(Number(price.toFixed(asset.digits)))
            .setText('\u2195')
            .setBodyFont(chartShapeFont)
            .setLineStyle(0)
            .setLineWidth(1)
          positionShape.pLine = pLine
        }
        pLine
          .setPrice(Number(price.toFixed(asset.digits)))
          .setTooltip(`${I18n.t('openPrice')}\n ${price.toFixed(asset.digits)}`)
          .setText(I18n.t('openPrice'))
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (positionShape.pLine && (positionShape.pLine as any)._line.toolname !== 'LineToolPosition') {
          positionShape.pLine.remove()
          delete positionShape.pLine
        }
        if (positionShape.pLine) {
          pLine = positionShape.pLine
        } else {
          try {
            pLine = this.chart.createPositionLine({ disableUndo: true })
          } catch (e) {
            console.log(`Error creating position line: ${e.message}`)
          }
        }

        if (!positionShape.pLine) {
          const typeColor = /buy/i.test(type) ? '#67AD5B' : '#E15241'
          pLine
            .setBodyFont(chartShapeFont)
            .setQuantityTextColor('#FFF')
            .setQuantityFont(chartShapeFont)
            .setQuantityBackgroundColor(typeColor)
            .setQuantity(invested)
            .setPrice(openPrice)
          positionShape.pLine = pLine
        }
        pLine
          .setBodyTextColor(profitColor)
          .setText(openPrice.toFixed(asset.digits))
          .setTooltip(
            `${I18n.t(type)}\n ${I18n.t('order')} #${order}\n ${I18n.t('price')} ${openPrice.toFixed(
              asset.digits,
            )}\n ${I18n.t('investment')} ${invested}`,
          )
          .onModify(() => this._selectPositionLine(positionShape))
      }
      if (order === selectedOrder) {
        pLine.setLineStyle(0)
        if (orderExpanded) {
          pLine.setLineWidth(2)
          this._drawPositionDetails(position, positionShape)
        } else {
          pLine.setLineWidth(1)
          this._erasePositionShape(positionShape, true)
        }
      } else {
        pLine.setLineStyle(2)
        pLine.setLineWidth(1)
        this._erasePositionShape(positionShape, true)
      }
    } else {
      // Open position price line
      let pLine = positionShape.pLine
      if (!pLine) {
        try {
          pLine = this.chart.createPositionLine({ disableUndo: true })
        } catch (e) {
          console.log(`Error creating position line: ${e.message}`)
        }
      }
      const { profit, type, investment, leverage, volume, order, openPrice } = position
      const profitColor = profit > 0 ? '#67AD5B' : '#E15241'
      const invested =
        investment && leverage
          ? `${formatCurrency(investment, currency, locale)} ${
              !isCurrentAccountLeverageTypeDynamic ? `(x${leverage})` : ''
            }`
          : `${volume} ${I18n.t('lots')}`
      const profitLoss = formatProfitLoss(profit, currency, locale)
      const percent = investment
        ? `(${(profit > 0 ? '+' : '') + ((+profit.toFixed(2) * 100) / investment).toFixed(2)}%)`
        : ''
      if (pLine) {
        if (!positionShape.pLine) {
          const typeColor = /buy/i.test(type) ? '#67AD5B' : '#E15241'
          pLine
            .setBodyFont(chartShapeFont)
            .setQuantityTextColor('#FFF')
            .setQuantityFont(chartShapeFont)
            .setQuantityBackgroundColor(typeColor)
            .setQuantity(invested)
            .setPrice(openPrice)
            .onModify(() => this._selectPositionLine(positionShape))
          positionShape.pLine = pLine
        }
        pLine
          .setBodyTextColor(profitColor)
          .setText(profitLoss)
          .setTooltip(
            `${I18n.t(type)}\n ${I18n.t('order')} #${order}\n ${I18n.t('investment')} ${invested}\n ${I18n.t(
              'profit',
            )}/${I18n.t('loss')} ${profitLoss} ${percent}`,
          )
        if (order === selectedPosition) {
          pLine.setLineStyle(0)
          if (positionExpanded) {
            pLine.setLineWidth(2)
            this._drawPositionDetails(position, positionShape)
          } else {
            pLine.setLineWidth(1)
            this._erasePositionShape(positionShape, true)
          }
        } else {
          pLine.setLineStyle(2)
          pLine.setLineWidth(1)
          this._erasePositionShape(positionShape, true)
        }
      }
    }
  }

  private _drawPositionDetails(position: Trade, positionShape: PositionShape) {
    const state = store.getState()
    const activeSignal = getActiveSignal(state)
    const { slInput, tpInput } = slTpInputSelector(state)
    const { sl, tp } = position
    if ((slInput !== undefined || sl) && !activeSignal) {
      this._drawSLTPLine(position, positionShape, 'slLine')
    } else if (positionShape.slLine) {
      positionShape.slLine.remove()
      delete positionShape.slLine
    }
    if ((tpInput !== undefined || tp) && !activeSignal) {
      this._drawSLTPLine(position, positionShape, 'tpLine')
    } else if (positionShape.tpLine) {
      positionShape.tpLine.remove()
      delete positionShape.tpLine
    }
    positionShape.expanded = true
  }

  private _drawSLTPLine(position: Trade, positionShape: PositionShape, type: 'slLine' | 'tpLine') {
    const state = store.getState()

    const profitValue = formProfitSelector(state)
    const lossValue = formLossSelector(state)
    const chartMode = chartModeSelector(state)
    const currency = mainCurrencySelector(state)
    const { slInput, tpInput } = slTpInputSelector(state)
    const selectedPosition = selectedPositionSelector(state)

    const assets = mt4.assets
    const { tp, sl, profit, commission, rate, symbol } = position
    const { typeColor, sltp, label } =
      type === 'tpLine'
        ? {
            typeColor: '#67AD5B',
            sltp: tpInput !== undefined ? tpInput : tp,
            label: I18n.t('takeProfit'),
          }
        : {
            typeColor: '#E15241',
            sltp: slInput !== undefined ? slInput : sl,
            label: I18n.t('stopLoss'),
          }
    const asset = assets[symbol]

    let pl = NaN
    if (chartMode === 'open' || (chartMode === 'trade' && !!selectedPosition)) {
      pl = asset
        ? /buy/i.test(position.type)
          ? type === 'tpLine'
            ? profitValue
            : lossValue
          : calculatePl({ ...(asset as Price), mask: sltp }, position, rate)
        : NaN
    } else if (chartMode === 'trade') {
      pl = type === 'tpLine' ? profit : commission
    }

    const sltpPL = isNaN(pl)
      ? ''
      : positiveNegativeFormat(pl, currency, {
          minimumFractionDigits: 2,
        })
    const tooltip = `${label} \n${I18n.t('price')} ${sltp} \n${
      type === 'tpLine' ? I18n.t('profit') : I18n.t('loss')
    } ${sltpPL}`
    if (!positionShape[type]) {
      // positionShape[type] = this.chart.createPositionLine()
      try {
        positionShape[type] = this.chart
          // .createOrderLine()
          .createPositionLine({ disableUndo: true })
          .setBodyTextColor(typeColor)
          .setBodyBorderColor(typeColor)
          .setQuantityFont(chartShapeFont)
          .setQuantityTextColor('#FFF')
          .setQuantityBackgroundColor(typeColor)
          .setQuantityBorderColor(typeColor)
          // .setQuantity(`${label} ${sltpPL}`)
          .setQuantity(sltpPL)
          .setLineColor(typeColor)
          .setLineStyle(0)
          .setLineWidth(1)
          .setPrice(sltp)
          // .setText('\u2195')
          .setText(label)
          .setBodyFont(chartShapeFont)
          .setTooltip(tooltip)
      } catch (error) {
        console.warn(`Error creating ${type} line`, error.message)
      }
    } else {
      const line = positionShape[type]

      if (!line) {
        return
      }

      line.setPrice(sltp).setQuantity(sltpPL).setTooltip(tooltip)
      // }
    }
  }

  private _erasePositionShape(positionShape: PositionShape, detailsOnly?: boolean) {
    const state = store.getState()
    const activeTab = activeTabSelector(state)

    const positionsOpened = activeTab === Location.positions
    const selectedPosition = selectedPositionSelector(state)?.order

    if (positionsOpened && positionShape.order === selectedPosition) {
      positionShape.expanded = false
    }
    Object.keys(positionShape)
      .filter((key) => /Line/.test(key))
      .filter((key) => !detailsOnly || (detailsOnly && key !== 'pLine'))
      .forEach((line) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(positionShape as any)[line].remove()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (positionShape as any)[line]
      })
  }

  private _selectPositionLine(positionShape: PositionShape) {
    const state = store.getState()
    const activeTab = activeTabSelector(state)

    const positionsOpened = activeTab === Location.positions || activeTab === Location.order
    const action = activeTab === Location.positions ? selectedOpenedPosition : selectedPendingPosition
    const selectedPosition = selectedPositionSelector(state)?.order

    // TODO: toggle modify order action
    const onSelectPosition = (order: number /*, expanded?: boolean */) => store.dispatch(action(`${order || ''}`))

    let order = NaN

    if (positionsOpened && selectedPosition) {
      order = selectedPosition !== positionShape.order ? positionShape.order : NaN
    }

    if (isNaN(order)) {
      onSelectPosition(order) // false
    } else {
      onSelectPosition(order) // true
    }
  }
}
