import { gql } from '@apollo/client'

export const GET_FAVOURITES = gql`
  query getFavourites {
    viewer {
      ... on Client {
        id
        tradingConfig {
          favourites
          __typename
        }
        __typename
      }
      __typename
    }
  }
`
