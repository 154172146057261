import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

import { pendingTabSelector } from '../../../../store/selectors'
import { TitleTopPanel } from '../../TitleTopPanel'
import { PendingTable } from '../../../Tables/PendingTable'

const useStyle = makeStyles(() => ({
  container: {
    maxHeight: '50%',
    height: '100%',
  },
}))

export const PendingPositionsContent: FC = () => {
  const classes = useStyle()
  const { t } = useTranslation()
  const topPanelVisible = useSelector(pendingTabSelector)

  if (!topPanelVisible) return null
  return (
    <Grid
      item
      container
      className={classes.container}
      direction={'column'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
    >
      <TitleTopPanel title={t('pendingPositions')} />
      <PendingTable />
    </Grid>
  )
}
