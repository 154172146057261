import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'

import makeStyles from '@mui/styles/makeStyles'

import { TradeMode } from '../../../../enums'
import {
  currentFreeMarginSelector,
  currentSymbolPriceSelector,
  formRequiredMarginAccountSelector,
  formSubmitEnabledSelector,
  isFormFieldValuesValidSelector,
  marketOpenedSelector,
  tradeModeSelector,
} from '../../../../store/selectors'
import { openOrderAction } from '../../../../store/actions'
import { isMobile } from '../../../../utils'
import { Theme } from '@mui/material'

const useStyles = makeStyles<Theme, { isMobileDevice?: boolean }>(({ palette }) => ({
  submitButton: {
    padding: '8px 0',
    textTransform: 'none',
    width: '100%',
    backgroundColor: '#007AFF',
    borderRadius: 6,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08)',
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 400,
    border: '1px solid #007AFF',
    '&:disabled': {
      backgroundColor: palette.mode === 'light' ? '#FAFAFA' : '#1F1F1F',
      borderColor: palette.mode === 'light' ? '#D6D6D6' : '#474747',
      color: palette.mode === 'light' ? '#C2C2C2' : '#474747',
      cursor: 'default',
      boxShadow: 'none',
    },
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: ({ isMobileDevice }) => (!isMobileDevice ? '21px 16px 25px' : '0 16px 12px'),
  },
}))

const SubmitButton: FC = () => {
  const isMobileDevice = isMobile()
  const classes = useStyles({ isMobileDevice })
  const tradeMode = useSelector(tradeModeSelector)
  const currentAsset = useSelector(currentSymbolPriceSelector)
  const submitEnabled = useSelector(formSubmitEnabledSelector)
  const isFormFieldsValid = useSelector(isFormFieldValuesValidSelector)
  const marketOpened = useSelector(marketOpenedSelector)
  const freeMargin = useSelector(currentFreeMarginSelector)
  const requiredMargin = useSelector(formRequiredMarginAccountSelector)
  const dispatch = useDispatch()

  const submitFormHandle = useCallback(() => {
    dispatch(openOrderAction())
  }, [dispatch])

  const { t } = useTranslation()

  return (
    <div className={classes.submitButtonContainer}>
      <Button
        data-guided-tour="clickSellBuyButton"
        onClick={submitFormHandle}
        className={classes.submitButton}
        variant={'contained'}
        size={'small'}
        type={'submit'}
        disabled={
          !marketOpened ||
          tradeMode === TradeMode.disabled ||
          freeMargin < requiredMargin ||
          !submitEnabled ||
          !isFormFieldsValid
        }
      >
        {t(tradeMode)} {currentAsset.label} {t('now')}
      </Button>
    </div>
  )
}

export default SubmitButton
