import React, { FC, ReactNode } from 'react'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'

import { MenuButton } from './MenuButton'
import { menuBarList } from '../pages/menuBarList'
import {
  openPositionsLengthSelector,
  pendingPositionsLengthSelector,
  closedPositionsLengthSelector,
} from '../../store/selectors'

const useStyle = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 80,
      borderRight: `1px solid ${theme.palette.action.selected}`,
      height: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    btnStyle: {
      display: 'block',
      color: theme.palette.text.disabled,
      backgroundColor: 'inherit',
      fontSize: '.7857rem',
      textTransform: 'none',
      lineHeight: '.9286rem',
      width: 80,
      minHeight: 80,
      fontWeight: 400,
      letterSpacing: '-0.5px',
      overflow: 'hidden',
      paddingLeft: 4,
      borderRadius: 'unset',
      borderRight: `1px solid ${theme.palette.action.selected}`,
    },
  }),
)

export const SideMenuBar: FC<{ bottomActions: ReactNode }> = ({ bottomActions }) => {
  const classes = useStyle()
  const openPositionsLength = useSelector(openPositionsLengthSelector)
  const pendingPositionsLength = useSelector(pendingPositionsLengthSelector)
  const closedPositionsLength = useSelector(closedPositionsLengthSelector)

  return (
    <div className={classes.container}>
      <div>
        {menuBarList.map(({ name, path, icon, guidedTourStep }) => {
          let counter
          switch (name) {
            case 'openPositions':
              counter = openPositionsLength
              break
            case 'pendingPositions':
              counter = pendingPositionsLength
              break
            case 'closedPositions':
              counter = closedPositionsLength
              break
          }
          return (
            <MenuButton
              key={name}
              Icon={icon}
              name={name}
              path={path}
              counter={counter}
              data-guided-tour={guidedTourStep}
              data-test={`menu-button-${name}`}
            />
          )
        })}
      </div>
      <div>{bottomActions}</div>
    </div>
  )
}
