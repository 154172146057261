export const getEnv = (name: string, defaultValue: string): string => {
  const value = process.env[`REACT_APP_LOCAL_${name}`] || process.env[`REACT_APP_${name}`]
  if (value === undefined) {
    return defaultValue
  }
  return value
}

export const getBooleanEnv = (name: string, defaultValue: boolean): boolean => {
  const value = process.env[name]
  if (value === undefined) {
    return defaultValue
  }
  return value === 'true'
}
