import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { currentSymbolDigitsSelector, formTakeProfitSelector } from '../../../../store/selectors'
import { NumberInput } from '../NumberInput'
import { numberFormat } from '../../../../utils'

const TakeProfitInput: FC = () => {
  const precision = useSelector(currentSymbolDigitsSelector)
  const { value, fieldInformation } = useSelector(formTakeProfitSelector)

  const handleValue = useMemo(() => {
    const numberValue = numberFormat(value, {
      maximumFractionDigits: precision,
    })
    return numberValue ? numberValue.toString() : ''
  }, [precision, value])

  return (
    <NumberInput
      name={'takeProfit'}
      placeholder={'takeProfit'}
      value={handleValue}
      fieldInformation={fieldInformation}
    />
  )
}

export default TakeProfitInput
