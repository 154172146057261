import { Observable, Subject } from 'rxjs'
import { filter } from 'rxjs/operators'

import {
  Command as MT4Command,
  Event as MT4Message,
  MT4Connector,
  GetChart,
  ChartData,
  ChartBar,
  ChartSecondsData,
  ChartSecond,
} from '@bdswiss/mt4-connector'

import { Bar, HistoryMetadata, LibrarySymbolInfo, PeriodParams } from '../types/datafeed-api'
import { removeServerOffset, resolution2Seconds } from '../utils'
import { fulfillCandlesData } from '../../../store/actions'
import store from '../../../store'
import isEqual from 'lodash/isEqual'

export interface GetBarsResult {
  bars: Bar[]
  meta: HistoryMetadata
}

export interface TVChartBar extends Bar {
  time: number
  open: number
  high: number
  low: number
  close: number
  volume?: number
}

// Define mapper to map MT4 Chart bar to JS API Chart bar
export function chartBarToTVChartBar({ ts, o, h, l, c, v }: ChartBar): TVChartBar {
  return {
    time: removeServerOffset(ts) * 1000,
    open: o,
    high: h,
    low: l,
    close: c,
    volume: v,
  }
}

export function convertBarsData(bars: ChartBar[], resolution: number, rangeEndDate: number): TVChartBar[] {
  const tvBars: TVChartBar[] = []
  if (resolution < 86400) {
    for (const bar of bars) {
      if (rangeEndDate < removeServerOffset(bar.ts)) {
        break
      }
      const { ts, o, h, l, c, v } = bar
      tvBars.push({
        time: removeServerOffset(ts) * 1000,
        open: o,
        high: h,
        low: l,
        close: c,
        volume: v,
      })
    }
  } else {
    for (const bar of bars) {
      if (rangeEndDate < bar.ts) {
        break
      }
      const { ts, o, h, l, c, v } = bar
      tvBars.push({
        time: ts * 1000,
        open: o,
        high: h,
        low: l,
        close: c,
        volume: v,
      })
    }
  }
  return tvBars
}

export function convertSecondsBarsData(bars: ChartSecond[]): TVChartBar[] {
  const tvBars: TVChartBar[] = []
  for (const bar of bars) {
    tvBars.push({
      time: removeServerOffset(bar[0]) * 1000,
      open: bar[1],
      high: bar[1],
      low: bar[1],
      close: bar[1],
    })
  }
  return tvBars
}

export class HistoryProvider {
  private static instance: HistoryProvider
  private readonly mt4: MT4Connector
  private barsObservable: Observable<ChartData | ChartSecondsData>
  private previousPeriodParams:
    | (PeriodParams & {
        symbol: string
      })
    | null = null

  public constructor() {
    this.mt4 = MT4Connector.Instance
    this.barsObservable = this.InitBarsObservable()
  }

  public static get Instance(): HistoryProvider {
    return this.instance || (this.instance = new this())
  }

  public getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: string,
    periodParams: PeriodParams,
  ): Promise<GetBarsResult> {
    const symbolName = symbolInfo.name
    const mt4Resolution = resolution2Seconds(resolution)
    let bars: Bar[] = []
    const meta: HistoryMetadata = {
      noData: true,
    }

    if (
      isEqual(this.previousPeriodParams, {
        ...periodParams,
        symbol: symbolName,
      })
    ) {
      return Promise.resolve({
        bars: [],
        meta: {
          noData: true,
        },
      })
    }

    return new Promise((resolve: (result: GetBarsResult) => void, reject: (reason: string) => void) => {
      // subscribe to chart message
      this.barsObservable.pipe(filter((data) => data.symbol === symbolName)).subscribe(
        ({ data }: ChartData | ChartSecondsData) => {
          if (data.length) {
            this.previousPeriodParams = {
              ...periodParams,
              symbol: symbolName,
            }
          }

          if (/^\d+S$/.test(resolution)) {
            if (data) {
              bars = convertSecondsBarsData(data as ChartSecondsData['data'])
            }
          } else {
            if (data) {
              bars = convertBarsData(data as ChartData['data'], mt4Resolution, periodParams.to)
            }
          }

          if (bars.length) {
            meta.noData = false
          } else {
            reject('LoadBarsError')
            return
          }

          store.dispatch(
            fulfillCandlesData({
              symbol: symbolName,
              resolution: mt4Resolution,
              bars: new Map(bars.map((b) => [b.time, b] as [number, Bar])),
            }),
          )
          resolve({ bars, meta })
        },
        (error: Error) => {
          reject(error.toString())
        },
      )

      // request chart
      const chart: GetChart = {
        resolution: mt4Resolution,
        symbol: symbolName,
      }
      this.mt4.sendCommand(MT4Command.GET_CHART, chart)
    })
  }

  private InitBarsObservable(): Observable<ChartData | ChartSecondsData> {
    const barsSubject = new Subject()
    this.mt4.onMessage(MT4Message.CHART).subscribe(barsSubject)
    return barsSubject.asObservable() as Observable<ChartData | ChartSecondsData>
  }
}
