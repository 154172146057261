import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { positiveNegativeFormat } from '../../../utils'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  positive: { color: theme.palette.green.mint },
  negative: { color: theme.palette.secondary.main },
  arrowIcon: {
    fontSize: '14px',
    paddingBottom: '1px',
  },
}))

export const Profit: FC<{ profit: number; currency: string }> = ({ profit, currency }) => {
  const classes = useStyles()
  return (
    <span
      className={clsx({
        [classes.negative]: profit < 0,
        [classes.positive]: profit >= 0,
      })}
    >
      {positiveNegativeFormat(profit, currency, { hidePositiveSign: true, shouldRound: false })}
    </span>
  )
}
