import BugsnagService, { Client, Event, NotifiableError, OnErrorCallback } from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'

import config from '../../config'

class Bugsnag {
  private readonly bugsnag: Client | undefined
  private static instance: Bugsnag

  private constructor() {
    if (!config.get('services.bugsnag.enabled')) return
    this.bugsnag = BugsnagService.start({
      apiKey: config.get('services.bugsnag.apiKey'),
      releaseStage: config.get('services.bugsnag.releaseStage'),
      plugins: [new BugsnagPluginReact()],
    })
  }

  public static get Instance(): Bugsnag {
    return this.instance || (this.instance = new this())
  }

  public notify(error: NotifiableError, onError?: OnErrorCallback, cb?: (err: unknown, event: Event) => void): void {
    if (!this.bugsnag) return
    this.bugsnag.notify(error, onError, cb)
  }

  public getPlugin(id: 'react'): BugsnagPluginReactResult | undefined {
    if (!this.bugsnag) return
    return this.bugsnag.getPlugin(id)
  }
}

export default Bugsnag
