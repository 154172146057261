import React, { FC, ReactElement } from 'react'
import { Grow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import HelpTooltip from '../HelpTooltip/HelpTooltip'
import { currencyFormat, numberUnformat } from '../../../utils'

interface TopMenuItemProps {
  description: ReactElement
  hint: ReactElement
  value: string | number
  isMarginLevel?: boolean
  currency?: string
}

const useStyles = makeStyles(() => ({
  menuItemGrid: {
    padding: '0 37px 0 12px',
    '&>div': {
      display: 'flex',
    },
  },
  blockAlignment: {
    display: 'flex',
    alignItems: 'center',
  },
  transparent: {
    opacity: '0.65',
    fontSize: '0.8rem',
    lineHeight: 1.65,
  },
  value: {
    fontSize: '1.2rem',
  },
  partNumber: {
    fontSize: '13px',
    lineHeight: '21px',
  },
  text: {
    lineHeight: '1.65',
  },
}))

export const TopMenuItem: FC<TopMenuItemProps> = ({
  description,
  hint,
  value,
  isMarginLevel = false,
  currency,
  ...props
}) => {
  const classes = useStyles()

  const replacedOnlyNumber = !!value ? value.toString().replace(/[^0-9.]/g, '') : '0.00'
  const formattedCurrency = currencyFormat(replacedOnlyNumber, currency || 'USD', {
    minimumFractionDigits: 2,
    useGrouping: true,
  })
  const [currentSymbol, currentValue] = formattedCurrency.split(/\s/)
  const [integerNumber, partNumber] = currentValue.split('.')

  return (
    <div className={classes.menuItemGrid} {...props}>
      <div className={classes.blockAlignment}>
        <Typography className={classes.transparent} variant={'body2'}>
          {description}
        </Typography>

        <HelpTooltip hint={hint} />
      </div>
      <div>
        <Grow in={true} timeout={2000}>
          <Typography variant={'body2'} className={classes.text}>
            {isMarginLevel ? (
              <span className={classes.value}>
                {!!replacedOnlyNumber ? integerNumber : '-'}
                {partNumber && <span className={classes.partNumber}>{`.${partNumber}%`}</span>}
              </span>
            ) : (
              <span className={classes.value}>
                <span className={classes.partNumber}>{currentSymbol}</span>{' '}
                {numberUnformat(value.toString()) < 0 && '-'}
                {integerNumber}
                {partNumber && <span className={classes.partNumber}>{`.${partNumber}`}</span>}
              </span>
            )}
          </Typography>
        </Grow>
      </div>
    </div>
  )
}
