import React from 'react'
import { CellProps, Renderer } from 'react-table'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'

import { numberFormat } from '../../../../utils'
import { Favourite } from '../../Common'
import { calculateDailyReturn, PriceExtended } from '@bdswiss/mt4-connector'

const NameCellWrap = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  '& .favourite-cell-icon': {
    padding: '0px 10px 10px 0',
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const Label = styled('div')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '20px',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
}))

const DailyChange = styled('div')<{
  value: number
}>(({ value }) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 10,
  lineHeight: '12px',
  ...(value >= 0 ? { color: '#009469' } : {}),
  ...(value < 0 ? { color: '#ED1C24' } : {}),
}))

export const NameCell: Renderer<CellProps<PriceExtended, string>> = ({ row }) => {
  const dailyChange = calculateDailyReturn('bid', row.original)

  return (
    <NameCellWrap>
      <Favourite value={row.original.symbol} />
      <Box display={'flex'} flexDirection={'column'}>
        <Label>{row.original.label}</Label>
        <DailyChange value={dailyChange}>
          {numberFormat(dailyChange, {
            maximumFractionDigits: 4,
          })}
          %
        </DailyChange>
      </Box>
    </NameCellWrap>
  )
}
