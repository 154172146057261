import React from 'react'
import { CellProps, Renderer } from 'react-table'
import { Trans } from 'react-i18next'

import { PriceExtended } from '@bdswiss/mt4-connector'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/types'
import { isMarketOpenedBySymbolSelector } from '../../../store/selectors'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  marketClosed: {
    color: '#999999',
  },
}))

export const TypeCell: Renderer<CellProps<PriceExtended, string>> = ({
  value,
  row: {
    original: { symbol },
  },
}) => {
  const classes = useStyles()
  const isMarketOpened = useSelector((state: RootState) => isMarketOpenedBySymbolSelector(state, symbol))
  return (
    <span className={clsx({ [classes.marketClosed]: !isMarketOpened })}>
      <Trans i18nKey={value} />
    </span>
  )
}
