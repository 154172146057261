import React, { FC } from 'react'
import { TradingForm } from '../../Forms/TradingForm'
import { ModifyingForm } from '../../Forms/ModifyingForm'
import { useSelector } from 'react-redux'
import { modifyPanelTradeSelector, tradingFormPanelSelector } from '../../../store/selectors'

export const TradePanel: FC = () => {
  const tradingForm = useSelector(tradingFormPanelSelector)
  const modify = useSelector(modifyPanelTradeSelector)
  return (
    <>
      {tradingForm && <TradingForm />}
      {modify && <ModifyingForm />}
    </>
  )
}
