import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'

interface IProps {
  counter: number
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    fontSize: '.6429rem',
    position: 'absolute',
    right: 15,
    top: 7,
    minWidth: 12,
    lineHeight: '13px',
    padding: '2px 6px',
  },
}))

export const MenuButtonCounter: FC<IProps> = ({ counter }) => {
  const classes = useStyles()

  return <div className={classes.container}>{counter}</div>
}
