import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import MuiDialogTitle from '@mui/material/DialogTitle'
import { Dialog, DialogActions, DialogContent } from '../../Dialog'
import { makeStyles } from '@mui/styles'
import { intentOpenGuidedTour } from '../../../store/actions'

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: '5px 24px 26px',
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    paddingTop: '1.5rem',
  },
  dialogText: {
    fontSize: '16px',
    color: '#757575',
  },
  container: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: 400,
      maxWidth: 450,
      borderRadius: 0,
      textAlign: 'left',
    },
  },
  button: {
    borderRadius: 0,
    textTransform: 'none',
    fontSize: 16,
  },
}))

interface DialogProps {
  handleSkip: () => void
}

export const StartDialog: FC<DialogProps> = ({ handleSkip }) => {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const guidedTourStatus = localStorage.getItem('guidedTourStatus')
    if (!guidedTourStatus) {
      // setShowDialog(true)   // TODO disabled tour
    }
  }, [])

  const startGuidedTour = useCallback(() => {
    setShowDialog(false)
    dispatch(intentOpenGuidedTour())
  }, [dispatch])

  const scipGuidedTour = useCallback(() => {
    setShowDialog(false)
    handleSkip()
  }, [handleSkip])

  return (
    <Dialog open={showDialog} className={classes.container}>
      <MuiDialogTitle>
        <Typography className={classes.dialogTitle}>
          <Trans
            i18nKey={'guidedTourModalTitle'}
            defaultValue={'Do you want a quick interactive tour to learn trading?'}
          />
        </Typography>
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box marginBottom={'20px'}>
          <Typography className={classes.dialogText}>
            <Trans
              i18nKey={'guidedTourModalTopText'}
              defaultValue={
                'We’ll show how to open a position, set Stop Loss and Take Profit, track your open positions and then close them. ' +
                'It is highly recommended for novice traders.'
              }
            />
          </Typography>
        </Box>
        <Typography className={classes.dialogText}>
          <Trans
            i18nKey={'guidedTourModalBottomText'}
            defaultValue={
              'You will be redirected to a special demo account with all values pre-defined, to show you the process.'
            }
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} color={'primary'} variant={'text'} onClick={scipGuidedTour} disableRipple>
          <Trans i18nKey={'guidedTourSkip'} defaultValue={'Skip'} />
        </Button>
        <Button className={classes.button} color={'primary'} variant={'contained'} onClick={startGuidedTour}>
          <Trans i18nKey={'guidedTourStartNow'} defaultValue={'Start now'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
