import React, { FC, MouseEvent, useCallback, useMemo, useReducer, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { expandedReducer, TableContext, VirtualizedTable, VirtualizedTableProps } from '../../VirtualizedTable'
import { VariableSizeList } from 'react-window'
import { filteredSignalsSelector, getActiveSignal } from '../../../store/selectors'
import { Signal } from '@bdswiss/mt4-connector'
import { AssetCell, DirectionCell, FavouriteCell, TextCell, ValueCell } from '../CommonCells'
import { appChangeActiveAsset, setActiveSignal } from '../../../store/actions'
import { TradeMode } from '../../../enums'

const resolutions = { 15: '15m', 30: '30m', 60: '1h', 240: '4h', 1440: '1D', 10800: '1W' }
const estimatedItemSize = 36

const SignalsTable: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const assets = useSelector(filteredSignalsSelector)
  const activeAsset = useSelector(getActiveSignal)
  const [expandedPositionsState, expandedPositionsDispatch] = useReducer(expandedReducer, {})
  const listRef = useRef<VariableSizeList>(null)
  const columns = useMemo<VirtualizedTableProps<Signal>['columns']>(
    () => [
      {
        Header: '',
        id: 'favorite',
        accessor: 'symbol',
        width: 50,
        Cell: FavouriteCell,
      },
      {
        Header: t('Asset').toString(),
        id: 'asset',
        accessor: 'normalizedSymbol',
        width: 140,
        Cell: AssetCell,
      },
      {
        Header: t('Direction').toString(),
        accessor: 'direction',
        width: 158,
        Cell: DirectionCell,
      },
      {
        Header: t('Type').toString(),
        accessor: ({ pattern, keylevel }) => (pattern && 'Chart Pattern') || (keylevel && 'Key Level'),
        width: 158,
        Cell: TextCell,
      },
      {
        Header: t('Name').toString(),
        accessor: ({ pattern, keylevel }) => pattern?.name || keylevel?.type,
        width: 158,
        Cell: TextCell,
      },
      {
        Header: t('Resolution').toString(),
        accessor: ({ resolution }) => resolutions[resolution as keyof typeof resolutions],
        width: 100,
        Cell: ValueCell,
      },
      {
        Header: t('Status').toString(),
        accessor: ({ complete }) => (complete ? 'Complete' : 'Emerging'),
        width: 100,
        Cell: TextCell,
      },
    ],
    [t],
  )

  const getItemSize = useCallback(() => {
    return estimatedItemSize
  }, [])

  const rowClickHandle = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const { id, symbol, direction } = e.currentTarget.dataset
      if (id && symbol) {
        dispatch(
          appChangeActiveAsset(symbol, {
            tradeMode: ['buy', 'UP'].includes(direction || '') ? TradeMode.buy : TradeMode.sell,
          }),
        )
        dispatch(setActiveSignal(id))
      }
    },
    [dispatch],
  )

  const isRowActive = useCallback(({ id }: Signal) => (!!activeAsset ? id === activeAsset.id : false), [activeAsset])

  return (
    <TableContext.Provider
      value={{
        keyPropertyName: 'symbol',
        expandedPositionsDispatch,
        expandedPositionsState,
        listRef,
      }}
    >
      <VirtualizedTable
        columns={columns}
        data={assets}
        rowClickHandle={rowClickHandle}
        estimatedItemSize={estimatedItemSize}
        getItemSize={getItemSize}
        isRowActive={isRowActive}
      />
    </TableContext.Provider>
  )
}
export default SignalsTable
