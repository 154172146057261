import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'

import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'

interface IProps {
  closeToast: () => void
}

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export const CloseToastButton: (props: IProps) => React.ReactElement = ({ closeToast }) => {
  const classes = useStyles()

  return (
    <div className={classes.buttonContainer}>
      <IconButton onClick={closeToast}>
        <CloseIcon style={{ color: '#FFFFFF' }} />
      </IconButton>
    </div>
  )
}
