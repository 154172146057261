import React, { createContext, FC, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { VirtualizedTable, VirtualizedTableProps } from '../../VirtualizedTable'
import { useDispatch, useSelector } from 'react-redux'
import { pendingPositionsSelector, selectedPendingOrderSelector } from '../../../store/selectors'
import { TradeObject } from '@bdswiss/mt4-connector'

import {
  changeLocation,
  clearActiveSignal,
  selectedPendingPosition,
  setMobileBackButtonData,
} from '../../../store/actions'
import { ClosePositionDialog } from '../../ClosePositionDialog'
import {
  FavouriteCell,
  DirectionCell,
  LeverageCell,
  EditActionCell,
  OrderCell,
  VolumeCell,
  OpenPriceCell,
  CurrentPriceCell,
  RenamedAssetCell,
} from '../CommonCells'
import { CloseActionCell } from './PendingTableColumns'
import { isMobile } from '../../../utils'
import { CloseOrderButton, MobileAssetCell } from '../../mobile/Common'
import { Location } from '../../../enums'

const estimatedItemSize = 35
const getItemSize = () => (!isMobile() ? 35 : 55)

export const PendingOrdersTableContext = createContext<(order: TradeObject) => void>(() => null)

export const PendingTable: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const positions = useSelector(pendingPositionsSelector)
  const activeAsset = useSelector(selectedPendingOrderSelector)
  const [closingOrder, setClosingOrder] = useState<TradeObject | undefined>()

  const dialogOpen = useCallback(
    (order: TradeObject) => {
      setClosingOrder(order)
    },
    [setClosingOrder],
  )

  const dialogClose = useCallback(() => {
    setClosingOrder(undefined)
  }, [setClosingOrder])

  const isMobileDevice = isMobile()
  const mobileColumns = useMemo<VirtualizedTableProps<TradeObject>['columns']>(
    () => [
      {
        Header: '',
        id: 'customCell',
        accessor: (row) => row,
        minWidth: 120,
        Cell: MobileAssetCell,
      },
      {
        Header: '',
        id: 'close',
        width: 30,
        Cell: CloseOrderButton,
      },
    ],
    [],
  )

  const columns = useMemo<VirtualizedTableProps<TradeObject>['columns']>(
    () => [
      {
        Header: '',
        id: 'favourite',
        accessor: 'symbol',
        width: 50,
        Cell: FavouriteCell,
      },
      {
        Header: t('asset').toString(),
        accessor: 'symbol',
        maxWidth: 100,
        Cell: RenamedAssetCell,
      },
      {
        Header: t('order').toString() + ' #',
        accessor: 'order',
        maxWidth: 130,
        Cell: OrderCell,
      },
      {
        Header: t('direction').toString(),
        accessor: 'type',
        maxWidth: 100,
        Cell: DirectionCell,
      },
      {
        Header: t('value').toString(),
        accessor: 'volume',
        width: 150,
        Cell: VolumeCell,
      },
      {
        Header: t('leverage').toString(),
        accessor: 'leverage',
        maxWidth: 150,
        Cell: LeverageCell,
      },
      {
        Header: t('openPrice').toString(),
        accessor: 'openPrice',
        maxWidth: 150,
        Cell: OpenPriceCell,
      },
      {
        Header: t('currentPrice').toString(),
        id: 'currentPrice',
        maxWidth: 150,
        Cell: CurrentPriceCell,
      },
      {
        Header: '',
        id: 'edit',
        width: 80,
        Cell: EditActionCell,
      },
      {
        Header: '',
        id: 'closeAll',
        width: 90,
        Cell: CloseActionCell,
      },
    ],
    [t],
  )

  const mobileRowClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      const { id, symbol } = e.currentTarget.dataset
      dispatch(changeLocation(Location.order, {}))
      id && symbol && dispatch(selectedPendingPosition(id, { activeAsset: symbol, currentLocation: Location.order }))
      dispatch(setMobileBackButtonData({ show: true, title: symbol }))
    },
    [dispatch],
  )

  const rowClickHandle = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      const { id, symbol } = e.currentTarget.dataset
      id && symbol && dispatch(selectedPendingPosition(id, { activeAsset: symbol }))
      dispatch(clearActiveSignal())
    },
    [dispatch],
  )

  const isRowActive = useCallback(({ id }: TradeObject) => id === +activeAsset, [activeAsset])

  return (
    <PendingOrdersTableContext.Provider value={dialogOpen}>
      <VirtualizedTable
        columns={!isMobileDevice ? columns : mobileColumns}
        showHeaders={!isMobileDevice}
        showBorders={!isMobileDevice}
        data={positions}
        rowClickHandle={!isMobileDevice ? rowClickHandle : mobileRowClick}
        isRowActive={isRowActive}
        estimatedItemSize={estimatedItemSize}
        getItemSize={getItemSize}
        tableName={(isMobileDevice && 'pending') || ''}
      />
      <ClosePositionDialog order={closingOrder} onClose={dialogClose} />
    </PendingOrdersTableContext.Provider>
  )
}
