import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

import { toggleHistoryPanel, toggleOpenPosPanel, togglePendingPanel, toggleTradePanel } from '../../store/actions'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'

interface IProps extends IconButtonProps {
  onClose?: () => void
}

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: '#999',
  },
}))

export const CloseButton: FC<IProps> = ({ onClose, ...rest }) => {
  const classes = useStyles()
  const { url } = useRouteMatch()
  const preUrl = url.split('/').slice(0, -1).join('/')
  const dispatch = useDispatch()
  const togglePanel = useCallback(() => {
    if (url === `${preUrl}/trade`) dispatch(toggleTradePanel())
    if (url === `${preUrl}/positions`) dispatch(toggleOpenPosPanel())
    if (url === `${preUrl}/order`) dispatch(togglePendingPanel())
    if (url === `${preUrl}/history`) dispatch(toggleHistoryPanel())
  }, [url, dispatch, preUrl])

  return (
    <div className={classes.buttonContainer}>
      <IconButton
        className={classes.button}
        edge={'start'}
        size="small"
        onClick={onClose ? onClose : togglePanel}
        {...rest}
      >
        <CloseIcon style={{ color: '#858585' }} />
      </IconButton>
    </div>
  )
}
