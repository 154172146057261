import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import IconButtonMui from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { Dialog } from '../../Dialog'

interface IProps {
  open: boolean
  image: string
  onClose: () => void
}

const StyledDialog = styled(Dialog)(() => ({
  ['& .MuiPaper-root']: {
    minWidth: 1000,
    boxShadow: 'none',
  },
}))

const StyledImage = styled('img')(() => ({
  width: '100%',
}))

const IconButton = styled(IconButtonMui)(() => ({
  position: 'absolute',
  right: 5,
  top: 5,
}))

export const TradingIdeasDialog: FC<IProps> = ({ open, image, onClose }) => (
  <StyledDialog open={open} onClose={onClose}>
    <IconButton size={'small'} onClick={onClose}>
      <CloseIcon fontSize={'small'} />
    </IconButton>
    <StyledImage src={image} alt={image} />
  </StyledDialog>
)
