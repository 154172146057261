import { FC } from 'react'

import { TradeHistory } from '../MenuBar/TradeHistory'
import { TopPanel } from '../MenuBar/Trade/TopPanel'
import { OpenPositionContent } from '../MenuBar/OpenPositions'
import { ModifyingForm } from '../Forms/ModifyingForm'
import { TradePanel } from '../MenuBar/Trade'
import { EconomicCalendar } from '../MenuBar/EconomicCalendar'
import { PendingPositionsContent } from '../MenuBar/PendingPositions'
import { ReactComponent as TrendingUpIcon } from '../../assets/images/icons/trade.svg'
import { ReactComponent as SyncAltIcon } from '../../assets/images/icons/open-positions.svg'
import { ReactComponent as AccessTimeIcon } from '../../assets/images/icons/pending-orders.svg'
import { ReactComponent as ReceiptIcon } from '../../assets/images/icons/trade-history.svg'
import { ReactComponent as EconomicCalendarIcon } from '../../assets/images/icons/economicCalendar.svg'
import { ReactComponent as SchoolIcon } from '../../assets/images/icons/guidedTour.svg'
import { getCookie } from '../../utils'
import { ReactComponent as TradingIdeasIcon } from '../../assets/images/icons/news-paper.svg'
import { ReactComponent as TradeIcon } from '../../assets/images/icons/mobile/trade.svg'
import { ReactComponent as HistoryIcon } from '../../assets/images/icons/mobile/history.svg'
import { ReactComponent as HamburgerIcon } from '../../assets/images/icons/mobile/more-hamburger.svg'
import { ReactComponent as PositionsIcon } from '../../assets/images/icons/mobile/positions.svg'
import { ReactComponent as ChartIcon } from '../../assets/images/icons/mobile/chart.svg'
import { MorePage } from '../mobile/Pages/More'
import { HistoryDetails } from '../mobile/Pages/History'
import { AccountInfoPage } from '../mobile/Pages/AccountInfoPage'
import { MarketsPanel } from '../mobile/Pages/Markets'
import { ChartPage } from '../mobile/Pages/ChartPage'
import { Positions } from '../mobile/Pages/Positions'
import { TradingIdeas } from '../Feed/TradingIdeas'

export type RootPage = {
  name: string
  icon: FC
  MainContent?: FC
  TradePanel?: FC
  MobileContent?: FC
  path?: string | string[]
  guidedTourStep?: string
  exact: boolean
  hidden: boolean
}

export const menuBarList: RootPage[] = [
  {
    name: 'trade',
    icon: TrendingUpIcon,
    path: '/trade',
    MainContent: TopPanel,
    TradePanel: TradePanel,
    exact: false,
    hidden: false,
  },
  {
    name: 'openPositions',
    icon: SyncAltIcon,
    path: '/positions',
    MainContent: OpenPositionContent,
    TradePanel: ModifyingForm,
    guidedTourStep: 'openPositions',
    exact: false,
    hidden: false,
  },
  {
    name: 'pendingPositions',
    icon: AccessTimeIcon,
    path: '/order',
    MainContent: PendingPositionsContent,
    TradePanel: ModifyingForm,
    exact: false,
    hidden: false,
  },
  {
    name: 'tradeHistory',
    icon: ReceiptIcon,
    path: '/history',
    MainContent: TradeHistory,
    exact: true,
    hidden: false,
  },
  {
    name: 'economicCalendar',
    icon: EconomicCalendarIcon,
    path: '/calendar',
    MainContent: EconomicCalendar,
    exact: false,
    hidden: false,
  },
]

export enum MenuItemsNames {
  markets = 'markets',
  positions = 'positions',
  chart = 'chart',
  history = 'history',
  more = 'more',
  historyDetails = 'historyDetails',
  accountInfo = 'accountInfo',
  economicCalendar = 'economicCalendar',
}

export const mobileMenuItems: RootPage[] = [
  {
    name: MenuItemsNames.markets,
    icon: TradeIcon,
    path: '/trade',
    MainContent: MarketsPanel,
    exact: false,
    hidden: false,
  },
  {
    name: MenuItemsNames.positions,
    icon: PositionsIcon,
    path: '/positions',
    MainContent: Positions,
    exact: false,
    hidden: false,
  },
  {
    name: MenuItemsNames.chart,
    icon: ChartIcon,
    path: '/chart',
    MainContent: ChartPage,
    exact: false,
    hidden: false,
  },
  {
    name: MenuItemsNames.history,
    icon: HistoryIcon,
    path: '/history',
    MainContent: TradeHistory,
    exact: true,
    hidden: false,
  },
  {
    name: MenuItemsNames.more,
    icon: HamburgerIcon,
    path: '/more',
    MainContent: MorePage,
    exact: true,
    hidden: false,
  },
  {
    name: MenuItemsNames.historyDetails,
    icon: ReceiptIcon,
    path: '/history/:historyId',
    MainContent: HistoryDetails,
    exact: true,
    hidden: true,
  },
  {
    name: MenuItemsNames.accountInfo,
    icon: HamburgerIcon,
    path: '/more/account-info',
    MainContent: AccountInfoPage,
    exact: true,
    hidden: true,
  },
  {
    name: 'economicCalendar',
    icon: EconomicCalendarIcon,
    path: '/more/calendar',
    MainContent: EconomicCalendar,
    exact: true,
    hidden: true,
  },
  {
    name: 'tradingIdeas',
    icon: TradingIdeasIcon,
    path: '/more/tradingIdeas',
    MainContent: TradingIdeas,
    exact: true,
    hidden: true,
  },
]
if (getCookie('enabledGuidedTourFeature')) {
  menuBarList.push({
    name: 'guidedTourMenuTitle',
    icon: SchoolIcon,
    guidedTourStep: 'start',
    exact: false,
    hidden: false,
  })
}
