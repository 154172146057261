import React, { FC, MouseEvent, useState, useCallback } from 'react'
import { styled } from '@mui/system'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

import BentoMenuButton from './BentoMenuButton'
import BentoMenuPopover from './BentoMenuPopover'
import DownloadMobileAppDialog from '../DownloadMobileAppDialog'
import { isMobile } from '../../utils'
import { activeTabSelector } from '../../store/selectors'
import { MenuItemsNames } from '../pages/menuBarList'

const BentoMenuWrap = styled('div')<{ elem: HTMLDivElement | null }>(({ theme, elem }) => ({
  borderRadius: 8,
  padding: 7,
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.2s',
  '&.active': {
    background: theme.palette.mode === 'dark' ? '#1F1F1F' : '#EBEBEB',
  },
  ...(isMobile()
    ? { zIndex: elem ? 1400 : 1200, marginLeft: '16px' }
    : {
        marginLeft: 8,
        ':hover': {
          cursor: 'pointer',
          background: theme.palette.mode === 'dark' ? '#1F1F1F' : '#EBEBEB',
        },
      }),
  '&.flushLeft': {
    marginLeft: '0px',
  },
}))

const BentoMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleOpen = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const activeTab = useSelector(activeTabSelector)

  return (
    <BentoMenuWrap
      elem={anchorEl}
      className={clsx({ active: Boolean(anchorEl), flushLeft: activeTab === MenuItemsNames.more })}
    >
      <BentoMenuButton onClick={Boolean(anchorEl) ? handleClose : handleOpen} />
      <BentoMenuPopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose} />
      <DownloadMobileAppDialog />
    </BentoMenuWrap>
  )
}

export default BentoMenu
