import React, { FC, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { TitleTopPanel } from '../TitleTopPanel'
import { userCompanySelector, userIdSelector, userLocaleSelector } from '../../../store/selectors'
import graphqlClient from '../../../services/graphql'
import Preloader from './Preloader'
import { setMobileBackButtonData } from '../../../store/actions'

const useStyles = makeStyles<
  Theme,
  {
    iframeLoaded: boolean
  }
>(() => ({
  iframe: {
    display: ({ iframeLoaded }) => (iframeLoaded ? 'block' : 'none'),
    height: '100%',
  },
}))

export const EconomicCalendar: FC = () => {
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false)
  const userId = useSelector(userIdSelector)
  const locale = useSelector(userLocaleSelector)
  const company = useSelector(userCompanySelector)
  const theme = useTheme()
  const classes = useStyles({ iframeLoaded })
  const [link, setLink] = useState('')

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setMobileBackButtonData({ show: true, title: t('economicCalendar') }))
    return () => {
      dispatch(setMobileBackButtonData(undefined))
    }
  }, [dispatch, t])

  useEffect(() => {
    dispatch(setMobileBackButtonData({ show: true, title: t('economicCalendar') }))
    return () => {
      dispatch(setMobileBackButtonData(undefined))
    }
  }, [dispatch, t])

  useEffect(() => {
    setIframeLoaded(false)
  }, [theme.palette.mode, locale])

  useEffect(() => {
    graphqlClient
      .getTradingCentralLink({
        page: 'economic_calendar',
        theme: theme.palette.mode,
      })
      .then((res) => setLink(res.data.tradingCentralLink))
  }, [company, theme.palette.mode])

  const iframeLoadHandle = useCallback(() => {
    setIframeLoaded(true)
  }, [])

  if (!userId) {
    return null
  }

  return (
    <>
      <TitleTopPanel title={<Trans i18nKey="economicCalendar" />} hideCloseButton={true} />
      {!iframeLoaded && <Preloader />}
      <iframe
        id="tradingcentral-economic-calendar"
        className={classes.iframe}
        onLoad={iframeLoadHandle}
        src={link}
        allowFullScreen={true}
        frameBorder="0"
        width="100%"
        height="2500"
      />
    </>
  )
}
