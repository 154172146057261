import { createAction, createCustomAction } from 'typesafe-actions'
import { AssetGroup, CurrentMarketState, LoginSuccess, Error as Mt4Error } from '@bdswiss/mt4-connector'
import { JSX } from 'react'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import i18n from 'i18next'

import {
  mt4AccessDeniedNotify,
  mt4InvalidCredentialNotify,
  mt4InvalidOrderNotify,
  mt4InvestmentToHighNotify,
  mt4InvestmentToLowNotify,
  mt4SystemErrorNotify,
  mt4ValidationErrorNotify,
} from '../../components/Notifies'

export const mt4Connect = createAction('@@mt4/connect')<void>()
export const mt4Connected = createAction('@@mt4/connected')<void>()
export const mt4Disconnect = createAction('@@mt4/disconnect')<void>()
export const mt4Disconnected = createAction('@@mt4/disconnected')<void>()
export const mt4Subscribed = createAction('@@mt4/subscribed')<void>()

export const mt4Login = createAction('@@mt4/login')<LoginSuccess>()

export const mt4AssetGroups = createAction('@@mt4/assetGroups')<AssetGroup[]>()

export const mt4MarketStateChanges = createAction('@@mt4/marketStateChanged')<CurrentMarketState[]>()

export const mt4Error = createCustomAction('@@mt4/error', (payload: Error | Mt4Error) => {
  const type = get(payload, 'type')
  const content: { [key: string]: () => JSX.Element } = {
    VALIDATION_ERROR: mt4ValidationErrorNotify,
    SYSTEM_ERROR: mt4SystemErrorNotify,
    ACCESS_DENIED: mt4AccessDeniedNotify,
    INVALID_CREDENTIALS: mt4InvalidCredentialNotify,
    INVESTMENT_TO_LOW: mt4InvestmentToLowNotify,
    INVESTMENT_TO_HIGH: mt4InvestmentToHighNotify,
    INVALID_ORDER: mt4InvalidOrderNotify,
  }
  toast.error(content[type]) || toast.error(i18n.t('mt4UnknownError'))
  return { payload }
})
