import { gql } from '@apollo/client'

export const GET_USER_DATA = gql`
  fragment AccountDetails on BaseForexAccount {
    id
    login
    #accountName
    balance
    currency
    metaTraderApiUrl
    leverageType
    isArchived
    hidden
    accountSubtype
    isDemo
    swapFree
    isReadOnly
    __typename
  }

  query userData {
    viewer {
      ... on DemoClient {
        accounts {
          ...AccountDetails
          ... on BaseAccount {
            id
          }
          __typename
        }
        __typename
      }
      ... on Client {
        id
        email
        locale
        company
        clientType
        whiteLabel
        themePreference
        isPhoneVerificationRequired
        autoChartistEnabled
        accounts(orderBy: createdAt) {
          ...AccountDetails
          ... on BaseAccount {
            id
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`
