import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import IconButtonMui from '@mui/material/IconButton'

import { ReactComponent as DepositIcon } from '../../assets/images/icons/deposit.svg'
import { currentIdAccountSelector, loginInfoSelector } from '../../store/selectors'
import { depositHandle } from '../../utils'

const DepositButtonWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const IconButton = styled(IconButtonMui)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  height: 32,
  color: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
  borderRadius: 8,
  padding: 0,
  marginLeft: 8,
  '&:hover': {
    background: theme.palette.mode === 'light' ? '#EBEBEB' : '#1F1F1F',
  },
  '& > svg': {
    width: 24,
    height: 24,
  },
}))

const DepositButton: FC = () => {
  const currentLogin = useSelector(loginInfoSelector)
  const currentAccountId = useSelector(currentIdAccountSelector)
  const login = currentLogin?.login.toString() as string
  return (
    <DepositButtonWrap>
      <IconButton onClick={depositHandle.bind(null, currentAccountId, login, { target: '_blank' })}>
        <DepositIcon />
      </IconButton>
    </DepositButtonWrap>
  )
}

export default DepositButton
