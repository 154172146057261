import React, { FC, useCallback, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import { Skeleton } from '@mui/material'

import DefaultIcon from '../../../../assets/images/icons/assets/default.svg'

const useStyles = makeStyles<Theme, { leftIcon?: string; rightIcon?: string }>(() => ({
  wrapper: {
    width: 24,
    height: 24,
    position: 'relative',
    borderRadius: 2,
    marginRight: 8,
    backgroundColor: (icon) => (icon ? 'transparent' : '#00000014'),
    '& img': {
      borderRadius: 2,
    },
  },
  leftImage: {
    position: ({ leftIcon, rightIcon }) => (leftIcon && rightIcon ? 'absolute' : 'static'),
    top: 0,
    left: 0,
    width: ({ leftIcon, rightIcon }) => (leftIcon && rightIcon ? 15 : '100%'),
    height: 'auto',
    zIndex: 5,
  },
  rightImage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: ({ leftIcon, rightIcon }) => (leftIcon && rightIcon ? 17 : '100%'),
    height: 'auto',
  },
  skeleton: {
    borderRadius: 2,
  },
}))

export const FormHeaderIcon: FC<{ label: string }> = ({ label }) => {
  const [icons, setIcons] = useState<
    | {
        [key: string]: string
      }
    | undefined
  >()
  const [leftIcon, rightIcon] = label.split(/[_/0-9]/)
  const classes = useStyles({ leftIcon: icons?.[leftIcon], rightIcon: icons?.[rightIcon] })

  const fetchIcon = useCallback(async (key: string) => {
    const clearKey = key.replace(/[^a-zA-Z0-9 ]/gi, '')
    await import(`../../../../assets/images/icons/assets/${clearKey}.svg`)
      .then((data) => setIcons((prevState) => ({ ...prevState, [key]: data.default })))
      .catch(() => setIcons((prevState) => ({ ...prevState, [key]: DefaultIcon })))
  }, [])

  useEffect(() => {
    leftIcon && fetchIcon(leftIcon)
    rightIcon && fetchIcon(rightIcon)
  }, [leftIcon, rightIcon, fetchIcon])

  const getImages = useCallback(() => {
    if (icons?.[leftIcon] && icons?.[rightIcon]) {
      return (
        <>
          <img src={icons[leftIcon]} className={classes.leftImage} alt={leftIcon} />
          <img src={icons[rightIcon]} className={classes.rightImage} alt={rightIcon} />
        </>
      )
    } else if (icons?.[leftIcon]) {
      return <img src={icons[leftIcon]} className={classes.leftImage} alt={leftIcon} />
    } else if (icons?.[rightIcon]) {
      return <img src={icons[rightIcon]} className={classes.rightImage} alt={rightIcon} />
    }

    return <Skeleton variant="rectangular" className={classes.skeleton} width={24} height={24} />
  }, [classes, icons, leftIcon, rightIcon])

  const images = getImages()

  return (
    <div className={classes.wrapper}>
      {images ? images : <Skeleton variant="rectangular" className={classes.skeleton} width={24} height={24} />}
    </div>
  )
}
