import React, { FC, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

import { ReactComponent as WbSunnyOutlinedIcon } from '../../assets/images/icons/day-mode.svg'
import { ReactComponent as Brightness3OutlinedIcon } from '../../assets/images/icons/night-mode.svg'
import { ThemeContext } from '../ThemeProvider'
import { sendPostMessage, storeItem } from '../../utils'
import { updateThemePreferenceRequest } from '../../store/actions'
import { PostMessageActionType } from '../../enums'

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 22,
      height: 12,
      padding: 0,
      display: 'flex',
      marginBottom: 5,
    },
    switchBase: {
      padding: '1px',
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(10.5px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.success.dark,
          borderColor: theme.palette.success.dark,
        },
      },
    },
    thumb: {
      width: 9.5,
      height: 9.5,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #007bff`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#007bff',
    },
    checked: {},
  }),
)(Switch)

const useStyle = makeStyles((theme: Theme) => ({
  themeLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '7px',
  },
  lightIcon: {
    padding: '1px 7px 0 0',
    '& svg': {
      maxWidth: 15,
      height: 'auto',
      width: '100%',
      fill: theme.palette.mode === 'light' ? '#000' : '#fff',
    },
    '&.active svg': {
      fill: '#007bff',
    },
  },
  darkIcon: {
    padding: '1px 0 0 7px',
    '& svg': {
      maxWidth: 15,
      height: 'auto',
      width: '100%',
      fill: theme.palette.mode === 'light' ? '#000' : '#fff',
    },
    '&.active svg': {
      fill: theme.palette.success.dark,
    },
  },
}))

const getInvertedThemeName = (theme: 'Light' | 'Dark'): string => (theme === 'Light' ? 'dark' : 'light')
const capitalizeFirstLetter = <T extends string>(value: string): T =>
  (value.charAt(0).toUpperCase() + value.slice(1)) as T

export const ThemeSwitch: FC = () => {
  const { theme, setTheme } = useContext(ThemeContext)
  const dispatch = useDispatch()
  const classes = useStyle()

  const handleChange = useCallback(() => {
    const invertedThemeName = getInvertedThemeName(theme)
    setTheme((prevTheme) => capitalizeFirstLetter(getInvertedThemeName(prevTheme)))
    sendPostMessage(PostMessageActionType.changeTheme, invertedThemeName)
    storeItem('themePreference', invertedThemeName)
    dispatch(updateThemePreferenceRequest(invertedThemeName))
  }, [theme, setTheme, dispatch])

  return (
    <Typography component="div" data-test="theme">
      <label className={classes.themeLabel}>
        <div
          className={clsx(classes.lightIcon, {
            active: theme === 'Dark',
          })}
        >
          <Brightness3OutlinedIcon />
        </div>

        <div>
          <AntSwitch checked={theme === 'Light'} onChange={handleChange} />
        </div>

        <div
          className={clsx(classes.darkIcon, {
            active: theme === 'Light',
          })}
        >
          <WbSunnyOutlinedIcon />
        </div>
      </label>
    </Typography>
  )
}
