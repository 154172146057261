import React, { FC } from 'react'
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles'

import { CloseButton } from '../../CloseButton'

const useStyles = makeStyles({
  container: {
    padding: '1.7rem 1.55rem',
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 18,
    color: '#999',
  },
})

export const DialogTitle: FC<DialogTitleProps & { close: () => void }> = ({ close, children, ...rest }) => {
  const classes = useStyles()

  return (
    <MuiDialogTitle className={classes.container} {...rest}>
      {children}
      <CloseButton onClose={close} className={classes.closeButton} />
    </MuiDialogTitle>
  )
}
