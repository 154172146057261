import { ThemeOptions } from '@mui/material/styles'

export const lightTheme: ThemeOptions = {
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#fff',
        },
        tooltip: {
          color: '#000',
          backgroundColor: '#fff',
          boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.4)',
          maxWidth: '150px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
          color: 'rgba(0, 0, 0, 0.87)',
          '&:hover': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiListItem-root': {
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    background: {
      paper: '#fff',
      default: '#fff',
    },
    text: {
      secondary: '#757575',
    },
    primary: {
      main: '#007AFF',
    },
    secondary: {
      main: '#ED1C24',
    },
    success: {
      main: '#00C78D',
    },
    green: {
      mint: '#009469',
    },
  },
}
