import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, List, ListItem, ListItemText, Popover } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'

import {
  activeAssetGroupSelector,
  assetGroupsModelSelector,
  currentAccountSelector,
  currentIdAccountSelector,
  favouritesLengthSelector,
  userDataSelector,
} from '../../../../../store/selectors'
import { changeActiveAssetGroup } from '../../../../../store/actions'
import { RootState } from '../../../../../store/types'
import clsx from 'clsx'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { depositHandleWithPreselectedValue } from '../../../../../utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minWidth: 180,
      width: 180,
      overflowY: 'auto',
      borderRight: `1px solid ${theme.palette.action.selected}`,
      maxHeight: '100%',
      fontSize: '.7857rem',
      '& .MuiTypography-body1': {
        fontSize: '.9286rem',
        fontWeight: 500,
        lineHeight: '1rem',
        letterSpacing: '-0.2px',
      },
    },
    list: {
      padding: '5px 0',
    },
    span: {
      background: theme.palette.action.disabled,
      padding: '3px 6px',
      borderRadius: 6,
      fontSize: '.6429rem',
      lineHeight: 1,
      color: '#FFF !important',
    },
    listItem: {
      padding: '8px 12px',
    },
    listItem_disabled: {
      opacity: 0.4,
    },
    button_fullWidth: {
      width: '100%',
    },
    popover_content__200: {
      width: 200,
    },
  }),
)

export const FilterItems: FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { groupsArray, symbolsLength } = useSelector(assetGroupsModelSelector)
  const activeAssetGroup = useSelector(activeAssetGroupSelector)
  const favoritesLength = useSelector(favouritesLengthSelector)
  const currentAccount = useSelector(currentAccountSelector)
  const currentAccountId = useSelector(currentIdAccountSelector)
  const isAutoChartistEnabled = useSelector((state: RootState) => userDataSelector(state, 'autoChartistEnabled'))
  const [anchorElListItem, setAnchorElListItem] = useState<HTMLElement | null>(null)
  const [popupContent, setPopupContent] = useState<React.ReactNode>('')

  const handleListItemClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { label } = event.currentTarget.dataset
      label && dispatch(changeActiveAssetGroup(label))
    },
    [dispatch],
  )

  const handleListItemClickDisabledTrendsAnalysis = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPopupContent(
      <div className={classes.popover_content__200}>
        <Typography mb={1}>
          <Trans i18nKey={'getAccessTradeAnalysisMessage'} defaults={'To access Trend analysis, deposit $500'} />
        </Typography>
        <Button
          color={'primary'}
          variant={'contained'}
          className={classes.button_fullWidth}
          onClick={depositHandleWithPreselectedValue.bind(null, currentAccountId, { amount: 500, currency: 'USD' })}
        >
          <Trans i18nKey={'deposit'} />
        </Button>
      </div>,
    )
    setAnchorElListItem(event.currentTarget)
  }

  const renderListItemPopup = () => {
    return (
      <Popover
        open={Boolean(anchorElListItem)}
        anchorEl={anchorElListItem}
        onClose={() => setAnchorElListItem(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        container={() => document.getElementById('root')!}
      >
        <Box p={2}>{popupContent}</Box>
      </Popover>
    )
  }

  const renderListItems = (
    item: {
      label: string
      symbols: string[]
    },
    properties: {
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
      clsx?: Array<string>
    } = {
      onClick: handleListItemClick,
      clsx: [classes.listItem],
    },
  ) => {
    return (
      <ListItem
        className={clsx(...(properties.clsx ? properties.clsx : []))}
        button
        selected={item.label === activeAssetGroup}
        onClick={(e) => properties.onClick(e)}
        key={item.label}
        data-label={item.label}
      >
        <ListItemText primary={<Trans i18nKey={`${item.label}`} />} />
        <Box className={classes.span}>
          {item.label === 'all' ? symbolsLength : item.label === 'favourites' ? favoritesLength : item.symbols.length}
        </Box>
      </ListItem>
    )
  }

  return (
    <div className={classes.container}>
      <List component="nav" aria-label="assets" className={classes.list}>
        {groupsArray.map((item) => {
          if (currentAccount && currentAccount.__typename.includes('MT5') && item.label === 'trendsAnalysis') {
            return null
          }
          if (item.label === 'trendsAnalysis' && !isAutoChartistEnabled) {
            return renderListItems(item, {
              onClick: handleListItemClickDisabledTrendsAnalysis,
              clsx: [classes.listItem, classes.listItem_disabled],
            })
          }
          return renderListItems(item)
        })}
      </List>
      {renderListItemPopup()}
    </div>
  )
}
