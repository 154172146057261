import {
  calcAmountValue,
  calcPendingPriceValue,
  calcSlValue,
  calcTpValue,
  PriceExtended,
  TradeCommandType,
} from '@bdswiss/mt4-connector'
import i18next from 'i18next'

import { FieldErrorKeys, OrderTypes, TradeMode } from '../enums'
import { FieldInfo } from '../store/reducers/form'

export const getStepForIncreaseFieldValue = (
  asset: PriceExtended,
  field: string,
  meta?: {
    action: 'increase' | 'decrease'
    fieldValue: number
  },
): number => {
  switch (field) {
    case 'amount':
      const maxValue = asset.lotMax * asset.lotSize
      let step = asset.lotStep * asset.lotSize
      if (meta?.action === 'increase' && meta?.fieldValue + step > maxValue) {
        step = maxValue - meta?.fieldValue
      } else if (meta?.action === 'decrease' && meta?.fieldValue - step < asset.lotMin * asset.lotSize) {
        step = meta?.fieldValue - step
      }

      return step
    default:
      return Math.pow(10, -asset.digits)
  }
}

export const calcStopPrice = (
  asset: PriceExtended,
  value: number,
  tradeMode?: Omit<TradeMode, TradeMode.disabled>,
): number => {
  if (
    (tradeMode === TradeMode.buy && value < asset.currentRate) ||
    (tradeMode === TradeMode.sell && value > asset.currentRate)
  ) {
    return asset.currentRate
  }

  return value
}

const calcExpandedLimitPrice = (
  stopPrice: number,
  tradeMode: TradeCommandType,
  asset: PriceExtended,
  value: number,
  orderType?: OrderTypes,
): number => {
  const limitValue = calcPendingPriceValue(tradeMode as TradeCommandType, asset, value)
  const step = getStepForIncreaseFieldValue(asset, 'priceLimit')

  if (orderType === OrderTypes.stopLimitOrder) {
    if (tradeMode === TradeMode.buy && value < stopPrice) return stopPrice
    if (tradeMode === TradeMode.sell && value > stopPrice) return limitValue - step
    return value
  }

  return limitValue
}

export const calcAmountOnRequiredMargin = (requiredMargin: number, leverage: number, price: number): number => {
  return Math.fround(requiredMargin * leverage) / price
}

export const getValidatedFieldValue = (
  asset: PriceExtended,
  field: string,
  value: number,
  tradeMode?: Omit<TradeMode, TradeMode.disabled>,
  openPrice?: number,
  isPendingOrder?: boolean,
  orderType?: OrderTypes,
  stopPriceFromField?: number,
): number => {
  const price = isPendingOrder
    ? openPrice
    : tradeMode === TradeMode.buy
    ? field === 'takeProfit'
      ? Math.max(openPrice || -Infinity, asset.mbid)
      : undefined
    : field === 'stopLoss'
    ? Math.min(openPrice || Infinity, asset.mask)
    : undefined

  const stopPrice = stopPriceFromField || calcStopPrice(asset, value, tradeMode)

  switch (field) {
    case 'amount':
      return calcAmountValue(asset, value)
    case 'takeProfit':
      return calcTpValue(tradeMode as TradeCommandType, asset, value, price, isPendingOrder)
    case 'stopLoss':
      return calcSlValue(tradeMode as TradeCommandType, asset, value, price, isPendingOrder)
    case 'priceLimit':
      // const priceLimit = tradeMode === TradeMode.buy ? asset.mask : asset.mbid
      // return tradeMode === TradeMode.buy
      //   ? value > priceLimit
      //     ? priceLimit - (asset.mask - asset.mbid)
      //     : value
      //   : value > priceLimit
      //   ? value
      //   : priceLimit
      // // TODO it is possible that the connector is counting incorrectly
      return calcExpandedLimitPrice(stopPrice, tradeMode as TradeCommandType, asset, value, orderType)
    case 'stopPrice':
      return calcStopPrice(asset, value, tradeMode)
  }
  return value
}

export const getMinFieldValue = (
  asset: PriceExtended,
  field: string,
  tradeMode?: Omit<TradeMode, TradeMode.disabled>,
  openPrice?: number,
  isPendingOrder?: boolean,
  orderType?: OrderTypes,
  stopPrice?: number,
): number => {
  switch (field) {
    case 'amount':
      return asset.lotMin * asset.lotSize
    case 'takeProfit':
    case 'stopLoss':
      return getValidatedFieldValue(asset, field, 0, tradeMode, openPrice, isPendingOrder)
    case 'priceLimit':
      return orderType === OrderTypes.stopLimitOrder
        ? tradeMode === TradeMode.buy
          ? stopPrice || asset.currentRate
          : 0
        : getValidatedFieldValue(asset, field, 0, tradeMode, openPrice, isPendingOrder)
    case 'stopPrice':
      if (tradeMode === TradeMode.buy) return asset.currentRate
      if (tradeMode === TradeMode.sell) return 0
  }

  return 0
}

export const getMaxFieldValue = (
  asset: PriceExtended,
  field?: string,
  tradeMode?: Omit<TradeMode, TradeMode.disabled>,
  openPrice?: number,
  isPendingOrder?: boolean,
  orderType?: OrderTypes,
  stopPrice?: number,
): number => {
  switch (field) {
    case 'amount':
      return asset.lotMax * asset.lotSize
    case 'takeProfit':
    case 'stopLoss':
      return getValidatedFieldValue(asset, field, Number.MAX_SAFE_INTEGER, tradeMode, openPrice, isPendingOrder)
    case 'priceLimit':
      return orderType === OrderTypes.stopLimitOrder
        ? tradeMode === TradeMode.buy
          ? Number.MAX_SAFE_INTEGER
          : stopPrice || 0
        : getValidatedFieldValue(asset, field, Number.MAX_SAFE_INTEGER, tradeMode, openPrice, isPendingOrder)
    case 'stopPrice':
      if (tradeMode === TradeMode.buy) return Number.MAX_SAFE_INTEGER
      if (tradeMode === TradeMode.sell) return asset.currentRate
  }

  return Number.MAX_SAFE_INTEGER
}

export const getErrorMessagesKeys = (
  field: string,
  value: number,
  minValue: number,
  maxValue: number,
  isValid: boolean,
  tradeMode?: Omit<TradeMode, TradeMode.disabled>,
  orderType?: OrderTypes,
  stopPrice?: number,
): string | undefined => {
  switch (field) {
    case 'amount':
      if (value <= 0) return i18next.t(FieldErrorKeys.zeroValue, { fieldName: field })
      if (value < minValue) return i18next.t(FieldErrorKeys.lessAmount, { value: minValue })
      if (value > maxValue) return i18next.t(FieldErrorKeys.higherAmount, { value: maxValue })
      break
    case 'priceLimit':
      if (orderType === OrderTypes.stopLimitOrder && stopPrice) {
        if (tradeMode === TradeMode.buy && value < stopPrice)
          return i18next.t(FieldErrorKeys.lessStopLimitPrice, { type: TradeMode.buy.toUpperCase() })
        if (tradeMode === TradeMode.sell && value > stopPrice)
          return i18next.t(FieldErrorKeys.moreStopLimitPrice, { type: TradeMode.sell.toUpperCase() })
      }
      if (tradeMode === TradeMode.buy && value > maxValue)
        return i18next.t(FieldErrorKeys.moreLimitPrice, { type: TradeMode.buy.toUpperCase() })
      if (tradeMode === TradeMode.sell && value < maxValue)
        return i18next.t(FieldErrorKeys.lessLimitPrice, { type: TradeMode.sell.toUpperCase() })
      break
    case 'stopPrice':
      if (tradeMode === TradeMode.buy && !isValid) return i18next.t(FieldErrorKeys.stopPriceBuy)
      if (tradeMode === TradeMode.sell && !isValid) return i18next.t(FieldErrorKeys.stopPriceSell)
      break
    default:
      return undefined
  }
}

export type AllFieldInfo = {
  asset: PriceExtended
  field: string
  value: number
  tradeMode?: Omit<TradeMode, TradeMode.disabled>
  openPrice?: number
  isPendingOrder?: boolean
  formOrderType?: OrderTypes
  stopPrice?: number
}

export const getAllFieldInfo = ({
  asset,
  field,
  value,
  tradeMode,
  openPrice,
  isPendingOrder,
  formOrderType,
  stopPrice,
}: AllFieldInfo): FieldInfo => {
  const minValue = getMinFieldValue(asset, field, tradeMode, openPrice, isPendingOrder, formOrderType, stopPrice)
  const maxValue = getMaxFieldValue(asset, field, tradeMode, openPrice, isPendingOrder, formOrderType, stopPrice)
  const isValidField = value >= minValue && value <= maxValue
  const step = getStepForIncreaseFieldValue(asset, field)
  const errorText = getErrorMessagesKeys(
    field,
    value,
    minValue,
    maxValue,
    isValidField,
    tradeMode,
    formOrderType,
    stopPrice,
  )

  return {
    canIncrease: value < maxValue || value + step <= maxValue,
    canDecrease: value > minValue || value - step >= minValue,
    errorText: errorText ? errorText : undefined,
    isValid: isValidField,
  }
}
