import React, { FC } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { menuBarList, mobileMenuItems } from './menuBarList'
import { PageLayout } from '../PageLayout/PageLayout'
import { isMobile } from '../../utils'

export const PageContent: FC = () => {
  const { path: routerPath } = useRouteMatch()
  const menuItems = isMobile() ? mobileMenuItems : menuBarList

  return (
    <PageLayout
      mainContent={
        <Switch>
          {menuItems.map(({ MainContent, name, path, exact }) => {
            return <Route exact={exact} key={name} path={`${routerPath}${path}`} component={MainContent} />
          })}
        </Switch>
      }
      tradePanel={
        <Switch>
          {menuItems.map(({ TradePanel, name, path, exact }) => {
            return <Route exact={exact} key={name} path={`${routerPath}${path}`} component={TradePanel} />
          })}
        </Switch>
      }
    />
  )
}
