import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import TakeProfitCheckbox from '../../Controls/TakeProfitCheckbox'
import StopLossCheckbox from '../../Controls/StopLossCheckbox'

const useStyles = makeStyles({
  settingsTitle: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  tpContainer: {
    marginTop: 21,
  },
  arrowContainer: {
    display: 'flex',
    '& svg': {
      maxWidth: '20px',
    },
  },
  collapse: {
    '& .MuiCollapse-wrapperInner': {
      paddingTop: '8px',
      paddingBottom: '3px',
    },
  },
  priceLimit: {
    marginBottom: '5px',
  },
})

export const TradeSettings: FC = () => {
  const classes = useStyles()

  return (
    <>
      <div data-guided-tour="takeProfitChBox" className={classes.tpContainer}>
        <TakeProfitCheckbox />
      </div>
      <div data-guided-tour="stopLossChBox">
        <StopLossCheckbox />
      </div>
    </>
  )
}
