import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import {
  currentEquitySelector,
  currentMarginSelector,
  currentFreeMarginSelector,
  loginInfoSelector,
  currentAccountSelector,
} from '../../../../store/selectors'

import { currencyFormat, positiveNegativeFormat } from '../../../../utils'
import { AccountInfoItem } from './AccountInfoItem'
import { setMobileBackButtonData } from '../../../../store/actions'

const useStyles = makeStyles(() => ({
  container: {
    padding: '0px 16px',
    marginTop: '4px',
  },
}))

export const AccountInfoPage: FC = () => {
  const currentAccount = useSelector(currentAccountSelector)

  const classes = useStyles()
  const { t } = useTranslation()
  const equity = useSelector(currentEquitySelector)
  const margin = useSelector(currentMarginSelector)
  const freeMargin = useSelector(currentFreeMarginSelector)
  const loginInfo = useSelector(loginInfoSelector)
  const marginLevel = margin ? ((equity / margin) * 100).toFixed(2) : '-'

  const currency = currentAccount?.currency || ''

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setMobileBackButtonData({ show: true, title: t('accountInfo') }))
    return () => {
      dispatch(setMobileBackButtonData(undefined))
    }
  }, [dispatch, t])

  if (!currentAccount?.currency) {
    return null
  }

  return (
    <div className={classes.container}>
      <AccountInfoItem
        title={t('balance')}
        value={currencyFormat(loginInfo?.balance || 0, currency)}
        color={'green'}
        hintKey={'helpBalance'}
      />

      <AccountInfoItem
        title={t('equity')}
        value={currencyFormat(equity, currency)}
        color={equity >= 0 ? 'neutral' : 'red'}
        hintKey={'helpEquity'}
      />

      <AccountInfoItem
        title={t('bonus')}
        value={currencyFormat(0.0, currency)}
        color={'neutral'}
        hintKey={'helpBonus'}
      />

      <AccountInfoItem
        title={t('margin')}
        value={currencyFormat(margin, currency)}
        color={'neutral'}
        hintKey={'helpUsedMargin'}
      />

      <AccountInfoItem
        title={t('marginLevel')}
        value={!isNaN(+marginLevel) ? `${marginLevel}%` : '-'}
        color={'neutral'}
        hintKey={'helpMarginLevel'}
      />

      <AccountInfoItem
        title={t('freeMargin')}
        value={
          freeMargin >= 0
            ? positiveNegativeFormat(freeMargin, currency).replace('+', '')
            : positiveNegativeFormat(freeMargin, currency)
        }
        color={freeMargin >= 0 ? 'neutral' : 'red'}
        hintKey={'helpFreeMargin'}
      />
    </div>
  )
}
