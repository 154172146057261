import { Account } from '../../store/types'
import { genApiUrl } from '../../utils'

export const guidedTourServiceAccount: Account = {
  __typename: 'ForexMauritiusAccount',
  id: 6127024,
  login: '6127024',
  metaTraderApiUrl: genApiUrl('', 'wss', 'mt4-api-real01'),
  isArchived: false,
  hidden: true,
  isDemo: false,
  accountName: 'Interactive Demo Account',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  currency: 'EUR',
}
