import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'

import { Dialog, DialogActions, DialogTitle, DialogContent } from '../Dialog'
import { closeAllOpenPositionsAction } from '../../store/actions'
import { isMobile } from '../../utils'

interface IProps {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles({
  container: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: 500,
    },
  },
  button: {
    maxHeight: 26,
    minWidth: 92,
    textTransform: 'none',
    boxShadow: '0 1px 4px 0 rgb(0 0 0 / 4%), 0 2px 8px 0 rgb(0 0 0 / 8%)',
    '&:hover': {
      boxShadow: '0 4px 16px 0 rgb(0 0 0 / 16%), 0 1px 4px 0 rgb(0 0 0 / 4%), 0 2px 8px 0 rgb(0 0 0 / 8%)',
    },
  },
  closePositionsTextMobile: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
})

export const CloseAllPositionsDialog: FC<IProps> = ({ open, onClose }) => {
  const isMobileDevice = isMobile()
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleConfirm = useCallback(() => {
    dispatch(closeAllOpenPositionsAction())
    onClose()
  }, [dispatch, onClose])

  return (
    <Dialog open={open} className={classes.container} hideBackdrop>
      <DialogTitle close={onClose}>
        <Typography className={isMobileDevice ? classes.closePositionsTextMobile : ''}>
          <Trans i18nKey={'closingPositions'} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <Trans i18nKey={'closeAllPositionsMessage'} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          variant={'contained'}
          color={'primary'}
          onClick={handleConfirm}
          disableRipple
          data-guided-tour="confirm"
        >
          <Trans i18nKey={'confirm'} />
        </Button>
        <Button className={classes.button} variant={'outlined'} onClick={onClose}>
          <Trans i18nKey={'cancel'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
