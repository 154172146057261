import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Collapse, Radio } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Trans, useTranslation } from 'react-i18next'

import HelpTooltip from '../../HelpTooltip/HelpTooltip'
import { AccountDetailsItem } from '../AccountDetailsItem/AccountDetailsItem'
import { BdSwissForexMt4AccountType } from '../../../../services/graphql/types'
import {
  currentEquitySelector,
  currentMarginSelector,
  currentFreeMarginSelector,
  loginInfoSelector,
} from '../../../../store/selectors'

import { capitalize, currencyFormat, positiveNegativeFormat } from '../../../../utils'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  dropdownItem: {
    background: 'transparent',
    border: 'none',
    maxWidth: 360,
    cursor: 'pointer',
    padding: '10px 21px 2px 9px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  accounts: {
    width: '100%',
  },
  accountRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  accountName: {
    fontSize: '1rem',
    color: theme.palette.text.primary,
    fontWeight: 500,
    letterSpacing: '0.7px',
    paddingTop: '3px',
    paddingRight: '40px',
    textAlign: 'left',
  },
  accountBalance: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.8rem',
    color: theme.palette.text.primary,
    opacity: 0.65,
    whiteSpace: 'nowrap',
    '& svg': {
      fontSize: 'initial',
      padding: 0,
      margin: '0 4px',
    },
  },
  accountNumber: {
    color: theme.palette.text.primary,
    opacity: 0.65,
    lineHeight: 1.3,
  },
  accountTotal: {
    marginTop: '4px',
    color: theme.palette.success.main,
    fontSize: '0.9375rem',
  },
  additional: {
    marginTop: 16,
  },
  divider: {
    margin: '3px 0 0 38px',
    border: 0,
    borderTop: '1px solid',
    borderColor: theme.palette.action.selected,
  },
  radioInput: {
    margin: '0px 5px 0 0',
  },
  lastDivider: {
    margin: '3px 0 0',
  },
}))

interface AccountCardProps {
  login: BdSwissForexMt4AccountType['login']
  balance: BdSwissForexMt4AccountType['balance']
  accountName: BdSwissForexMt4AccountType['accountName']
  swapFree: BdSwissForexMt4AccountType['swapFree']
  selected: boolean
  currency: string
  accountSubtype: BdSwissForexMt4AccountType['accountSubtype']
  isDemo: BdSwissForexMt4AccountType['isDemo']
  isLast: boolean
}

export const AccountCard: FC<AccountCardProps> = ({
  selected,
  login,
  balance,
  accountName,
  accountSubtype,
  swapFree,
  currency,
  isDemo,
  isLast,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const equity = useSelector(currentEquitySelector)
  const margin = useSelector(currentMarginSelector)
  const freeMargin = useSelector(currentFreeMarginSelector)
  const loginInfo = useSelector(loginInfoSelector)
  const marginLevel = margin ? ((equity / margin) * 100).toFixed(2) : '-'

  return (
    <button className={classes.dropdownItem}>
      <label className={classes.itemContainer}>
        <Radio color={'primary'} size={'small'} value={login} checked={selected} className={classes.radioInput} />
        <div className={classes.accounts}>
          <div className={classes.accountRow}>
            <div className={classes.accountName}>
              {!!accountName ? (
                accountName
              ) : (
                <>
                  {swapFree && (
                    <>
                      <Trans i18nKey={'swapFree'} defaults={'Swap Fre'} />
                      &nbsp;
                    </>
                  )}
                  <Trans
                    i18nKey={`${isDemo ? 'practice' : 'real'}${capitalize(
                      `${accountSubtype ? accountSubtype : ''}`,
                    )}Account`}
                  />
                </>
              )}
            </div>
            <div className={classes.accountBalance}>
              <HelpTooltip hint={<Trans i18nKey={'helpBalance'} />} />
              <Trans i18nKey={'balance'} />
            </div>
          </div>

          <div className={classes.accountRow}>
            <div className={classes.accountNumber}>{login}</div>
            <div className={classes.accountTotal}>
              {currencyFormat((selected ? loginInfo?.balance : balance) || 0, currency)}
            </div>
          </div>

          <Collapse in={selected} className={classes.additional}>
            <AccountDetailsItem
              fieldName={t('equity')}
              hint={<Trans i18nKey={'helpEquity'} />}
              money={currencyFormat(equity, currency)}
            />
            <AccountDetailsItem
              fieldName={t('bonus')}
              hint={<Trans i18nKey={'helpBonus'} />}
              money={currencyFormat(0.0, currency)}
            />
            <AccountDetailsItem
              fieldName={t('margin')}
              hint={<Trans i18nKey={'helpUsedMargin'} />}
              money={currencyFormat(margin, currency)}
            />
            <AccountDetailsItem
              fieldName={t('marginLevel')}
              hint={<Trans i18nKey={'helpMarginLevel'} />}
              money={!isNaN(+marginLevel) ? `${marginLevel}%` : '-'}
            />
            <AccountDetailsItem
              fieldName={t('freeMargin')}
              hint={<Trans i18nKey={'helpFreeMargin'} />}
              money={
                freeMargin >= 0
                  ? positiveNegativeFormat(freeMargin, currency).replace('+', '')
                  : positiveNegativeFormat(freeMargin, currency)
              }
            />
          </Collapse>
        </div>
      </label>
      <hr className={clsx(classes.divider, isLast && classes.lastDivider)} />
    </button>
  )
}
