import { Error } from '@bdswiss/mt4-connector'
import { Account } from '../store/types'
import {
  cancelPendingDemoOrderAttempted,
  cancelPendingDemoOrderCompleted,
  cancelPendingDemoOrderFailed,
  cancelPendingRealOrderAttempted,
  cancelPendingRealOrderCompleted,
  cancelPendingRealOrderFailed,
  closeDemoOrderAttempted,
  closeDemoOrderCompleted,
  closeDemoOrderFailed,
  closeRealOrderAttempted,
  closeRealOrderCompleted,
  closeRealOrderFailed,
} from './events'
import { trackEvent } from './firebaseLogger'
import { isMobile } from '../utils'

export const ORDER_TYPE_OPEN = 'open'
export const ORDER_TYPE_PENDING = 'pending'

export function trackCloseOrderAttemptEvent(orderId: number, account?: Account, orderType?: string): void {
  if (!account || !orderType) return

  let event: string | undefined
  if (account.isDemo) {
    event = orderType === ORDER_TYPE_PENDING ? cancelPendingDemoOrderAttempted : closeDemoOrderAttempted
  } else {
    event = orderType === ORDER_TYPE_PENDING ? cancelPendingRealOrderAttempted : closeRealOrderAttempted
  }

  event && trackEvent(event, { positionId: orderId })
}

export function trackCloseOrderCompletedEvent(orderId: number, account?: Account, orderType?: string): void {
  if (!account || !orderType) return

  let event: string | undefined
  if (account.isDemo) {
    event = orderType === 'pending' ? cancelPendingDemoOrderCompleted : closeDemoOrderCompleted
  } else {
    event = orderType === 'pending' ? cancelPendingRealOrderCompleted : closeRealOrderCompleted
  }

  event && trackEvent(event, { positionId: orderId })
}

export function trackCloseOrderFailedEvent(orderId: number, error: Error, account?: Account, orderType?: string): void {
  if (!account || !orderType) return

  let event: string | undefined
  if (account.isDemo) {
    event = orderType === 'pending' ? cancelPendingDemoOrderFailed : closeDemoOrderFailed
  } else {
    event = orderType === 'pending' ? cancelPendingRealOrderFailed : closeRealOrderFailed
  }

  event &&
    trackEvent(event, {
      positionId: orderId,
      reason: error.type + (error.message ? `: ${error.message}` : ''),
    })
}

export const getDeviceSource = (): string => {
  return isMobile() ? 'mobileResponsive' : 'desktop'
}
