import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import HelpIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import { ClickAwayListener } from '@mui/material'
import { isMobile } from '../../../utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    questionMark: {
      marginLeft: 4,
      fill: '#999',
      '&:hover': {
        fill: isMobile() ? '#999' : '#007AFF',
      },
    },
    mobileTooltip: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.22)',
      background: theme.palette.mode === 'light' ? '#FFFFFF' : '#1F1F1F',
      maxWidth: 193,
      padding: '8px 12px',
    },
    arrow: {
      color: theme.palette.mode === 'light' ? '#FFFFFF' : '#1F1F1F',
    },
  }),
)

interface HelpHintProps {
  hint: NonNullable<ReactNode>
}
interface MobileTooltipProps {
  open: boolean
  hint: HelpHintProps['hint']
  onOpen: () => void
  onClose: () => void
}

const MobileTooltip: FC<MobileTooltipProps> = ({ open, hint, onOpen, onClose }) => {
  const classes = useStyles()
  return useMemo(
    () => (
      <ClickAwayListener onClickAway={onClose}>
        <Tooltip
          title={hint}
          arrow
          open={open}
          classes={{ tooltip: classes.mobileTooltip, arrow: classes.arrow }}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-10, -17],
                },
              },
            ],
          }}
          placement={'bottom-start'}
        >
          <HelpIcon className={classes.questionMark} fontSize="inherit" onClick={onOpen} />
        </Tooltip>
      </ClickAwayListener>
    ),
    [open, classes, onOpen, onClose], // eslint-disable-line react-hooks/exhaustive-deps
  )
}

const HelpTooltip: FC<HelpHintProps> = ({ hint }) => {
  const classes = useStyles()
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const closeTooltip = useCallback(() => {
    setTooltipOpen(false)
  }, [])

  const openTooltip = useCallback(() => {
    setTooltipOpen(true)
  }, [])

  if (!hint) {
    return null
  }

  return (
    <>
      {isMobile() ? (
        <MobileTooltip open={tooltipOpen} hint={hint} onOpen={openTooltip} onClose={closeTooltip} />
      ) : (
        <Tooltip title={hint} arrow>
          <HelpIcon className={classes.questionMark} fontSize="inherit" />
        </Tooltip>
      )}
    </>
  )
}

export default HelpTooltip
