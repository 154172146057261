import React, { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { setFullscreenElement, setMobileBackButtonData } from '../../../../store/actions'
import Chart, { ChartPanel } from '../../../Chart'

import { FullscreenableElement } from '../../ToggleFullscreenButton'
import { TradeButtons } from './TradeButtons'
import { tradingFormPanelSelector } from '../../../../store/selectors'
import { TradingForm } from '../../../Forms/TradingForm'

const useStyles = makeStyles((theme) => ({
  tradeButtonsContainer: {
    padding: '12px 16px',
    marginTop: '10px',
    background: theme.palette.background.default,
    borderTop: `0.5px solid ${theme.palette.mode === 'light' ? '#e0e3ea' : '#222222'}`,
  },
}))

export const ChartPage: FC = () => {
  const classes = useStyles()
  const fullscreenNodeRef = useRef<FullscreenableElement>(null)
  const dispatch = useDispatch()
  const form = useSelector(tradingFormPanelSelector)

  useEffect(() => {
    dispatch(setMobileBackButtonData(undefined))
    dispatch(setFullscreenElement(fullscreenNodeRef.current || undefined))
    return () => {
      dispatch(setFullscreenElement(undefined))
    }
  }, [dispatch])

  if (form) return <TradingForm />

  return (
    <ChartPanel fullscreenNodeRef={fullscreenNodeRef}>
      <Chart />
      <div className={classes.tradeButtonsContainer}>
        <TradeButtons />
      </div>
    </ChartPanel>
  )
}
