import React, { MutableRefObject } from 'react'
import { VariableSizeList } from 'react-window'

export interface ExpandedAction {
  type: 'toggle' | 'clear'
  key: string
}

export interface ResetAction {
  type: 'reset'
}

export function expandedReducer(
  state: { [key: string]: boolean },
  action: ExpandedAction | ResetAction,
): { [key: string]: boolean } {
  switch (action.type) {
    case 'toggle':
      return { ...state, [action.key]: !state[action.key] }
    case 'clear':
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.key]: id, ...rest } = state
      return rest
    case 'reset':
      return {}
  }
}

export interface TableContextProps {
  expandedPositionsState: React.ReducerState<
    (state: { [p: string]: boolean }, action: ExpandedAction | ResetAction) => { [p: string]: boolean }
  >
  expandedPositionsDispatch?: React.Dispatch<
    React.ReducerAction<
      (state: { [p: string]: boolean }, action: ExpandedAction | ResetAction) => { [p: string]: boolean }
    >
  >
  keyPropertyName: string
  listRef?: MutableRefObject<VariableSizeList | null>
}

export const TableContext = React.createContext<TableContextProps>({
  keyPropertyName: 'id',
  expandedPositionsState: {},
})
