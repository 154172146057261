import React, { FC, useCallback, createRef, RefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/styles'
import { get, isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'

import { PricesExtended, TradeObject, Signal } from '@bdswiss/mt4-connector'

import {
  ChartingLibraryWidgetConstructor,
  EntityId,
  EntityInfo,
  IChartingLibraryWidget,
  IChartWidgetApi,
  TradingTerminalFeatureset,
  ResolutionString,
  LanguageCode,
  AccessList,
  Timezone,
  ChartingLibraryWidgetOptions,
  ChartStyle,
  SaveChartToServerOptions,
  ChartData,
  StudyTemplateMetaInfo,
  ChartTemplate,
  ChartTemplateContent,
  ChartMetaInfo,
  StudyTemplateData,
  SeriesType,
} from './types'
import { MT4DatafeedBase } from './datafeed/mt4-datafeed-base'
import { ChartPositions } from './positions/ChartPositions'
import { ChartSignal } from './signals/ChartSignal'
import { getCookie, isMobile } from '../../utils'
import { chartLocalesMap, resolution2Seconds } from './utils'
import {
  activeAssetSelector,
  chartModeSelector,
  formOrderTypeSelector,
  getActiveSignal,
  formValuesSelector,
  isFullscreenSelector,
  pricesSelector,
  userLocaleSelector,
  currentSymbolPriceSelector,
  overrideParamsSelector,
} from '../../store/selectors'
import { appChangeActiveAsset, toggleFullscreen } from '../../store/actions'
import Preloader from './Preloader'
import { OrderTypes } from '../../enums'
import { trackEvent } from '../../analytics/firebaseLogger'
import {
  chartStyleChanged,
  chartTimeframeChanged,
  indicatorAdded,
  indicatorRemoved,
  indicatorsButtonClicked,
  indicatorSettingsSaved,
} from '../../analytics/events'
import store from '../../store'
import { fullscreenIconContent } from '../../assets/images/icons/mobile/fullscreen_icon'
import { OverrideParams } from '../../store/types'

declare global {
  interface Window {
    TradingView: {
      widget: ChartingLibraryWidgetConstructor
    }
  }
}

type ChartMode = 'open' | 'pending' | 'trade'
type AppTheme = 'Light' | 'Dark'

enum ChartSeriesType {
  Bars = 0,
  Candles = 1,
  Line = 2,
  Area = 3,
  HeikenAshi = 8,
  HollowCandles = 9,
  Baseline = 10,
  HiLo = 12,
  Column = 13,
  LineWithMarkers = 14,
  Stepline = 15,
  HLCArea = 16,
}

export interface Props {
  appTheme: AppTheme
  symbol: string
  timeframe: string
  overrideParams: OverrideParams
  locale: string
  chartMode: ChartMode
  positions: TradeObject[]
  pendingOrders: TradeObject[]
  // positionsInputs: PositionsInputs
  formValues: (number | boolean)[]
  formOrderType: OrderTypes
  onChangeSymbol: (symbol: string) => void
  assets: PricesExtended
  visibleRange?: { from: number; to: number; interval: string }
  positionsOpened?: boolean
  selectedPosition?: number
  positionExpanded?: boolean
  selectedOrder?: number
  orderExpanded?: boolean
  signal?: Signal | null
  refreshChart?: boolean
  showSidebar: boolean
  // onChartReady: () => void
  askPrice?: number
}

export interface State {
  resolution: ResolutionString
  chartStyle: number
  visibleRange: { from: number; to: number; timeScale: number }
}

class Chart extends React.Component<Props, State> {
  private chart!: IChartWidgetApi
  private tvWidget!: IChartingLibraryWidget
  private chartDatafeed = new MT4DatafeedBase()
  private chartSignal = new ChartSignal()
  private chartPositions = new ChartPositions()
  private chartSpinnerElementRef: RefObject<HTMLDivElement>
  private chartContainerElementRef: RefObject<HTMLDivElement>
  private deleteButtonElements: NodeListOf<HTMLDivElement> | []
  private indicatorLegendWrapElements: NodeListOf<HTMLDivElement> | []
  private iframeElement: HTMLIFrameElement | null
  private currentIndicatorName: string
  private askLineShapeId: EntityId | null = null

  constructor(props: Props) {
    super(props)
    this.chartSpinnerElementRef = createRef()
    this.chartContainerElementRef = createRef()
    this.deleteButtonElements = []
    this.indicatorLegendWrapElements = []
    this.iframeElement = null
    this.currentIndicatorName = ''

    const { chartResolution: resolution, chartStyle } = props.overrideParams

    this.state = {
      resolution:
        resolution && this.chartDatafeed.supportedResolutions.includes(resolution as ResolutionString)
          ? (resolution as ResolutionString)
          : ((localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution') || '240') as ResolutionString),
      chartStyle: (() => {
        switch (true) {
          case chartStyle && isNaN(+chartStyle) && chartStyle in ChartSeriesType:
            return ChartSeriesType[chartStyle as keyof typeof ChartSeriesType]
          case chartStyle && chartStyle in ChartSeriesType:
            return +chartStyle
          default:
            return Number(localStorage.getItem('tradingview.chart.lastUsedStyle') || 1)
        }
      })(),
      visibleRange: { from: 0, to: 0, timeScale: 0 },
    }
  }

  public render() {
    return (
      <div className="chart-wrapper">
        <div ref={this.chartSpinnerElementRef} id="tv_chart_spinner" className="chart-spinner">
          <div className="spinner">
            <Preloader />
          </div>
        </div>
        <div
          ref={this.chartContainerElementRef}
          id="tv_chart_container"
          className="chart-wrapper"
          style={{ opacity: '0' }}
        />
      </div>
    )
  }

  public componentDidMount() {
    if (get(JSON.parse(localStorage.getItem('tradingview.charts') || '{}'), '0.theme') !== this.props.appTheme) {
      localStorage.removeItem('tradingview.charts')
    }
    this.configurateChart()
  }

  public componentDidUpdate(prevProps: Props) {
    const { chartMode, appTheme, symbol, refreshChart, showSidebar } = this.props
    let shouldReconfigurateChart = false
    // only update chart if the data has changed
    if (prevProps.appTheme !== appTheme) {
      this.applyChartTheme(appTheme)
    }
    if (isEqual(prevProps.formValues, this.props.formValues)) {
      this.tvWidget.onChartReady(() => {
        this.chartPositions.clearPositions()
        this.chartPositions.displayPositions()
      })
    }
    if (prevProps.symbol !== symbol) {
      this.tvWidget.onChartReady(() => {
        this.updateVisibleRange()
        this.chart?.setSymbol(symbol)
      })
    } else if (prevProps.refreshChart !== refreshChart && refreshChart === true) {
      const resetted = this.chartDatafeed.resetCache()
      if (resetted) {
        this.chart?.resetData()
      } else {
        shouldReconfigurateChart = true
      }
    } else if (
      prevProps.chartMode !== chartMode ||
      (chartMode === 'open' && prevProps.positions !== this.props.positions) ||
      (chartMode === 'pending' && prevProps.pendingOrders !== this.props.pendingOrders) ||
      ((!isNaN(prevProps.selectedPosition as number) || !isNaN(this.props.selectedPosition as number)) &&
        prevProps.selectedPosition !== this.props.selectedPosition) ||
      ((!isNaN(prevProps.selectedOrder as number) || !isNaN(this.props.selectedOrder as number)) &&
        prevProps.selectedOrder !== this.props.selectedOrder) ||
      prevProps.positionExpanded !== this.props.positionExpanded ||
      prevProps.orderExpanded !== this.props.orderExpanded ||
      !isEqual(prevProps.formValues, this.props.formValues) ||
      prevProps.formOrderType !== this.props.formOrderType
    ) {
      this.chart && this.chartPositions.displayPositions()
    }
    if (prevProps.showSidebar !== showSidebar) {
      shouldReconfigurateChart = true
    }
    if (prevProps.visibleRange !== this.props.visibleRange) {
      this.updateResolutionVisibleRange()
    }

    if (this.chart && prevProps.askPrice !== this.props.askPrice) {
      this.refreshAskLine()
    }

    if (shouldReconfigurateChart) {
      this.reconfigurateChart()
    }
  }

  private refreshAskLine() {
    this.tvWidget.onChartReady(() => {
      try {
        if (this.askLineShapeId) {
          this.chart.removeEntity(this.askLineShapeId)
        }

        if (!!this.props.askPrice) {
          this.askLineShapeId = this.chart.createShape(
            {
              price: this.props.askPrice,
              time: Date.now(),
            },
            {
              disableSave: true,
              disableUndo: true,
              shape: 'horizontal_line',
              disableSelection: true,
              lock: true,
              overrides: {
                linecolor: 'rgb(81,163,154)',
                linestyle: 1,
                linewidth: 1,
              },
            },
          )
        }
      } catch (e) {
        console.info(`Error displaying Ask Line on chart: ${e.message}`)
      }
    })
  }

  private reconfigurateChart() {
    this.removeChart()
    this.configurateChart()
  }

  public componentWillUnmount() {
    this.removeChart()
    this.unsubscribeFromFirebaseEvents()
  }

  private unsubscribeFromFirebaseEvents() {
    this.unsubscribeFromIndicatorDeleteButtons()
    this.unsubscribeFromChartStyleChanges()
    this.unsubscribeOnChartTableClicks()
    this.unsubscribeOnIndicatorLegendWrap()
  }

  public updateVisibleRange() {
    const resolution = resolution2Seconds(this.state.resolution)
    const lastBarTime = this.chartDatafeed.lastHistoryBar.time / 1000
    const from = lastBarTime - 40 * resolution
    !this.props.signal && this.chart.setVisibleRange({ from, to: lastBarTime }, { percentRightMargin: 10 })
  }

  public updateResolutionVisibleRange() {
    try {
      if (this.chart && this.props.visibleRange) {
        const { interval } = this.props.visibleRange
        if (this.chart.resolution() !== interval) {
          this.chart.setResolution(interval as ResolutionString)
        }
      }
    } catch (e) {
      console.log(`Error updating chart resolution: ${e.message}`)
    }
  }

  private applyChartStyle(style?: SeriesType, interval?: string) {
    if (/^\d+S$/.test(this.chart.resolution())) {
      style = 2
    }

    this.chart.setChartType(style || this.state.chartStyle)
    this.setState((state) =>
      Object.assign(state, {
        chartStyle: style || this.chart.chartType(),
        ...(interval ? { resolution: interval } : {}),
      }),
    )
  }

  private applyChartTheme(theme: AppTheme) {
    try {
      this.tvWidget.changeTheme(theme, { disableUndo: true }).then(() => {
        const overridesProps =
          theme === 'Dark'
            ? {
                'paneProperties.background': '#000000',
                'scalesProperties.backgroundColor': '#000000',
                'paneProperties.vertGridProperties.color': '#1f1f1f',
                'paneProperties.horzGridProperties.color': '#1f1f1f',
                'mainSeriesProperties.priceLineColor': 'rgb(222,94,86)',
              }
            : {
                'paneProperties.background': '#ffffff',
                'scalesProperties.backgroundColor': '#ffffff',
                'paneProperties.vertGridProperties.color': '#f0f5f8',
                'paneProperties.horzGridProperties.color': '#f0f5f8',
                'mainSeriesProperties.priceLineColor': 'rgb(222,94,86)',
              }

        this.tvWidget.applyOverrides({
          ...overridesProps,
          'mainSeriesProperties.style': (/^\d+S$/.test(this.state.resolution)
            ? 2
            : this.chart?.chartType()) as ChartStyle,
          'paneProperties.backgroundType': 'solid',
        })
        this.saveChartProperties()
      })
    } catch (e) {
      console.log(`Error applying chart theme: ${e.message}`)
    }
  }

  private saveChartProperties(options: SaveChartToServerOptions = {}) {
    this.tvWidget.saveChartToServer(undefined, undefined, { chartName: 'default', ...options })
  }

  private subscribeOnIndicatorSettingsDialogOkButton(key: string) {
    const okButton: HTMLButtonElement = this.iframeElement?.contentWindow?.document?.querySelector(
      'div[data-name="indicator-properties-dialog"] button[name="ok"]',
    ) as HTMLButtonElement
    okButton.addEventListener(
      'click',
      () => {
        trackEvent(indicatorSettingsSaved, {
          key,
        })
      },
      { once: true },
    )
  }

  private subscribeOnChartStyleChanges() {
    const chartStyleButton = this.iframeElement?.contentWindow?.document?.getElementById('header-toolbar-chart-styles')
    if (chartStyleButton) {
      chartStyleButton.addEventListener('click', this.subscribeOnChartStyleButton)
    }
  }

  private unsubscribeFromChartStyleChanges() {
    const chartStyleButton = this.iframeElement?.contentWindow?.document?.getElementById('header-toolbar-chart-styles')
    if (chartStyleButton) {
      chartStyleButton.removeEventListener('click', this.subscribeOnChartStyleButton)
    }
  }

  private subscribeOnChartStyleButton = () => {
    const timeout = setTimeout(() => {
      const styleNodeList = this.iframeElement?.contentWindow?.document?.querySelectorAll(
        '#overlap-manager-root div[data-value]',
      )
      styleNodeList?.forEach(
        (node) => {
          node.addEventListener('click', (node) => {
            const target: HTMLDivElement = node?.target as HTMLDivElement
            const style = target?.dataset?.value || target.closest<HTMLDivElement>('div[data-value]')?.dataset.value
            trackEvent(chartStyleChanged, { style })
            this.subscribeOnIndicatorLegendWrap()
          })
        },
        { once: true },
      )
      clearTimeout(timeout)
    }, 1)
  }

  private unsubscribeFromIndicatorDeleteButtons() {
    this.deleteButtonElements.forEach((node) => {
      node.removeEventListener('click', this.emitRemoveEvent)
    })
    this.deleteButtonElements = []
  }

  private getAllIndicatorDeleteButtons() {
    if (this.deleteButtonElements) {
      this.unsubscribeFromIndicatorDeleteButtons()
    }

    const iframe = this.chartContainerElementRef.current?.getElementsByTagName('iframe')[0]
    const deleteButtonList = iframe?.contentWindow?.document?.querySelectorAll<HTMLDivElement>(
      '.pane-legend .study .pane-legend-icon-container .delete',
    )

    if (deleteButtonList) {
      this.deleteButtonElements = deleteButtonList
    }
  }

  private subscribeOnIndicatorDeleteButtons() {
    setTimeout(() => {
      this.getAllIndicatorDeleteButtons()

      if (this.deleteButtonElements) {
        this.deleteButtonElements.forEach((deleteButton) => {
          deleteButton?.addEventListener('click', this.emitRemoveEvent)
        })
      }
    }, 700)
  }

  private emitRemoveEvent = () => {
    const key = this.currentIndicatorName
    const allIndicators = this.chart.getAllStudies()
    trackEvent(indicatorRemoved, {
      'BDSwiss ID': store.getState().user.data.id,
      key,
      indicatorsAddedTotal: allIndicators.length,
      indicatorsAddedClone: this.quantitySameIndicators(allIndicators, key),
    })

    this.subscribeOnIndicatorLegendWrap()
    this.subscribeOnIndicatorDeleteButtons()
  }

  private getAllIndicatorLegendWrap() {
    if (this.indicatorLegendWrapElements) {
      this.unsubscribeOnIndicatorLegendWrap()
    }

    const indicatorLegendWrapList =
      this.iframeElement?.contentWindow?.document?.querySelectorAll<HTMLDivElement>('.pane-legend-wrap.study')

    if (indicatorLegendWrapList) {
      this.indicatorLegendWrapElements = indicatorLegendWrapList
    }
  }

  private subscribeOnIndicatorLegendWrap = () => {
    setTimeout(() => {
      this.getAllIndicatorLegendWrap()

      if (this.indicatorLegendWrapElements) {
        this.indicatorLegendWrapElements.forEach((indicatorLegendElement) => {
          indicatorLegendElement.addEventListener('mouseover', this.indicatorMouseOver)
          indicatorLegendElement.addEventListener('contextmenu', this.subscribeOnIndicatorContextMenu)
        })
      }
    }, 500)
  }

  private subscribeApplyDefaultChartSettings = () => {
    this.tvWidget.subscribe('edit_object_dialog', () => {
      const iframe = this.chartContainerElementRef.current?.getElementsByTagName('iframe')[0]?.contentDocument
      if (iframe) {
        const observer = new MutationObserver((mutations) => {
          for (const mutation of mutations) {
            for (const node of mutation.removedNodes) {
              ;(node as Element).getAttribute('data-outside-boundary-for') === 'series-properties-dialog' &&
                observer.disconnect()
            }
          }

          const applyDefaultsSettingsButton = iframe.querySelector<HTMLDivElement>(
            'div[data-name="series-theme-manager-apply-defaults"]',
          )

          if (applyDefaultsSettingsButton) {
            applyDefaultsSettingsButton.addEventListener('click', () => {
              observer.disconnect()
              this.applyChartTheme(this.props.appTheme)
            })
          }
        })

        observer.observe(iframe, {
          childList: true,
          subtree: true,
        })
      }
    })
  }

  private unsubscribeOnIndicatorLegendWrap = () => {
    this.indicatorLegendWrapElements.forEach((node) => {
      node.removeEventListener('mouseover', this.indicatorMouseOver)
      node.removeEventListener('contextmenu', this.subscribeOnIndicatorContextMenu)
    })
    this.indicatorLegendWrapElements = []
  }

  private indicatorMouseOver = (e: MouseEvent) => {
    const currentTarget = e.currentTarget as HTMLDivElement
    const indicatorName = currentTarget.querySelector('.pane-legend-title__description')?.textContent
    if (indicatorName) this.currentIndicatorName = Chart.normalizeIndicatorName(indicatorName)
  }

  private static normalizeIndicatorName(str: string): string {
    return str.split('(')[0]?.trim()
  }

  private quantitySameIndicators(allIndicators: EntityInfo[], key: string): number {
    return allIndicators.reduce((acc, currentIndicator) => {
      if (currentIndicator.name === key) {
        return acc + 1
      } else {
        return acc
      }
    }, 0)
  }

  private configurateChart() {
    const { timeframe, locale, onChangeSymbol, appTheme, symbol } = this.props
    const { themeBgColor, themeGridColor } =
      appTheme === 'Light'
        ? { themeBgColor: '#ffffff', themeGridColor: '#f0f5f8' }
        : { themeBgColor: '#000000', themeGridColor: '#555' }
    const chartConfig = {
      // debug: true, // uncomment this line to see Library errors and warnings in the console
      theme: appTheme,
      timezone: 'exchange' as Timezone,
      fullscreen: true,
      autosize: true,
      symbol,
      interval: this.state.resolution,
      container: 'tv_chart_container',
      datafeed: this.chartDatafeed,
      library_path: '/assets/charting_library/',
      locale: locale as LanguageCode,
      drawings_access: {
        type: 'black' as AccessList['type'],
        tools: [{ name: 'Regression Trend' }, { name: 'header_undo_redo' }],
      },
      disabled_features: [
        'save_chart_properties_to_local_storage',
        'use_localstorage_for_settings',
        'header_saveload',
        'header_compare',
        'header_symbol_search',
        'symbol_search_hot_key',
        ...(isMobile()
          ? ['header_screenshot', 'header_fullscreen_button', 'header_settings', 'edit_buttons_in_legend']
          : []),
        ...(this.props.showSidebar ? [] : ['left_toolbar']),
      ] as TradingTerminalFeatureset[],
      enabled_features: ['seconds_resolution'] as TradingTerminalFeatureset[],
      auto_save_delay: 1,
      load_last_chart: true,
      save_load_adapter: {
        charts: JSON.parse(localStorage.getItem('tradingview.charts') || '[]') as Array<
          ChartData & { timestamp: number; theme: string }
        >,
        chartTemplates: [] as Array<Record<string, unknown>>,
        studyTemplates: [] as Array<StudyTemplateData>,
        drawingTemplates: [] as Array<Record<string, unknown>>,
        getAllCharts: function () {
          return Promise.resolve(this.charts as unknown as Array<ChartMetaInfo>)
        },
        getChartContent: function (id: number) {
          return new Promise((resolve, reject) => {
            for (const chart of this.charts) {
              if (chart.id === id.toString()) {
                resolve(chart.content)
              }
            }

            reject('Error while chart content loading')
          })
        },
        saveChart: function (chartData: ChartData) {
          return new Promise((resolve) => {
            if (!chartData.id) {
              chartData.id = uuid()
            }

            this.charts = [
              {
                ...chartData,
                timestamp: Date.now(),
                theme: getCookie('theme') || 'Light',
              },
            ]
            localStorage.setItem('tradingview.charts', JSON.stringify(this.charts))
            resolve(chartData.id)
          })
        },
        removeChart: function () {
          return Promise.resolve()
        },
        async getAllChartTemplates() {
          return Promise.resolve(this.chartTemplates.map((template) => template.name))
        },
        async getChartTemplateContent(templateName: string) {
          const chartTemplate: ChartTemplate = {}
          const content = this.chartTemplates.find((template) => template.name === templateName)
            ?.content as ChartTemplateContent

          if (content) {
            chartTemplate.content = content
          }

          return Promise.resolve(chartTemplate)
        },
        async saveChartTemplate(templateName: string, content: ChartTemplateContent) {
          const chartTemplate = this.chartTemplates.find((template) => template.name === templateName)
          if (chartTemplate) {
            chartTemplate.content = content
          } else {
            this.chartTemplates.push({ name: templateName, content })
          }
        },
        async removeChartTemplate(templateName: string) {
          this.chartTemplates = this.chartTemplates.filter((template) => template.name !== templateName)
        },
        getAllStudyTemplates: function () {
          return Promise.resolve(this.studyTemplates)
        },
        getStudyTemplateContent: function (studyTemplateData: StudyTemplateMetaInfo) {
          for (const template of this.studyTemplates) {
            if (template.name === studyTemplateData.name) {
              return Promise.resolve(template?.content)
            }
          }

          return Promise.reject('Error while study template content loading')
        },
        saveStudyTemplate: function (studyTemplateData: StudyTemplateData) {
          for (const template of this.studyTemplates) {
            if (template.name === studyTemplateData.name) {
              this.studyTemplates.splice(this.studyTemplates.indexOf(template), 1)
              break
            }
          }

          this.studyTemplates.push(studyTemplateData)
          return Promise.resolve()
        },
        removeStudyTemplate: function (studyTemplateData: StudyTemplateMetaInfo) {
          for (const template of this.studyTemplates) {
            if (template.name === studyTemplateData.name) {
              this.studyTemplates.splice(this.studyTemplates.indexOf(template), 1)
              return Promise.resolve()
            }
          }

          return Promise.reject('Error removing the study template')
        },
        getDrawingTemplates: function () {
          return Promise.resolve(this.drawingTemplates.map((template) => template.name))
        },
        loadDrawingTemplate: function (toolName: string, templateName: string) {
          for (const template of this.drawingTemplates) {
            if (template.name === templateName) {
              return Promise.resolve(template.content)
            }
          }

          return Promise.reject('Error while drawing template loading')
        },
        saveDrawingTemplate: function (toolName: string, templateName: string, content: string) {
          for (const template of this.drawingTemplates) {
            if (template.name === templateName) {
              this.drawingTemplates.splice(this.drawingTemplates.indexOf(template), 1)
              break
            }
          }

          this.drawingTemplates.push({ name: templateName, content })
          return Promise.resolve()
        },
        removeDrawingTemplate: function (toolName: string, templateName: string) {
          for (const template of this.drawingTemplates) {
            if (template.name === templateName) {
              this.drawingTemplates.splice(this.drawingTemplates.indexOf(template), 1)
              return Promise.resolve()
            }
          }

          return Promise.reject('Error while drawing template removing')
        },
      },
      favorites: {
        intervals: ['1', '15', '30', '60', '240', '1D'] as ResolutionString[],
        chartTypes: ['Candles', 'Line'],
      },
      overrides: {
        'scalesProperties.showStudyLastValue': false,
        'paneProperties.background': themeBgColor,
        'scalesProperties.backgroundColor': themeBgColor,
        'paneProperties.vertGridProperties.color': themeGridColor,
        'paneProperties.horzGridProperties.color': themeGridColor,
        ...(isMobile() ? { 'scalesProperties.lineColor': 'rgba(234,234,234,1)' } : {}),
      },
      custom_css_url: '/assets/charting_library/css/custom.css',
      ...(/^\d+S$/.test(this.state.resolution) ? { timeframe } : {}),
    }
    try {
      const widget = (this.tvWidget = new window.TradingView.widget(chartConfig as ChartingLibraryWidgetOptions))

      if (isMobile()) {
        widget.headerReady().then(function () {
          const button = widget.createButton({ align: 'right', useTradingViewStyle: false })
          const shape = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
          shape.setAttribute('width', '14')
          shape.setAttribute('height', '14')
          shape.setAttribute('viewBox', '0 0 14 14')
          shape.setAttribute('fill', 'none')
          shape.innerHTML = fullscreenIconContent
          button.appendChild(shape)

          button.addEventListener('click', function () {
            store.dispatch(toggleFullscreen())
          })
        })
      }

      widget.onChartReady(() => {
        this.iframeElement = this.chartContainerElementRef.current?.getElementsByTagName(
          'iframe',
        )[0] as HTMLIFrameElement

        if (this.chartSpinnerElementRef.current) {
          this.chartSpinnerElementRef.current.style.display = 'none'
        }

        if (this.chartContainerElementRef.current) {
          this.chartContainerElementRef.current.style.opacity = '1'
        }

        this.chart = widget.activeChart()
        this.updateVisibleRange()
        const symbolInfo = this.chartDatafeed.symbolsStorage.symbolsInfo[this.props.symbol]
        symbolInfo && this.chartSignal.initChart(this.tvWidget, symbolInfo)

        this.chartPositions.initChart(this.chart)
        this.chart.onIntervalChanged().subscribe(null, (interval: string) => {
          trackEvent(chartTimeframeChanged, { value: interval })
          this.subscribeOnIndicatorDeleteButtons()
          this.subscribeOnIndicatorLegendWrap()
          this.applyChartStyle(this.chart.chartType(), interval)
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(this.chart.onSymbolChanged().subscribe as any)(null, (symbolInfo: any) => {
          if (this.chart && /^\d+S$/.test(this.chart.resolution())) this.configurateChart()
          if (this.props.symbol !== symbolInfo.name) {
            if (this.props.signal === null) {
              onChangeSymbol(symbolInfo.name)
            }
          }
        })
        this.chart.onDataLoaded().subscribe(null, () => {
          this.chartSignal.dataSubject.next({
            symbol: this.props.symbol,
            resolution: this.chart.resolution(),
          })
        })

        if (!localStorage.getItem('tradingview.charts')) {
          this.applyChartTheme(this.props.appTheme)
        }

        this.applyChartStyle(this.state.chartStyle)
        this.chartPositions.displayPositions()

        this.tvWidget.subscribe('indicators_dialog', () => {
          trackEvent(indicatorsButtonClicked, {
            MemberID: store.getState().user.data.id,
          })
        })

        this.tvWidget.subscribe('edit_object_dialog', (params) => {
          if (params.objectType === 'study') {
            const key = Chart.normalizeIndicatorName(params.scriptTitle)
            this.subscribeOnIndicatorSettingsDialogOkButton(key)
          }
        })

        this.tvWidget.subscribe('study', (indicatorInfo) => {
          const key = indicatorInfo.value
          const allIndicators = this.chart.getAllStudies()
          trackEvent(indicatorAdded, {
            MemberID: store.getState().user.data.id,
            key,
            indicatorsAddedTotal: allIndicators.length + 1,
            indicatorsAddedClone: this.quantitySameIndicators(allIndicators, key),
          })

          this.subscribeOnIndicatorDeleteButtons()
          this.subscribeOnIndicatorLegendWrap()
        })

        // Save chart properties on click Remove Drawings item in context menu
        this.tvWidget.subscribe('drawing_event', () => {
          this.saveChartProperties()
        })

        this.tvWidget.subscribe('onAutoSaveNeeded', () => {
          this.saveChartProperties()
        })

        this.subscribeOnIndicatorDeleteButtons()
        this.subscribeOnIndicatorLegendWrap()
        this.subscribeOnChartStyleChanges()
        this.subscribeOnChartTableClicks()
        this.subscribeOnIndicatorLegendWrap()
        this.subscribeApplyDefaultChartSettings()
      })
    } catch (e) {
      console.log(`Chart initialization error: ${e.message}`)
    }
  }

  private subscribeOnChartTableClicks() {
    const chartTable = this.iframeElement?.contentWindow?.document?.querySelector<HTMLTableElement>(
      '.chart-markup-table',
    ) as HTMLTableElement
    if (chartTable) {
      chartTable.addEventListener('click', this.chartTableClickActions)
    }
  }

  private unsubscribeOnChartTableClicks() {
    const chartTable = this.iframeElement?.contentWindow?.document?.querySelector<HTMLTableElement>(
      '.chart-markup-table',
    ) as HTMLTableElement
    if (chartTable) {
      chartTable.removeEventListener('click', this.chartTableClickActions)
    }
  }

  private chartTableClickActions = () => {
    this.subscribeOnIndicatorContextMenu()
    this.subscribeOnIndicatorDeleteButtons()
    this.subscribeOnIndicatorLegendWrap()
  }

  private subscribeOnIndicatorContextMenu = () => {
    const timeout = setTimeout(() => {
      const contextMenu = this.iframeElement?.contentWindow?.document?.querySelector<HTMLTableElement>(
        '.context-menu-wrapper',
      ) as HTMLTableElement

      if (contextMenu) {
        contextMenu.addEventListener(
          'click',
          () => {
            this.subscribeOnIndicatorDeleteButtons()
            this.subscribeOnIndicatorLegendWrap()
          },
          { once: true },
        )
      }

      const contextmenuButtonList = contextMenu?.querySelectorAll('tbody > tr[class*="interactive"]')
      const deleteButton = contextmenuButtonList?.length
        ? contextmenuButtonList[contextmenuButtonList.length - 2]
        : null

      if (deleteButton && deleteButton.lastElementChild?.lastElementChild?.lastElementChild?.textContent === 'Del') {
        deleteButton.addEventListener('click', this.emitRemoveEvent, { once: true })
      }

      clearTimeout(timeout)
    }, 500)
  }

  private removeChart() {
    try {
      this.tvWidget && this.tvWidget.remove()
    } catch (e) {
      console.log(`Error removing chart widget: ${e.message}`)
    }
  }
}

const TradingChartContainer: FC = () => {
  const dispatch = useDispatch()
  const activeAsset = useSelector(activeAssetSelector)
  const prices = useSelector(pricesSelector)
  const userLocale = useSelector(userLocaleSelector)
  const formValues = useSelector(formValuesSelector)
  const overrideParams = useSelector(overrideParamsSelector)
  const timeframe = '1D'
  const locale = chartLocalesMap[userLocale] || userLocale
  const chartMode = useSelector(chartModeSelector)
  const orderType = useSelector(formOrderTypeSelector)
  const isFullscreen = useSelector(isFullscreenSelector)
  const signal = useSelector(getActiveSignal)
  const currentAssetPrice = useSelector(currentSymbolPriceSelector)
  const theme = useTheme()

  const changeSymbolHandle = useCallback(
    (symbol: string) => {
      dispatch(appChangeActiveAsset(symbol))
    },
    [dispatch],
  )

  if (!activeAsset || !prices) {
    return null
  }

  return (
    <Chart
      symbol={activeAsset}
      assets={prices}
      overrideParams={overrideParams}
      timeframe={timeframe}
      locale={locale}
      chartMode={chartMode}
      appTheme={theme.palette.mode === 'light' ? 'Light' : 'Dark'}
      positions={[]}
      pendingOrders={[]}
      onChangeSymbol={changeSymbolHandle}
      formValues={formValues}
      formOrderType={orderType}
      showSidebar={!isMobile() || isFullscreen}
      signal={signal}
      askPrice={currentAssetPrice.mask}
    />
  )
}

export default TradingChartContainer
