import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { currentSymbolDigitsSelector, formStopLossSelector } from '../../../../store/selectors'
import { NumberInput } from '../NumberInput'
import { numberFormat } from '../../../../utils'

const StopLossInput: FC = () => {
  const { t } = useTranslation()
  const precision = useSelector(currentSymbolDigitsSelector)
  const { value, fieldInformation } = useSelector(formStopLossSelector)

  const handleValue = useMemo(() => {
    const numberValue = numberFormat(value, {
      maximumFractionDigits: precision,
    })
    return numberValue ? numberValue.toString() : ''
  }, [precision, value])

  if (!precision) {
    return null
  }

  return (
    <NumberInput
      name={'stopLoss'}
      placeholder={t(`stopLoss`, 'Stop loss')}
      value={handleValue}
      fieldInformation={fieldInformation}
    />
  )
}

export default StopLossInput
