import React from 'react'
import { CellProps, Renderer } from 'react-table'

import { TradeObject } from '@bdswiss/mt4-connector'

import { currencyVolumeFormat } from '../../../utils'

export const OpenPriceCell: Renderer<CellProps<TradeObject, TradeObject['volume']>> = ({
  value,
  row: {
    original: { digits },
  },
}) => {
  const openPrice = currencyVolumeFormat(value, '', { minimumFractionDigits: digits })
  return <div title={openPrice}>{openPrice}</div>
}
