import React, { FC } from 'react'
import { styled } from '@mui/system'
import { ReactComponent as BentoMenuIcon } from './assets/bentoMenu.svg'

const BentoMenuButtonWrap = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const BentoMenuButton: FC<{
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}> = (props) => {
  return (
    <BentoMenuButtonWrap {...props}>
      <BentoMenuIcon />
    </BentoMenuButtonWrap>
  )
}

export default BentoMenuButton
