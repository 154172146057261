import React, { FC } from 'react'
import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { getCompanyLogo } from '../../utils'

const useStyles = makeStyles(() => ({
  headerLogo: {
    height: 24,
  },
  spinnerLogo: {
    width: 200,
    paddingBottom: 14,
  },
  swissmarketsHeaderLogo: {
    width: 200,
    height: 55,
  },
  vivernoHeaderLogo: {
    height: 45,
  },
  klarinvestLogo: {
    width: 200,
    marginBottom: -40,
  },
  klarinvestHeaderLogo: {
    height: 80,
  },
}))

export const Logo: FC<{ variant: 'header' | 'loader' }> = ({ variant }) => {
  const classes = useStyles()
  const theme = useTheme()
  const logoProps = getCompanyLogo(variant, theme, classes)

  return <img className={logoProps.style} alt={'Logo'} src={logoProps.src} />
}
