import { isActionOf } from 'typesafe-actions'
import { from, of } from 'rxjs'
import { catchError, exhaustMap, filter, map } from 'rxjs/operators'

import { RootEpic } from '../types'
import { Location } from '../../enums'
import { history } from '../../utils'
import {
  getUserFailure,
  getUserRequest,
  getUserSuccess,
  updateThemePreferenceFailure,
  updateThemePreferenceRequest,
  updateThemePreferenceSuccess,
} from '../actions'

export const getUserEpic: RootEpic = (action$, store, { graphqlClient: { getUser }, bugsnag }) => {
  return action$.pipe(
    filter(isActionOf([getUserRequest])),
    exhaustMap(() =>
      from(getUser()).pipe(
        map((response) => {
          if (window.location.pathname === '/') {
            const account = response.data?.viewer?.accounts?.find((account) => !account?.hidden && !account?.isArchived)
            if (account?.login) {
              history.push(`/forex/${account.login}/${Location.trade}`)
            } else {
              // TODO: Add a toast message
              console.warn('No available account found for trade.')
            }
          }

          return getUserSuccess(response.data.viewer)
        }),
        catchError((error) => {
          bugsnag.notify(error)
          return of(getUserFailure(error))
        }),
      ),
    ),
  )
}

export const updateThemePreferenceEpic: RootEpic = (
  action$,
  store$,
  { graphqlClient: { updateThemePreference }, bugsnag },
) => {
  return action$.pipe(
    filter(isActionOf(updateThemePreferenceRequest)),
    exhaustMap(({ payload }) =>
      from(
        updateThemePreference({
          themePreference: payload,
        }),
      ).pipe(
        map(() => updateThemePreferenceSuccess(payload)),
        catchError((error) => {
          bugsnag.notify(error)
          return of(updateThemePreferenceFailure(error))
        }),
      ),
    ),
  )
}

export default [getUserEpic, updateThemePreferenceEpic]
