import React, { FC, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { isArray } from 'lodash'
import { useTranslation } from 'react-i18next'

import { RootPage } from '../../pages/menuBarList'
import {
  toggleHistoryPanel,
  toggleOpenPosPanel,
  togglePendingPanel,
  toggleTradePanel,
  changeLocation,
  intentOpenGuidedTour,
} from '../../../store/actions'
import { historyTabSelector, guidedTourRunSelector } from '../../../store/selectors'
import { MenuButtonCounter } from '../MenuButtonCounter'

interface MenuButtonProps {
  Icon: FC
  name: string
  path: RootPage['path']
  counter?: number
}

interface StyleProps {
  active: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  btnStyle: {
    display: 'block',
    color: ({ active }) => (!active ? 'hsla(0, 0%, 100%, .7)' : 'inherit'),
    backgroundColor: ({ active }) => (active ? theme.palette.action.selected : 'inherit'),
    fontSize: '.7857rem',
    textTransform: 'none',
    lineHeight: '.9286rem',
    width: 80,
    minHeight: 80,
    fontWeight: 400,
    letterSpacing: '-0.5px',
    overflow: 'hidden',
    padding: 4,
    borderRadius: 'unset',
    borderRight: `1px solid ${theme.palette.action.selected}`,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.04)',
    },
    '& svg': {
      width: '100%',
      maxWidth: 25,
      height: 'auto',
      fill: theme.palette.mode === 'light' ? '#000' : '#fff',
      opacity: ({ active }) => (active ? 1 : 0.4),
    },
  },
  title: {
    marginTop: 7,
    fontSize: '.775rem',
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    opacity: ({ active }) => (active ? 1 : 0.4),
  },
}))

export const MenuButton: FC<MenuButtonProps> = ({ Icon, name, path, counter, ...props }: MenuButtonProps) => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const active = !!useRouteMatch(isArray(path) ? [] : `${url}${path}`)
  const classes = useStyles({ active })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const locationPath = location.pathname
  const historySelector = useSelector(historyTabSelector)
  const guidedTour = useSelector(guidedTourRunSelector)

  return (
    <Button
      className={classes.btnStyle}
      title={t(name)}
      onClick={useCallback(() => {
        if (!path && !guidedTour) dispatch(intentOpenGuidedTour())
        if (locationPath === url + path) {
          if (path === '/trade') dispatch(toggleTradePanel())
          if (path === '/positions') dispatch(toggleOpenPosPanel())
          if (path === '/order') dispatch(togglePendingPanel())
          if (!historySelector && path === '/history') dispatch(toggleHistoryPanel())
        } else {
          path && dispatch(changeLocation(isArray(path) ? path[0] : path, { prev: locationPath }))
          path && history.push(isArray(path) ? path[0] : `${url}${path}`)
        }
      }, [locationPath, url, path, dispatch, historySelector, guidedTour, history])}
      {...props}
    >
      <Icon />
      <div className={classes.title}>{t(name)}</div>
      {!!counter && <MenuButtonCounter counter={counter} />}
    </Button>
  )
}
