import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import makeStyles from '@mui/styles/makeStyles'
import CallMadeIcon from '@mui/icons-material/CallMade'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import clsx from 'clsx'

import { isMobile } from '../../../../../utils'

const useStyles = makeStyles(({ palette }) => ({
  container: {
    lineHeight: 1,
    marginBottom: 6.5,
  },
  name: {
    color: palette.mode === 'light' ? '#5C5C5C' : '#fff',
    fontSize: !isMobile() ? 12 : 14,
    lineHeight: !isMobile() ? 1.35 : '20px',
    ...(isMobile() && {
      color: palette.mode === 'light' ? '#858585' : '#fff',
    }),
  },
  bold: {
    textTransform: 'none',
    fontSize: !isMobile() ? 12 : 14,
    lineHeight: !isMobile() ? 1.35 : '20px',
    fontWeight: !isMobile() ? 700 : 500,
  },
  positive: { color: !isMobile() ? '#4caf50' : palette.mode === 'light' ? '#009469' : '#00C78D' },
  negative: { color: !isMobile() ? '#dc3545' : palette.mode === 'light' ? '#ED1C24' : '#FC4950' },
  arrowIcon: {
    fontSize: !isMobile() ? 14 : 16,
    paddingBottom: 1,
    ...(isMobile() && { marginRight: 4 }),
  },
}))

interface InfoItemProps {
  name: string
  value: string | number
}

export const InfoItem: FC<InfoItemProps> = ({ name, value }) => {
  const classes = useStyles()
  return (
    <Grid item container className={classes.container} justifyContent={'space-between'} wrap={'nowrap'}>
      <Grid item xs={8}>
        <Typography className={classes.name} display={'inline'} variant={'caption'}>
          <Trans i18nKey={name} />
        </Typography>
      </Grid>

      <Grid item container justifyContent={'flex-end'} alignItems={'center'}>
        {value === 'buy' ? (
          <CallMadeIcon className={clsx(classes.bold, classes.arrowIcon, classes.positive)} />
        ) : value === 'sell' ? (
          <CallReceivedIcon className={clsx(classes.bold, classes.arrowIcon, classes.negative)} />
        ) : null}

        <Typography
          className={clsx(
            {
              [classes.negative]: value === 'sell' || (name === 'profit' && /-/.test(value.toString())),
              [classes.positive]: value === 'buy' || (name === 'profit' && !/-/.test(value.toString())),
            },
            classes.bold,
          )}
          display={'inline'}
          variant={'caption'}
        >
          {name === 'direction' ? <Trans i18nKey={value.toString()} /> : value}
        </Typography>
      </Grid>
    </Grid>
  )
}
