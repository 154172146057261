/* eslint-disable  @typescript-eslint/no-explicit-any */
import moment, { Moment } from 'moment-timezone'
import { Subject, EMPTY, combineLatest } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { filter, switchMap } from 'rxjs/operators'

import { Signal } from '@bdswiss/mt4-connector'

import { IChartingLibraryWidget, IChartWidgetApi, Nominal, CreateShapeOptions, PricedPoint } from '../types'
import { LibrarySymbolInfo } from '../types/datafeed-api'
import { serverTimeZone, SymbolSessions, convertSymbolSessions, convertResolution, resolution2Seconds } from '../utils'
import { CandlesData } from '../types'
import { RootEpic } from '../../../store/types'
import { clearActiveSignal, fulfillCandlesData, setActiveSignal } from '../../../store/actions'
import { getActiveSignal } from '../../../store/selectors'

export interface IChartSignal {
  initChart(widget: IChartingLibraryWidget, symbolInfo: LibrarySymbolInfo): void
  displaySignal(signal: Signal, candles: CandlesData): void
  clearSignal(): void
}

// workaround type for charting_library.d.ts
// the type is incomplete and in fact supports other shapes like 'arrow' and 'rectangle' used here
type ShapeType = 'arrow_up' | 'arrow_down' | 'flag' | 'vertical_line' | 'horizontal_line'

let chartSignal: ChartSignal

export const chartSignalEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([setActiveSignal])),
    switchMap(() => {
      // console.log(`SET_TRADING_SIGNAL: ${signal}`)
      const signal = getActiveSignal(state$.value)
      signal && chartSignal.displaySignal({ ...signal, symbol: state$.value.app.activeAsset })
      return EMPTY
    }),
  )

export const clearSignalEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([clearActiveSignal])),
    switchMap(() => {
      chartSignal?.clearSignal()
      return EMPTY
    }),
  )

export const chartCandlesEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([fulfillCandlesData])),
    switchMap(({ payload: { symbol, resolution, bars } }) => {
      if (
        !(chartSignal.signal && chartSignal.dataKeyString === `${chartSignal.signal.id}/${symbol}/${resolution / 60}`)
      ) {
        chartSignal.candles = { symbol, resolution, bars }
        chartSignal.candlesSubject.next({ symbol, resolution })
      }
      return EMPTY
    }),
  )

export const tradingViewSignalsEpics = [chartSignalEpic, clearSignalEpic, chartCandlesEpic]

export class ChartSignal implements IChartSignal {
  private widget!: IChartingLibraryWidget
  private chart!: IChartWidgetApi
  private symbolSessions: SymbolSessions = new Map()
  private signalShapes: Map<string, { id: Nominal<string, 'EntityId'>; shape: any }> = new Map()
  private baseShapeOptions: CreateShapeOptions<any> = {
    zOrder: 'top',
    lock: true,
    disableSelection: true,
    disableSave: true,
    disableUndo: true,
    showInObjectsTree: false,
  }
  private timePaddingCandles = 5
  private symbolInfo?: LibrarySymbolInfo
  private symbolSubject = new Subject<string>()
  private resolutionSubject = new Subject<string>()
  private signalSubject = new Subject<any>()
  public candlesSubject = new Subject<Pick<CandlesData, 'resolution' | 'symbol'>>()
  public dataSubject = new Subject<{ symbol: string; resolution: string }>()
  public signal?: any
  public candles?: CandlesData
  public dataKeyString = ''
  private prevType = 1

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    chartSignal = this
    combineLatest([
      this.symbolSubject, //.do(symbol => console.log(`symbolSubject: ${symbol}`)),
      this.resolutionSubject, //.do(resolution => console.log(`resolutionSubject: ${resolution}`)),
      this.candlesSubject, //.do(candles => console.log(`candlesSubject: ${candles.symbol}/${candles.resolution / 60}`)),
      this.signalSubject, //.do(signal => console.log(`signalSubject: ${signal.symbol}${signal.resolution}`)),
      this.dataSubject, //.do(data => console.log(`dataSubject: ${data.symbol}/${data.resolution}`)),
    ]).subscribe(([symbol, resolution, candles, signal]) => {
      // console.log(`DATA OBSERVE:
      //   CHART - ${symbol}/${resolution},
      //   CANDLES - ${(candles as any).symbol}/${candles.resolution}
      //   SIGNAL - ${(signal as any).symbol}/${signal.resolution}
      //   DATA - ${(data as any).symbol}/${data.resolution}
      // `)
      if (this.signal) {
        // console.log(`DATA READY CHECK - ${this.dataKeyString}`)
        const candlesResolution = convertResolution(candles.resolution / 60)
        const signalResolution = convertResolution(signal.resolution)
        const resolutionsMatch = resolution === candlesResolution && resolution === signalResolution
        const symbolsMatch = symbol === candles.symbol && symbol === signal.symbol
        if (symbolsMatch && resolutionsMatch) {
          // console.log('DRAW')
          const timeout = setTimeout(() => {
            this._drawSignal()
            clearTimeout(timeout)
          }, 0)
        } else {
          this.clearSignal()
        }
      }
    })
  }

  public initChart(widget: IChartingLibraryWidget, symbolInfo: LibrarySymbolInfo): void {
    this.widget = widget
    this._setSymbol(symbolInfo)
    this.chart = this.widget.activeChart()
    this.resolutionSubject.next(this.chart.resolution())
    ;(this.chart.onSymbolChanged().subscribe as any)(null, (symbolInfo: any) => {
      // console.log(`CHART:onSymbolChanged - ${JSON.stringify(symbolInfo.ticker)}`)
      this._setSymbol(symbolInfo)
    })
    this.chart.onIntervalChanged().subscribe(null, (interval: any) => {
      // console.log(`CHART:onIntervalChanged - ${JSON.stringify(interval)}`)
      this.resolutionSubject.next(interval)
    })
  }

  public displaySignal(signal: Signal): void {
    const resolution = this.chart?.resolution()
    this.prevType = this.chart?.chartType() || this.prevType
    this.signal = { ...signal }
    // console.log(JSON.stringify(this.signal, undefined, 2))
    const signalResolution = convertResolution(signal.resolution)
    if (resolution !== signalResolution) {
      this.widget.onChartReady(() => {
        this.chart.setResolution(signalResolution, () => {
          const timeout = setTimeout(() => {
            this.signalSubject.next(this.signal)
            clearTimeout(timeout)
          }, 0)
        })
      })
    } else {
      this.signalSubject.next(this.signal)
    }
  }

  public clearSignal(): void {
    for (const shape of this.signalShapes.values()) {
      // console.log('CLEAR ', shape.id)
      this.chart.removeEntity(shape.id)
    }
    // this.chart.removeAllShapes()
    this.signalShapes.clear()
  }

  private _setSymbol(symbolInfo: LibrarySymbolInfo) {
    // console.log('SET SYMBOL')
    if (this.symbolInfo && this.symbolInfo.ticker === symbolInfo.ticker) return
    this.symbolInfo = symbolInfo
    this.symbolSessions = convertSymbolSessions(symbolInfo)
    this.symbolSubject.next(symbolInfo.ticker)
  }

  private _drawSignal() {
    const type = this.chart.chartType()
    // console.log('RAW INPUT SIGNAL', JSON.stringify(this.signal, undefined, 2))
    // console.log(
    //   'INPUT SIGNAL',
    //   JSON.stringify(
    //     mapValues(this.signal, (val, key) =>
    //       // prettier-ignore
    //       key === 'annotations'
    //         ? val.map((a: any) => ({line: mapValues(a.line, (v, k) => (['x0', 'x1'].includes(k) ? moment(Number(v)) : v))}))
    //         : ['patternStartTime', 'patternEndTime', 'predictionTimeTo'].includes(key) ? moment(Number(val)) : val,
    //     ),
    //     undefined,
    //     2,
    //   ),
    // )
    this.clearSignal()
    const { patternStartTime, patternEndTime, resolution, predictionTimeTo, symbol, id } = this.signal
    this.chart.setChartType(type)
    this.prevType = type
    const patternStartTimeTZ = moment(Number(patternStartTime)).tz(serverTimeZone)
    const patternEndTimeTZ = moment(Number(patternEndTime)).tz(serverTimeZone)
    const graphBars = this._getDurationInBars(patternStartTimeTZ, patternEndTimeTZ)
    let predictionTimeToTZ = this._extrapolateBarFromTimeByCount(patternEndTimeTZ, Math.ceil(graphBars / 2))
    if (Number(predictionTimeTo)) {
      const predictionTime = this._extrapolateBarForTime(moment(Number(predictionTimeTo)).tz(serverTimeZone))
      if (predictionTime < predictionTimeToTZ) predictionTimeToTZ = predictionTime
    }

    this.signal = {
      ...this.signal,
      patternStartTime: patternStartTimeTZ,
      patternEndTime: patternEndTimeTZ,
      predictionTimeTo: predictionTimeToTZ,
    }
    // console.log('RESULTING SIGNAL', JSON.stringify(this.signal, undefined, 2))
    'pattern' in this.signal && this._drawChartPattern()
    'keylevel' in this.signal && this._drawKeyLevel()
    const dataKeyString = `${id}/${symbol}/${resolution}`
    // console.log('SIGNAL KEYS', `${id}/${this.dataKeyString}/${dataKeyString}`)
    this._setChartVisibleTimeRange()
    this._setChartVisiblePriceRange()
    this.dataKeyString = dataKeyString
  }

  private _drawChartPattern() {
    // console.log('DRAW CHART PATTERN')
    const shapeOptions = {
      ...this.baseShapeOptions,
      shape: 'trend_line' as ShapeType,
      overrides: {
        linecolor: '#007AFF',
        linewidth: 2, // 1-4
      },
    }
    const { annotations, patternEndTime, resolution } = this.signal
    const chart = this.widget.activeChart()
    // SUPPORT
    annotations
      .filter((a: any) => a.line.type === 'SUPPORT' && !a.line.forecast)
      .forEach((s: any) => {
        const x0 = moment(Number(s.line.x0)).tz(serverTimeZone),
          x1 = moment(Number(s.line.x1)).tz(serverTimeZone)

        // console.log(
        //   `x0: ${s.line.x0}/${moment(
        //     Number(s.line.x0)
        //   ).toISOString()}/${x0.toISOString()}/${x0.toLocaleString()}`
        // )
        // console.log(
        //   `x1: ${s.line.x1}/${moment(
        //     Number(s.line.x1)
        //   ).toISOString()}/${x1.toISOString()}/${x1.toLocaleString()}`
        // )
        let lineShapeId = chart.createMultipointShape(
          [
            { time: x0.unix(), channel: 'low' },
            { time: x1.unix(), channel: 'low' },
          ],
          shapeOptions,
        )
        if (lineShapeId) {
          const linePoints = chart.getShapeById(lineShapeId).getPoints()
          // console.log('SUPPORT_LINE', JSON.stringify(linePoints))
          if (x1 < patternEndTime) {
            chart.removeEntity(lineShapeId)
            lineShapeId = chart.createMultipointShape(
              [{ time: x0.unix(), channel: 'low' }, this._getLinePointAtTime(linePoints, patternEndTime)],
              shapeOptions,
            )
          }

          if (lineShapeId) {
            const supportShape = chart.getShapeById(lineShapeId)
            // console.log('SUPPORT_TREND', JSON.stringify(supportShape.getPoints()))
            this.signalShapes.set('support', {
              id: lineShapeId,
              shape: supportShape,
            })
          }
        }
      })
    // RESISTANCE
    annotations
      .filter((a: any) => a.line.type === 'RESISTANCE' && !a.line.forecast)
      .forEach((r: any) => {
        let x0: Moment, x1: Moment
        if (resolution >= 1440) {
          x0 = this._extrapolateBarForTime(
            moment(Number(r.line.x0)).tz(serverTimeZone),
            // .add(userOffsetMinutes, 'minutes'),
            true,
          )
          x1 = this._extrapolateBarForTime(
            moment(Number(r.line.x1)).tz(serverTimeZone),
            // .add(userOffsetMinutes, 'minutes'),
            true,
          )
        } else {
          x0 = this._extrapolateBarForTime(moment(Number(r.line.x0)).tz(serverTimeZone))
          x1 = this._extrapolateBarForTime(moment(Number(r.line.x1)).tz(serverTimeZone))
        }
        // console.log(
        //   `x0: ${r.line.x0}/${moment(
        //     Number(r.line.x0)
        //   ).toISOString()}/${x0.toISOString()}/${x0.toLocaleString()}`
        // )
        // console.log(
        //   `x1: ${r.line.x1}/${moment(
        //     Number(r.line.x1)
        //   ).toISOString()}/${x1.toISOString()}/${x1.toLocaleString()}`
        // )
        let lineShapeId = chart.createMultipointShape(
          [
            { time: x0.unix(), channel: 'high' },
            { time: x1.unix(), channel: 'high' },
          ],
          shapeOptions,
        )
        if (lineShapeId) {
          const linePoints = chart.getShapeById(lineShapeId).getPoints()
          // console.log('RESISTANCE_LINE', JSON.stringify(linePoints))
          if (x1 < patternEndTime) {
            chart.removeEntity(lineShapeId)
            lineShapeId = chart.createMultipointShape(
              [{ time: x0.unix(), channel: 'high' }, this._getLinePointAtTime(linePoints, patternEndTime)],
              shapeOptions,
            )
          }

          if (lineShapeId) {
            const resistanceShape = chart.getShapeById(lineShapeId)
            // console.log('RESISTANCE_TREND', JSON.stringify(resistanceShape.getPoints()))
            this.signalShapes.set('resistance', {
              id: lineShapeId,
              shape: resistanceShape,
            })
          }
        }
      })
    // PATTERN END
    this._drawPatternEndLine()
    // PREDICTION
    this._drawPrediction()
    // console.log('SHAPES', JSON.stringify(Array.from(this.signalShapes.entries()).map(([k,v])=>[k, v.id])))
  }

  private _drawKeyLevel() {
    // console.log('DRAW KEY LEVEL')
    const chart = this.widget.activeChart()
    const {
      keylevel: { type },
      annotations,
      direction,
      patternEndTime,
      resolution,
    } = this.signal
    // PRICE LINE
    annotations
      .filter((a: any) => a.line.type === type && !a.line.forecast)
      .forEach((p: any) => {
        let x0: Moment /* , x1: Moment */
        if (resolution >= 1440) {
          x0 = this._extrapolateBarForTime(
            moment(Number(p.line.x0)).tz(serverTimeZone),
            // .add(userOffsetMinutes, 'minutes'),
            true,
          )
        } else {
          x0 = this._extrapolateBarForTime(moment(Number(p.line.x0)).tz(serverTimeZone))
        }
        // console.log(
        //   `x0: ${p.line.x0}/${x0.unix()}/${moment(
        //     Number(p.line.x0)
        //   ).toISOString()}/${x0.toISOString()}/${x0.toLocaleString()}`
        // )
        let lineShapeId = chart.createMultipointShape(
          [{ time: x0.unix(), channel: direction === 'UP' ? 'high' : 'low' }],
          { ...this.baseShapeOptions, shape: 'horizontal_line' },
        )
        if (lineShapeId) {
          const priceLevelShape = chart.getShapeById(lineShapeId)
          // console.log('KEY_LEVEL_PRICE', JSON.stringify(priceLevelShape.getPoints()))
          const chartKeyLevelPrice = priceLevelShape.getPoints()[0].price
          this.signalShapes.set('price_level', { id: lineShapeId, shape: priceLevelShape })
          lineShapeId = chart.createMultipointShape(
            [
              { time: x0.unix(), price: chartKeyLevelPrice },
              { time: patternEndTime.unix(), price: chartKeyLevelPrice },
            ],
            {
              ...this.baseShapeOptions,
              shape: 'trend_line' as ShapeType,
              overrides: {
                linecolor: direction === 'UP' ? '#00FF00' : '#FF0000', // '#007AFF',
                linewidth: 2, // 1-4
                showPriceRange: true,
              },
            },
          )

          if (lineShapeId) {
            const lineShape = chart.getShapeById(lineShapeId)
            // console.log('KEY_LEVEL_TREND', JSON.stringify(lineShape.getPoints()))
            this.signalShapes.set(type, { id: lineShapeId, shape: lineShape })
          }
        }
      })
    // PATTERN END
    this._drawPatternEndLine()
    // PREDICTION
    this._drawPrediction()
    // console.log('SHAPES', JSON.stringify(Array.from(this.signalShapes.entries()).map(([k,v])=>[k, v.id])))
  }

  private _drawPatternEndLine() {
    const { patternEndTime } = this.signal
    const chart = this.widget.activeChart()
    const signalShapeId = chart.createMultipointShape([{ time: patternEndTime.unix() }], {
      ...this.baseShapeOptions,
      shape: 'vertical_line',
      overrides: {
        linecolor: '#007AFF',
        linewidth: 2, // 1-4
        linestyle: 1, // 0 (solid), 1 (dotted), 2 (dashed), 3 (large dashed)
      },
    })

    signalShapeId &&
      this.signalShapes.set('pattern_end', {
        id: signalShapeId,
        shape: chart.getShapeById(signalShapeId),
      })
  }

  private _drawPrediction() {
    const predictionLineOptions = {
      ...this.baseShapeOptions,
      shape: 'trend_line' as ShapeType,
      overrides: {
        linecolor: '#007AFF',
        linewidth: 2, // 1-4
        linestyle: 2, // 0 (solid), 1 (dotted), 2 (dashed), 3 (large dashed)
      },
    }
    const chart = this.widget.activeChart()
    const { complete, direction, predictionPriceFrom, predictionPriceTo, predictionTimeTo, patternEndTime } =
      this.signal
    let predictionShapeId
    if (complete) {
      predictionShapeId = chart.createMultipointShape(
        [
          {
            time: patternEndTime.unix(),
            price: predictionPriceFrom,
          },
          {
            time: predictionTimeTo.unix(),
            price: predictionPriceTo,
          },
        ],
        {
          ...this.baseShapeOptions,
          shape: 'rectangle' as ShapeType,
          overrides: {
            linecolor: direction === 'UP' ? '#00FF00' : '#FF0000', // '#007AFF',
            linewidth: 1, // 1-4
            backgroundColor: direction === 'UP' ? '#00FF00' : '#FF0000', // '#007AFF',
            transparency: 75,
          },
        },
      )
      if (predictionShapeId) {
        const completeShape = chart.getShapeById(predictionShapeId)
        // console.log('COMPLETE_PREDICTION', JSON.stringify(completeShape.getPoints()))
        this.signalShapes.set('complete_prediction', {
          id: predictionShapeId,
          shape: completeShape,
        })
      }
    } else {
      if ('pattern' in this.signal) {
        this._drawChartPatternPrediction(predictionLineOptions)
      }
      if ('keylevel' in this.signal) {
        const priceLine = this.signalShapes.get(this.signal.keylevel.type)?.shape.getPoints()
        predictionShapeId = chart.createMultipointShape(
          [priceLine[1], { time: predictionTimeTo.unix(), price: priceLine[1].price }],
          {
            ...predictionLineOptions,
            overrides: {
              ...predictionLineOptions.overrides,
              linecolor: direction === 'UP' ? '#00FF00' : '#FF0000',
            },
          },
        )
        // console.log(
        //   `KEY_LEVEL PREDICTION LINE: ${JSON.stringify(
        //     chart.getShapeById(predictionShapeId).getPoints()
        //   )}`
        // )

        if (predictionShapeId) {
          this.signalShapes.set('prediction', {
            id: predictionShapeId,
            shape: chart.getShapeById(predictionShapeId),
          })
        }
      }
    }
    // ARROW
    this._drawPriceArrow()
  }

  private _drawChartPatternPrediction(predictionLineOptions: Partial<CreateShapeOptions<any>>) {
    const chart = this.widget.activeChart()
    const { predictionTimeTo, patternEndTime } = this.signal
    const supportLine = this.signalShapes.get('support')?.shape.getPoints()
    const resistanceLine = this.signalShapes.get('resistance')?.shape.getPoints()

    let supportPredictionPoint = this._getLinePointAtTime(supportLine, predictionTimeTo)
    let resistancePredictionPoint = this._getLinePointAtTime(resistanceLine, predictionTimeTo)
    if (supportPredictionPoint.price > resistancePredictionPoint.price) {
      // console.log('INTERSECTION')
      const patternPriceDif = resistanceLine[1].price - supportLine[1].price
      const predictionPriceDif = supportPredictionPoint.price - resistancePredictionPoint.price
      const predicitonBars = this._getDurationInBars(patternEndTime, predictionTimeTo)
      const barsToIntersection = Math.floor(predicitonBars / (predictionPriceDif / patternPriceDif + 1))
      const predictionTime = this._extrapolateBarFromTimeByCount(patternEndTime, barsToIntersection)
      this.signal.predictionTimeTo = predictionTime
      supportPredictionPoint = this._getLinePointAtTime(supportLine, predictionTime)
      resistancePredictionPoint = this._getLinePointAtTime(resistanceLine, predictionTime)
    }
    // console.log(`SUPPORT PREDICTION POINT: ${JSON.stringify(supportPredictionPoint)}`)
    // console.log(`RESISTANCE PREDICTION POINT: ${JSON.stringify(resistancePredictionPoint)}`)
    const supportShapeId = chart.createMultipointShape(
      [{ time: patternEndTime.unix(), price: supportLine[1].price }, supportPredictionPoint],
      predictionLineOptions,
    )
    // console.log(
    //   `SUPPORT PREDICTION LINE: ${JSON.stringify(
    //     chart.getShapeById(supportShapeId).getPoints()
    //   )}`
    // )

    supportShapeId &&
      this.signalShapes.set('support_prediction', {
        id: supportShapeId,
        shape: chart.getShapeById(supportShapeId),
      })

    const resistanceShapeId = chart.createMultipointShape(
      [{ time: patternEndTime.unix(), price: resistanceLine[1].price }, resistancePredictionPoint],
      predictionLineOptions,
    )
    // console.log(
    //   `RESISTANCE PREDICTION LINE: ${JSON.stringify(
    //     chart.getShapeById(resistanceShapeId).getPoints()
    //   )}`
    // )
    resistanceShapeId &&
      this.signalShapes.set('resistance_prediction', {
        id: resistanceShapeId,
        shape: chart.getShapeById(resistanceShapeId),
      })
  }

  private _drawPriceArrow() {
    const chart = this.widget.activeChart()
    const {
      complete,
      direction,
      predictionPriceFrom,
      predictionPriceTo,
      predictionTimeTo,
      patternEndTime,
      patternStartTime,
    } = this.signal

    const priceGraphCandles = this._getDurationInBars(patternStartTime, patternEndTime)

    let priceArrowCandles = Math.ceil(priceGraphCandles * 0.2)
    if (complete) {
      priceArrowCandles = Math.ceil(priceGraphCandles * 0.1)
    } else if ('pattern' in this.signal) {
      // debugger
      const tempShape = chart.createMultipointShape([{ time: patternEndTime.unix(), channel: 'close' }], {
        ...this.baseShapeOptions,
        shape: 'horizontal_line',
      })
      if (tempShape) {
        const arrowY0 = chart.getShapeById(tempShape).getPoints()[0].price
        chart.removeEntity(tempShape)
        const arrowX1Candles = Math.ceil(priceGraphCandles * 0.2)
        const [{ time: supportStart, price: supportStartPrice }, { time: supportEnd, price: supportEndPrice }] =
          this.signalShapes.get('support')?.shape.getPoints()
        const supportStartTime = moment(supportStart * 1000).tz(serverTimeZone)
        const supportEndTime = moment(supportEnd * 1000).tz(serverTimeZone)
        const [
          { time: resistanceStart, price: resistanceStartPrice },
          { time: resistanceEnd, price: resistanceEndPrice },
        ] = this.signalShapes.get('resistance')?.shape.getPoints()
        const resistanceStartTime = moment(resistanceStart * 1000).tz(serverTimeZone)
        const resistanceEndTime = moment(resistanceEnd * 1000).tz(serverTimeZone)
        const arrowY1 =
          direction === 'UP'
            ? arrowY0 + (resistanceStartPrice - supportStartPrice)
            : arrowY0 - (resistanceStartPrice - supportStartPrice)
        const arrowGradient = -(arrowY0 - arrowY1) / arrowX1Candles
        const lineGradient =
          direction === 'UP'
            ? (resistanceEndPrice - resistanceStartPrice) /
              this._getDurationInBars(resistanceStartTime, resistanceEndTime)
            : (supportEndPrice - supportStartPrice) / this._getDurationInBars(supportStartTime, supportEndTime)
        const linePoint =
          direction === 'UP'
            ? resistanceEndPrice + lineGradient * this._getDurationInBars(resistanceEndTime, patternEndTime)
            : supportEndPrice + lineGradient * this._getDurationInBars(supportEndTime, patternEndTime)
        priceArrowCandles = Math.abs(Math.ceil((linePoint - arrowY1) / (lineGradient - arrowGradient)))
      }
    }

    let priceArrowTime = this._extrapolateBarFromTimeByCount(patternEndTime, priceArrowCandles)
    // console.log(priceArrowTime.toISOString())

    if (priceArrowTime > predictionTimeTo) priceArrowTime = predictionTimeTo
    // console.log(
    //   priceGraphCandles,
    //   priceArrowCandles,
    //   predictionTimeTo.toISOString(),
    //   priceArrowTime.toISOString()
    // )

    const arrowX1 = priceArrowTime.unix()
    let arrowY1
    if (complete) {
      arrowY1 = direction === 'UP' ? predictionPriceFrom : predictionPriceTo
    } else {
      if ('pattern' in this.signal) {
        const predictionLine = this.signalShapes.get(direction === 'UP' ? 'resistance' : 'support')?.shape.getPoints()
        const pricePredictionPoint = this._getLinePointAtTime(predictionLine, priceArrowTime)
        arrowY1 = pricePredictionPoint.price
      }
      if ('keylevel' in this.signal) {
        arrowY1 = this.signalShapes.get('prediction')?.shape.getPoints()[1].price
      }
    }

    const arrowShapeId = chart.createMultipointShape(
      // [{time: arrowX0, price: arrowY0},
      [
        { time: patternEndTime.unix(), channel: 'close' },
        { time: arrowX1, price: arrowY1 },
      ],
      {
        ...this.baseShapeOptions,
        shape: 'arrow' as ShapeType,
        overrides: {
          linecolor: direction === 'UP' ? '#00FF00' : '#FF0000', // '#007AFF',
          linewidth: 2, // 1-4
        },
      },
    )
    arrowShapeId && this.signalShapes.set('arrow', { id: arrowShapeId, shape: chart.getShapeById(arrowShapeId) })
  }

  private _setChartVisibleTimeRange() {
    // debugger
    const { patternStartTime, predictionTimeTo, resolution } = this.signal
    const startTime = this._extrapolateBarForTime(
      moment(patternStartTime).subtract(this.timePaddingCandles * resolution, 'minutes'),
    )
    const endTime = this._extrapolateBarFromTimeByCount(predictionTimeTo, this.timePaddingCandles)
    const chartRange = {
      from: startTime.unix(),
      to: endTime.unix(),
    }
    // console.log(`TIME RANGE REQUESTED ${JSON.stringify(chartRange)}`)
    this.chart.setVisibleRange(chartRange)
    // .then(() => console.log(`TIME RANGE SET ${JSON.stringify(this.chart.getVisibleRange())}`))
  }

  private _setChartVisiblePriceRange() {
    let prices = Array.from(this.signalShapes.values()).flatMap((item) =>
      (item.shape.getPoints() as PricedPoint[]).map(({ price }) => price),
    )
    const timeRange = this.chart.getVisibleRange()
    prices = [
      ...prices,
      ...Array.from(this.candles?.bars.entries() || [])
        .filter(([t]) => t >= timeRange.from * 1000 && t <= timeRange.to * 1000)
        .flatMap(([, b]) => [b.high, b.low]),
    ]
    if (prices.length > 1) {
      const sortedPrices = prices.sort((a, b) => a - b)
      const fromPrice = sortedPrices.shift()
      const toPrice = sortedPrices.pop()
      if (fromPrice && toPrice) {
        const diff = toPrice - fromPrice
        const priceScale = this.chart.getPanes()[0].getMainSourcePriceScale()
        priceScale &&
          priceScale.setVisiblePriceRange({
            from: fromPrice - diff * 0.2,
            to: toPrice + diff * 0.2,
          })
      }
    }
    // console.log(`PRICE RANGE ${JSON.stringify(priceScale.getVisiblePriceRange())}`)
  }

  private _getLinePointAtTime(linePoints: PricedPoint[], time: Moment): { time: number; price: number } {
    const [{ time: start, price: startPrice }, { time: end, price: endPrice }] = linePoints
    const startTime = moment(start * 1000).tz(serverTimeZone)
    const endTime = moment(end * 1000).tz(serverTimeZone)
    const lineDuration = this._getDurationInBars(startTime, endTime)
    return {
      time: time.unix(),
      price: endPrice - (this._getDurationInBars(endTime, time) * (startPrice - endPrice)) / lineDuration,
    }
  }

  private _alignTimePointToBar(time: Moment): Moment {
    const { barBuilder: builder } = this.chart._prepareEndOfPeriodArgs()
    builder.moveTo(time.unix() * 1e3)
    return moment(builder.alignTimeIfPossible(time.unix() * 1e3)).tz(serverTimeZone)
  }

  private _extrapolateBarForTime(time: Moment, forward = false): Moment {
    const { resolution } = this.signal //minutes
    const curTime = this._alignTimePointToBar(time)
    while (forward && curTime <= time) {
      curTime.add(resolution, 'minutes')
    }
    let barTime = curTime.valueOf()
    const { barBuilder: builder } = this.chart._prepareEndOfPeriodArgs()
    builder.moveTo(barTime)

    const barIndex = builder.indexOfBar(barTime)
    if (barIndex === -1) {
      // Time is before session start, go to first bar in the session
      barTime = builder.startOfBar(0)
    } else if (barIndex === -2) {
      // Time is after session end, so go to the end of current session
      barTime = builder.startOfBar(-2)
    }

    return moment(barTime).tz(serverTimeZone)
  }

  private _getDurationInBars(start: Moment, end: Moment): number {
    const resolution = resolution2Seconds(this.chart.resolution())
    return Math.round((end.unix() - start.unix()) / (this.timePaddingCandles * resolution))
  }

  private _extrapolateBarFromTimeByCount(time: Moment, count: number): Moment {
    let nextStamp = time.valueOf()

    const { barBuilder: builder } = this.chart._prepareEndOfPeriodArgs()
    builder.moveTo(nextStamp)

    let barsCount = 0
    while (barsCount < count) {
      const barIndex = builder.indexOfBar(nextStamp)

      if (barIndex === -1) {
        // Time is before session start, go to first bar in the session
        nextStamp = builder.startOfBar(0)
      } else if (barIndex === -2) {
        nextStamp = moment(nextStamp).tz(serverTimeZone).valueOf()
        builder.moveTo(nextStamp)
      } else {
        barsCount++
        nextStamp = builder.startOfBar(barIndex + 1)
      }
      // if extrapolated time was set to session end
      if (nextStamp >= builder.sessionEnd && barsCount === count) {
        nextStamp = moment(nextStamp).tz(serverTimeZone).valueOf()
      }
    }

    return moment(nextStamp).tz(serverTimeZone)
  }

  private _showSignalError(message: string) {
    this.widget.showNoticeDialog({
      title: `Signal Error`,
      body: `<p style="color: red;">${message}</p>`,
      callback: () => void true,
    })
  }
}
