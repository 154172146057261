import React, { FC, useEffect, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import get from 'lodash/get'
import { Theme } from '@mui/material/styles'
import isEqual from 'lodash/isEqual'
import clsx from 'clsx'

import { TradeObject } from '@bdswiss/mt4-connector'

import { clearSelectedOpenedPosition, clearSelectedPendingPosition, modifyOrderAction } from '../../../store/actions'
import {
  activeTabSelector,
  currentPriceForOrder,
  formSubmitEnabledSelector,
  formValuesSelector,
  modifyPanelOpenSelector,
  modifyPanelPendingSelector,
  selectedPositionSelector,
  marketOpenedSelector,
  initialFormValues,
  currentSymbolPriceSelector,
  downloadAppBannerSelector,
  isCurrentAccountLeverageTypeDynamicSelector,
} from '../../../store/selectors'
import { InfoList } from './InfoList'
import TakeProfitCheckbox from '../Controls/TakeProfitCheckbox'
import StopLossCheckbox from '../Controls/StopLossCheckbox'
import { ClosePositionDialog } from '../../ClosePositionDialog'
import { Location } from '../../../enums'
import FormHeader from '../Controls/FormHeader'
import { PendingOrderInput } from '../TradingForm/PendingOrderInput'
import { isMobile } from '../../../utils'

type StyleTypes = {
  orderType: string
  isMobileDevice: boolean
  bannerShown?: boolean
}

const useStyles = makeStyles<Theme, StyleTypes>((theme) => ({
  container: {
    maxWidth: ({ isMobileDevice }) => (!isMobileDevice ? 276 : '100%'),
    borderLeft: theme.palette.mode === 'light' ? '1px solid #EBEBEB' : '1px solid rgba(255, 255, 255, 0.2)',
    display: 'flex',
    justifyContent: ({ isMobileDevice }) => (!isMobileDevice ? 'start' : 'space-between'),
    flexGrow: 1,
    flexDirection: 'column',
    maxHeight: ({ isMobileDevice, bannerShown }) =>
      !isMobileDevice ? '100%' : `calc(100vh - ${bannerShown ? '176px' : '120px'})`,
    minWidth: 260,
    overflowY: 'auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  positionSettings: {
    margin: '15px 0 20px',
    fontSize: 14,
    lineHeight: 1.7,
    letterSpacing: -0.1,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: !isMobile() ? 20 : 0,
    padding: 8,
  },
  buttons: {
    textTransform: 'none',
    width: !isMobile() ? 115 : '100%',
    margin: 8,
    height: 36,
    borderRadius: 6,
    fontSize: 14,
    lineHeight: 1.45,
    letterSpacing: 0.25,
    fontWeight: 500,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08)',
    '&:disabled': {
      backgroundColor: '#FAFAFA',
      borderColor: '#D6D6D6',
      cursor: 'default',
      color: '#C2C2C2',
      boxShadow: 'none',
    },
  },
  cancelBtn: {
    backgroundColor: '#ED1C24',
    color: '#fff',
  },
  submitBtn: {
    backgroundColor: '#007AFF',
    color: '#fff',
  },
  tpContainer: {
    marginTop: 15,
  },
  slContainer: {
    marginTop: 15,
  },
  formBody: {
    height: ({ isMobileDevice }) => (!isMobileDevice ? '100%' : ''),
    padding: 16,
    ...(isMobile() && {
      paddingBottom: 4,
    }),
  },
}))

const ModifyingFormContent: FC = () => {
  const isMobileDevice = isMobile()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const formValues = useSelector(formValuesSelector)
  const submitEnabled = useSelector(formSubmitEnabledSelector)
  const currentPrice = useSelector(currentPriceForOrder)
  const editOrder = useSelector(selectedPositionSelector)
  const activeTab = useSelector(activeTabSelector)
  const marketOpened = useSelector(marketOpenedSelector)
  const initValues = useSelector(initialFormValues)
  const currentAsset = useSelector(currentSymbolPriceSelector)
  const bannerShown = useSelector(downloadAppBannerSelector)
  const isCurrentAccountLeverageTypeDynamic = useSelector(isCurrentAccountLeverageTypeDynamicSelector)

  const [closingOrder, setClosingOrder] = useState<TradeObject | undefined>()
  const [dirty, setDirty] = useState(false)
  const classes = useStyles({ orderType: editOrder?.type || 'buy', isMobileDevice, bannerShown })

  const getRoundedWithPrecision = (value: number | boolean, roundingAccuracy: number): number | boolean => {
    if (typeof value === 'number') {
      return Math.round(value * roundingAccuracy) / roundingAccuracy
    }
    return value
  }

  const handleSubmit = useCallback(() => {
    if (editOrder) {
      dispatch(
        modifyOrderAction({
          order: editOrder.order,
        }),
      )
    }
  }, [dispatch, editOrder])

  const toggleCloseForm = useCallback(() => {
    ;(activeTab === Location.positions || activeTab === Location.trade) &&
      dispatch(clearSelectedOpenedPosition({ currentLocation: activeTab }))
    activeTab === Location.order && dispatch(clearSelectedPendingPosition({ currentLocation: activeTab }))
  }, [activeTab, dispatch])

  const dialogOpen = useCallback(() => {
    setClosingOrder(editOrder)
  }, [editOrder])

  const dialogClose = useCallback(() => {
    setClosingOrder(undefined)
  }, [setClosingOrder])

  useEffect(() => {
    const digits = currentPrice?.digits
    if (digits) {
      const roundingPrecision = Math.pow(10, digits)
      const roundedInitValues = initValues.map((value) => getRoundedWithPrecision(value, roundingPrecision))
      const roundedFormValues = formValues.map((value) => getRoundedWithPrecision(value, roundingPrecision))
      setDirty(!isEqual(roundedInitValues, roundedFormValues))
    } else {
      setDirty(!isEqual(initValues, formValues))
    }
  }, [currentPrice?.digits, dispatch, editOrder, formValues, initValues])

  if (!editOrder) {
    return null
  }

  const buttonSubmitDisabled = !marketOpened || !submitEnabled || !dirty

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <FormHeader onClose={toggleCloseForm} />

          <div className={classes.formBody}>
            <InfoList
              modifyPendingOrder={activeTab === Location.order}
              order={editOrder.order}
              direction={editOrder.type}
              profit={editOrder.profit}
              investment={editOrder.investment ? `${editOrder.investment}` : `${editOrder.volume} ${t('lots')}`}
              leverage={isCurrentAccountLeverageTypeDynamic ? t('wtDynamic', 'Dynamic') : `1:${editOrder.leverage}`}
              value={editOrder.volume * +get(currentPrice, 'lotSize', '1')}
              currentPrice={
                /buy/i.test(editOrder.type) ? get(currentPrice, 'mbid', '0.00') : get(currentPrice, 'mask', '0.00')
              }
              openPrice={editOrder.openPrice}
              commissions={editOrder.commission}
              swaps={editOrder.swaps}
              contractType={get(currentPrice, 'contractType', '')}
              digits={editOrder.digits}
              openDate={editOrder.openTime}
            />

            <Typography className={classes.positionSettings}>
              <Trans i18nKey={'positionSettings'} />
            </Typography>

            {activeTab === Location.order && <PendingOrderInput currency={currentAsset.currency} />}

            <div className={classes.tpContainer}>
              <TakeProfitCheckbox />
            </div>

            <div className={classes.slContainer}>
              <StopLossCheckbox />
            </div>
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            className={clsx(classes.buttons, classes.cancelBtn)}
            color={'secondary'}
            variant={'contained'}
            disabled={!marketOpened}
            onClick={dialogOpen}
          >
            <Trans i18nKey={activeTab === Location.order ? 'cancel' : 'close'} />
          </Button>

          <Button
            className={clsx(classes.buttons, classes.submitBtn, {
              disabled: buttonSubmitDisabled,
            })}
            disabled={buttonSubmitDisabled}
            color={'primary'}
            variant={'contained'}
            type={'submit'}
            onClick={handleSubmit}
          >
            <Trans i18nKey={'save'} />
          </Button>
        </div>
      </div>

      <ClosePositionDialog order={closingOrder} onClose={dialogClose} />
    </>
  )
}

export const ModifyingForm: FC = () => {
  const order = useSelector(selectedPositionSelector)?.order
  const open = useSelector(modifyPanelOpenSelector)
  const pending = useSelector(modifyPanelPendingSelector)
  return <>{(open || pending) && <ModifyingFormContent key={order} />}</>
}
