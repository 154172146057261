import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import IframeResizer from 'iframe-resizer-react'

import { userIdSelector, userLocaleSelector, userCompanySelector } from '../../store/selectors'
import { Loader } from './Loader'
import graphqlClient from '../../services/graphql'

const useStyles = makeStyles<
  Theme,
  {
    iframeLoaded: boolean
  }
>((theme) => ({
  iframe: {
    width: 1,
    minWidth: '100%',
    height: '100%',
    display: ({ iframeLoaded }) => (iframeLoaded ? 'block' : 'none'),
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(.8)',
      transformOrigin: 'top left',
      width: '125%',
      height: '125%',
    },
  },
}))

export const EconomicCalendar: FC = () => {
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false)
  const [link, setLink] = useState('')
  const userId = useSelector(userIdSelector)
  const locale = useSelector(userLocaleSelector)
  const company = useSelector(userCompanySelector)
  const theme = useTheme()
  const classes = useStyles({ iframeLoaded })

  useEffect(() => {
    setIframeLoaded(false)
  }, [theme.palette.mode, locale])

  useEffect(() => {
    graphqlClient
      .getTradingCentralLink({
        page: 'economic_calendar',
        theme: theme.palette.mode,
      })
      .then((res) => setLink(res.data.tradingCentralLink))
  }, [company, theme.palette.mode])

  const iframeLoadHandle = useCallback(() => {
    setIframeLoaded(true)
  }, [])

  if (!userId) {
    return null
  }

  return (
    <>
      {!iframeLoaded && <Loader />}
      <IframeResizer
        scrolling
        onLoad={iframeLoadHandle}
        src={link}
        frameBorder="0"
        id="tradingcentral-economic-calendar"
        className={classes.iframe}
      />
    </>
  )
}
