import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { LabelValueTooltip } from '../../Controls/LabelValueTooltip'
import { currentSymbolPriceSelector } from '../../../../store/selectors'

const CurrentSpread: FC = () => {
  const currentPrice = useSelector(currentSymbolPriceSelector)

  if (!currentPrice) {
    return null
  }

  const { mask, mbid, digits } = currentPrice

  return <LabelValueTooltip label={'spread'} value={(mask - mbid).toFixed(digits)} tooltip={'helpSpread'} />
}

export default CurrentSpread
