import React, { useCallback, FC, ChangeEvent, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { setAssetFilter } from '../../store/actions'

import { CloseButton } from '../CloseButton'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    padding: '20px 7px 24px 17px',
    fontSize: '15px',
  },
})

const useSearchStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 28px)',
    '& .MuiInput-underline:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${theme.palette.action.selected}`,
    },
    '& .MuiInput-underline:after': {
      transition: 'none',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '& .MuiTypography-body1': {
      padding: '0 8px',
      fontSize: 12,
      fontStyle: 'italic',
      fontWeight: 300,
      cursor: 'pointer',
    },
    '& .MuiTypography-body1:hover': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputBase-input': {
      padding: '4px 0 8px',
      fontSize: '0.9375rem',
    },
  },
}))

const SearchField: FC<TextFieldProps> = (props) => {
  const classes = useSearchStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  const changeHandle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setAssetFilter(e.currentTarget.value))
      setValue(e.currentTarget.value)
    },
    [dispatch],
  )

  const resetInput = useCallback(() => {
    setValue('')
    dispatch(setAssetFilter(''))
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(setAssetFilter(''))
    }
  }, [dispatch])

  return (
    <TextField
      {...props}
      classes={classes}
      type={'text'}
      variant={'standard'}
      value={value}
      placeholder={t('search')}
      onChange={changeHandle}
      InputProps={{
        endAdornment: value ? (
          <InputAdornment position={'end'} onClick={resetInput}>
            {t('clear')}
          </InputAdornment>
        ) : (
          ''
        ),
      }}
    />
  )
}

export const InputSearch: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <SearchField />
      <CloseButton />
    </div>
  )
}
