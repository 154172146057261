import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { CellProps, Renderer } from 'react-table'
import moment from 'moment-timezone'
import { Trade } from '../../../services/graphql/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/types'
import { getAssetLabelSelector, mainCurrencySelector } from '../../../store/selectors'
import { Direction, Favourite, Profit } from '.'
import { capitalize } from 'lodash'
import { pricesSelector } from '../../../store/selectors'
import { currencyVolumeFormat } from '../../../utils'

const DATE_FORMAT = 'dd mmm yyyy'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 16px 0px 16px',
    alignItems: 'center',
    fontFamily: 'Roboto Condensed',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  customItem: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.primary,
    gap: '9px',
  },
  customColumnItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '6px',
    alignItems: 'center',
    color: theme.palette.mode === 'light' ? '#858585' : '#999999',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '13px',
  },
  symbol: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  profit: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'right',
    letterSpacing: '0.25px',
  },
  favourite: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}))

export const MobileAssetCell: Renderer<CellProps<Record<string, string>, Trade>> = ({ value }) => {
  const classes = useStyles()
  const symbol = useSelector((state: RootState) => getAssetLabelSelector(state, value.symbol || ''))
  const asset = useSelector(pricesSelector)
  const currency = useSelector(mainCurrencySelector)
  const volume = currencyVolumeFormat(
    (value.volume || 0) * asset[value.symbol || '']?.lotSize,
    asset[value.symbol || '']?.contractType,
  )

  if (!value) return null

  return (
    <div key={value.id} className={classes.container}>
      <div className={classes.itemContainer}>
        <div className={classes.customItem}>
          <div className={classes.favourite}>
            <Favourite value={value.symbol || ''} />
          </div>
          <div className={classes.symbol}>
            <div>{symbol}</div>
            <div className={classes.customColumnItem}>
              <div>
                <Direction direction={capitalize(value.type || '')} onlyIcon />{' '}
              </div>
              <div> {volume} </div>
              <div>
                {value.openTime
                  ? moment(value.openTime).format(DATE_FORMAT)
                  : value.closeTime && moment(value.closeTime).format(DATE_FORMAT)}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Profit profit={value.profit || 0} currency={currency} />
        </div>
      </div>
    </div>
  )
}
