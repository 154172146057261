export const viewTrendAnalysis = 'viewTrendAnalysis'
export const expandClosedOrder = 'expandClosedOrder'
export const expandOpenOrders = 'expandOpenOrders'
export const favouriteSymbol = 'favouriteSymbol'
export const unfavouriteSymbol = 'unfavouriteSymbol'
export const demoTradeIntent = 'demoTradeIntent'
export const realTradeIntent = 'realTradeIntent'
export const traderOpened = 'traderOpened'
export const demoTradeAttempted = 'demoTradeAttempted'
export const realTradeAttempted = 'realTradeAttempted'
export const demoTradeCompleted = 'demoTradeCompleted'
export const realTradeCompleted = 'realTradeCompleted'
export const demoTradeFailed = 'demoTradeFailed'
export const realTradeFailed = 'realTradeFailed'
export const modifyDemoOrderAttempted = 'modifyDemoOrderAttempted'
export const modifyDemoOrderCompleted = 'modifyDemoOrderCompleted'
export const modifyDemoOrderFailed = 'modifyDemoOrderFailed'
export const modifyRealOrderAttempted = 'modifyRealOrderAttempted'
export const modifyRealOrderCompleted = 'modifyRealOrderCompleted'
export const modifyRealOrderFailed = 'modifyRealOrderFailed'
export const switchAccount = 'switchAccount'
export const closeDemoOrderCompleted = 'closeDemoOrderCompleted'
export const closeDemoOrderFailed = 'closeDemoOrderFailed'
export const closeDemoOrderAttempted = 'closeDemoOrderAttempted'
export const closeRealOrderCompleted = 'closeRealOrderCompleted'
export const closeRealOrderFailed = 'closeRealOrderFailed'
export const closeRealOrderAttempted = 'closeRealOrderAttempted'
export const cancelPendingDemoOrderAttempted = 'cancelPendingDemoOrderAttempted'
export const cancelPendingDemoOrderCompleted = 'cancelPendingDemoOrderCompleted'
export const cancelPendingDemoOrderFailed = 'cancelPendingDemoOrderFailed'
export const cancelPendingRealOrderAttempted = 'cancelPendingRealOrderAttempted'
export const cancelPendingRealOrderCompleted = 'cancelPendingRealOrderCompleted'
export const cancelPendingRealOrderFailed = 'cancelPendingRealOrderFailed'
export const closeAllDemoAttempted = 'closeAllDemoAttempted'
export const closeAllDemoCompleted = 'closeAllDemoCompleted'
export const closeAllDemoFailed = 'closeAllDemoFailed'
export const closeAllRealAttempted = 'closeAllRealAttempted'
export const closeAllRealCompleted = 'closeAllRealCompleted'
export const closeAllRealFailed = 'closeAllRealFailed'
export const viewPendingTrades = 'viewPendingTrades'
export const chartStyleChanged = 'chartStyleChanged'
export const chartTimeframeChanged = 'chartTimeframeChanged'
export const indicatorAdded = 'indicatorAdded'
export const indicatorRemoved = 'indicatorRemoved'
export const indicatorsButtonClicked = 'indicatorsButtonClicked'
export const indicatorSettingsSaved = 'indicatorSettingsSaved'
export const clickTrendAnalysisAsset = 'clickTrendAnalysisAsset'
export const drawingToolRemoved = 'drawingToolRemoved'
export const drawingToolClicked = 'drawingToolClicked'
export const drawingToolAdded = 'drawingToolAdded'
export const drawingToolSettingSaved = 'drawingToolSettingSaved'
export const bentoMenuItemClick = 'bentoMenuItemClick'
export const onQRCodeButtonClicked = 'onQRCodeButtonClicked'
export const onQRCodePopupPresented = 'onQRCodePopupPresented'
export const mt4Error = 'mt4Error'

export const SOURCES_BY_ASSET_GROUP: Record<string, string> = {
  favourites: 'Favourites',
  trendsAnalysis: 'Trend Analysis',
  all: 'All',
  popular: 'Popular',
  forex: 'Forex',
  crypto: 'Crypto',
  commodities: 'Commodities',
  shares: 'Indices',
  indices: 'Indices',
}
