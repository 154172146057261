import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import debounce from 'lodash/debounce'

import { currentSymbolPriceSelector, marketOpenedSelector } from '../../../../store/selectors'
import { PrettyTradeButton } from './PrettyTradeButton'
import { toast } from 'react-toastify'
import { Trans } from 'react-i18next'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: '8.5px',
  },
}))

export const TradeButtons: FC = () => {
  const classes = useStyles()
  const price = useSelector(currentSymbolPriceSelector)
  const marketOpened = useSelector(marketOpenedSelector)

  useEffect(() => {
    !marketOpened && debounce(() => toast.success(<Trans i18nKey={'marketClosed'} defaults={'Market closed'} />), 100)
  }, [marketOpened])

  return (
    <div className={classes.container}>
      <PrettyTradeButton type={'sell'} price={price?.mbid || 0} digits={price?.digits} enabled={marketOpened} />
      <PrettyTradeButton type={'buy'} price={price?.mask || 0} digits={price?.digits} enabled={marketOpened} />
    </div>
  )
}
