import React, { FC, ReactNode, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'
import { mainCurrencySelector } from '../../../../store/selectors'
import { currencyFormat, positiveNegativeFormat } from '../../../../utils'
import { useHistory } from 'react-router'
import { Trade } from '../../../../services/graphql/types'
import { capitalize } from 'lodash'
import { setMobileBackButtonData } from '../../../../store/actions'
import { Direction, Profit } from '../../Common'
import { RootState } from '../../../../store/types'
import { getAssetLabelSelector } from '../../../../store/selectors'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '16px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '8px',
  },
  column: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.mode === 'light' ? '#858585' : '#999999',
  },
  item: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'right',
    letterSpacing: '0.25px',
    color: theme.palette.text.primary,
  },
  children: {
    width: '100%',
  },
}))

const SubItem: FC<{ label?: string; value?: string | number | ReactNode | null }> = ({ label, value, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.itemContainer}>
      {children ? (
        <div className={classes.children}>{children}</div>
      ) : (
        <>
          <div className={classes.column}>
            <Trans i18nKey={label} />
          </div>
          <div className={classes.item}>{value}</div>
        </>
      )}
    </div>
  )
}

export const HistoryDetails: FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const currency = useSelector(mainCurrencySelector)
  const historyDetails = history.location.state as Trade
  const symbol = useSelector((state: RootState) => getAssetLabelSelector(state, historyDetails.symbol || ''))

  useEffect(() => {
    dispatch(setMobileBackButtonData({ show: true, title: symbol }))
    return () => {
      dispatch(setMobileBackButtonData(undefined))
    }
  }, [dispatch, symbol])

  if (!historyDetails) return null
  const { id, type, investment, leverage, volume, profit, openPrice, closePrice, commission, swaps, closeTime } =
    historyDetails

  return (
    <div className={classes.container}>
      <SubItem label="Order #" value={id} />
      <SubItem label="Direction" value={<Direction direction={capitalize(type || '')} />} />
      <SubItem label="Investment" value={currencyFormat(investment || 0, currency)} />
      <SubItem label="Leverage" value={`x${leverage}`} />
      <SubItem label="Value" value={volume} />
      <SubItem label="Profit" value={<Profit profit={profit || 0} currency={currency} />} />
      <SubItem label="Open Price" value={openPrice} />
      <SubItem label="Close Price" value={closePrice} />
      <SubItem label="Commission" value={positiveNegativeFormat(commission || 0, currency)} />
      <SubItem label="Swaps" value={positiveNegativeFormat(swaps || 0, currency)} />
      <SubItem label="Close Date" value={<span>{closeTime && new Date(closeTime).toLocaleString()}</span>} />
    </div>
  )
}
