import React, { FC, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'

import { AssetTradeMode } from '@bdswiss/mt4-connector'

import {
  currentSymbolPriceSelector,
  formOrderTypeSelector,
  hasCurrentSymbolPriceSelector,
  tradingFormPanelSelector,
  tradeModeSelector,
  marketOpenedSelector,
  downloadAppBannerSelector,
} from '../../../store/selectors'
import { AmountLeverage } from './AmountLeverage'
import { TradeSettings } from './TradeSettings'
import DailyChange from './DailyChange'
import { MarketSentiment } from './MarketSentiment'
import ContractValue from './ContractValue'
import FormHeader from '../Controls/FormHeader'
import CurrentPrice from './CurrentPrice'
import CurrentSpread from './CurrentSpread'
import AmountInput from './AmountInput'
import SubmitButton from './SubmitButton'
import { appChangeTradeMode, toggleTradingFormPanel } from '../../../store/actions'
import { OrderTypes, TradeMode } from '../../../enums'
import { TypeOrderSelect } from './TypeOrderSelect'
import { PendingOrderInput } from './PendingOrderInput'
import { StopOrderInput } from './StopOrderInput'
import { RequiredMargin } from './RequiredMargin'
import { isMobile } from '../../../utils'

type BuyOrSell = TradeMode.buy | TradeMode.sell
type StyleTypes = {
  type: BuyOrSell
  isMobileDevice?: boolean
  bannerShown?: boolean
}

const useStyles = makeStyles<Theme, StyleTypes>(({ palette }) => ({
  formContainer: {
    display: 'flex',
    justifyContent: ({ isMobileDevice }) => (!isMobileDevice ? 'start' : 'space-between'),
    flexGrow: 1,
    flexDirection: 'column',
    maxWidth: ({ isMobileDevice }) => (!isMobileDevice ? 260 : '100%'),
    maxHeight: ({ isMobileDevice, bannerShown }) =>
      !isMobileDevice ? '100%' : `calc(100vh - ${bannerShown ? '176px' : '120px'})`,
    minWidth: 260,
    borderLeft: palette.mode === 'light' ? '1px solid #EBEBEB' : '1px solid rgba(255, 255, 255, 0.2)',
    overflowY: 'auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  tabContainer: {
    '&.MuiTabs-root': {
      minHeight: 40,
    },
    '& .MuiTab-root': {
      padding: '10px 0',
      minHeight: 40,
      fontSize: 14,
      lineHeight: 1.4,
      letterSpacing: 0.25,
      backgroundColor: palette.mode === 'light' ? '#FAFAFA' : '#1F1F1F',
      '&.Mui-selected': {
        color: ({ type }) => (type === TradeMode.buy ? '#00AD7B' : '#D41920'),
        backgroundColor: palette.mode === 'light' ? '#fff' : '#000',
      },
    },
    '& 	.MuiTabs-indicator': {
      height: 1,
      backgroundColor: ({ type }) => (type === TradeMode.buy ? '#00C78D ' : '#ED1C24'),
    },
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: 16,
  },
  buy: {
    color: '#00AD7B',
  },
  sell: {
    color: '#D41920',
  },
  spreadWrap: {
    marginBottom: 23,
  },
  marketClosed: {
    color: palette.mode === 'light' ? '#000' : '#fff',
    backgroundColor: palette.mode === 'light' ? '#FFF6E6' : '#1F1F1F',
    margin: '12px 16px',
    padding: 8,
    fontSize: 12,
    lineHeight: '16px',
    borderRadius: 4,
  },
}))

export const TradingForm: FC = () => {
  const isMobileDevice = isMobile()
  const dispatch = useDispatch()
  const hasCurrentPrice = useSelector(hasCurrentSymbolPriceSelector)
  const currentAsset = useSelector(currentSymbolPriceSelector)
  const formDisplay = useSelector(tradingFormPanelSelector)
  const orderType = useSelector(formOrderTypeSelector)
  const tradeMode = useSelector(tradeModeSelector)
  const bannerShown = useSelector(downloadAppBannerSelector)

  const [currentTab, setCurrentTab] = useState<BuyOrSell>(tradeMode as BuyOrSell)
  const classes = useStyles({ type: currentTab, isMobileDevice, bannerShown })

  const isMarketOpened = useSelector(marketOpenedSelector)

  const switchTradeModeHandle = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      if (e !== null && e.target instanceof HTMLElement) {
        const { mode } = e.target.dataset
        mode && dispatch(appChangeTradeMode(mode as TradeMode))
      }
    },
    [dispatch],
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: BuyOrSell): void => {
    newValue && setCurrentTab(newValue)
    switchTradeModeHandle(event)
  }

  const toggleCloseFormHandle = useCallback(() => {
    dispatch(toggleTradingFormPanel(false))
  }, [dispatch])

  const applyProps = (index: string) => {
    return {
      id: `form-tab-${index}`,
      'aria-controls': `form-tabpanel-${index}`,
    }
  }

  useEffect(() => {
    setCurrentTab(tradeMode as BuyOrSell)
  }, [tradeMode])

  if (!hasCurrentPrice || !formDisplay) {
    return null
  }

  return (
    <div className={classes.formContainer} data-test="sideForm">
      <div className={classes.wrapper}>
        <FormHeader onClose={toggleCloseFormHandle} />
        {!isMarketOpened && (
          <div className={classes.marketClosed}>
            <Trans i18nKey={'marketClosed'} defaults={'Market is closed'} />
          </div>
        )}
        {currentAsset.trade === AssetTradeMode.enable && (
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="fullWidth"
            className={classes.tabContainer}
            selectionFollowsFocus
            TabIndicatorProps={
              !isMobile()
                ? {
                    style: {
                      left: currentTab === TradeMode.buy ? 129.5 : 0,
                      width: 129.5,
                    },
                  }
                : undefined
            }
          >
            <Tab
              value={TradeMode.sell}
              data-mode={TradeMode.sell}
              label={<Trans i18nKey={'sell'} defaults={'Sell'} />}
              className={classes.sell}
              {...applyProps('sell')}
            />
            <Tab
              value={TradeMode.buy}
              data-mode={TradeMode.buy}
              label={<Trans i18nKey={'buy'} defaults={'Buy'} />}
              className={classes.buy}
              {...applyProps('buy')}
              aria-checked
            />
          </Tabs>
        )}

        <div className={classes.tabsContainer}>
          <div className={classes.priceContainer}>
            <CurrentPrice label={currentAsset.pair.quote ?? currentAsset.pair.base} digits={currentAsset.digits} />
            <DailyChange />
          </div>
          <div className={classes.spreadWrap}>
            <CurrentSpread />
          </div>
          <MarketSentiment label={currentAsset.label} />
          <TypeOrderSelect />
          {(orderType === OrderTypes.stopOrder || orderType === OrderTypes.stopLimitOrder) && (
            <StopOrderInput currency={currentAsset.currency} />
          )}
          {(orderType === OrderTypes.pendingOrder || orderType === OrderTypes.stopLimitOrder) && (
            <PendingOrderInput currency={currentAsset.currency} />
          )}
          <AmountInput contractType={currentAsset.contractType} />
          <AmountLeverage />
          <ContractValue />
          <RequiredMargin />
          <TradeSettings />
        </div>
      </div>
      <SubmitButton />
    </div>
  )
}
