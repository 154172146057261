import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { CellProps, Renderer } from 'react-table'
import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from '@mui/icons-material/Close'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import clsx from 'clsx'

import { MarketState, TradeObject } from '@bdswiss/mt4-connector'

import { currencyFormat, positiveNegativeFormat, currencyVolumeFormat } from '../../../utils'
import { TradeTableContext } from './TradeTable'
import {
  getSymbolMarketStateSelector,
  isCurrentAccountLeverageTypeDynamicSelector,
  mainCurrencySelector,
  pricesSelector,
} from '../../../store/selectors'
import { RootState } from '../../../store/types'

const useStyles = makeStyles((theme) => ({
  editClose: {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '& svg': {
      fontSize: 14,
      margin: '0 4px',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  positive: {
    color: theme.palette.green.mint,
  },
  negative: {
    color: theme.palette.secondary.main,
  },
  type: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 16,
    },
  },
  disabledButton: {
    color: theme.palette.grey[500],
  },
}))

export const TypeCell: Renderer<CellProps<TradeObject, TradeObject['type']>> = ({ value }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        {
          [classes.positive]: /buy/i.test(value),
          [classes.negative]: /sell/i.test(value),
        },
        classes.type,
      )}
    >
      <div className={classes.iconWrapper}>
        {value === 'buy' ? (
          <CallMadeIcon className={classes.positive} />
        ) : (
          <CallReceivedIcon className={classes.negative} />
        )}
      </div>
      &nbsp;
      <Trans i18nKey={value} />
    </div>
  )
}

export const InvestmentCell: Renderer<CellProps<TradeObject, TradeObject['volume']>> = ({
  row: {
    original: { volume, symbol },
  },
}) => {
  const asset = useSelector(pricesSelector)
  const value = currencyVolumeFormat(volume * asset[symbol]?.lotSize, asset[symbol]?.contractType)
  return (
    <div title={value}>
      <span className="inline-label">
        <Trans i18nKey={'value'} />:{' '}
      </span>
      {value}
    </div>
  )
}

export const OpenPriceCell: Renderer<CellProps<TradeObject, TradeObject['openPrice']>> = ({
  value,
  row: {
    original: { digits },
  },
}) => {
  const openPrice = currencyVolumeFormat(value, '', { minimumFractionDigits: digits })
  return (
    <div title={openPrice}>
      <span className="inline-label">
        <Trans i18nKey={'openPrice'} />:{' '}
      </span>
      {openPrice}
    </div>
  )
}

export const LeverageCell: Renderer<CellProps<TradeObject, TradeObject['leverage']>> = ({ value }) => {
  const isCurrentAccountLeverageTypeDynamic = useSelector(isCurrentAccountLeverageTypeDynamicSelector)
  return value ? (
    <div title={!isCurrentAccountLeverageTypeDynamic ? `${value}` : ''}>
      <span className="inline-label">
        <Trans i18nKey={'leverage'} />:{' '}
      </span>
      {isCurrentAccountLeverageTypeDynamic ? <Trans i18nKey="wtDynamic" defaults="Dynamic" /> : <>1:{value}</>}
    </div>
  ) : null
}

export const ProfitCell: Renderer<CellProps<TradeObject, TradeObject['profit']>> = ({
  row: {
    original: { profit },
  },
}) => {
  const classes = useStyles()
  const currency = useSelector(mainCurrencySelector)
  return (
    <div title={currencyFormat(profit, currency)} data-guided-tour="profitCell">
      <span className="inline-label">
        <Trans i18nKey={'profit'} />:{' '}
      </span>
      <span
        className={clsx({
          [classes.positive]: profit > 0,
          [classes.negative]: profit < 0,
        })}
      >
        {positiveNegativeFormat(profit, currency)}
      </span>
    </div>
  )
}

export const CloseActionCell: Renderer<CellProps<TradeObject, string>> = ({ row: { original } }) => {
  const classes = useStyles()
  const marketOpened =
    useSelector((state: RootState) => getSymbolMarketStateSelector(state, original.symbol)) === MarketState.Open
  const dialogOpen = useContext(TradeTableContext)
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      dialogOpen(original)
    },
    [dialogOpen, original],
  )

  return (
    <button
      className={marketOpened ? classes.editClose : `${classes.editClose} ${classes.disabledButton}`}
      disabled={!marketOpened}
      onClick={handleClick}
    >
      <CloseIcon />
      <Trans i18nKey={'close'} />
    </button>
  )
}

export const OrderIdCell: Renderer<CellProps<Record<string, unknown>, string>> = ({ value }) => {
  return <>{!!value && `#${value}`}</>
}
