import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactJoyride, { Styles, ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

import { changeActiveAssetGroup, openLoadingDialog, runGuidedTour } from '../../store/actions'
import { tradeDemo } from './scenarios'
import { GuidedTourAction, GuidedTourStatus } from '../../enums'
import { DefaultStep } from './Templates/DefaultStep'
import { currentAccountSelector, getLoginFirstAccountSelector } from '../../store/selectors'
import { StartDialog } from './Dialogs/StartDialog'
import { LoadingDialog } from './Dialogs/LoadingDialog'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { guidedTourServiceAccount } from './config'
import { guidedTourRunSelector } from '../../store/selectors'

export const GuidedTour: FC = () => {
  const dispatch = useDispatch()
  const [run, setRun] = useState(false)
  const [step, setStep] = useState(0)
  const theme = useTheme()
  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const history = useHistory()
  const defaultLogin = useSelector(getLoginFirstAccountSelector) || ''
  const login = useSelector(currentAccountSelector)?.login
  const guidedTourRun = useSelector(guidedTourRunSelector)

  useEffect(() => {
    tradeDemo.forEach((step) => {
      if (step.content) {
        const stepText: string = step.content.toString()
        step.content = <Trans i18nKey={stepText} />
      }
    })
  }, [])

  useEffect(() => {
    if (login == guidedTourServiceAccount.login || !guidedTourRun) return
    login && localStorage.setItem('loginToReturnFromGuideTour', login)
    guidedTourServiceAccount.login &&
      history.push(generatePath(path, { accountId: guidedTourServiceAccount.login }) + '/trade')
  }, [dispatch, history, guidedTourRun, login, path])

  useEffect(() => {
    if (run) {
      dispatch(changeActiveAssetGroup('popular'))
      return () => {
        setStep(0)
        dispatch(runGuidedTour(false))
        dispatch(openLoadingDialog())
      }
    }
  }, [dispatch, run])

  const addStep = useCallback(() => {
    if (!run) return
    setTimeout(() => {
      setStep(step + 1)
    }, 500)
  }, [run, step])

  const elementList = useRef<Element[]>([])

  useEffect(() => {
    if (run) {
      const stepsShouldClicked = tradeDemo.filter((step) => step.nonce === GuidedTourAction.tap)
      elementList.current = stepsShouldClicked.reduce((acc: Element[], { target }) => {
        const foundTarget = document.querySelector(target.toString())
        if (foundTarget) {
          acc.push(foundTarget)
        }
        return acc
      }, [])
      elementList.current.forEach((element) => element.addEventListener('click', addStep))
    }
    return () => {
      if (elementList.current.length) {
        elementList.current.forEach((element) => element.removeEventListener('click', addStep))
        elementList.current.length = 0
      }
    }
  }, [addStep, run])

  const handleSkip = useCallback(() => {
    setRun(false)
    localStorage.setItem('guidedTourStatus', GuidedTourStatus.skipped)
    const savedLogin = localStorage.getItem('loginToReturnFromGuideTour')
    savedLogin && localStorage.removeItem('loginToReturnFromGuideTour')
    history.push(generatePath(path, { accountId: savedLogin ? savedLogin : defaultLogin }) + '/trade')
  }, [defaultLogin, history, path])

  const handleStart = useCallback(() => {
    setRun(true)
    localStorage.setItem('guidedTourStatus', GuidedTourStatus.started)
  }, [])

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { action, index, status, type } = data

      if (status === STATUS.SKIPPED) {
        handleSkip()
      }
      if (status === STATUS.FINISHED) {
        localStorage.setItem('guidedTourStatus', GuidedTourStatus.finished)
        setRun(false)
      }
      const events: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]
      if (events.includes(type)) {
        const nextStep = index + (action === ACTIONS.PREV ? -1 : 1)
        setStep(nextStep)
      }
    },
    [handleSkip, setRun],
  )
  const localeTranslate = useMemo(
    () => ({
      open: t('guidedTourContinueTour'),
      next: t('guidedTourNext'),
      back: t('guidedTourBack'),
      close: t('guidedTourClose'),
      last: t('guidedTourClose'),
      skip: t('guidedTourSkip'),
    }),
    [t],
  )

  const defaultStyles: Styles = useMemo(() => {
    const spotlightColor = theme.palette.mode === 'dark' ? '#8f8f8f' : 'gray' // TODO: theme.palette
    return {
      spotlight: {
        backgroundColor: spotlightColor,
      },
      options: {
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: theme.palette.primary.main,
        textColor: '#757575', // TODO: theme.palette
        zIndex: theme.zIndex.tooltip,
        arrowColor: theme.palette.background.default,
      },
    }
  }, [theme.palette.background.default, theme.palette.primary.main, theme.palette.mode, theme.zIndex.tooltip])

  return (
    <>
      <ReactJoyride
        run={run}
        tooltipComponent={DefaultStep}
        continuous={true}
        callback={handleJoyrideCallback}
        steps={tradeDemo}
        // floaterProps={{ autoOpen: true }}
        stepIndex={step}
        scrollToFirstStep={true}
        showProgress={true}
        spotlightPadding={5}
        showSkipButton={true}
        styles={defaultStyles}
        locale={localeTranslate}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        disableScrolling={true}
      />
      <StartDialog handleSkip={handleSkip} />
      <LoadingDialog handleStart={handleStart} />
    </>
  )
}
