import { createSelector } from 'reselect'

import { MT4Connector, AssetGroup } from '@bdswiss/mt4-connector'

import { AssetGroups, RootState } from '../types'
import { openPositionsSelector } from './positions'
import { getTradeableAssets } from '../../utils'

export const assetGroupsSelector = (state: RootState): AssetGroups => state.assetGroups.data
export const activeAssetGroupSelector = (state: RootState): RootState['assetGroups']['activeGroup'] =>
  state.assetGroups.activeGroup

export const assetGroupsModelSelector = createSelector(
  () => MT4Connector.Instance?.assets || {},
  openPositionsSelector,
  assetGroupsSelector,
  (assets, positions, groups) => {
    const symbols = Object.values(groups)
      .filter((group) => !['favourites', 'trendsAnalysis'].includes(group.label))
      .flatMap(({ symbols }) => symbols)

    const tradeableAssets = getTradeableAssets(assets, positions, symbols).map((asset) => asset.symbol)

    return Object.values(groups).reduce(
      (acc, elem) => {
        acc.symbolsLength = [...new Set(symbols.filter((symbol) => tradeableAssets.includes(symbol)))].length
        acc.groupsArray.push({
          ...elem,
          symbols: elem?.symbols?.filter((symbol) => tradeableAssets.includes(symbol)),
        })
        return acc
      },
      { symbolsLength: 0, groupsArray: [] as AssetGroup[] },
    )
  },
)

export const assetGroupsSymbolsSelector = createSelector(assetGroupsSelector, (assetGroups) =>
  Object.values(assetGroups).flatMap((group) => group.symbols),
)
