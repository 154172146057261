import React, { FC } from 'react'
import { makeStyles } from '@mui/styles'

import spinner from '../../images/spinner.gif'

const useStyles = makeStyles(({ palette }) => ({
  preloader: {
    width: '100%',
    height: '100%',
    background: palette.background.default,
    position: 'absolute',
    zIndex: 1001,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& img': {
      margin: '0 auto',
      width: '100px',
      height: '100px',
    },
  },
}))

const Preloader: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.preloader}>
      <img alt="Loading..." src={spinner} />
    </div>
  )
}

export default Preloader
