import { createReducer } from 'typesafe-actions'

import { ActionTypes, MarketStateChanges } from '../types'
import { mt4MarketStateChanges } from '../actions'

export type MarketState = MarketStateChanges

export const marketStateChanges = createReducer<MarketState, ActionTypes>({}).handleAction(
  mt4MarketStateChanges,
  (state, { payload }) =>
    Object.assign(
      {},
      ...payload.map((item) => ({
        [item.asset]: item,
      })),
    ),
)
