import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { Trans } from 'react-i18next'

import { ReactComponent as BDSwissBannerImg } from '../../../assets/images/icons/mobile/bdswissMobileAppBannerLogo.svg'
import { ReactComponent as VivernoBannerImg } from '../../../assets/images/icons/mobile/vivernoMobileAppBannerLogo.svg'
import { handleDownloadMobileApp } from '../../BentoMenu/utils'
import { downloadAppBannerSelector, userCompanyLabelSelector } from '../../../store/selectors'
import { setCookie } from '../../../utils'
import DownloadMobileAppDialog from '../../DownloadMobileAppDialog'
import { downloadAppBannerAction } from '../../../store/actions'

const BannerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.mode === 'light' ? '#F1F8FF' : '#08233F',
  minHeight: 56,
  padding: '12px 16px',
}))

const BannerLogo = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}))

const BannerLogoWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  marginLeft: 12,
  letterSpacing: '0.4px',
}))

const BannerTitle = styled('div')(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  color: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF',
}))

const BannerSubTitle = styled('div')(({ theme }) => ({
  fontSize: 10,
  lineHeight: '14px',
  color: theme.palette.mode === 'light' ? '#858585' : '#999999',
}))

const BannerActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))

const BannerOpenButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 16px',
  border: 'none',
  borderRadius: 24,
  color: '#FFFFFF',
  fontSize: 14,
  lineHeight: '24px',
  background: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
  marginRight: 12,
}))

const BannerCloseButton = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#858585' : '#999999',
  width: 16,
  height: 16,
}))

export const DownloadAppBanner: FC = () => {
  const dispatch = useDispatch()
  const companyLabel = useSelector(userCompanyLabelSelector)
  const open = useSelector(downloadAppBannerSelector)

  const handleClose = useCallback(() => {
    dispatch(downloadAppBannerAction())
    setCookie('download_app_banner', 'false', 1)
  }, [dispatch])

  if (!open) return null

  return (
    <BannerContainer>
      <BannerLogo>
        {companyLabel === 'Viverno' ? <VivernoBannerImg /> : <BDSwissBannerImg />}
        <BannerLogoWrapper>
          <BannerTitle>
            <Trans
              i18nKey={'downloadMobileAppBannerTitle'}
              values={{ companyLabel }}
              defaults={`${companyLabel} App`}
            />
          </BannerTitle>
          <BannerSubTitle>
            <Trans i18nKey={'downloadMobileAppBannerSubtitle'} defaults={'Download & start trading'} />
          </BannerSubTitle>
        </BannerLogoWrapper>
      </BannerLogo>
      <BannerActions>
        <BannerOpenButton data-id={'app-banner'} onClick={handleDownloadMobileApp}>
          <Trans i18nKey={'open'} defaults={'Open'} />
        </BannerOpenButton>
        <BannerCloseButton onClick={handleClose} />
      </BannerActions>
      <DownloadMobileAppDialog />
    </BannerContainer>
  )
}
