import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Trans } from 'react-i18next'

import { LabelValueTooltip } from '../../Controls/LabelValueTooltip'
import { formTotalValueSelector } from '../../../../store/selectors'
import HelpTooltip from '../../../TopMenu/HelpTooltip/HelpTooltip'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  contractValue: {
    display: 'inline-block',
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: '0.02rem',
    '& span:nth-child(2)': {
      transition: 'all .15s ease-in,transform .15s ease-in,-webkit-transform .15s ease-in',
    },
  },
}))

const ContractValue: FC = () => {
  const classes = useStyles()
  const value = useSelector(formTotalValueSelector)

  return (
    <div className={classes.container}>
      <div className={classes.contractValue}>
        <LabelValueTooltip label={'contractValue'} value={value} />
      </div>

      <HelpTooltip hint={<Trans i18nKey={'helpContractValue'} />} />
    </div>
  )
}

export default ContractValue
