import React, { FC, useCallback } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { IconButton } from '@mui/material'
import { ReactComponent as BackButton } from '../../../assets/images/icons/mobile/back-button.svg'
import { ReactComponent as Reload } from '../../../assets/images/icons/reloadFeeds.svg'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'

import { activeFeedPanelSelector, activeTabSelector, tradingIdeasStatusSelector } from '../../../store/selectors'
import { Location, FeedPanel } from '../../../enums'
import {
  clearSelectedOpenedPosition,
  clearSelectedPendingPosition,
  getTIItemsRequest,
  setActivePanelAction,
  setMobileBackButtonData,
  toggleTradingFormPanel,
} from '../../../store/actions'
import { MenuItemsNames } from '../../pages/menuBarList'

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px',
    paddingLeft: '20px',
  },
  title: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  reload: {
    position: 'absolute',
    right: 18,
    '& svg': {
      width: 16,
      height: 16,
    },
  },
}))

export const BackButtonMobile: FC<{ show: boolean; title: string }> = ({ show, title }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const tab = useSelector(activeTabSelector)
  const activeFeedPanel = useSelector(activeFeedPanelSelector)
  const ideasStatus = useSelector(tradingIdeasStatusSelector)

  const handleBack = useCallback(() => {
    if (tab === Location.trade) {
      dispatch(toggleTradingFormPanel(false))
      dispatch(setMobileBackButtonData(undefined))
    }
    if (tab === Location.positions) {
      dispatch(clearSelectedOpenedPosition())
      dispatch(setMobileBackButtonData(undefined))
    }
    if (tab === Location.order) {
      dispatch(clearSelectedPendingPosition())
      dispatch(setMobileBackButtonData(undefined))
    }
    if (tab === Location.chart) {
      dispatch(toggleTradingFormPanel(false))
      dispatch(setMobileBackButtonData(undefined))
    }
    if ([MenuItemsNames.more as string, Location.history].includes(tab)) {
      dispatch(setActivePanelAction(null))
      history.goBack()
    }
  }, [dispatch, history, tab])

  const reloadTradingIdeas = useCallback(() => {
    dispatch(getTIItemsRequest())
  }, [dispatch])

  return show ? (
    <div className={classes.main}>
      <IconButton edge={'start'} size="small" onClick={handleBack}>
        <BackButton fontSize={'inherit'} />
      </IconButton>
      <div className={classes.title}>
        {(() => {
          switch (tab) {
            case Location.trade:
            case Location.chart:
              return <Trans i18nKey={'newPosition'} defaults={'New Position'} />
            case Location.positions:
            case Location.order:
              return <Trans i18nKey={'editAnOrder'} defaults={'Edit an Order'} />
            default:
              return title
          }
        })()}
      </div>
      {activeFeedPanel === FeedPanel.tradingIdeas && (
        <IconButton disabled={ideasStatus === 'Fetching'} className={classes.reload} onClick={reloadTradingIdeas}>
          <Reload />
        </IconButton>
      )}
    </div>
  ) : null
}
