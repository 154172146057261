import React from 'react'
import { CellProps, Renderer } from 'react-table'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'

import { isCurrentAccountLeverageTypeDynamicSelector } from '../../../store/selectors'

export const LeverageCell: Renderer<CellProps<Record<string, unknown>>> = ({ value }) => {
  const isCurrentAccountLeverageTypeDynamic = useSelector(isCurrentAccountLeverageTypeDynamicSelector)
  return isCurrentAccountLeverageTypeDynamic ? <Trans i18nKey="wtDynamic" defaults="Dynamic" /> : <span>1:{value}</span>
}
