import { gql } from '@apollo/client'

export const GET_CLOSED_POSITIONS = gql`
  query closedPositions($login: Int!, $server: MT4ServerType!, $limit: Int!, $offset: Int!) {
    closedPositions(login: $login, server: $server, limit: $limit, offset: $offset) {
      id
      closeTime
      symbol
      profit
      type
      investment
      leverage
      openPrice
      closePrice
      volume
      commission
      swaps
      taxes
      __typename
    }
  }
`
