import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  fromPromise,
  ApolloQueryResult,
  FetchResult,
} from '@apollo/client'
import { ServerError } from '@apollo/client/link/utils'
import { onError } from '@apollo/client/link/error'

import { GET_USER_DATA } from './queries/user'
import { GET_CLOSED_POSITIONS } from './queries/closedPositions'
import { GET_FAVOURITES } from './queries/getFavourites'
import { UPDATE_FAVOURITES } from './queries/updateFavourites'
import { CREATE_IB_SINGLE_SIGN_ON_LINK } from './queries/createIBSingleSignOnLink'
import { CREATE_AFFILIATE_SINGLE_SIGN_ON_LINK } from './queries/createAffiliateSingleSignOnLink'
import { TRADING_CENTRAL_LINK } from './queries/getTradingCentralLink'
import { UPDATE_THEME_PREFERENCE } from './queries/updateThemePreference'
import { TRADING_IDEAS } from './queries/getTradingIdeas'
import possibleTypes from './possibleTypes.json'
import {
  UserResponse,
  ClosedPositionsResponse,
  GetFavoritesResponse,
  UpdateFavouritesResponse,
  TradingCentralLink,
  TradingIdeas,
} from '../../store/types'
import { genApiUrl } from '../../utils'
import {
  IbLink,
  AffiliateLink,
  BdSwissMutationsCreateIbSingleSignOnLinkArgs,
  RootQueryTypeCreateAffiliateSingleSignOnLinkArgs,
} from './types'

const errorLink = onError(({ networkError, forward, operation }) => {
  if (networkError && (networkError as ServerError)?.statusCode === 404) {
    return fromPromise(
      fetch(`${genApiUrl('/auth/create-demo-account')}`, {
        credentials: 'include',
        method: 'POST',
      }),
    ).flatMap(() => forward(operation))
  }
})

const httpLink = new HttpLink({
  uri: () => genApiUrl(),
  credentials: 'include',
})

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes,
  }),
})

export const getUser = (): Promise<ApolloQueryResult<UserResponse>> =>
  client.query<UserResponse>({
    query: GET_USER_DATA,
  })

export type QVariables = {
  limit: number
  login: number
  offset: number
  server: string
}

export type TCVariables = {
  page: string
  theme: string
}
export type TIVariables = {
  typeProduct?: string
  product?: string
  term?: string
  days?: number
  lastTa?: boolean
  taday?: boolean
  country?: string
  sectorid?: string
  title?: string
  pageSize?: number
  pageNumber?: number
}

export const getClosedPositions = ({
  limit,
  login,
  offset,
  server,
}: QVariables): Promise<ApolloQueryResult<ClosedPositionsResponse>> =>
  client.query<ClosedPositionsResponse, QVariables>({
    query: GET_CLOSED_POSITIONS,
    variables: {
      limit: limit,
      login: login,
      offset: offset,
      server: server,
    },
    fetchPolicy: 'network-only',
  })

export const getFavourites = (): Promise<ApolloQueryResult<GetFavoritesResponse>> =>
  client.query<GetFavoritesResponse>({
    query: GET_FAVOURITES,
  })

export type UpdateFavouritesVariables = {
  favourites: string[]
}

export const updateFavourites = ({
  favourites,
}: UpdateFavouritesVariables): Promise<FetchResult<UpdateFavouritesResponse>> =>
  client.mutate<UpdateFavouritesResponse, UpdateFavouritesVariables>({
    mutation: UPDATE_FAVOURITES,
    variables: {
      favourites: favourites,
    },
  })

export const createAffiliateSingleSignOnLink = ({
  accountId,
}: RootQueryTypeCreateAffiliateSingleSignOnLinkArgs): Promise<ApolloQueryResult<{ data: AffiliateLink }>> => {
  return client.query({
    query: CREATE_AFFILIATE_SINGLE_SIGN_ON_LINK,
    variables: {
      accountId,
    },
  })
}

export const createIBSingleSignOnLink = ({
  accountId,
  ibId,
}: BdSwissMutationsCreateIbSingleSignOnLinkArgs): Promise<FetchResult<{ data: IbLink }>> => {
  return client.mutate<{ data: IbLink }, BdSwissMutationsCreateIbSingleSignOnLinkArgs>({
    mutation: CREATE_IB_SINGLE_SIGN_ON_LINK,
    variables: {
      accountId,
      ibId,
    },
  })
}

export type UpdateThemePreferenceVariables = {
  themePreference: string
}
export const updateThemePreference = ({
  themePreference,
}: UpdateThemePreferenceVariables): Promise<FetchResult<UserResponse>> =>
  client.mutate<UserResponse, UpdateThemePreferenceVariables>({
    mutation: UPDATE_THEME_PREFERENCE,
    variables: {
      themePreference,
    },
  })

export const getTradingCentralLink = ({ page, theme }: TCVariables): Promise<ApolloQueryResult<TradingCentralLink>> =>
  client.query<TradingCentralLink, TCVariables>({
    query: TRADING_CENTRAL_LINK,
    variables: { page, theme },
    fetchPolicy: 'network-only',
  })

export const getTradingIdeas = ({
  typeProduct,
  product,
  term,
  days,
  lastTa,
  taday,
  country,
  sectorid,
  title,
  pageSize,
  pageNumber,
}: TIVariables): Promise<ApolloQueryResult<TradingIdeas>> =>
  client.query<TradingIdeas, TIVariables>({
    query: TRADING_IDEAS,
    variables: { typeProduct, product, term, days, lastTa, taday, country, sectorid, title, pageSize, pageNumber },
    fetchPolicy: 'network-only',
  })
