import React, { FC, useEffect } from 'react'
import { styled } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'

import { TradingForm } from '../../../Forms/TradingForm'
import MarketsCategories from './MarketsCategories'
import MarketsSearchInput from './MarketsSearchInput'
import MarketsTable from './MarketsTable'
import { tradingFormPanelSelector } from '../../../../store/selectors'
import { setMobileBackButtonData } from '../../../../store/actions'

const MarketsPanelWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const MarketsPanel: FC = () => {
  const dispatch = useDispatch()
  const formPanel = useSelector(tradingFormPanelSelector)
  useEffect(() => {
    dispatch(setMobileBackButtonData(undefined))
  }, [dispatch])
  return !formPanel ? (
    <MarketsPanelWrap>
      <MarketsCategories />
      <MarketsSearchInput />
      <MarketsTable />
    </MarketsPanelWrap>
  ) : (
    <TradingForm />
  )
}

export default MarketsPanel
