import { createReducer } from 'typesafe-actions'

import { FeedPanel } from '../../enums'
import { ActionTypes } from '../types'

import {
  getTIItemsFailure,
  getTIItemsRequest,
  getTIItemsSuccess,
  setActivePanelAction,
  toggleExpandedTIItem,
} from '../actions'

export type FeedItem = {
  id: string
  expanded: boolean
  name: string
  date: string
  hour: string
  product: string
  media: string
  alternativeMedia: Array<{
    theme: string
    width: string
    image: string
  }>
  option: {
    opinionIntraday: string
    opinionST: string
    opinionMT: string
  }
  code: Array<{ type: string; key: string }>
  title: string
  summary: string
  term: string
}

const SUPPORTED_TRADE_TYPES = ['forex', 'crypto', 'index', 'stock']

export type FeedState = {
  activePanel: FeedPanel | null
  panels: Record<
    FeedPanel,
    {
      status: 'Pending' | 'Fetching' | 'Ready' | 'Failed' | string
      data: Record<string, FeedItem>
    }
  >
}

const initialState = {
  activePanel: FeedPanel.tradingIdeas,
  panels: {
    tradingIdeas: { status: 'Pending', data: {} },
    economicCalendar: { status: 'Ready', data: {} },
  },
}

export const feed = createReducer<FeedState, ActionTypes>(initialState)
  .handleAction(setActivePanelAction, (state, { payload }) => ({
    ...state,
    activePanel: payload,
  }))
  .handleAction(getTIItemsRequest, (state) => ({
    ...state,
    panels: {
      ...state.panels,
      tradingIdeas: {
        status: 'Fetching',
        data: {},
      },
    },
  }))
  .handleAction(getTIItemsSuccess, (state, { payload }) => ({
    ...state,
    panels: {
      ...state.panels,
      tradingIdeas: {
        status: 'Ready',
        data: Object.assign(
          {},
          ...payload
            .filter((item) => SUPPORTED_TRADE_TYPES.includes(item.product))
            .map((item) => ({ [item.id]: item })),
        ),
      },
    },
  }))
  .handleAction(getTIItemsFailure, (state) => ({
    ...state,
    panels: {
      ...state.panels,
      tradingIdeas: {
        status: 'Failed',
        data: {},
      },
    },
  }))
  .handleAction(toggleExpandedTIItem, (state, { payload: id }) => ({
    ...state,
    panels: {
      ...state.panels,
      tradingIdeas: {
        ...state.panels.tradingIdeas,
        data: {
          ...Object.values(state.panels.tradingIdeas.data).reduce(
            (prevValue, currentValue) => ({
              ...prevValue,
              [currentValue.id]: {
                ...currentValue,
                expanded: false,
              },
            }),
            {},
          ),
          [id]: {
            ...state.panels.tradingIdeas.data[id],
            expanded: !state.panels.tradingIdeas.data[id].expanded,
          },
        },
      },
    },
  }))
