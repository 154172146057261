import React, { FC, useCallback } from 'react'
import { TooltipRenderProps } from 'react-joyride'
import { makeStyles } from '@mui/styles'

import { MT4Connector } from '@bdswiss/mt4-connector'

import guidedTourLoadingSvg from '../../../images/guided-tour-loading.svg'

const useStyles = makeStyles((theme) => ({
  tooltipBody: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    minWidth: '290px',
    maxWidth: '420px',
    position: 'relative',
    borderRadius: 4,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(255 255 255 / 25%)'
        : '',
  },
  tooltipContent: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: '20px',
  },
  tooltipTitle: {
    color: '#f04',
    padding: '20px',
    margin: 0,
  },
  tooltipFooter: {
    marginTop: '1rem',
    paddingBottom: 15,
  },
  button: {
    backgroundColor: '#007AFF',
    color: '#fff',
    textTransform: 'none',
    border: 0,
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: 1,
    padding: 8,
    appearance: 'none',
  },
}))

const mt4Connector = MT4Connector.Instance

export const LoadingStep: FC<TooltipRenderProps> = ({
  continuous,
  step,
  primaryProps: { onClick, ...restPrimaryProps },
  tooltipProps,
}) => {
  const classes = useStyles()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setTimeout(() => {
        mt4Connector.adjustPricesToMatchProfit([
          { symbol: 'EURUSD', openPrice: 1.1194, orderType: 'sell', targetProfit: 57540000, volume: 750000 },
          { symbol: 'USOIL', openPrice: 61.776, orderType: 'buy', targetProfit: -30000, volume: 5000 },
        ])
        onClick && onClick(event)
      }, 1000)
    },
    [onClick],
  )
  return (
    <div className={classes.tooltipBody} {...tooltipProps}>
      <img alt="loading" src={guidedTourLoadingSvg} />
      {step.title && <h2 className={classes.tooltipTitle}>{step.title}</h2>}
      <div className={classes.tooltipContent}>{step.content}</div>
      <div className={classes.tooltipFooter}>
        <button className={classes.button} {...restPrimaryProps} onClick={handleClick}>
          <span id={continuous ? 'next' : 'close'}>{restPrimaryProps.title}</span>
        </button>
      </div>
    </div>
  )
}
