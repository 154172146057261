import { createSelector } from 'reselect'

import { RootState } from '../types'
import { FormState } from '../reducers/form'
import { currentMarginCurrencySelector } from './prices'
import { currencyFormat } from '../../utils'
import { activeTabSelector, tradeModeSelector, mainCurrencySelector, loginInfoSelector } from './app'
import { Location, OrderTypes, TradeMode } from '../../enums'
import i18next from '../../i18next'

export const formSubmitEnabledSelector = (state: RootState): FormState['submitEnabled'] => state.form.submitEnabled
export const formLotsSelector = (state: RootState): FormState['lots'] => state.form.lots
export const formAmountSelector = (state: RootState): FormState['amount'] => state.form.amount
export const formValueSelector = (state: RootState): FormState['value'] => state.form.value
export const formValueAccountSelector = (state: RootState): FormState['valueAccount'] => state.form.valueAccount
export const formTotalValueSelector = createSelector(
  formValueSelector,
  currentMarginCurrencySelector,
  mainCurrencySelector,
  formValueAccountSelector,
  (value, marginCurrency, accountCurrency, valueAccount) =>
    marginCurrency !== accountCurrency
      ? currencyFormat(valueAccount, accountCurrency)
      : currencyFormat(value, marginCurrency),
)
export const formRequiredMarginSelector = (state: RootState): FormState['requiredMargin'] => state.form.requiredMargin
export const formRequiredMarginAccountSelector = (state: RootState): FormState['requiredMarginAccount'] =>
  state.form.requiredMarginAccount
export const transformedRequiredMarginSelector = createSelector(
  formRequiredMarginAccountSelector,
  loginInfoSelector,
  (requiredMargin, loginInfo) => {
    if (!loginInfo) return undefined
    const { currency: accountCurrency } = loginInfo
    return currencyFormat(requiredMargin, accountCurrency)
  },
)

export const formTakeProfitEnabledSelector = (state: RootState): FormState['takeProfitEnabled'] =>
  state.form.takeProfitEnabled

export const formTakeProfitSelector = (state: RootState): FormState['takeProfit'] => state.form.takeProfit
export const formStopLossEnabledSelector = (state: RootState): FormState['stopLossEnabled'] =>
  state.form.stopLossEnabled
export const formOrderTypeSelector = (state: RootState): FormState['formOrderType'] => state.form.formOrderType
export const formStopLossSelector = (state: RootState): FormState['stopLoss'] => state.form.stopLoss
export const formLossSelector = (state: RootState): FormState['loss'] => state.form.loss
export const formLossPercentSelector = (state: RootState): FormState['lossPercent'] => state.form.lossPercent
export const formProfitSelector = (state: RootState): FormState['profit'] => state.form.profit
export const formProfitPercentSelector = (state: RootState): FormState['profitPercent'] => state.form.profitPercent
export const formStopPriceSelector = (state: RootState): FormState['stopPrice'] => state.form.stopPrice
export const formPriceLimitSelector = (state: RootState): FormState['priceLimit'] => state.form.priceLimit
export const formPriceDifferenceSelector = (state: RootState): FormState['priceDifference'] =>
  state.form.priceDifference

export const formPriceDifferencePercentSelector = (state: RootState): FormState['priceDifferencePercent'] =>
  state.form.priceDifferencePercent

export const formValuesSelector = createSelector(
  formTakeProfitSelector,
  formStopLossSelector,
  formPriceLimitSelector,
  formTakeProfitEnabledSelector,
  formStopLossEnabledSelector,
  (takeProfit, stopLoss, priceLimit, ...restValues) => [
    takeProfit.value,
    stopLoss.value,
    priceLimit.value,
    ...restValues,
  ],
)

export const initialFormValues = createSelector(
  (state: RootState): FormState['initialStateValue'] => state.form.initialStateValue,
  (values) => values,
)

export const isFormFieldValuesValidSelector = createSelector(
  formAmountSelector,
  formTakeProfitSelector,
  formStopLossSelector,
  formPriceLimitSelector,
  formStopPriceSelector,
  (...fields) => fields.every((field) => field.fieldInformation.isValid),
)

export const formSlSelector = createSelector(
  activeTabSelector,
  formStopLossEnabledSelector,
  formStopLossSelector,
  formLossSelector,
  (tab, slEnabled, sl, loss) => {
    return (tab === Location.trade || tab === Location.positions || tab === Location.order) && slEnabled
      ? {
          label: i18next.t('stopLoss'),
          value: sl.value,
          amount: loss,
        }
      : undefined
  },
)

export const formTpSelector = createSelector(
  activeTabSelector,
  formTakeProfitEnabledSelector,
  formTakeProfitSelector,
  formProfitSelector,
  (tab, tpEnabled, tp, profit) => {
    return (tab === Location.trade || tab === Location.positions || tab === Location.order) && tpEnabled
      ? {
          label: i18next.t('takeProfit'),
          value: tp.value,
          amount: profit,
        }
      : undefined
  },
)

export const formPlSelector = createSelector(
  tradeModeSelector,
  activeTabSelector,
  formPriceLimitSelector,
  formPriceDifferenceSelector,
  formOrderTypeSelector,
  (tradeMode, tab, pl, difference, orderType) => {
    return tab === Location.trade && [OrderTypes.pendingOrder, OrderTypes.stopLimitOrder].includes(orderType)
      ? {
          label: i18next.t(tradeMode === TradeMode.buy ? 'buyAt' : 'sellAt'),
          value: pl.value,
          amount: difference,
        }
      : undefined
  },
)

export const formPOSelector = createSelector(
  activeTabSelector,
  formPriceLimitSelector,
  (state: RootState) => state.app.selectedPendingPosition,
  (tab, pl, selectedPendingPosition) => {
    return tab === Location.order && selectedPendingPosition
      ? {
          id: selectedPendingPosition,
          value: pl.value,
        }
      : undefined
  },
)

export const formSymbolExposureSelector = (state: RootState): FormState['symbolExposure'] => state.form.symbolExposure

export const slTpInputSelector = (state: RootState): { slInput: number; tpInput: number } => {
  const {
    takeProfitEnabled,
    takeProfit: { value: takeProfitValue },
    stopLossEnabled,
    stopLoss: { value: stopLossValue },
  } = state.form

  return {
    slInput: stopLossEnabled ? stopLossValue : 0,
    tpInput: takeProfitEnabled ? takeProfitValue : 0,
  }
}
