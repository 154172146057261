import React, { useCallback, MouseEvent } from 'react'
import { CellProps, Renderer } from 'react-table'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Price, calculateDailyReturn, AssetTradeMode } from '@bdswiss/mt4-connector'

import { currencyFormat, isMobile, numberFormat } from '../../../utils'
import { appChangeActiveAsset, setMobileBackButtonData, toggleTradingFormPanel } from '../../../store/actions'
import { TradeMode } from '../../../enums'
import { RootState } from '../../../store/types'
import { getActiveButtonSelector, isMarketOpenedBySymbolSelector } from '../../../store/selectors'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles<Theme, { isMarketClosed?: boolean }>((theme) => ({
  buySellIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '16px',
    height: '16px',
    left: '5px',
  },
  positive: {
    color: ({ isMarketClosed }) => (isMarketClosed ? '#C2C2C2' : theme.palette.green.mint),
  },
  negative: {
    color: ({ isMarketClosed }) => (isMarketClosed ? '#C2C2C2' : theme.palette.secondary.main),
  },
  dailyPositive: {
    color: ({ isMarketClosed }) => (isMarketClosed ? '#C2C2C2' : theme.palette.success.main),
  },
  dailyNegative: {
    color: ({ isMarketClosed }) => (isMarketClosed ? '#C2C2C2' : theme.palette.secondary.main),
  },
  sell: {
    display: 'flex',
    width: 'calc(100% - 8px)',
    cursor: 'pointer',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: 24,
    backgroundColor: ({ isMarketClosed }) =>
      isMarketClosed
        ? `${theme.palette.mode === 'light' ? '#FAFAFA' : '#1a1a1a'}`
        : `${theme.palette.secondary.main}${theme.palette.mode === 'light' ? '1A' : '4D'}`,
    border: 'none',
    padding: '0 4px',
    color: theme.palette.secondary.main,
    ...(isMobile() && {
      minWidth: 109,
      borderRadius: 2,
      margin: 4,
      fontFamily: 'Roboto Condensed',
    }),
    '&:disabled': {
      ...(isMobile()
        ? {
            background: `${theme.palette.mode === 'light' ? '#FAFAFA' : '#1a1a1a'}`,
            color: '#999',
            '& svg': { color: '#999' },
          }
        : {
            backgroundColor: 'rgba(131,131,131,0.2)',
            cursor: 'not-allowed',
          }),
    },
  },
  buy: {
    display: 'flex',
    width: 'calc(100% - 8px)',
    position: 'relative',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: 24,
    backgroundColor: ({ isMarketClosed }) =>
      isMarketClosed
        ? `${theme.palette.mode === 'light' ? '#FAFAFA' : '#1a1a1a'}`
        : `${theme.palette.success.main}${theme.palette.mode === 'light' ? '1A' : '4D'}`,
    border: 'none',
    padding: '0 4px',
    color: theme.palette.green.mint,
    ...(isMobile() && {
      minWidth: 109,
      borderRadius: 2,
      margin: 4,
      fontFamily: 'Roboto Condensed',
    }),
    '&:disabled': {
      ...(isMobile()
        ? {
            background: `${theme.palette.mode === 'light' ? '#FAFAFA' : '#1a1a1a'}`,
            color: '#999',
            '& svg': { color: '#999' },
          }
        : {
            backgroundColor: 'rgba(131,131,131,0.2)',
            cursor: 'not-allowed',
          }),
    },
  },
  activeSell: {
    backgroundColor: `${theme.palette.secondary.main}${theme.palette.mode === 'light' ? '33' : '66'}`,
  },
  activeBuy: {
    backgroundColor: `${theme.palette.success.main}${theme.palette.mode === 'light' ? '33' : '66'}`,
  },
  buttonActionTitle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  buttonValue: {
    fontSize: 14,
    padding: '5px 0',
    flexGrow: 1,
    marginRight: '6px',
    textAlign: 'right',
  },
  icon: {
    padding: '6px 0 0 6px',
    '& svg': {
      fontSize: 14,
    },
  },
  direction: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    textTransform: 'uppercase',
    overflow: 'hidden',
  },
  marketClosedText: {
    color: '#999999',
  },
}))

export const DailyChangeCell: Renderer<CellProps<Price, number>> = ({ row: { original: asset } }) => {
  const value = calculateDailyReturn('bid', asset)
  const isMarketOpened = useSelector((state: RootState) => isMarketOpenedBySymbolSelector(state, asset.symbol))
  const classes = useStyles({
    isMarketClosed: !isMarketOpened,
  })

  return (
    <div
      className={clsx({
        [classes.dailyNegative]: value < 0,
        [classes.dailyPositive]: value >= 0,
        [classes.marketClosedText]: !isMarketOpened,
      })}
    >
      {numberFormat(value, {
        maximumFractionDigits: 2,
      })}
      %
    </div>
  )
}

export const SellCell: Renderer<CellProps<Price, Price['mbid']>> = ({
  value,
  row: {
    original: { symbol, trade, digits, currency },
  },
}) => {
  const isMarketOpened = useSelector((state: RootState) => isMarketOpenedBySymbolSelector(state, symbol))
  const classes = useStyles({
    isMarketClosed: !isMarketOpened,
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const disabled = ![AssetTradeMode.enable, AssetTradeMode.sellOnly].includes(trade)
  const isActive = useSelector((state: RootState) =>
    getActiveButtonSelector(state, { symbol, tradeMode: TradeMode.sell }),
  )
  const clickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      dispatch(appChangeActiveAsset(symbol, { tradeMode: TradeMode.sell }))

      if (isMobile() && isMarketOpened && !disabled) {
        dispatch(toggleTradingFormPanel(true))
        dispatch(setMobileBackButtonData({ show: true, title: symbol }))
      }
    },
    [disabled, dispatch, isMarketOpened, symbol],
  )

  return (
    <button
      className={clsx(
        {
          [classes.activeSell]: isActive,
          [classes.marketClosedText]: !isMarketOpened,
        },
        classes.sell,
      )}
      title={`${numberFormat(value, { maximumFractionDigits: digits, useGrouping: false })} ${t('sell')}`}
      onClick={clickHandler}
      disabled={disabled}
    >
      <div className={classes.direction}>
        <div className={classes.buttonValue}>
          {!isMobile()
            ? numberFormat(value, {
                maximumFractionDigits: digits,
                useGrouping: false,
              })
            : currencyFormat(value, currency, { maximumFractionDigits: digits })}
        </div>
      </div>
      <CallReceivedIcon className={clsx(classes.negative, classes.buySellIcon)} />
    </button>
  )
}

export const BuyCell: Renderer<CellProps<Price, Price['mask']>> = ({
  value,
  row: {
    original: { symbol, trade, digits, currency },
  },
}) => {
  const isMarketOpened = useSelector((state: RootState) => isMarketOpenedBySymbolSelector(state, symbol))
  const classes = useStyles({
    isMarketClosed: !isMarketOpened,
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const disabled = ![AssetTradeMode.enable, AssetTradeMode.buyOnly].includes(trade)
  const isActive = useSelector((state: RootState) =>
    getActiveButtonSelector(state, { symbol, tradeMode: TradeMode.buy }),
  )
  const clickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      dispatch(appChangeActiveAsset(symbol, { tradeMode: TradeMode.buy }))

      if (isMobile() && isMarketOpened && !disabled) {
        dispatch(toggleTradingFormPanel(true))
        dispatch(setMobileBackButtonData({ show: true, title: symbol }))
      }
    },
    [disabled, dispatch, isMarketOpened, symbol],
  )

  return (
    <button
      className={clsx(
        {
          [classes.activeBuy]: isActive,
          [classes.marketClosedText]: !isMarketOpened,
        },
        classes.buy,
      )}
      title={`${numberFormat(value, { maximumFractionDigits: digits, useGrouping: false })} ${t('buy')}`}
      onClick={clickHandler}
      disabled={disabled}
    >
      <div className={classes.direction}>
        <div className={classes.buttonValue}>
          {!isMobile()
            ? numberFormat(value, {
                maximumFractionDigits: digits,
                useGrouping: false,
              })
            : currencyFormat(value, currency, { maximumFractionDigits: digits })}
        </div>
      </div>
      <CallMadeIcon className={clsx(classes.positive, classes.buySellIcon)} />
    </button>
  )
}
