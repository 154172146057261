import { createReducer } from 'typesafe-actions'

import { SymbolExposure } from '@bdswiss/mt4-connector'

import {
  setSubmitEnabled,
  setTakeProfitEnabled,
  setStopLossEnabled,
  updateFormValues,
  formUpdateField,
  setSymbolExposure,
  setFormOrderStatus,
  initialStateValuesForModifying,
  resetInputValue,
  clearSymbolExposure,
} from '../actions'
import { ActionTypes, FormField } from '../types'
import { OrderTypes, TradeMode } from '../../enums'

export type FieldInfo = {
  canIncrease: boolean
  canDecrease: boolean
  isValid: boolean
  errorText?: string
}

export type FormState = {
  submitEnabled: boolean
  lots: number
  value: number
  valueAccount: number
  requiredMargin: number
  requiredMarginAccount: number
  takeProfitEnabled: boolean
  stopLossEnabled: boolean
  loss: number
  lossPercent: number
  profit: number
  profitPercent: number
  priceDifference: number
  priceDifferencePercent: number
  amount: FormField
  takeProfit: FormField
  takeProfitInitial: number
  stopLoss: FormField
  stopLossInitial: number
  priceLimit: FormField
  priceLimitInitial: number
  stopPrice: FormField
  isPendingOrder: boolean
  placedOrderTradeMode?: Omit<TradeMode, TradeMode.disabled>
  symbolExposure: SymbolExposure
  formOrderType: OrderTypes
  initialStateValue: Array<number | boolean>
  dependedAmountValue: number
  priceLimitEnabled: boolean
}

const formFieldFactory = (name: string) => ({
  name,
  value: 0,
  fieldInformation: {
    canIncrease: false,
    canDecrease: false,
    isValid: true,
    errorText: undefined,
  },
})

const initialState: FormState = {
  submitEnabled: true,
  lots: 0,
  value: 0,
  valueAccount: 0,
  requiredMargin: 0,
  requiredMarginAccount: 0,
  takeProfitEnabled: false,
  stopLossEnabled: false,
  loss: 0,
  lossPercent: 0,
  profit: 0,
  profitPercent: 0,
  priceDifference: 0,
  priceDifferencePercent: 0,
  amount: formFieldFactory('amount'),
  takeProfit: formFieldFactory('takeProfit'),
  takeProfitInitial: 0,
  stopLoss: formFieldFactory('stopLoss'),
  stopLossInitial: 0,
  priceLimit: formFieldFactory('priceLimit'),
  priceLimitInitial: 0,
  stopPrice: formFieldFactory('stopPrice'),
  isPendingOrder: false,
  formOrderType: OrderTypes.marketOrder,
  symbolExposure: {},
  initialStateValue: [],
  dependedAmountValue: 0,
  priceLimitEnabled: false,
}

export const form = createReducer<FormState, ActionTypes>(initialState)
  .handleAction(formUpdateField, (state, { payload: { field, value, fieldInformation } }) => ({
    ...state,
    [field]: {
      ...state[field],
      value,
      fieldInformation,
    },
  }))
  .handleAction(setTakeProfitEnabled, (state, { payload }) => ({
    ...state,
    takeProfitEnabled: payload,
  }))
  .handleAction(setStopLossEnabled, (state, { payload }) => ({
    ...state,
    stopLossEnabled: payload,
  }))
  .handleAction(updateFormValues, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setSubmitEnabled, (state, { payload }) => ({
    ...state,
    submitEnabled: payload,
  }))
  .handleAction(setSymbolExposure, (state, { payload }) => ({
    ...state,
    symbolExposure: payload,
  }))
  .handleAction(clearSymbolExposure, (state) => ({
    ...state,
    symbolExposure: {},
  }))
  .handleAction(setFormOrderStatus, (state, { payload }) => ({
    ...state,
    formOrderType: payload,
  }))
  .handleAction(initialStateValuesForModifying, (state, { payload: initialStateValue }) => ({
    ...state,
    initialStateValue,
  }))
  .handleAction(resetInputValue, (state, { payload: { key, value } }) => ({
    ...state,
    [key]: {
      ...state[key],
      value,
    },
  }))
