import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import clsx from 'clsx'

import BentoMenuListIcon from './BentoMenuListIcon'
import { getMenuItemsForClient } from './utils'
import {
  accountsDataSelector,
  userCompanySelector,
  userLocaleSelector,
  userWhiteLabelSelector,
} from '../../store/selectors'

const BentoMenuListWrap = styled('div')(({ theme }) => ({
  width: 552,
  padding: 24,
  [theme.breakpoints.down('md')]: {
    padding: '26px 0 8px 26px',
    width: 260,
  },
}))

const BentoMenuGrid = styled(Grid)(({ theme }) => ({
  padding: 0,
  '& .MuiGrid-root': {
    width: 'calc(100% + 24px)',
  },
  '& .MuiGrid-item': {
    [theme.breakpoints.up('md')]: {
      '&:not(:nth-of-type(-n+2))': {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
  [theme.breakpoints.down('md')]: {
    '&.divider': {
      paddingTop: 0,
      '+.MuiGrid-item': {
        paddingTop: 0,
      },
    },
  },
}))

const BentoDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#EBEBEB',
  margin: '8px 0',
  [theme.breakpoints.down('md')]: {
    margin: '4px 0',
  },
}))

const BentoMenuListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: 8,
  padding: 14,
  transition: '0.2s',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      background: theme.palette.mode === 'dark' ? '#2A2A2A' : '#FAFAFA',
    },
  },
}))

const BentoMenuListTitle = styled('div')(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  fontSize: 14,
  paddingLeft: 14,
}))

const BentoMenuListSubtitle = styled('div')({
  paddingLeft: 27,
  fontSize: 10,
  letterSpacing: '0.4px',
  color: '#858585',
})

interface Props {
  onClose: () => void
}

const BentoMenuList: FC<Props> = ({ onClose }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const accounts = useSelector(accountsDataSelector)
  const company = useSelector(userCompanySelector)
  const whiteLabel = useSelector(userWhiteLabelSelector)
  const locale = useSelector(userLocaleSelector)

  return (
    <BentoMenuListWrap>
      <BentoMenuGrid container spacing={2}>
        {getMenuItemsForClient({ accounts, company, whiteLabel, locale })?.map((item) => (
          <BentoMenuGrid
            className={clsx({ divider: item.id === 'Divider' })}
            md={item.id === 'Divider' ? 12 : 6}
            xs={12}
            key={item.id}
            flexDirection={'column'}
            item
          >
            {item.id === 'Divider' ? (
              <BentoDivider />
            ) : (
              <BentoMenuListItem
                onClick={() => {
                  item?.handle?.()
                  onClose?.()
                }}
              >
                <Box display={'flex'} flexDirection={'column'}>
                  <Box display={'flex'} flexDirection={'row'}>
                    <BentoMenuListIcon id={item.id} />
                    <BentoMenuListTitle>
                      <Trans
                        i18nKey={item.title.translationKey}
                        values={item.title.translationValues}
                        defaults={item.title.defaultTranslation}
                      />
                    </BentoMenuListTitle>
                  </Box>
                  {!isMobile && item?.subtitle && (
                    <BentoMenuListSubtitle>
                      <Trans
                        i18nKey={item.subtitle.translationKey}
                        values={item.title.translationValues}
                        defaults={item.subtitle.defaultTranslation}
                      />
                    </BentoMenuListSubtitle>
                  )}
                </Box>
              </BentoMenuListItem>
            )}
          </BentoMenuGrid>
        ))}
      </BentoMenuGrid>
    </BentoMenuListWrap>
  )
}

export default BentoMenuList
