import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { NumberInputLabel } from '../../Controls/NumberInputLabel'
import { currentSymbolDigitsSelector, formStopPriceSelector } from '../../../../store/selectors'
import { numberFormat } from '../../../../utils'
import { NumberInput } from '../../Controls/NumberInput'

export const StopOrderInput: FC<{ currency: string }> = ({ currency }) => {
  const { t } = useTranslation()
  const precision = useSelector(currentSymbolDigitsSelector)
  const { value, fieldInformation } = useSelector(formStopPriceSelector)

  const handleValue = useMemo(() => {
    const numberValue = numberFormat(value, {
      maximumFractionDigits: precision,
    })
    return numberValue ? numberValue.toString() : ''
  }, [precision, value])

  if (!precision) {
    return null
  }

  return (
    <>
      <NumberInputLabel name="stopPrice" unit={currency} />

      <NumberInput
        name={'stopPrice'}
        placeholder={t(`stopPrice`, 'Stop price')}
        value={handleValue}
        fieldInformation={fieldInformation}
      />
    </>
  )
}
