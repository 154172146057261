import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'

import { CloseButton } from '../../../CloseButton'
import { FormHeaderIcon } from '../FormHeaderIcon'
import { currentSymbolLabelSelector } from '../../../../store/selectors'
import { isMobile } from '../../../../utils'

const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    padding: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.5,
  },
  closeButton: {
    border: palette.mode === 'light' ? '1px solid #EBEBEB' : '1px solid rgba(0, 0, 0, 0.5)',
    color: palette.mode === 'light' ? '#1f1f1f' : '#FAFAFA',
    padding: 4,
    fontSize: 15,
    borderRadius: 4,
    background: palette.mode === 'light' ? 'transparent' : '#1F1F1F',
  },
}))

const FormHeader: FC<{ onClose: () => void }> = ({ onClose }) => {
  const classes = useStyles()
  const label = useSelector(currentSymbolLabelSelector)
  const isMobileDevice = isMobile()

  if (!label) return null

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <FormHeaderIcon label={label} />
        {label}
      </div>

      {!isMobileDevice && <CloseButton onClose={onClose} className={classes.closeButton} data-test={'closeSideForm'} />}
    </div>
  )
}

export default FormHeader
