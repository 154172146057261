import { getType, isActionOf } from 'typesafe-actions'
import { EMPTY, merge, of } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, sample, switchMap, switchMapTo } from 'rxjs/operators'
import { matchPath } from 'react-router'
import { get } from 'lodash'

import {
  AssetGroup,
  CurrentMarketState,
  Error,
  FeedEvent,
  LoginSuccess,
  ModifyTrade,
  Signal,
  Signals,
  SymbolExposure,
  TradeObject,
  TradingAccount,
} from '@bdswiss/mt4-connector'

import { Prices, RootEpic } from '../types'
import {
  balanceUpdateAction,
  getUserSuccess,
  modifiedOpenOrderAction,
  modifiedPendingOrderAction,
  mt4AssetGroups,
  mt4Connect,
  mt4Connected,
  mt4Disconnect,
  mt4Disconnected,
  mt4Error,
  mt4Login,
  mt4MarketStateChanges,
  mt4Subscribed,
  orderClosedAction,
  orderOpenedAction,
  pendingOrderClosedAction,
  pendingOrderOpenedAction,
  pricesInitAction,
  pricesUpdatedAction,
  setSymbolExposure,
  signalsTickAction,
  signalsUpdatedAction,
  updateAccountLogin,
  updateMainCurrency,
} from '../actions'
import {
  getFilterableSignals,
  getServerTypeByAccountType,
  sendPostMessage,
  updateLocationByAccountId,
} from '../../utils'
import { ROOT_PATH } from '../../utils/constants'
import { filteredAccountsDataSelector } from '../selectors'
import { trackEvent } from '../../analytics/firebaseLogger'
import { mt4Error as mt4ErrorEvent } from '../../analytics/events'
import { PostMessageActionType } from '../../enums'

export const SubscriptionEpic: RootEpic = (action$, state$, { mt4Connector, bugsnag }) =>
  action$.pipe(
    filter(isActionOf([getUserSuccess])),
    switchMapTo(
      merge(
        mt4Connector.onMessage<void>(FeedEvent.CONNECTED).pipe(
          map(mt4Connected),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<void>(FeedEvent.DISCONNECTED).pipe(
          map(mt4Disconnected),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<LoginSuccess>(FeedEvent.LOGIN_SUCCESS).pipe(
          map((payload) => {
            mt4Connector.subscribeToSymbol('@ALL')
            return mt4Login(payload)
          }),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<Prices>(FeedEvent.PRICES).pipe(
          sample(action$.pipe(filter(isActionOf(mt4Login)))),
          map((payload) => pricesInitAction(payload)),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<Prices>(FeedEvent.PRICES_UPDATE).pipe(
          map(pricesUpdatedAction),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<Signals>(FeedEvent.SIGNALS).pipe(
          map(() =>
            signalsUpdatedAction(
              getFilterableSignals(
                mt4Connector.assets,
                mt4Connector.signals,
                mt4Connector.assetGroups.flatMap((group) => group.symbols),
              ).map((signal) => signal.symbol),
            ),
          ),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<Signal>(FeedEvent.SIGNAL).pipe(
          map((payload) => signalsTickAction(payload)),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<AssetGroup[]>(FeedEvent.ASSET_GROUPS).pipe(
          map(mt4AssetGroups),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<CurrentMarketState[]>(FeedEvent.MARKET_STATE_CHANGED).pipe(
          map(mt4MarketStateChanges),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<TradeObject>(FeedEvent.ORDER_OPENED).pipe(
          map(orderOpenedAction),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<TradeObject>(FeedEvent.ORDER_CLOSED).pipe(
          map(orderClosedAction),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<TradeObject>(FeedEvent.PENDING_ORDER_CANCELED).pipe(
          map(pendingOrderClosedAction),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<TradeObject>(FeedEvent.PENDING_ORDER_OPENED).pipe(
          map(pendingOrderOpenedAction),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<ModifyTrade>(FeedEvent.ORDER_UPDATED).pipe(
          map(modifiedOpenOrderAction),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<ModifyTrade>(FeedEvent.PENDING_ORDER_UPDATED).pipe(
          map(modifiedPendingOrderAction),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<SymbolExposure>(FeedEvent.SYMBOL_EXPOSURE).pipe(
          map(setSymbolExposure),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<TradingAccount>(FeedEvent.BALANCE_UPDATE).pipe(
          map(balanceUpdateAction),
          catchError((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return of(mt4Error(error))
          }),
        ),
        mt4Connector.onMessage<Error>(FeedEvent.ERROR).pipe(
          map((error) => {
            bugsnag.notify({ name: error.type, message: error.message })
            return mt4Error(error)
          }),
        ),
        of(mt4Subscribed()),
      ),
    ),
  )

export const ConnectEpic: RootEpic = (action$, state$, { mt4Connector }) =>
  action$.pipe(
    filter(isActionOf([mt4Connect, updateAccountLogin, getUserSuccess])),
    distinctUntilChanged((previous, current) => previous.payload === current.payload),
    switchMap(({ type, payload }) => {
      let selectedAccount = filteredAccountsDataSelector(state$.value).find((account) => {
        if (getType(updateAccountLogin) === type && payload) {
          return account.login === payload
        }

        return (
          account.login ===
          matchPath<{ accountId: string }>(window.location.pathname, {
            path: ROOT_PATH,
          })?.params?.accountId
        )
      })

      if (!selectedAccount || selectedAccount.hidden || selectedAccount.isArchived) {
        selectedAccount =
          state$.value.accounts.data
            .filter((account) => !account.isDemo && !account.hidden && !account.isArchived)
            .sort((a, b) => Number(b.balance) - Number(a.balance))?.[0] || state$.value.accounts.data[0]

        selectedAccount?.login && sendPostMessage(PostMessageActionType.changePageTitle, selectedAccount.login)
      }

      if (mt4Connector.connected) {
        mt4Connector.disconnect()
      }

      if (selectedAccount?.metaTraderApiUrl && selectedAccount?.login) {
        mt4Connector.connect(selectedAccount?.metaTraderApiUrl, {
          login: selectedAccount.login,
          server: getServerTypeByAccountType(selectedAccount?.__typename),
          version: state$.value.app.version,
          platform: state$.value.app.platform,
        })

        updateLocationByAccountId(selectedAccount.login)
        return of(updateMainCurrency(selectedAccount?.currency || 'USD'))
      }

      return EMPTY
    }),
  )

export const DisconnectEpic: RootEpic = (action$, state$, { mt4Connector }) =>
  action$.pipe(
    filter(isActionOf([mt4Disconnect])),
    switchMap(() => {
      mt4Connector.disconnect()
      return EMPTY
    }),
  )

export const Mt4ErrorEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([mt4Error])),
    switchMap(({ payload }) => {
      trackEvent(mt4ErrorEvent, { type: get(payload, 'type', 'UNKNOWN_ERROR'), message: get(payload, 'message', '') })
      return EMPTY
    }),
  )

export default [SubscriptionEpic, ConnectEpic, DisconnectEpic, Mt4ErrorEpic]
