import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import { FilterItems } from './FilterItems'
import { Search } from './Search'
import { tradeTabSelector } from '../../../../store/selectors'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    maxHeight: '50%',
    height: '100%',
    borderRight: `1px solid ${theme.palette.action.selected}`,
  },
}))

export const TopPanel: FC = () => {
  const classes = useStyles()
  const trade = useSelector(tradeTabSelector)
  if (!trade) return null
  return (
    <div className={classes.container}>
      <FilterItems />
      <Search />
    </div>
  )
}
