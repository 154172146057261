import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import CallMadeIcon from '@mui/icons-material/CallMade'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  item: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'right',
    letterSpacing: '0.25px',
    color: theme.palette.text.primary,
  },
  positive: { color: theme.palette.green.mint },
  negative: { color: theme.palette.secondary.main },
}))

export const Direction: FC<{ direction: string; onlyIcon?: boolean }> = ({ direction, onlyIcon }) => {
  const classes = useStyles()
  return (
    <Grid item container justifyContent={'flex-end'} alignItems={'center'} gap={'8px'}>
      {direction === 'Buy' ? (
        <CallMadeIcon className={clsx(classes.item, classes.positive)} />
      ) : direction === 'Sell' ? (
        <CallReceivedIcon className={clsx(classes.item, classes.negative)} />
      ) : null}

      {!onlyIcon && (
        <Typography
          className={clsx(
            {
              [classes.negative]: direction === 'Sell',
              [classes.positive]: direction === 'Buy',
            },
            classes.item,
          )}
          display={'inline'}
          variant={'caption'}
        >
          {direction === 'Buy' || 'Sell' ? <Trans i18nKey={direction.toString().toLowerCase()} /> : { direction }}
        </Typography>
      )}
    </Grid>
  )
}
