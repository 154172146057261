import React, { FC, ReactNode } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import HelpTooltip from '../../HelpTooltip/HelpTooltip'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    marginBottom: 10,
  },
  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    opacity: 0.65,
    '& svg': {
      fontSize: 'initial',
    },
  },
}))

interface AccountDetailsItemProps {
  fieldName: string
  hint: NonNullable<ReactNode>
  money: number | string | null
}

export const AccountDetailsItem: FC<AccountDetailsItemProps> = ({ fieldName, hint, money }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.label}>
        {fieldName}
        <HelpTooltip hint={hint} />
      </div>
      <div>{money ? `${money}` : '0.00'}</div>
    </div>
  )
}
