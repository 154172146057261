import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react'
import { HeaderGroup } from 'react-table'
import clsx from 'clsx'

const InnerElementType = (
  headerGroups: HeaderGroup[],
  classes: Record<string, string>,
  columnsWidth: number,
  containerWidth: number,
  scrollBarSize: number,
): ForwardRefExoticComponent<PropsWithoutRef<Record<string, unknown>> & RefAttributes<HTMLDivElement>> =>
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement>(({ children, ...rest }, ref) => {
    return (
      <div ref={ref} {...rest}>
        <div
          key="wrap_header"
          className={classes.head}
          style={{
            width: columnsWidth > containerWidth ? columnsWidth : containerWidth - scrollBarSize,
          }}
        >
          <div>
            {headerGroups.map((headerGroup, index) => {
              return (
                <div {...headerGroup.getHeaderGroupProps()} key={`Header_group_cell_${index}`} className={classes.head}>
                  {headerGroup.headers.map((column, index) => (
                    <div
                      {...column.getHeaderProps()}
                      className={clsx(classes.headItem, `cell-${column.id}`)}
                      key={`Header_cell_${index}`}
                    >
                      {column.render('Header')}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        </div>
        {children}
      </div>
    )
  })

export default InnerElementType
