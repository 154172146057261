import React, { FC } from 'react'
import { ListChildComponentProps } from 'react-window'
import { Row } from 'react-table'
import clsx from 'clsx'
import { MarketState } from '@bdswiss/mt4-connector'
import { useSelector } from 'react-redux'

import { RootState } from '../../store/types'
import { getSymbolMarketStateSelector } from '../../store/selectors'

const VariableSizeListRow: FC<ListChildComponentProps> = ({
  index,
  style,
  data: { rows, prepareRow, rowClickHandle, isRowActive, width, classes, SubComponent, showHeaders },
}) => {
  const row: Row<Record<string, unknown>> = rows[index]
  prepareRow(row)

  const isMarketClosed =
    useSelector((state: RootState) => getSymbolMarketStateSelector(state, row.original.symbol as string)) ===
    MarketState.Closed

  return (
    <div
      {...row.getRowProps({
        style: {
          ...style,
          width,
          top: Number(style.top) + (showHeaders ? 26 : 0),
        },
      })}
      className={clsx(
        classes.rows,
        {
          [classes.marketClosed]: isMarketClosed,
        },
        {
          [classes.marketClosedActive]: isRowActive?.(row.original) && isMarketClosed,
        },
      )}
      data-test={`tableRow-${index}`}
      data-id={row.original.id}
      data-symbol={row.original.symbol}
      data-direction={row.original.direction}
      onClick={rowClickHandle}
    >
      <div
        className={clsx(classes.cellsWrap, {
          [classes.active]: isRowActive?.(row.original),
        })}
      >
        {row.cells.map((cell, index) => {
          return (
            <div
              {...cell.getCellProps()}
              key={`Row_cell_${index}`}
              className={clsx(classes.tr, `cell-${cell.column.id}`)}
            >
              {cell.render('Cell')}
            </div>
          )
        })}
      </div>
      {SubComponent && <SubComponent {...row.original} />}
    </div>
  )
}

export default VariableSizeListRow
