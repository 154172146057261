import currencyFormatter from 'currency-formatter'
import moment from 'moment-timezone'

import { LibrarySymbolInfo } from './types/datafeed-api'
import { ResolutionString } from './types'

const PRIMARY_CURRENCIES = ['USD', 'GBP', 'EUR']
const PRIMARY_SYMBOLS = ['$', '£', '€']
const DELIMITERS = { thousand: ',', decimal: '.' }

type FormatOptions = {
  code?: string
  locale?: string
  symbol?: string
  decimal?: string
  thousand?: string
  precision?: number
  format?:
    | string
    | {
        pos: string
        neg: string
        zero: string
      }
}

export const serverTimeZone = 'Europe/Athens'

export type SymbolSessions = Map<number, { start: number; end: number; duration: number }>

export const serverOffset = moment().tz(serverTimeZone).utcOffset() * 60

export function removeServerOffset(time: number): number {
  return time - serverOffset
}

export const chartLocalesMap: Record<string, string> = {
  da: 'da_DK',
  nl: 'nl_NL',
  id: 'id_ID',
  ms: 'ms_MY',
  hi: 'en',
  ph: 'en',
}

export function convertSymbolSessions(symbolInfo: LibrarySymbolInfo): SymbolSessions {
  const symbolSessions = new Map()
  symbolInfo.session.split('|').forEach((session) => {
    const [times, day] = session.split(':')
    const [startTime, endTime] = times.split('-')
    const start = stringTimeToSessionTime(startTime) as number
    const end = stringTimeToSessionTime(endTime) as number
    symbolSessions.set((day as unknown as number) - 1, {
      start,
      end,
      duration: end - start,
    })
  })
  // console.log('SYMBOL_INFO_SESSIONS', symbolInfo.session)
  // console.log('SYMBOL_SESSIONS_MAP', symbolSessions)
  return symbolSessions
}

export function stringTimeToSessionTime(timeString: string, asString?: boolean): number | string {
  let timeHours = parseInt(timeString.substr(0, 2), 10)
  let timeMins = parseInt(timeString.substr(2, 2), 10)
  const rem = timeMins % 5
  if (rem < 3) {
    timeMins -= rem
  } else {
    if (timeMins > 55) {
      timeMins = 0
      timeHours += 1
    } else {
      timeMins += 5 - rem
    }
  }
  return asString ? `${('0' + timeHours).slice(-2)}${('0' + timeMins).slice(-2)}` : timeHours * 60 * 60 + timeMins * 60
}

export function convertResolution(resolution: number): ResolutionString {
  const supported_resolutions: { [key: number]: string } = {
    1: '1',
    5: '5',
    15: '15',
    30: '30',
    60: '60',
    240: '240',
    1440: '1D',
    10080: '1W',
    43200: '1M',
  }
  return (supported_resolutions[resolution] || '60') as ResolutionString
}

export function humanReadableResolution(resolution: number): string {
  const supported_resolutions: { [key: number]: string } = {
    1: '1m',
    5: '5m',
    15: '15m',
    30: '30m',
    60: '1h',
    240: '4h',
    1440: '1D',
    10080: '1W',
    43200: '1M',
  }
  return supported_resolutions[resolution] || '60'
}

export function resolution2Seconds(resolution: string): number {
  const m: { [s: string]: number } = {
    S: 1,
    D: 60 * 60 * 24,
    W: 60 * 60 * 24 * 7,
    M: 60 * 60 * 24 * 30,
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return resolution
    .match(/^(\d+)?([SDWM])?$/)!
    .reduce(
      (acc, cur, i) => (i === 0 ? 0 : i === 1 ? (cur ? Number(cur) : 1) : cur ? acc * Number(m[cur]) : acc * 60),
      0,
    )
}

export function formatProfitLoss(value: number, currency: string, locale?: string, options?: FormatOptions): string {
  const cur = findCurrency(currency)
  const format = value > 0 ? '%s +%v' : '%s %v'
  return currencyFormatter.format(value, { code: currency, format, thousand: ',', decimal: '.', ...cur, ...options })
}

function findCurrency(currency: string) {
  if (currency === 'CUD') {
    return {
      code: 'CUD',
      symbol: 'CUD',
      thousandsSeparator: ',',
      decimalSeparator: '',
      symbolOnLeft: true,
      spaceBetweenAmountAndSymbol: false,
      decimalDigits: 0,
      precision: 0,
    }
  }

  const cur = currencyFormatter.findCurrency(currency) || { symbol: '404notfound' }
  if (PRIMARY_SYMBOLS.includes(cur.symbol) && !PRIMARY_CURRENCIES.includes(currency)) {
    return undefined
  }
  return cur
}

export function formatCurrency(value: number, currency: string, locale?: string, options?: FormatOptions): string {
  const cur = findCurrency(currency)
  return currencyFormatter.format(value, {
    ...cur,
    code: currency,
    format: cur ? '%s %v' : `%v ${currency}`,
    ...DELIMITERS,
    ...options,
  })
}

export function formatPriceDifference(value: number, precision: number, currency: string, positive = true): string {
  const cur = findCurrency(currency)
  const valueFormat = positive ? '+%v' : '%v'
  return currencyFormatter.format(value, {
    code: currency,
    precision,
    format: cur ? `%s ${valueFormat}` : `${valueFormat} ${currency}`,
    ...DELIMITERS,
  })
}
