import i18next from 'i18next'
import { camelCase, escapeRegExp } from 'lodash'
import { createBrowserHistory } from 'history'
import { ClassNameMap, Theme } from '@mui/material/styles'
import { matchPath } from 'react-router'
import { generatePath } from 'react-router-dom'
import MobileDetect from 'mobile-detect'
import platform from 'platform'

import {
  AssetTradeMode,
  Signal,
  Signals,
  PriceExtended,
  PricesExtended,
  ServerType,
  TradeObject,
} from '@bdswiss/mt4-connector'
import { getTradeableSignals } from '@bdswiss/mt4-connector/build/main/utils/helpers'

import currencies from './currencies.json'
import { TradeMode } from '../enums'
import { FeedItem } from '../store/reducers/feed'
import config from '../config'
import { PostMessageActionType } from '../enums'
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR, ROOT_PATH } from './constants'

export const history = createBrowserHistory()

export const otherUsdSymbols = ['AUD', 'CAD', 'NZD']

export const storeItem = (key: string, item: string): void => {
  if (window.localStorage) {
    localStorage.setItem(`__BD:${key}`, JSON.stringify(item))
  }
}

export const getCookie = (name: string): string | null => {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
  )
  return matches ? decodeURIComponent(matches[1]) : null
}

export const setCookie = (name: string, value: string, days: number): void => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  const hostname = window.location.hostname
  const arr = hostname.split('.')
  const domain = [arr[arr.length - 2], arr[arr.length - 1]].join('.')
  document.cookie = `${name}=${value || ''}${expires}; path=/; domain=.${domain}`
}

export const genApiUrl = (
  endpoint = config.get('restApi.endpoint') || '/graphql',
  protocol = config.get('restApi.protocol') || location.protocol?.replace(':', ''),
  prefix = config.get('restApi.prefix') ||
    (process.env.REACT_APP_REST_API_PREFIX
      ? `${process.env.REACT_APP_REST_API_PREFIX}${/staging/.test(location.hostname.split('.')[0]) ? '-staging' : ''}`
      : ''),
): string =>
  `${protocol}://${prefix && prefix + '.'}${
    config.get('restApi.domain') ||
    process.env.REACT_APP_REST_API_DOMAIN ||
    location.hostname.split('.').slice(-2).join('.')
  }${endpoint}`

export const loadConfiguration = async <T extends NonNullable<unknown>>(companyKey?: string): Promise<T> => {
  const url = new URL(genApiUrl('/api/webtrader/config'))
  url.searchParams.append('referrer', document.referrer || window.location.href)
  companyKey && url.searchParams.append('companyKey', companyKey)
  const response = await fetch(url.toString(), { credentials: 'include' })
  const data = await response.json()

  if (response.status >= 400) {
    throw new Error(data.error)
  }

  return data
}

export const loadTranslations = async (locale: string): Promise<void> => {
  const response = await fetch(genApiUrl('/api/translations/webtrader'), {
    credentials: 'include',
  })
  const data = await response.json()
  i18next.addResources(locale, 'translation', data)
}

export const getServerTypeByAccountType = (accountTypename: string): ServerType => {
  return /demo/i.test(accountTypename) ? 'demo' : 'real'
}

export const getSymbolFromCurrency = (currencyCode?: string): string => {
  if (!currencyCode) {
    return ''
  }
  const code = currencyCode.toUpperCase()

  return currencies[code as keyof typeof currencies] || ''
}

export const currencyFormat = (
  value: number | string,
  currency: string,
  options?: Intl.NumberFormatOptions,
): string => {
  const numValue = Number(value)
  const finalValue = numValue.toLocaleString('en-US', {
    style: 'currency',
    currency,
    ...options,
    ...(currency === 'CUD' && numValue > 1 ? { maximumFractionDigits: 2, minimumFractionDigits: 0 } : {}),
  })
  return !otherUsdSymbols.includes(currency)
    ? finalValue.replace(/[$]|[€]|[£]/gi, '$& ')
    : `${finalValue.slice(finalValue.indexOf('$') + 1)} ${currency}`
}

export const currencyVolumeFormat = (
  value: number | string,
  currency: string,
  options?: Intl.NumberFormatOptions,
): string => {
  const symbol = getSymbolFromCurrency(currency)
  try {
    const currentVolume = Number(value).toLocaleString('en-US', options)
    return !otherUsdSymbols.includes(currency) ? `${symbol} ${currentVolume}` : `${currentVolume} ${symbol}`
  } catch (error) {
    return Number(value).toFixed(2)
  }
}

export const positiveNegativeFormat = (
  value: number | string,
  currency: string,
  options?: Intl.NumberFormatOptions & {
    shouldRound?: boolean
    hideNegativeSign?: boolean
    hidePositiveSign?: boolean
  },
): string => {
  const symbol = getSymbolFromCurrency(currency) || currency

  const shouldRound = options?.shouldRound === false ? false : true
  const finalValue = shouldRound ? Math.round(+value * 100) / 100 : value
  const hideNegativeSign = !!options?.hideNegativeSign
  const hidePositiveSign = !!options?.hidePositiveSign

  return currencyFormat(value, currency, options)
    .replace('-', '')
    .replace(
      new RegExp(`${escapeRegExp(symbol)}(\\s)?`),
      `${symbol} ${!hidePositiveSign && finalValue > 0 ? '+' : !hideNegativeSign && finalValue < 0 ? '-' : ''}`,
    )
}

export const numberFormat = (value: number | string, options?: Intl.NumberFormatOptions): string => {
  return Number(value).toLocaleString('en-US', options)
}

export const numberUnformat = (value: string): number => {
  return (
    parseFloat(
      value.replace(new RegExp('\\' + THOUSAND_SEPARATOR, 'g'), '').replace(new RegExp('\\' + DECIMAL_SEPARATOR), '.'),
    ) || 0
  )
}

export const capitalize = (str: string): string => str.trim().charAt(0).toUpperCase() + camelCase(str).slice(1)

export const getTradeableAssets = (
  prices: PricesExtended,
  openPositions: TradeObject[],
  symbols: string[],
): PriceExtended[] =>
  Object.values(prices).filter(
    (price) =>
      symbols.includes(price.symbol) &&
      (price.trade > AssetTradeMode.closeOnly ||
        (price.trade === AssetTradeMode.closeOnly &&
          Object.values(openPositions)
            .map((position) => position.symbol)
            .includes(price.symbol))),
  )

export const getCompanyName = (): string => {
  let companyName = 'BDSwiss'
  const curUrl = document.referrer || window.location.href
  switch (true) {
    case new RegExp('swissmarkets', 'i').test(curUrl):
      companyName = 'SwissMarkets'
      break
    case new RegExp('klarinvest', 'i').test(curUrl):
      companyName = 'Klarinvest'
      break
    case new RegExp('bdstrading', 'i').test(curUrl):
      companyName = 'BDSTrading'
      break
    case new RegExp('viverno', 'i').test(curUrl):
      companyName = 'Viverno'
      break
  }
  return companyName
}

export const getCompanyLogo = (
  variant: 'header' | 'loader',
  theme: Theme,
  classes: ClassNameMap<
    | 'headerLogo'
    | 'spinnerLogo'
    | 'swissmarketsHeaderLogo'
    | 'vivernoHeaderLogo'
    | 'klarinvestLogo'
    | 'klarinvestHeaderLogo'
  >,
): { src: string; style: string } => {
  const curUrl = document.referrer || window.location.href
  const props = {
    src: `/assets/img/logo${theme.palette.mode === 'dark' ? '-dark' : ''}.svg`,
    style: variant === 'header' ? classes.headerLogo : classes.spinnerLogo,
  }
  switch (true) {
    case new RegExp('swissmarkets', 'i').test(curUrl):
      props.src = `/assets/img/swissmarkets${theme.palette.mode === 'dark' ? '-dark' : ''}.svg`
      props.style = variant === 'header' ? classes.swissmarketsHeaderLogo : classes.spinnerLogo
      break
    case new RegExp('klarinvest', 'i').test(curUrl):
      props.src = '/assets/img/klarinvest.svg'
      props.style = variant === 'header' ? classes.klarinvestHeaderLogo : classes.klarinvestLogo
      break
    case new RegExp('bdstrading', 'i').test(curUrl):
      props.src = `/assets/img/bdstrading${theme.palette.mode === 'dark' ? '-dark' : ''}.svg`
      props.style = variant === 'header' ? classes.headerLogo : classes.spinnerLogo
      break
    case new RegExp('viverno', 'i').test(curUrl):
      props.src = `/assets/img/vivernoNew-${theme.palette.mode === 'dark' ? 'dark' : 'light'}.svg`
      props.style = variant === 'header' ? classes.vivernoHeaderLogo : classes.spinnerLogo
      break
  }
  return props
}

export const getUrlParam = (name: string): string | null => {
  const url = new URL(window.location.toString())
  return url.searchParams.get(name)
}

export const getCurrentLocation = (): string => {
  const url = new URL(document.referrer || window.location.href)
  return url.origin
}

export const getFilterableSignals = (assets: PricesExtended, signals: Signals, symbols: string[]): Array<Signal> => {
  const tradeableSignals: Signals = getTradeableSignals(assets, signals)

  return Object.values(tradeableSignals)
    .filter((signal) => symbols.includes(signal.symbol))
    .filter(
      (signal) =>
        assets[signal.symbol]?.['trade'] === AssetTradeMode.enable ||
        (assets[signal.symbol]?.['trade'] === AssetTradeMode.buyOnly && signal.direction === 'UP') ||
        (assets[signal.symbol]?.['trade'] === AssetTradeMode.sellOnly && signal.direction === 'DOWN'),
    )
}

/**
 * This function is used to tell if the viewing device is a mobile device or not.
 */
export const isMobile = (): boolean => {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() || !!md.versionStr('Mobile')
}

/**
 * This function is used to tell if the viewing device is an iOS mobile device or not.
 */
export const isIos = (): boolean => {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() && md.is('iOS')
}

/**
 * This function is used to tell if the viewing device is an Android mobile device or not.
 */
export const isAndroid = (): boolean => {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() && md.is('AndroidOS')
}

/**
 * This function is used to tell you the platform information related to the viewing device.
 */
export const getDevicePlatformInfo = (): {
  platform: string
  platformType: string
  operatingSystem?: string
  device?: string
  browser?: string
} => {
  const md = new MobileDetect(window.navigator.userAgent)
  const isMobile = md.mobile()

  return {
    platform: 'web',
    platformType: isMobile ? 'mobile' : 'desktop',
    operatingSystem: `${platform.os?.family}/${platform.os?.architecture}`,
    device: platform.product,
    browser: platform.name,
  }
}

/**
 * This function is used to remove the trailing slash from a string.
 */
export const removeTrailingSlash = (s: string): string => {
  return s.endsWith('/') ? s.slice(0, -1) : s
}

export const sendPostMessage = (type: PostMessageActionType, payload?: unknown, meta?: unknown): void => {
  const targetOrigin = config.get('dashboardUrl')
  if (targetOrigin) {
    window.parent.postMessage(
      JSON.stringify({
        type,
        ...(payload ? { payload } : {}),
        ...(meta ? { meta } : {}),
      }),
      targetOrigin,
    )
  } else {
    console.warn(`Message ${type} was not sent because target origin is not set`)
  }
}

export const getTradeModeByTradeAvailable = (trade: number, preferredMode: TradeMode = TradeMode.buy): TradeMode => {
  if (trade === AssetTradeMode.buyOnly) {
    return TradeMode.buy
  } else if (trade === AssetTradeMode.sellOnly) {
    return TradeMode.sell
  }

  return preferredMode
}

export const getFeedItemTradeModeByTerm = ({ term, option }: Pick<FeedItem, 'term' | 'option'>) => {
  const key = {
    INTRADAY: 'opinionIntraday',
    ST: 'opinionST',
    MT: 'opinionMT',
  }[term] as keyof typeof option

  if (key && key in option) {
    switch (option[key]) {
      case '2':
      case '1':
        return TradeMode.buy
      case '-1':
      case '-2':
        return TradeMode.sell
    }
  } else {
    console.warn('Feed:: Key missing in option parameters', { key, option })
  }

  return TradeMode.disabled
}

export const updateLocationByAccountId = (accountId: string): void => {
  const lastPath = location.pathname.split('/').slice(3).join('')
  const isExact = matchPath<{ isExact: string }>(window.location.pathname, { path: ROOT_PATH })?.isExact
  const pathSuffix = !isExact && lastPath.length ? `/${lastPath}` : ''
  history.push({
    pathname: generatePath(ROOT_PATH, { accountId }) + pathSuffix,
    search: window.location.search,
  })
}

export const backToAccountsHandle = (accountId: string): void => {
  if (window !== window.parent) {
    sendPostMessage(PostMessageActionType.backToAccount)
  } else {
    const dashboardUrl = config.get('dashboardUrl') || location.hostname.split('.').slice(-2).join('.')
    window.location.href = `${dashboardUrl}/accounts/${accountId}`
  }
}

export const backToDashboardHandle = (): void => {
  if (window !== window.parent) {
    sendPostMessage(PostMessageActionType.backToDashboard)
  } else {
    const dashboardUrl = config.get('dashboardUrl') || location.hostname.split('.').slice(-2).join('.')
    window.location.href = `${dashboardUrl}/dashboard`
  }
}

export const depositHandle = (accountId: string, accountLogin: string, meta: { target?: string }): void => {
  if (window !== window.parent) {
    sendPostMessage(PostMessageActionType.deposit, accountLogin, meta)
  } else {
    const dashboardUrl = config.get('dashboardUrl') || location.hostname.split('.').slice(-2).join('.')
    window.location.href = `${dashboardUrl}/transactions/${accountId}/deposit`
  }
}

export const openUrlHandle = (url: string): void => {
  if (window !== window.parent) {
    sendPostMessage(PostMessageActionType.openUrl, url)
  } else {
    window.location.href = url
  }
}

export const changeAccountHandle = (accountId: string): void => {
  if (window !== window.parent) {
    sendPostMessage(PostMessageActionType.changeAccount, accountId)
  } else {
    updateLocationByAccountId(accountId)
  }
}

export const depositHandleWithPreselectedValue = (
  accountId: string,
  preselectedCustom: { amount: number; currency: string },
): void => {
  if (window !== window.parent) {
    sendPostMessage(PostMessageActionType.depositWithPreselectedValue, { accountId, preselectedCustom })
  }
}
