import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import { SwitchProps } from '@mui/material/Switch/Switch'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 14,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(7px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    left: 0,
    color: '#fff',
    '&.Mui-checked': {
      transform: 'translateX(13px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#00AD7B',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    position: 'relative',
    top: -1,
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 18,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'light' ? '#C2C2C2' : '#474747',
    boxSizing: 'border-box',
  },
}))

export const StyledSwitch: FC<SwitchProps> = (props) => {
  return <AntSwitch color="default" inputProps={{ 'aria-label': 'decorative checkbox' }} {...props} />
}
