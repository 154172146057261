import { gql } from '@apollo/client'

export const UPDATE_FAVOURITES = gql`
  mutation updateFavourites($favourites: [String]!) {
    updateFavourites(favourites: $favourites) {
      favourites
      __typename
    }
  }
`
