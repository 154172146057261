import React, { FC, MouseEvent, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'
import moment from 'moment-timezone'
import { useTheme } from '@mui/styles'
import { styled } from '@mui/material/styles'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import clsx from 'clsx'

import {
  downloadAppBannerSelector,
  tradingIdeasDataSelector,
  tradingIdeasStatusSelector,
} from '../../../store/selectors'
import { TradingIdeasSubItem } from './TradingIdeasSubItem'
import { setMobileBackButtonData, setUpdateActiveAsset, toggleExpandedTIItem } from '../../../store/actions'
import { TradeMode } from '../../../enums'
import { SkeletonLoader } from './SkeletonLoader'
import { ReactComponent as NoItemsFoundIcon } from '../../../assets/images/icons/noItems.svg'
import { ReactComponent as ArrowDown } from '../../../assets/images/icons/mobile/arrow-down.svg'
import { ReactComponent as ArrowUp } from '../../../assets/images/icons/mobile/arrow-up.svg'
import { getFeedItemTradeModeByTerm, isMobile } from '../../../utils'

const TradingIdeasWrapper = styled('div')(() => ({
  overflow: 'scroll',
  height: '100%',
  ['::-webkit-scrollbar']: {
    width: 0,
    height: 0,
  },
  ...(isMobile() && {
    height: 'calc(100vh - 120px)',
    '&.bannerShown': {
      height: 'calc(100vh - 176px)',
    },
  }),
}))
const TradingIdeasItemContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  ...(!isMobile() && {
    '& :hover > .item__text, .expanded': {
      color: theme.palette.mode === 'light' ? '#000' : '#fff',
    },
  }),
}))

const TradingIdeasItem = styled('div')(() => ({
  width: 'inherit',
  cursor: 'pointer',
}))

const TradingItemCallMadeIcon = styled(CallMadeIcon)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: 2,
  marginRight: 6,
  color: '#009469',
  background: theme.palette.mode === 'light' ? '#E5F9F3' : '#003022',
}))

const TradingItemCallReceivedIcon = styled(CallReceivedIcon)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: 2,
  marginRight: 6,
  color: '#FC4950',
  background: `${theme.palette.secondary.main}${theme.palette.mode === 'light' ? '1A' : '4D'}`,
}))

const TradingIdeasSymbol = styled('div')<{ mode: TradeMode }>(({ theme, mode }) => ({
  display: 'flex',
  alignItems: 'center',
  color: (() => {
    switch (mode) {
      case TradeMode.buy:
        return '#009469'
      case TradeMode.sell:
        return theme.palette.secondary.main
      default:
        return theme.palette.grey[500]
    }
  })(),
  fontSize: 12,
  lineHeight: theme.spacing(2),
  letterSpacing: '0.4px',
  paddingBottom: theme.spacing(0.5),
  textTransform: 'uppercase',
  ...(isMobile() && {
    position: 'relative',
  }),
}))

const TradingIdeasMoreButton = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: 6,
  color: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
  fontSize: 14,
  lineHeight: theme.spacing(2),
  letterSpacing: '0.25px',
  textTransform: 'none',
  '& svg': {
    fill: theme.palette.mode === 'light' ? '#007AFF' : '#419CFF',
  },
}))

const TradingIdeasSubSymbol = styled('div')(({ theme }) => ({
  opacity: 0.6,
  fontSize: 12,
  lineHeight: theme.spacing(2),
  letterSpacing: '0.4px',
  padding: '10px 0',
}))

const TradingIdeasText = styled('div')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  letterSpacing: '0.25px',
  maxWidth: 200,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  color: theme.palette.mode === 'light' ? '#5C5C5C' : '#C2C2C2',
  '&.expanded': {
    display: 'block',
  },
  ...(isMobile() && {
    maxWidth: '100%',
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
  }),
}))

const EmptyData = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 40,
  color: theme.palette.mode === 'dark' ? '#707070' : '#999',
  '& svg': {
    marginLeft: 4,
  },
}))

export const TradingIdeas: FC = () => {
  const isMobileDevise = isMobile()
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const itemsStatus = useSelector(tradingIdeasStatusSelector)
  const itemsData = useSelector(tradingIdeasDataSelector)
  const bannerShown = useSelector(downloadAppBannerSelector)

  useEffect(() => {
    dispatch(setMobileBackButtonData({ show: true, title: t('tradingIdeas') }))
    return () => {
      dispatch(setMobileBackButtonData(undefined))
    }
  }, [dispatch, t])

  const handleViewItem = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const { id, symbol, mode } = e.currentTarget.dataset
      if (!isMobileDevise) {
        id && dispatch(toggleExpandedTIItem(id))
        symbol && mode && dispatch(setUpdateActiveAsset({ symbol, mode: mode as TradeMode }))
      }
    },
    [dispatch, isMobileDevise],
  )

  const mobileHandleViewItem = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const { id } = e.currentTarget.dataset
      id && dispatch(toggleExpandedTIItem(id))
    },
    [dispatch],
  )

  if (itemsStatus === 'Fetching') return <SkeletonLoader />
  if (itemsStatus === 'Failed' || !itemsData)
    return (
      <EmptyData>
        No items to display <NoItemsFoundIcon />
      </EmptyData>
    )

  return (
    <TradingIdeasWrapper className={clsx({ bannerShown })}>
      {itemsData.map(
        ({ id, expanded, name, option, date, hour, product, media, title, summary, alternativeMedia, code, term }) => {
          const tradeMode = getFeedItemTradeModeByTerm({ term, option })
          return (
            <TradingIdeasItemContainer key={id}>
              <TradingIdeasItem
                onClick={handleViewItem}
                data-id={id}
                data-symbol={code.filter((ticker) => ticker.type === 'TICKER')[0].key}
                data-mode={tradeMode}
              >
                <TradingIdeasSymbol mode={tradeMode}>
                  {tradeMode === TradeMode.buy ? <TradingItemCallMadeIcon /> : <TradingItemCallReceivedIcon />}
                  {name.replace('Dollar', 'USD').replace('Euro', 'EUR')}
                  {isMobileDevise && (
                    <TradingIdeasMoreButton data-id={id} onClick={mobileHandleViewItem}>
                      {expanded ? (
                        <>
                          <Trans i18nKey={'less'} defaults={'Less'} />
                          <ArrowUp />
                        </>
                      ) : (
                        <>
                          <Trans i18nKey={'more'} defaults={'More'} />
                          <ArrowDown />
                        </>
                      )}
                    </TradingIdeasMoreButton>
                  )}
                </TradingIdeasSymbol>
                <TradingIdeasSubSymbol>
                  {moment(date + ' ' + hour, 'YYYYMMDD hh:mm')
                    .startOf('hour')
                    .fromNow()}
                  &nbsp;&middot;&nbsp;
                  {capitalize(product === 'forex' ? 'currencies' : product)}
                </TradingIdeasSubSymbol>
                <TradingIdeasText
                  className={clsx('item__text', {
                    expanded: expanded,
                  })}
                >
                  {title}. {summary}
                </TradingIdeasText>
              </TradingIdeasItem>
              <TradingIdeasSubItem
                mode={tradeMode}
                open={expanded}
                src={
                  theme.palette.mode === 'light'
                    ? media
                    : alternativeMedia.filter((el) => el.theme === 'dark' && el.width === '590')[0].image
                }
                fullImg={
                  theme.palette.mode === 'light'
                    ? alternativeMedia.filter((el) => el.theme === 'light' && el.width === '929')[0].image
                    : alternativeMedia.filter((el) => el.theme === 'dark' && el.width === '929')[0].image
                }
                name={name}
                symbol={code.filter((ticker) => ticker.type === 'TICKER')[0].key}
              />
            </TradingIdeasItemContainer>
          )
        },
      )}
    </TradingIdeasWrapper>
  )
}
