import { makeStyles } from '@mui/styles'
import React, { FC } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { numberFormat } from '../../../../utils'

interface Props {
  value: number
  digits: number
}

const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline-block',
  },
  wholePart: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.38px',
  },
  decimalPart: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '0.364px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
}))

export const StandoutDecimalPriceLabel: FC<Props> = ({ value, digits }) => {
  const classes = useStyles()

  const wholePart = Math.floor(value)
  const decimalPart = (value - wholePart).toFixed(digits).substring(2)

  return (
    <div className={classes.container}>
      <Typography className={clsx(classes.inline, classes.wholePart)}>
        {numberFormat(wholePart).replace(/,/g, ' ') + ','}
      </Typography>
      <Typography className={clsx(classes.inline, classes.decimalPart)}>{decimalPart}</Typography>
    </div>
  )
}
