import React, { FC } from 'react'
import { TooltipRenderProps } from 'react-joyride'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  tooltipBody: {
    textAlign: 'left',
    backgroundColor: theme.palette.background.default,
    minWidth: '290px',
    maxWidth: '410px',
    position: 'relative',
    padding: 10,
    borderRadius: 4,
    // boxShadow: theme.shadows[2].toString(),
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(255 255 255 / 25%)'
        : '',
  },
  tooltipContent: {
    color: theme.palette.text.secondary,
    padding: '5px',
  },
  tooltipTitle: {
    color: '#f04',
    margin: 0,
  },
  tooltipFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  },
  skipButtonWrap: {
    flex: '1 1 0%',
    textAlign: 'left',
  },
  button: {
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: 1,
    appearance: 'none',
    textTransform: 'none',
    border: 0,
  },
  skipButton: {
    backgroundColor: 'transparent',
    color: theme.palette.mode === 'dark' ? '#707070' : '#C4C4C4',
  },
  backButton: {
    padding: 8,
    backgroundColor: 'transparent',
    color: '#0078FF',
  },
  nextButton: {
    padding: 8,
    backgroundColor: '#007AFF',
    color: '#fff',
    borderRadius: 4,
    marginLeft: 10,
  },
}))

export const DefaultStep: FC<TooltipRenderProps> = ({
  continuous,
  index,
  step,
  backProps,
  skipProps,
  isLastStep,
  primaryProps,
  tooltipProps,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.tooltipBody} {...tooltipProps}>
      {step.title && <h2 className={classes.tooltipTitle}>{step.title}</h2>}
      <div className={classes.tooltipContent}>{step.content}</div>
      {!step.hideFooter && (
        <div className={classes.tooltipFooter}>
          {!isLastStep && (
            <div className={classes.skipButtonWrap}>
              <button className={`${classes.button} ${classes.skipButton}`} {...skipProps}>
                <span id="skip">{skipProps.title}</span>
              </button>
            </div>
          )}
          {index > 0 && !step.hideBackButton && (
            <button className={`${classes.button} ${classes.backButton}`} {...backProps}>
              <span id="back">{backProps.title}</span>
            </button>
          )}
          <button
            className={`${classes.button} ${classes.nextButton}`}
            style={step.styles?.buttonNext}
            {...primaryProps}
          >
            <span id={continuous ? 'next' : 'close'}>{primaryProps.title}</span>
          </button>
        </div>
      )}
    </div>
  )
}
