import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { TradeMode } from '../../../../enums'
import HelpTooltip from '../../../TopMenu/HelpTooltip/HelpTooltip'

const useStyles = makeStyles(({ palette }) => ({
  value: {
    paddingLeft: 3,
    fontWeight: 700,
    letterSpacing: 0.5,
    color: palette.mode === 'light' ? '#000' : '#EBEBEB',
  },
  container: {
    minWidth: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    lineHeight: 1.16,
    letterSpacing: 0.5,
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    marginTop: -1,
  },
  leftSide: {
    marginRight: 8,
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: 0.8,
    color: palette.mode === 'light' ? '#000' : '#EBEBEB',
  },
}))

interface LabelValueTooltipProps {
  label: string
  value?: string | number
  tooltip?: string
  tradeMode?: TradeMode
  control?: React.ReactNode
}

export const LabelValueTooltip: FC<LabelValueTooltipProps> = ({ label, value, tooltip, control }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {control && <div className={classes.leftSide}>{control}</div>}
      <div className={classes.rightSide}>
        <Typography display={'inline'} variant={'caption'} className={classes.text}>
          <Trans i18nKey={label} />
          {!!value && ':'}
        </Typography>
        {!!value && (
          <Typography className={clsx(classes.text, classes.value)} display={'inline'} variant={'caption'}>
            {value}
          </Typography>
        )}
        {!!tooltip && (
          <div className={classes.tooltip}>
            <HelpTooltip hint={<Trans i18nKey={tooltip} />} />
          </div>
        )}
      </div>
    </div>
  )
}
