import React, { FC, ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { ReactComponent as NewSection } from '../../assets/images/icons/newSection.svg'

const NewSectionContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 6px',
}))

const animation = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(0);
  }
`

export const NewSectionTooltip: FC<{ children: ReactElement; open: boolean }> = ({ children, open }) => (
  <Tooltip
    open={open}
    PopperProps={{
      sx: {
        [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
          marginTop: '4px !important',
          marginRight: '4px !important',
          animation: `${animation} 1s infinite`,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          display: 'flex',
          alignItems: 'center',
          fontSize: 14,
          lineHeight: '20px',
          color: '#FFFFFF',
          background: (theme) => (theme.palette.mode === 'light' ? '#00AD7B' : '#00C78D'),
        },
        [`& .${tooltipClasses.arrow}`]: {
          color: (theme) => (theme.palette.mode === 'light' ? '#00AD7B' : '#00C78D'),
          top: '-6px !important',
        },
      },
    }}
    title={
      <NewSectionContent>
        <NewSection />
        &nbsp;New section
      </NewSectionContent>
    }
    arrow
    placement={'left-start'}
  >
    {children}
  </Tooltip>
)
