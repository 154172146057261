import React, { FC } from 'react'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { MenuItemsNames, mobileMenuItems } from '../../pages/menuBarList'
import {
  closedPositionsLengthSelector,
  openPositionsLengthSelector,
  pendingPositionsLengthSelector,
} from '../../../store/selectors'
import { BottomMenuButton } from '../BottomMenuButton'

export const BOTTOM_MENU_HEIGHT = 80

const useStyle = makeStyles((theme) =>
  createStyles({
    container: {
      maxHeight: BOTTOM_MENU_HEIGHT,
      borderRight: `1px solid ${theme.palette.action.selected}`,
      width: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      boxShadow:
        theme.palette.mode === 'light'
          ? '0px -1px 6px rgba(0, 0, 0, 0.08)'
          : '0px -2px 8px rgba(0, 0, 0, 0.16), 0px -1px 0px rgba(150, 150, 150, 0.25)',
      padding: '7px 0px',
    },
  }),
)

export const BottomMenu: FC = () => {
  const classes = useStyle()
  const openPositionsLength = useSelector(openPositionsLengthSelector)
  const pendingPositionsLength = useSelector(pendingPositionsLengthSelector)
  const closedPositionsLength = useSelector(closedPositionsLengthSelector)

  return (
    <div className={classes.container}>
      {mobileMenuItems
        .filter((item) => !item.hidden)
        .map(({ name, path, icon }) => {
          let counter
          switch (name) {
            case MenuItemsNames.positions:
              counter = pendingPositionsLength + openPositionsLength
              break
            case MenuItemsNames.history:
              counter = closedPositionsLength
              break
          }
          return <BottomMenuButton key={name} Icon={icon} name={name} path={path} counter={counter} />
        })}
    </div>
  )
}
