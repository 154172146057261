import { combineLatest, EMPTY, merge, of } from 'rxjs'
import { StateObservable } from 'redux-observable'
import { filter, switchMap } from 'rxjs/operators'
import { getType, isActionOf } from 'typesafe-actions'

import {
  calcPendingPriceValue,
  calcPl,
  calcRequiredMarginFromAmount,
  calcSlInitial,
  calcTpInitial,
  calculateLots,
  calculateValue,
  findChainRate,
  getProfitCurrency,
  MT4Connector,
  PriceExtended,
} from '@bdswiss/mt4-connector'

import {
  appChangeActiveAsset,
  appChangeTradeMode,
  changeLocation,
  formUpdateField,
  initialStateValuesForModifying,
  mt4Login,
  pricesInitAction,
  pricesUpdatedAction,
  selectedOpenedPosition,
  selectedPendingPosition,
  setFormOrderStatus,
  setStopLossEnabled,
  setTakeProfitEnabled,
  updateFormValues,
} from '../actions'
import { RootEpic, RootState } from '../types'
import { Location, OrderTypes, TradeMode } from '../../enums'
import { getAllFieldInfo } from '../../utils/form'
import { selectedPositionSelector } from '../selectors'
import { numberFormat } from '../../utils'

const mt4 = MT4Connector.Instance

const initialFormValuesEpic: RootEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(
        isActionOf([
          appChangeActiveAsset,
          appChangeTradeMode,
          changeLocation,
          selectedOpenedPosition,
          selectedPendingPosition,
        ]),
      ),
    ),
    combineLatest([action$.ofType(getType(mt4Login)), action$.ofType(getType(pricesInitAction))]),
  ).pipe(
    switchMap(() => {
      const values = getDataForValuesUpdate(state$, 'init')
      const { priceLimit, takeProfit, stopLoss, takeProfitEnabled, stopLossEnabled } = values
      return of(
        updateFormValues(values),
        initialStateValuesForModifying([
          takeProfit?.value || 0,
          stopLoss?.value || 0,
          priceLimit?.value || 0,
          takeProfitEnabled || false,
          stopLossEnabled || false,
        ]),
      )
    }),
  )

const updateFieldAfterChangeTradeModeEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([appChangeTradeMode])),
    switchMap(() => {
      const {
        app: { activeAsset, tradeMode },
        form: {
          stopPrice: { value },
          formOrderType,
        },
      } = state$.value
      const asset = mt4.assets[activeAsset]

      return formOrderType === OrderTypes.stopLimitOrder
        ? of(
            formUpdateField({
              field: 'priceLimit',
              value,
              fieldInformation: getAllFieldInfo({
                asset,
                field: 'priceLimit',
                value,
                tradeMode,
                formOrderType,
                stopPrice: value,
              }),
            }),
          )
        : EMPTY
    }),
  )

const updateFormValuesEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(
      isActionOf([pricesUpdatedAction, formUpdateField, setTakeProfitEnabled, setStopLossEnabled, setFormOrderStatus]),
    ),
    switchMap(() => {
      return of(updateFormValues(getDataForValuesUpdate(state$)))
    }),
  )
}

const getInitialLots = (price: PriceExtended): number => {
  const security = price.security
  let result = 10

  switch (true) {
    case /forex/gi.test(security):
      result = 0.1
      break
    case /energies|metals|stocks|etf/gi.test(security):
      result = 1
      break
    case /equities/gi.test(security):
      result = 100
      break
  }

  if (result > price.lotMax) {
    result = price.lotMax
  } else if (result < price.lotMin) {
    result = price.lotMin
  }

  return result
}

const getDataForValuesUpdate = (
  state$: StateObservable<RootState>,
  mode: 'init' | 'update' = 'update',
): Partial<RootState['form']> => {
  const {
    app: { activeAsset, loginInfo, tradeMode: formTradeMode, activeTab },
    form: { stopLossEnabled, takeProfitEnabled, priceLimit, amount, takeProfit, stopLoss, stopPrice, formOrderType },
    ui: {
      modifyPanel: { open: modifyPanelOpen },
    },
  } = state$.value
  const asset = mt4.assets[activeAsset]
  if (!asset || !loginInfo || formTradeMode === TradeMode.disabled) return {}

  const { leverage, currency } = loginInfo
  const { mbid, mask, marginCurrency, lotSize } = asset
  const profitCurrency = getProfitCurrency(asset)
  const marginRate = findChainRate(marginCurrency, currency, mt4.assets).value
  const position = selectedPositionSelector(state$.value)
  const notPriceSyncedPLCalculation = !modifyPanelOpen && !!position

  const placedOrderTradeMode =
    position?.type === 'buy' || position?.type === 'buy limit'
      ? TradeMode.buy
      : position?.type === 'sell' || position?.type === 'sell limit'
      ? TradeMode.sell
      : undefined

  const tradeMode = placedOrderTradeMode || formTradeMode

  let profitRate
  if (profitCurrency === marginCurrency) {
    profitRate = marginRate
  } else {
    profitRate = findChainRate(getProfitCurrency(asset), currency, mt4.assets).value
  }

  // Initial calculations
  const lotsInitial = getInitialLots(asset)
  const amountInitial = lotsInitial * lotSize || 0
  const getInitialLimitPrice = (): number => {
    if (formOrderType === OrderTypes.stopLimitOrder) return stopPrice.value
    return position?.openPrice
      ? position.openPrice
      : tradeMode === TradeMode.buy
      ? asset.mask - (asset.mask - asset.mbid)
      : asset.mbid
  }
  const priceLimitInitial = getInitialLimitPrice()
  // TODO it is possible that the connector is counting incorrectly
  // const priceLimitInitial = position?.openPrice
  //   ? position.openPrice
  //   : calcPendingPriceValue(tradeMode, asset, tradeMode === TradeMode.buy ? asset.mask : asset.mbid)
  const isPendingOrder = (position && !position.investment) || false
  const dependedLeverage = asset.marginMode === 'Forex' ? Number(leverage) : Number((100 / asset.percentage).toFixed(0))

  //  Lots calculations
  const lots =
    position?.volume ||
    +numberFormat(calculateLots(asset, mode === 'init' ? amountInitial : amount.value), {
      maximumFractionDigits: asset?.lotRounding,
    })

  //  Stop price calculations
  const stopPriceInitial = asset.currentRate

  //  Limit price calculations
  const priceLimitEnabled =
    (!position && [OrderTypes.pendingOrder, OrderTypes.stopLimitOrder].includes(formOrderType)) ||
    activeTab === Location.order ||
    false
  const price = mode === 'init' ? priceLimitInitial : priceLimit.value
  const currentAsset = priceLimitEnabled
    ? {
        ...asset,
        ...(tradeMode === TradeMode.buy ? { mask: price } : { mbid: price }),
      }
    : asset
  const priceDifference = tradeMode === TradeMode.buy ? price - mask : price - mbid
  const priceDifferencePercent =
    tradeMode === TradeMode.buy ? (priceDifference * 100) / mask : (priceDifference * 100) / mbid

  // Amount calculations
  const value = calculateValue(asset, mode === 'init' ? amountInitial : amount.value, tradeMode) || 0
  const valueAccount = value * marginRate

  //  Required margin calculations
  const requiredMargin =
    calcRequiredMarginFromAmount({
      asset,
      lots,
      contractSize: lotSize,
      leverage: dependedLeverage,
      limitPrice:
        formOrderType === OrderTypes.pendingOrder || formOrderType === OrderTypes.stopLimitOrder
          ? priceLimit.value
          : undefined,
    }) || 0
  const requiredMarginAccount = requiredMargin * marginRate

  //  Take profit calculations
  const tp =
    mode === 'init' && position?.tp
      ? position.tp
      : calcTpInitial(tradeMode, asset, priceLimitEnabled || isPendingOrder ? price : undefined, isPendingOrder)

  const profit =
    calcPl(
      {
        ...currentAsset,
        ...(tradeMode === TradeMode.buy ? { mbid: takeProfit.value } : { mask: takeProfit.value }),
      },
      tradeMode,
      notPriceSyncedPLCalculation && position?.openPrice
        ? position.openPrice
        : priceLimitEnabled
        ? price
        : tradeMode === TradeMode.buy
        ? mask
        : mbid,
      lots,
    ) * profitRate

  const profitPercent = (+profit.toFixed(2) * 100) / requiredMarginAccount || 0
  //  Stop loss calculations
  const sl =
    mode === 'init' && position?.sl
      ? position.sl
      : calcSlInitial(tradeMode, currentAsset, priceLimitEnabled || isPendingOrder ? price : undefined, isPendingOrder)

  const loss =
    calcPl(
      {
        ...currentAsset,
        ...(tradeMode === TradeMode.buy ? { mbid: stopLoss.value } : { mask: stopLoss.value }),
      },
      tradeMode,
      notPriceSyncedPLCalculation && position?.openPrice
        ? position.openPrice
        : priceLimitEnabled
        ? price
        : tradeMode === TradeMode.buy
        ? mask
        : mbid,
      lots,
    ) * profitRate
  const lossPercent = (+loss.toFixed(2) * 100) / requiredMarginAccount || 0

  return {
    ...{
      lots,
      value,
      valueAccount,
      requiredMargin,
      requiredMarginAccount,
      profitPercent,
      lossPercent,
      priceLimit,
      priceLimitEnabled,
      ...(takeProfitEnabled ? { profit } : {}),
      ...(stopLossEnabled ? { loss } : {}),
      ...(priceLimitEnabled ? { priceDifference, priceDifferencePercent } : {}),
    },
    ...(mode === 'init'
      ? {
          takeProfitInitial: tp,
          stopLossInitial: sl,
          priceLimitInitial,
          amount: {
            ...amount,
            value: amountInitial,
            fieldInformation: getAllFieldInfo({
              asset,
              field: 'amount',
              value: amountInitial,
              tradeMode,
              openPrice: price,
              isPendingOrder,
              formOrderType,
            }),
          },
          takeProfit: {
            ...takeProfit,
            value: tp,
            fieldInformation: getAllFieldInfo({
              asset,
              field: 'takeProfit',
              value: tp,
              tradeMode,
              openPrice: price,
              isPendingOrder,
              formOrderType,
            }),
          },
          stopLoss: {
            ...stopLoss,
            value: sl,
            fieldInformation: getAllFieldInfo({
              asset,
              field: 'stopLoss',
              value: sl,
              tradeMode,
              openPrice: price,
              isPendingOrder,
              formOrderType,
            }),
          },
          priceLimit: {
            ...priceLimit,
            value: priceLimitInitial,
            fieldInformation: getAllFieldInfo({
              asset,
              field: 'priceLimit',
              value: priceLimitInitial,
              tradeMode,
              openPrice: price,
              isPendingOrder,
              formOrderType,
            }),
          },
          stopPrice: {
            ...stopPrice,
            value: stopPriceInitial,
            fieldInformation: getAllFieldInfo({
              asset,
              field: 'stopPrice',
              value: stopPriceInitial,
              tradeMode,
              openPrice: price,
              isPendingOrder,
              formOrderType,
            }),
          },
          lots: lotsInitial,
          takeProfitEnabled: !!position?.tp,
          stopLossEnabled: !!position?.sl,
          placedOrderTradeMode,
        }
      : {
          takeProfitInitial: tp,
          stopLossInitial: sl,
          ...(position ? { priceLimitInitial: calcPendingPriceValue(position, asset, asset.currentRate) } : {}),
        }),
    isPendingOrder: isPendingOrder || priceLimitEnabled,
  }
}

export default [initialFormValuesEpic, updateFormValuesEpic, updateFieldAfterChangeTradeModeEpic]
