import React, { FC } from 'react'
import MuiDialogActions, { DialogActionsProps } from '@mui/material/DialogActions'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material'

import { isMobile } from '../../../utils'

const useStyles = makeStyles<Theme, { isMobileDevise?: boolean }>({
  actions: {
    display: 'flex',
    flexDirection: ({ isMobileDevise }) => (!isMobileDevise ? 'row' : 'column'),
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: ({ isMobileDevise }) => (!isMobileDevise ? '' : '100%'),
    padding: '0 1.6rem 1.7rem',
  },
})

export const DialogActions: FC<DialogActionsProps> = ({ children, ...rest }) => {
  const isMobileDevise = isMobile()
  const classes = useStyles({ isMobileDevise })

  return (
    <MuiDialogActions className={classes.actions} {...rest}>
      {children}
    </MuiDialogActions>
  )
}
