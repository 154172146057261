import { combineEpics } from 'redux-observable'

import accounts from './users'
import app from './app'
import mt4 from './mt4'
import orders from './orders'
import favourites from './favourites'
import form from './form'
import trade from './trade'
import guidedTour from './guidedTour'
import feed from './feed'
import { tradingViewSignalsEpics } from '../../components/Chart/signals/ChartSignal'

export const rootEpics = combineEpics(
  ...(accounts || []),
  ...(app || []),
  ...(mt4 || []),
  ...(orders || []),
  ...(favourites || []),
  ...(form || []),
  ...(trade || []),
  ...(guidedTour || []),
  ...(tradingViewSignalsEpics || []),
  ...(feed || []),
)
