import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'

import { TableContext } from '../../VirtualizedTable'
import { isCurrentAccountLeverageTypeDynamicSelector, mainCurrencySelector } from '../../../store/selectors'
import { currencyFormat, positiveNegativeFormat } from '../../../utils'

interface IProps {
  id: string
  investment: number
  leverage: number
  commission: number
  swaps: number
}

const useStyles = makeStyles({
  subHistory: {
    padding: '0 20px',
  },
  subLabel: {
    fontSize: 12,
  },
  subItem: {
    padding: '0 10px',
    fontSize: 14,
  },
})

const SubItem: FC<{ label: string; value: number | string }> = ({ children, value, label }) => {
  const classes = useStyles()
  return (
    <span className={classes.subItem} title={`${value}`}>
      <span className={classes.subLabel}>
        <Trans i18nKey={label} />:{' '}
      </span>
      {children}
    </span>
  )
}

export const HistorySubComponent: FC<IProps> = ({ id, investment, leverage, commission, swaps }) => {
  const classes = useStyles()
  const currency = useSelector(mainCurrencySelector)
  const isCurrentAccountLeverageTypeDynamic = useSelector(isCurrentAccountLeverageTypeDynamicSelector)
  const { expandedPositionsState } = useContext(TableContext)
  const active = expandedPositionsState[id]
  if (!active) return null

  return (
    <div className={classes.subHistory}>
      <SubItem label={'investment'} value={currencyFormat(investment, currency)}>
        {currencyFormat(investment, currency)}
      </SubItem>
      <SubItem label={'leverage'} value={leverage}>
        {isCurrentAccountLeverageTypeDynamic ? <Trans i18nKey="wtDynamic" defaults="Dynamic" /> : <>x{leverage}</>}
      </SubItem>
      <SubItem label={'commission'} value={positiveNegativeFormat(commission, currency)}>
        {positiveNegativeFormat(commission, currency)}
      </SubItem>
      <SubItem label={'swaps'} value={positiveNegativeFormat(swaps, currency)}>
        {positiveNegativeFormat(swaps, currency)}
      </SubItem>
    </div>
  )
}
