import { createAction } from 'typesafe-actions'

import { SymbolExposure } from '@bdswiss/mt4-connector'

import { RootState, FormFieldUnion } from '../types'
import { OrderTypes } from '../../enums'
import { FieldInfo } from '../reducers/form'

export const setSubmitEnabled = createAction('@form/setSubmitEnabled')<boolean>()
export const setTakeProfitEnabled = createAction('@form/setTakeProfitEnabled')<boolean>()
export const setStopLossEnabled = createAction('@form/setStopLossEnabled')<boolean>()
export const updateFormValues = createAction('@form/updateFormValues')<Partial<RootState['form']>>()

export const formInputChange = createAction('@form/inputChange')<{
  field: FormFieldUnion
  value: number
}>()

export const formInputBlur = createAction('@form/inputBlur')<{
  field: FormFieldUnion
  value: string
}>()

export const formChangeButtonClick = createAction('@form/changeButtonClick')<{
  action: 'increase' | 'decrease'
  field: FormFieldUnion
}>()

export const formChangeButtonPress = createAction('@form/changeButtonPress')<{
  action: 'increase' | 'decrease'
  field: FormFieldUnion
}>()

export const formChangeButtonRelease = createAction('@form/changeButtonRelease')<void>()

export const formUpdateField = createAction('@form/updateField')<{
  field: FormFieldUnion
  value: number
  fieldInformation?: FieldInfo
}>()

export const setSymbolExposure = createAction('@form/setSymbolExposure')<SymbolExposure>()
export const clearSymbolExposure = createAction('@form/clearSymbolExposure')()
export const setFormOrderStatus = createAction('@form/setFormOrderStatus')<OrderTypes>()

export const resetInputValue = createAction('@form/resetInputValue')<{
  key: 'takeProfit' | 'stopLoss'
  value: number
}>()
