import { createReducer } from 'typesafe-actions'

import { ActionTypes } from '../types'
import {
  getFavouritesRequest,
  getFavouritesSuccess,
  getFavouritesFailure,
  updateFavouritesSuccess,
  updateFavouritesRequest,
  updateFavouritesFailure,
} from '../actions'
import { LoadingState } from '../../enums'

export type GetFavouritesState = {
  status: LoadingState
  data: string[]
  error: Error | null
}

export const initialState = {
  status: LoadingState.IDLE,
  data: [],
  error: null,
}

export const favourites = createReducer<GetFavouritesState, ActionTypes>(initialState)
  .handleAction([getFavouritesRequest, updateFavouritesRequest], (state) => ({
    ...state,
    status: LoadingState.LOADING,
  }))
  .handleAction([getFavouritesSuccess, updateFavouritesSuccess], (state, { payload }) => ({
    ...state,
    status: LoadingState.SUCCESS,
    data: payload,
  }))
  .handleAction([getFavouritesFailure, updateFavouritesFailure], (state, { payload }) => ({
    ...state,
    status: LoadingState.ERROR,
    error: payload,
  }))
