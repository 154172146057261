import { createSelector } from 'reselect'

import { RootState } from '../types'
import { pricesSelector, tradeableAssetsSelector } from './prices'
import { activeAssetSelector } from './app'

export const getFavouritesSelector = (state: RootState): string[] => state.favourites.data

export const getFavouriteSelector = createSelector(
  getFavouritesSelector,
  (state: RootState, symbol: string) => symbol,
  (favourites, symbol) => favourites.includes(symbol),
)

export const isFavouriteSelector = createSelector(getFavouritesSelector, activeAssetSelector, (favourites, symbol) =>
  favourites.includes(symbol),
)

export const isCurrentSymbolFavouriteSelector = createSelector(
  pricesSelector,
  activeAssetSelector,
  getFavouritesSelector,
  (prices, activeAsset, favouritesAssets) => {
    return favouritesAssets.includes(prices[activeAsset]?.['label'])
  },
)
export const favouritesLengthSelector = createSelector(
  tradeableAssetsSelector,
  getFavouritesSelector,
  (prices, favouritesAssets) =>
    favouritesAssets.filter((symbol) => prices.find((symbolPrice) => symbolPrice.symbol === symbol)).length,
)
