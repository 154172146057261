import React, { FC, ReactChild, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Trans } from 'react-i18next'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import { OpenPositionTable } from '../../../Tables/OpenPositionTable'
import { PendingTable } from '../../../Tables/PendingTable'
import { downloadAppBannerSelector, selectedPositionSelector } from '../../../../store/selectors'
import { ModifyingForm } from '../../../Forms/ModifyingForm'
import {
  clearSelectedOpenedPosition,
  clearSelectedPendingPosition,
  setMobileBackButtonData,
  currentMobilePositionTab,
} from '../../../../store/actions'

const PositionsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  '&.bannerShown': {
    height: 'calc(100% - 56px)',
  },
})
const TabStyled = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  textTransform: 'none',
}))

export const Positions: FC = () => {
  const [currentTab, setCurrentTab] = useState<string>('open')
  const order = useSelector(selectedPositionSelector)?.order
  const bannerShown = useSelector(downloadAppBannerSelector)
  const dispatch = useDispatch()
  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string): void => {
      newValue && setCurrentTab(newValue)
      dispatch(currentMobilePositionTab(newValue))
    },
    [dispatch],
  )

  const tabs: { [key: string]: ReactChild } = {
    open: <OpenPositionTable />,
    pending: <PendingTable />,
  }

  const applyProps = (index: string) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  useEffect(() => {
    dispatch(setMobileBackButtonData(undefined))
    dispatch(clearSelectedOpenedPosition())
    dispatch(clearSelectedPendingPosition())
  }, [dispatch])

  if (order) return <ModifyingForm />

  return (
    <PositionsContainer className={clsx({ bannerShown })}>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        variant="fullWidth"
        textColor="inherit"
        indicatorColor="secondary"
        selectionFollowsFocus
      >
        <TabStyled
          value={'open'}
          data-tab={'open'}
          label={<Trans i18nKey={'open'} defaults={'Open'} />}
          {...applyProps('open')}
          aria-checked
        />
        <TabStyled
          value={'pending'}
          data-mode={'pending'}
          label={<Trans i18nKey={'pending'} defaults={'Pending'} />}
          {...applyProps('pending')}
        />
      </Tabs>
      {tabs[currentTab]}
    </PositionsContainer>
  )
}
