import React, { useState, useCallback, FC, ChangeEvent, FocusEvent, MouseEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import RemoveIcon from '@mui/icons-material/Remove'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'

import {
  formChangeButtonPress,
  formChangeButtonRelease,
  formChangeButtonClick,
  formInputBlur,
  formInputChange,
} from '../../../../store/actions'
import { numberUnformat } from '../../../../utils'
import { FormFieldUnion } from '../../../../store/types'
import { FieldInfo } from '../../../../store/reducers/form'

const useInputStyles = makeStyles(({ palette }) => {
  return {
    root: {
      width: '100%',
      '& .Mui-focused .MuiInput-input': {
        borderColor: '#007AFF',
      },
      '& .MuiInput-input': {
        background: palette.mode === 'light' ? '#fff' : '#000',
        border: palette.mode === 'light' ? `1px solid #EBEBEB` : `1px solid #474747`,
        padding: '5px 0',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: 1.35,
        letterSpacing: 0.4,
        borderRadius: 4,
      },
      '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        display: `none`,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `none`,
      },
    },
  }
})

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButtons: {
    position: 'absolute',
    zIndex: 5,
    borderRadius: 4,
    padding: 4,
    '& svg': {
      fontSize: 21,
    },
  },
  incrementButton: {
    color: '#00C78D',
    right: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  decrementButton: {
    color: '#ED1C24',
    left: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  error: {
    '& .MuiInput-input, & .Mui-focused .MuiInput-input': {
      border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.light}`,
    },
  },
  errorText: {
    textAlign: 'center',
    fontSize: 11,
    lineHeight: 1.25,
    display: 'block',
    color: '#ED1C24',
    letterSpacing: 0.4,
    margin: 0,
    marginTop: 8,
  },
  unitButtonWrap: {
    marginTop: 6,
    display: 'flex',
    justifyContent: 'center',
  },
  changeUnitsButton: {
    padding: 0,
    border: 'none',
    background: 'none',
    fontSize: 11,
    lineHeight: 1.25,
    letterSpacing: 0.4,
    color: '#007aff',
    cursor: 'pointer',
  },
  priceChange: {
    display: 'block',
    textAlign: 'center',
    marginTop: 2,
    letterSpacing: 1.3,
    fontSize: 10,
    lineHeight: 1.6,
    fontWeight: 500,
  },
  positive: { color: '#00C78D' },
  negative: { color: '#ED1C24' },
  pulseContractValue: {
    '&  .MuiInput-input': {
      color: '#007AFF',
    },
  },
  lotsWrap: {
    display: 'block',
    textAlign: 'center',
    fontSize: 11,
    lineHeight: 1.25,
    color: '#474747',
    marginTop: 6,
  },
}))

export const NumberInput: FC<{
  placeholder: string
  name: FormFieldUnion
  value: string
  fieldInformation: FieldInfo
  isAmountField?: boolean
  lots?: number
}> = ({
  placeholder,
  name,
  value,
  fieldInformation: { isValid, errorText, canIncrease, canDecrease },
  isAmountField,
  lots,
}) => {
  const [localValue, setLocalValue] = useState<string>('')
  const [editedNow, setEditedNow] = useState(false)
  const classes = useStyles()
  const inputClasses = useInputStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const focusInputHandle = useCallback(() => {
    setEditedNow(true)
    const numberValue = numberUnformat(localValue)
    setLocalValue(numberValue ? numberValue.toString() : '')
  }, [localValue])

  const actionOnChange = useCallback(
    (value: string) => {
      dispatch(
        formInputChange({
          field: name,
          value: numberUnformat(value),
        }),
      )
    },
    [dispatch, name],
  )

  useEffect(() => {
    if (!value) return
    if (editedNow) {
      const numberValue = numberUnformat(value)
      setLocalValue(numberValue.toString())
    } else {
      setLocalValue(value)
    }
  }, [editedNow, value])

  const blurInputHandle = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      actionOnChange(e.currentTarget.value)
      dispatch(
        formInputBlur({
          field: name,
          value,
        }),
      )
      value && setLocalValue(value)
      setEditedNow(false)
    },
    [actionOnChange, dispatch, name, value],
  )

  const changeButtonClickHandle = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const action = e.currentTarget.dataset.action as 'increase' | 'decrease'
      dispatch(
        formChangeButtonClick({
          field: name,
          action,
        }),
      )
    },
    [dispatch, name],
  )
  const changeButtonMouseDownHandle = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const action = e.currentTarget.dataset.action as 'increase' | 'decrease'
      dispatch(
        formChangeButtonPress({
          field: name,
          action,
        }),
      )
    },
    [dispatch, name],
  )
  const changeHandle = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const fieldValue = e.currentTarget.value.replace(/,/g, '.')
      if (!/^(0|[1-9]\d*)([.]?|[.]\d+)?$/g.test(fieldValue ? fieldValue : '0')) return
      if (/[.]0*$/g.test(fieldValue)) {
        if (!Number(fieldValue)) actionOnChange('0')
        setLocalValue(fieldValue)
      } else {
        fieldValue !== '' && actionOnChange(fieldValue)
        setLocalValue(fieldValue)
      }
    },
    [actionOnChange],
  )

  const changeButtonMouseUpLeaveHandle = useCallback(() => {
    dispatch(formChangeButtonRelease())
  }, [dispatch])

  return (
    <>
      <div className={classes.container}>
        <IconButton
          size={'small'}
          color={'secondary'}
          disabled={!canDecrease}
          onClick={changeButtonClickHandle}
          onMouseDown={changeButtonMouseDownHandle}
          onMouseUp={changeButtonMouseUpLeaveHandle}
          onMouseLeave={changeButtonMouseUpLeaveHandle}
          className={clsx(classes.actionButtons, classes.decrementButton)}
          data-action={'decrease'}
        >
          <RemoveIcon />
        </IconButton>

        <TextField
          name={name}
          variant={'standard'}
          value={localValue}
          className={clsx({
            [classes.error]: !isValid,
          })}
          classes={inputClasses}
          onChange={changeHandle}
          onFocus={focusInputHandle}
          onBlur={blurInputHandle}
          placeholder={t(placeholder)}
          autoComplete="off"
        />

        <IconButton
          size={'small'}
          disabled={!canIncrease}
          onClick={changeButtonClickHandle}
          onMouseDown={changeButtonMouseDownHandle}
          onMouseUp={changeButtonMouseUpLeaveHandle}
          onMouseLeave={changeButtonMouseUpLeaveHandle}
          className={clsx(classes.actionButtons, classes.incrementButton)}
          data-action={'increase'}
        >
          <AddIcon />
        </IconButton>
      </div>
      {!isValid && errorText && <p className={classes.errorText}>{errorText}</p>}
      {!!lots && (
        <div className={classes.lotsWrap}>
          (<Trans i18nKey="lots" />: {lots})
        </div>
      )}
      {isAmountField && (
        <div className={classes.unitButtonWrap}>
          <button className={classes.changeUnitsButton}>
            <Trans i18nKey="changeUnits" />
          </button>
        </div>
      )}
    </>
  )
}
