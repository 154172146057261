import React, { useCallback, FC, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'

import { LabelValueTooltip } from '../LabelValueTooltip'
import { StyledSwitch } from '../StyledSwitch'
import { resetInputValue, setStopLossEnabled } from '../../../../store/actions'
import {
  mainCurrencySelector,
  formStopLossEnabledSelector,
  initialFormValues,
  formLossSelector,
  formLossPercentSelector,
} from '../../../../store/selectors'
import StopLossInput from '../StopLossInput'
import { NumberInputLabel } from '../NumberInputLabel'
import { numberFormat, positiveNegativeFormat } from '../../../../utils'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
  },
  labelWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  value: {
    display: 'flex',
    textAlign: 'right',
    fontSize: 10,
    lineHeight: 1.4,
  },
  positive: { color: '#00AD7B' },
  negative: { color: '#D41920' },
}))

const StopLossCheckbox: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const slEnabled = useSelector(formStopLossEnabledSelector)
  const loss = useSelector(formLossSelector)
  const lossPercent = useSelector(formLossPercentSelector)
  const currency = useSelector(mainCurrencySelector)
  const initValue = useSelector(initialFormValues)

  const slEnableChangeHandle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setStopLossEnabled(e.target.checked))
      dispatch(resetInputValue({ key: 'stopLoss', value: initValue[1] as number }))
    },
    [dispatch, initValue],
  )

  const stopLoss = positiveNegativeFormat(loss, currency, {
    minimumFractionDigits: 2,
  })

  const stopLossPercent = numberFormat(lossPercent, {
    maximumFractionDigits: 2,
  })

  return (
    <div className={classes.container}>
      <div className={classes.labelWrap}>
        <LabelValueTooltip
          label={t('stopLoss')}
          tooltip={'helpStopLoss'}
          control={<StyledSwitch name={'slEnabled'} onChange={slEnableChangeHandle} checked={slEnabled} />}
        />

        {slEnabled && (
          <div
            className={clsx(classes.value, {
              [classes.positive]: loss >= 0,
              [classes.negative]: loss < 0,
            })}
          >
            {stopLoss} ({stopLossPercent}%)
          </div>
        )}
      </div>

      {slEnabled && (
        <div style={{ marginTop: -8 }}>
          <NumberInputLabel name="atPrice" unit={currency} />
          <StopLossInput />
        </div>
      )}
    </div>
  )
}

export default StopLossCheckbox
