import React, { useCallback, useContext } from 'react'
import { Trans } from 'react-i18next'
import { CellProps, Renderer } from 'react-table'
import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'

import { MarketState, TradeObject } from '@bdswiss/mt4-connector'
import { PendingOrdersTableContext } from './PendingTable'
import { getSymbolMarketStateSelector } from '../../../store/selectors'
import { RootState } from '../../../store/types'

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 14,
    margin: 4,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  disabledButton: {
    color: theme.palette.grey[500],
  },
}))

export const CloseActionCell: Renderer<CellProps<TradeObject, string>> = ({ row: { original } }) => {
  const classes = useStyles()
  const dialogOpen = useContext(PendingOrdersTableContext)
  const marketOpened =
    useSelector((state: RootState) => getSymbolMarketStateSelector(state, original.symbol)) === MarketState.Open
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      dialogOpen(original)
    },
    [dialogOpen, original],
  )

  return (
    <button
      className={marketOpened ? classes.button : `${classes.button} ${classes.disabledButton}`}
      onClick={handleClick}
      disabled={!marketOpened}
    >
      <CloseIcon className={classes.icon} />
      <Trans i18nKey={'close'} />
    </button>
  )
}
