import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import makeStyles from '@mui/styles/makeStyles'
import { Trans } from 'react-i18next'

import { VirtualizedTableProps } from './types'
import List from './VariableSizeList'
import { ReactComponent as NoItemsFoundIcon } from '../mobile/Pages/Markets/assets/noItems.svg'
import { isMobile } from '../../utils'
import { capitalize } from 'lodash'

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'inherit',
    flex: 1,
    height: '100%',
    '& .cell-favorite, .cell-favourite, .cell-fundamental-analysis, .leveraged-label': {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: '0 !important',
    },
  },
  noDataContainer: {
    position: 'relative',
    ...(isMobile() && {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 40,
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#707070' : '#999',
        width: 19.2,
        height: 19.2,
        marginTop: 2.4,
        marginLeft: 2.4,
      },
    }),
  },
  noDataText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 0,
    ...(isMobile()
      ? {
          marginRight: 4,
          fontSize: 16,
          lineHeight: '24px',
          position: 'unset',
          transform: 'none',
          color: theme.palette.mode === 'dark' ? '#707070' : '#999',
        }
      : {}),
  },
}))

// eslint-disable-next-line @typescript-eslint/ban-types
export const VirtualizedTable = <T extends object = {}>(props: VirtualizedTableProps<T>): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <AutoSizer>
        {({ width, height }) => {
          return props.data.length ? (
            <List height={height} width={width} {...props} />
          ) : (
            <div className={classes.noDataContainer} style={{ height, width }}>
              <p className={classes.noDataText}>
                <Trans
                  i18nKey={`${props.tableName ? `no${capitalize(props.tableName)}TableData` : 'noTableData'}`}
                  defaults={`${
                    props.tableName ? `You do not have ${props.tableName} positions` : 'No items to display'
                  }`}
                />
              </p>
              {isMobile() && <NoItemsFoundIcon />}
            </div>
          )
        }}
      </AutoSizer>
    </div>
  )
}
