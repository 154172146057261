import { RootState } from '../types'
import { TradeMode, TradeModeReason } from '../../enums'
import { TradingAccount } from '@bdswiss/mt4-connector'
import { createSelector } from 'reselect'
import { tradingFormPanelSelector } from './ui'

export const appInitializedSelector = (state: RootState): boolean => state.app.initialized

export const activeAssetSelector = (state: RootState): string => state.app.activeAsset
export const tradeModeSelector = (state: RootState): TradeMode => state.app.tradeMode
export const tradeModeReasonSelector = (state: RootState): TradeModeReason | undefined => state.app.tradeModeReason

export const loginSelector = (state: RootState): string | undefined =>
  state.app.login || state.app.loginInfo?.login.toString()

export const loginInfoSelector = (state: RootState): TradingAccount | undefined => state.app.loginInfo

export const assetFilterSelector = (state: RootState): RootState['app']['assetFilter'] => state.app.assetFilter

export const activeTabSelector = (state: RootState): RootState['app']['activeTab'] => state.app.activeTab

export const mainCurrencySelector = (state: RootState): string => state.app.mainCurrency

export const selectedOpenOrderSelector = (state: RootState): RootState['app']['selectedOpenedPosition'] =>
  state.app.selectedOpenedPosition

export const selectedPendingOrderSelector = (state: RootState): RootState['app']['selectedPendingPosition'] =>
  state.app.selectedPendingPosition

export const getActiveButtonSelector = createSelector(
  tradingFormPanelSelector,
  activeAssetSelector,
  tradeModeSelector,
  (state: RootState, currentButton: { symbol: string; tradeMode: TradeMode }) => currentButton,
  (tradingFormPanel, activeAsset, tradeMode, currentButton) =>
    activeAsset === currentButton.symbol && tradeMode === currentButton.tradeMode && tradingFormPanel,
)

export const overrideParamsSelector = (state: RootState): RootState['app']['overrideParams'] => state.app.overrideParams
