import { RootState, User } from '../types'
import get from 'lodash/get'

import { companies } from '@bdswiss/common-enums'

export const userStatusSelector = (state: RootState): string => state.user.status
export const userDataSelector = (state: RootState, field: keyof User): unknown => state.user.data[field]
export const userLocaleSelector = (state: RootState): string => state.user.data.locale || 'en'
export const userIdSelector = (state: RootState): number | undefined => state.user.data.id as number | undefined
export const userCompanySelector = (state: RootState): string =>
  state.app.overrideParams?.company || state.user.data.company || 'bdswiss'
export const userCompanyLabelSelector = (state: RootState): string =>
  get(companies, [state.user.data?.company || 'bdSwissMauritius', 'brandLabel'], 'BDSwiss')
export const userWhiteLabelSelector = (state: RootState): string => state.user.data.whiteLabel || ''
