import { createSelector } from 'reselect'
import { MarketState } from '@bdswiss/mt4-connector'
import { currentSymbolPriceSelector, pricesSelector } from '.'
import { MarketStateChanges, RootState } from '../types'

export const marketStateChangesSelector = (state: RootState): MarketStateChanges => state.marketStateChanges

export const marketOpenedSelector = createSelector(
  currentSymbolPriceSelector,
  marketStateChangesSelector,
  (currentPrice, changes) => {
    if (currentPrice) {
      const currentChanges = changes[currentPrice.symbol]

      if (currentChanges) {
        return currentChanges.state === MarketState.Open
      } else {
        return currentPrice.marketState === MarketState.Open
      }
    } else {
      return false
    }
  },
)

export const isMarketOpenedBySymbolSelector = createSelector(
  marketStateChangesSelector,
  pricesSelector,
  (state: RootState, symbol: string) => symbol,
  (changes, prices, symbol) => {
    if (symbol) {
      const currentChanges = changes[symbol]
      if (currentChanges) {
        return currentChanges.state === MarketState.Open
      } else {
        return prices[symbol].marketState === MarketState.Open
      }
    } else {
      return false
    }
  },
)
