import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Popover from '@mui/material/Popover'

import BentoMenuList from './BentoMenuList'
import { isMobile } from '../../utils'

interface Props {
  open: boolean
  onClose: () => void
  anchorEl: HTMLDivElement | null
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: isMobile() ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
    },
  },
  paper: {
    boxShadow: theme.palette.mode === 'dark' ? 'none' : '0px 6px 20px rgba(0, 0, 0, 0.12)',
    background: theme.palette.mode === 'dark' ? '#1F1F1F' : theme.palette.background.paper,
    borderRadius: 8,
  },
}))

const BentoMenuPopover: FC<Props> = ({ open, anchorEl, onClose }) => {
  const classes = useStyles()
  return (
    <Popover
      id={open ? 'bento-menu-popover' : undefined}
      classes={classes}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      BackdropProps={{ invisible: !isMobile() }}
      anchorOrigin={{
        vertical: 40,
        horizontal: -4,
      }}
    >
      <BentoMenuList onClose={onClose} />
    </Popover>
  )
}

export default BentoMenuPopover
