import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import HelpTooltip from '../../../TopMenu/HelpTooltip/HelpTooltip'
import {
  currentSymbolPriceSelector,
  isCurrentAccountLeverageTypeDynamicSelector,
  loginInfoSelector,
} from '../../../../store/selectors'

const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 6px',
  },
  leverageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  leverage: {
    fontSize: '0.8rem',
  },
  value: {
    fontWeight: 'bold',
    color: palette.mode === 'light' ? '#000' : '#EBEBEB',
  },
}))

export const AmountLeverage: FC = () => {
  const classes = useStyles()
  const currentPrice = useSelector(currentSymbolPriceSelector)
  const loginInfo = useSelector(loginInfoSelector)
  const isCurrentAccountLeverageTypeDynamic = useSelector(isCurrentAccountLeverageTypeDynamicSelector)

  if (!loginInfo || !currentPrice) return null

  const { marginMode, percentage } = currentPrice

  return (
    <div className={classes.container}>
      {/*<Typography className={classes.label} display={'inline'}>*/}
      {/*  <Trans i18nKey={'amount'} />: {contractType}*/}
      {/*</Typography>*/}
      <div className={classes.leverageContainer}>
        <Typography className={classes.leverage} display={'inline'} variant={'caption'}>
          <Trans i18nKey={'leverage'} />:{' '}
          {isCurrentAccountLeverageTypeDynamic ? (
            <Trans i18nKey={'wtDynamic'} defaults={'Dynamic'} />
          ) : (
            <span className={classes.value}>
              1:
              {marginMode === 'Forex' ? loginInfo.leverage : (100 / percentage).toFixed(0)}
            </span>
          )}
        </Typography>
        <HelpTooltip hint={<Trans i18nKey={'helpLeverage'} />} />
      </div>
    </div>
  )
}
