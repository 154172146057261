import { createAction, createAsyncAction } from 'typesafe-actions'
import { GraphQLError } from 'graphql'

export const {
  request: getFavouritesRequest,
  success: getFavouritesSuccess,
  failure: getFavouritesFailure,
} = createAsyncAction('@@favourites/get', '@@favourites/set', '@@favourites/error')<void, string[], GraphQLError>()

export const propagateFavouriteRequest = createAction('@@favourites/propagateUpdate')<string>()

export const {
  request: updateFavouritesRequest,
  success: updateFavouritesSuccess,
  failure: updateFavouritesFailure,
} = createAsyncAction('@@favourites/updateRequest', '@@favourites/updateSuccess', '@@favourites/updateError')<
  string,
  string[],
  GraphQLError
>()
