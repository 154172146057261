import { CellProps, Renderer } from 'react-table'
import clsx from 'clsx'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import { Trans } from 'react-i18next'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  direction: {
    display: 'flex',
    alignItems: 'center',
  },
  positive: {
    color: theme.palette.green.mint,
  },
  negative: {
    color: theme.palette.secondary.main,
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 4,
    fontSize: 14,
  },
}))

export const DirectionCell: Renderer<CellProps<Record<string, string>, string>> = ({ value }) => {
  const classes = useStyles()
  const positive = value === 'buy' || value === 'buy limit' || value === 'UP'
  return (
    <div
      className={clsx(
        {
          [classes.negative]: !positive,
          [classes.positive]: positive,
        },
        classes.direction,
      )}
    >
      {positive ? <CallMadeIcon className={classes.icon} /> : <CallReceivedIcon className={classes.icon} />}
      <Trans i18nKey={`${value?.toLowerCase()}`} />
    </div>
  )
}
