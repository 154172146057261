import { Theme, ThemeOptions, createTheme } from '@mui/material/styles'

import { darkTheme } from './dark'
import { lightTheme } from './light'

const themeMap: { [key: string]: ThemeOptions } = {
  Light: lightTheme,
  Dark: darkTheme,
}

const override: ThemeOptions = {
  components: {
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-focused': {
    //         '.MuiOutlinedInput-notchedOutline': {
    //           borderWidth: '0 0 1px 0',
    //         },
    //       },
    //     },
    //     notchedOutline: {
    //       borderWidth: '0 0 1px 0',
    //       borderRadius: 0,
    //       outline: 'none',
    //     },
    //   },
    // },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '6px 24px 7px 0',
          marginTop: 0,
          '&.MuiOutlinedInput-input': {
            paddingRight: 24,
          },
        },
        icon: {
          right: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            position: 'static',
            lineHeight: 1,
            transform: 'translate(0, 0) scale(0.75)',
          },
        },
      },
    },
  },
}

// We need to augment MUI theme to support more props
declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    green: {
      mint: string
    }
  }
  interface Palette {
    green: {
      mint: string
    }
  }
}

export const getThemeByName = (name: string): Theme => {
  return createTheme(themeMap[name], override)
}
