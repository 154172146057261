import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import { currentPriceValueSelector } from '../../../../store/selectors'
import { currencyFormat } from '../../../../utils'

const useStyles = makeStyles(({ palette }) => ({
  value: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.15,
    color: palette.mode === 'light' ? '#000' : '#fff',
    letterSpacing: 0.15,
  },
}))

const CurrentPrice: FC<{ label: string; digits: number }> = ({ label, digits }) => {
  const classes = useStyles()
  const value = useSelector(currentPriceValueSelector)
  return <span className={classes.value}>{currencyFormat(value, label, { minimumFractionDigits: digits })}</span>
}

export default CurrentPrice
