import React, { FC, ReactNode } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CloseButton } from '../../CloseButton'

export interface ITitleTopPanelProps {
  title: ReactNode
  hideCloseButton?: boolean
}

const useStyle = makeStyles(() => ({
  container: {
    padding: '12px 16px',
    fontSize: '1.143rem',
  },
}))

export const TitleTopPanel: FC<ITitleTopPanelProps> = ({ title, hideCloseButton }) => {
  const classes = useStyle()

  return (
    <Grid
      item
      className={classes.container}
      container
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
    >
      <Grid item>{title}</Grid>
      {!hideCloseButton && <CloseButton />}
    </Grid>
  )
}
